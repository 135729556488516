"use client";

import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useContext,
} from "react";
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Title } from "chart.js";
import { ReconXContext } from "context/ReconX/ReconXContext";

ChartJS.register(ArcElement, Tooltip, Title);

export default function PieChart({
  chartData = [],
  label = "",
  value = "",
  title,
  sortKey,
  enableSorting = false,
  selectedPeriod,
  source = "",
}) {
  const [selectedSorting, setSelectedSorting] = useState("Descending");
  const chartRef = useRef(null);
  const navigate = useNavigate();
  const ReconXContextData = useContext(ReconXContext);

  const generateColors = useCallback((count) => {
    const baseColors = [
      "rgb(255, 99, 132)", // Red-Orange
      "rgb(255, 159, 64)", // Orange
      "rgb(255, 205, 86)", // Yellow
      "rgb(75, 192, 192)", // Green
      "rgb(54, 162, 235)", // Blue
      "rgb(153, 102, 255)", // Purple
      "rgb(255, 99, 71)", // Pink
    ];

    const mixColors = (color1, color2, weight) => {
      const w1 = weight;
      const w2 = 1 - w1;
      const rgb1 = color1.match(/\d+/g).map(Number);
      const rgb2 = color2.match(/\d+/g).map(Number);
      const mixed = rgb1.map((c, i) => Math.round(c * w1 + rgb2[i] * w2));
      return `rgb(${mixed.join(",")})`;
    };

    return Array.from({ length: count }, (_, i) => {
      let color;
      if (i < baseColors.length) {
        color = baseColors[i];
      } else {
        const index1 = i % baseColors.length;
        const index2 = (i + 1) % baseColors.length;
        const weight = i % 2 === 0 ? 0.7 : 0.3;
        color = mixColors(baseColors[index1], baseColors[index2], weight);
      }

      return color.replace("rgb", "rgba").replace(")", ", 0.5)");
    });
  }, []);

  const sortedData = useMemo(() => {
    if (!chartData || chartData.length === 0) return [];
    if (!enableSorting) return chartData;
    return [...chartData].sort((a, b) => {
      if (selectedSorting === "Ascending") {
        return a[sortKey] - b[sortKey];
      } else {
        return b[sortKey] - a[sortKey];
      }
    });
  }, [chartData, sortKey, selectedSorting, enableSorting]);

  // Generate colors based on sortedData length
  const backgroundColors = useMemo(
    () => generateColors(sortedData.length),
    [sortedData.length, generateColors]
  );

  const data = useMemo(() => {
    const labels = sortedData.map((item) => item[label]);
    const dataValues = sortedData.map((item) => item[value]);

    return {
      labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor: sortedData.map(
            (_, index) => backgroundColors[index]
          ),
          borderColor: sortedData.map((_, index) =>
            backgroundColors[index].replace("0.5", "1")
          ),
          borderWidth: 1,
        },
      ],
    };
  }, [sortedData, label, value, backgroundColors]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "65%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          family: "Poppins",
        },
        bodyFont: {
          family: "Poppins",
        },
      },
      gridlines: {
        id: "gridlines",
        beforeDraw: (chart) => {
          const { ctx, chartArea } = chart;
          const { top, bottom, left, right } = chartArea;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const radius = Math.min(right - left, bottom - top) / 2;

          ctx.save();
          ctx.beginPath();
          ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
          ctx.lineWidth = 1;

          for (let i = 1; i <= 3; i++) {
            ctx.beginPath();
            ctx.arc(centerX, centerY, (radius / 4) * i, 0, 2 * Math.PI);
            ctx.stroke();
          }

          ctx.restore();
        },
      },
    },
  };

  // Add the click handler
  const handleClick = useCallback(
    (event) => {
      const chart = chartRef.current;
      if (!title) return;
      if (!chart) return;

      const elements = chart.getElementsAtEventForMode(
        event,
        "nearest",
        { intersect: true },
        true
      );

      if (elements.length > 0) {
        const element = elements[0];
        const dataIndex = element.index;
        const dataItem = sortedData[dataIndex];
        const { reconProcessId, recordStatus, dataSetId } = dataItem;
        const period = selectedPeriod.tenorPeriodVal;
        const payload = {
          selectedReconStatus: recordStatus,
        };
        ReconXContextData.saveReconProcessesData(payload);
        if (source === "ReconX") {
          navigate(
            `/saya-platform/saya-reconx/1-way?id=${reconProcessId}&period=${period}`,
            { state: { selectedPeriod, reconStatus: recordStatus } }
          );
        } else if (source === "DataX") {
          navigate(
            `/saya-platform/datax?dataSetId=${dataSetId}&period=${period}`,
            { state: { selectedPeriod } }
          );
        }
      }
    },
    [sortedData]
  );

  const CustomLegend = ({ data, type }) => (
    <div
      className={`${
        type === "Open status records per ops process" ? "h-[120px]" : ""
      } w-full overflow-y-auto relative z-[9] top-4`}
    >
      <div className="flex flex-wrap gap-2 p-2 justify-center">
        {data.labels.map((label, index) => (
          <div key={index} className="flex items-center">
            <div
              className="w-2.5 h-2.5 mr-2 rounded-lg"
              style={{
                backgroundColor: data.datasets[0].backgroundColor[index],
              }}
            />
            <span className="text-xs">{label}</span>
          </div>
        ))}
      </div>
    </div>
  );

  if (chartData.length === 0) {
    return (
      <div className="w-full h-[400px] bg-white rounded-lg shadow-md flex items-center justify-center">
        <p className="text-gray-500 text-sm">
          No data available for the selected criteria.
        </p>
      </div>
    );
  }
  console.log("======>>>1");
  return (
    <div className="w-full h-[420px] bg-white rounded-lg shadow-md overflow-hidden">
      <div className="px-6 py-3.5 border-b">
        <h2 className="leading-4 sm:leading-normal text-sm font-medium text-gray-800">
          {title}
        </h2>
      </div>
      <div
        className={`h-[calc(100%-4rem)] p-4 relative ${
          title === "Open status records per ops process" ? "" : "pt-[50px]"
        }`}
      >
        <div className="absolute inset-0 z-0 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:4rem_4rem]" />
        <div className="relative z-[1] h-[220px]">
          {/* Add the onClick prop to the Doughnut component */}
          <Doughnut
            data={data}
            options={options}
            ref={chartRef}
            onClick={handleClick}
          />
        </div>
        <CustomLegend data={data} type={title} />
      </div>
    </div>
  );
}
