import {
  defaultFont,
  container,
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
export default function footerStyle() {
  const theme = useTheme();
  return {
    block: {
      color: "inherit",
      padding: "15px",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block",
      ...defaultFont,
      fontWeight: "500",
      fontSize: "12px",
    },
    left: {
      float: "left!important",
      display: "block",
    },
    right: {
      padding: "5px 0",
      margin: "0 16px 0px 0",
      fontSize: "14px",
      float: "right !important",
      width: "97.7%",
      textAlign: "right",
      borderTop: "1px solid #dddddd",
    },
    footer: {
      bottom: "0",
      //borderTop: "1px solid " + alpha(theme.palette.text.primary,0.2),
      padding: "0px 0",
      ...defaultFont,
    },
    container,
    a: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      fontWeight: "400",
      backgroundColor: "transparent",
      "&:hover": {
        color: theme.palette.text.primary,
        textDecoration: "underline",
      },
    },
    list: {
      marginBottom: "0",
      padding: "0",
      marginTop: "0",
    },
    inlineBlock: {
      display: "inline-block",
      padding: "0px",
      width: "auto",
    },
    leftText: {
      color: theme.palette.text.primary,
      fontSize: "14px",
    },
  };
}
