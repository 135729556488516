import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";

import { useTheme } from "@mui/material/styles";

export default function dashboardStyle() {
  const theme = useTheme();

  return {
    "@global": {
      ".ct-series-a .ct-point,.ct-series-a .ct-line, .ct-series-a .ct-bar,  .ct-series-a .ct-slice-donut":
        {
          stroke: theme.palette.primary.main,
        },
        ".chartist-tooltip":{
          background: "rgba(" + hexToRgb(theme.palette.primary.main) + ", 0.25)",
          color: theme.palette.primary.main
        },
        ".chartist-tooltip:before":{
          borderTopColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", 0.25)",
        }
    },
    successText: {
      color: successColor[0],
    },
    upArrowCardCategory: {
      width: "16px",
      height: "16px",
    },
    stats: {
      display: "inline-flex",
      color: theme.palette.text.primary,
      fontSize: "12px",
      lineHeight: "22px",
      alignItems: "baseline",
      [theme.breakpoints.up("lg")]: {
        // iphone
        whiteSpace: "nowrap",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 11,
        lineHeight: "16px",
      },
      "& svg": {
        top: "4px",
        width: "16px",
        height: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px",
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        top: "4px",
        fontSize: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px",
      },
    },
    cardCategory: {
      color: theme.palette.text.primary,
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      paddingTop: "10px",
      marginBottom: "0",
      [theme.breakpoints.down("md")]: {
        paddingTop: "0px",
      },
    },
    cardCategoryWhite: {
      color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitle: {
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      marginBottom: "3px",
      textDecoration: "none",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("lg")]: {
        zoom: 0.8,
      },
      [theme.breakpoints.down("md")]: {
        zoom: 1,
      },
      "& small": {
        //color: "rgba(" + hexToRgb(theme.palette.text.primary) + ",.7)",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    cardTitleWhite: {
      color: whiteColor,
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      paddingLeft: "15px",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: grayColor[1],
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    analytixCardBody: {
      minHeight: 500,
      "& iframe": {
        border: `1px solid ${grayColor[4]}`,
        borderRadius: 5,
      },
    },
    pinkVariant: {
      "& > a > div:first-child > div": {
        background: theme.palette.iconsBg.pink,
        //border: '1px solid '+ "rgba(" + hexToRgb(theme.palette.icons.pink) + ",.4)",
        boxShadow: "none",
        "& .material-icons, svg": {
          color: theme.palette.icons.pink,
          fill: theme.palette.icons.pink,
          stroke: theme.palette.icons.pink,
        },
      },
    },
    greenVariant: {
      "& > div:first-child > div": {
        height: '72px',
        background: theme.palette.iconsBg.green,
        //border: '1px solid '+ "rgba(" + hexToRgb(theme.palette.icons.green) + ",.4)",
        boxShadow: "none",
        "& svg": {
          margin: 0,
          "& g text": {
            display: 'none'
          }
        },
        "& .material-icons, svg": {
          color: theme.palette.icons.green,
          fill: theme.palette.icons.green,
          stroke: theme.palette.icons.green,
        },
      },
    },
    blueVariant: {
      "& > div:first-child > div": {
        background: theme.palette.iconsBg.blue,
        //border: '1px solid '+ "rgba(" + hexToRgb(theme.palette.icons.blue) + ",.4)",
        boxShadow: "none",
        "& .material-icons, svg": {
          color: theme.palette.icons.blue,
          fill: theme.palette.icons.blue,
          stroke: theme.palette.icons.blue,
        },
      },
    },
    yellowVariant: {
      "& > div:first-child > div": {
        background: theme.palette.iconsBg.yellow,
        //border: '1px solid '+ "rgba(" + hexToRgb(theme.palette.icons.yellow) + ",.4)",
        boxShadow: "none",
        "& .material-icons, svg": {
          color: theme.palette.icons.yellow,
          fill: theme.palette.icons.yellow,
          stroke: theme.palette.icons.yellow,
        },
      },
    },
    iconImage: {
      width: 25,
    },
    adminCardTitle: {
      [theme.breakpoints.down("lg")]: {
        position: "absolute",
        right: 0,
        bottom: 0,
      },
    },
    switchControl: {
      "& label span:last-child": {
          fontSize: '13px',
          color: '#637381',
      }
    }
  };
}
