import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import ReactDOM from "react-dom";
import Button from "@mui/material/Button";
import CustomSwitch from "../../components/Switch/CustomSwitch";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import BrightnessAutoOutlinedIcon from "@mui/icons-material/BrightnessAutoOutlined";
import {
  Box,
  FormControl,
  TextareaAutosize,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import Snackbar from "../../components/Snackbar/Snackbar";
import {
  KeyboardBackspace,
  CloudUploadOutlined,
  AppRegistrationOutlined,
  LayersClearOutlined,
  FactCheckOutlined,
  PublishedWithChangesOutlined,
  AddLinkOutlined,
  CloudUpload,
  AddToQueue,
  BlurLinear,
  Label,
} from "@mui/icons-material";
import LyricsOutlinedIcon from "@mui/icons-material/LyricsOutlined";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@mui/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext";
import { ReconXContext } from "context/ReconX/ReconXContext";
import { CheckAccess } from "components/Permissions/CheckAccess";
import {
  showAlertNotification,
  showFailureNotification,
  showSuccessNotification,
} from "action-handlers/NotificationsHandler";
import {
  triggerReconOptimizerJob,
  triggerReconciliationJob,
  triggerAutonomousToleranceCalibrator,
} from "action-handlers/ReconXActionHandler";
import {
  API_RECON_OPTIMIZE_SUBMITTED,
  API_RECON_OPTIMIZE_SUBMITTED_SUCCESS,
  API_RECON_OPTIMIZE_FAILED,
  API_RECON_SUBMITTED,
  API_RECON_SUCCESS,
  API_RECON_FAILED,
  ATC_SUBMITTED_SUCCESS,
  ATC_FAILED,
  ATC_SUBMITTED,
} from "messages/DashboardMessages";
import { notificationParams } from "variables/general";
import styles from "./ReconStyles";
import Overlay from "components/Overlay/Overlay";
import { sendToResolveX } from "action-handlers/ReconXActionHandler";
import { API_SENDTORESOLVEX_SUCCESS } from "messages/DashboardMessages";
import { API_SENDTORESOLVEX_FAILED } from "messages/DashboardMessages";
import { triggerLinkRecords } from "action-handlers/ReconXActionHandler";
import { API_LINK_SUCCESS } from "messages/DashboardMessages";
import { API_LINK_FAILURE } from "messages/DashboardMessages";
import { triggerDelinkRecords } from "action-handlers/ReconXActionHandler";
import { API_DELINK_SUCCESS } from "messages/DashboardMessages";
import { API_DELINK_FAILURE } from "messages/DashboardMessages";
import { downloadRowsData } from "action-handlers/ReconXActionHandler";
import { fetchRulesData } from "action-handlers/ReconXActionHandler";
import { fetchMetaDataSrc } from "action-handlers/ReconXActionHandler";
import { API_FETCH_RULEDTO_FAILURE } from "messages/DashboardMessages";
import SayaReconRuleDialog from "components/CustomDialog/SayaReconRuleDialog";
import { saveOrModifyRule } from "action-handlers/ReconXActionHandler";
import { MULTI_MATCH_EXCEPTION } from "messages/DashboardMessages";
import { API_SAVE_MODIFY_RULE_SUCCESS } from "messages/DashboardMessages";
import TextToSpeech from "components/tts/TextToSpeech";
import SayaEthics from "components/CustomDialog/SayaEthics";
import { useTheme } from "@mui/material/styles";
import { getAIEthics } from "action-handlers/ReconXActionHandler";
import { fetchmappedFlowUnits } from "action-handlers/ReconXActionHandler";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";
import ToggleSwitch from "components/Switch/CssCustomSwitch";
import ReconXUpload from "./ReconXUpload";

function ReconXOptions(props) {
  const theme = useTheme();
  const [isReconOptimizerRunning, setReconOptimizerRunning] = useState(false);
  const [isToleranceCalibratorRunning, setToleranceCalibratorRunning] =
    useState(false);
  const [tts, setTTS] = useState({ text: "", isEnable: false });
  const [isReconcileRunning, setReconcileRunning] = useState(false);
  const [showOverlay, setOverlay] = useState(false);
  const [userNotes, setUserNotes] = useState("");
  const [showRuleWindow, setShowRuleWindow] = useState(false);
  const [rulesData, setRulesData] = useState([]);
  const [rulesHeader, setRulesHeader] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const selectedItem = useRef({ ele: "" });
  const [actionEl, setActionEl] = React.useState(null);
  const [mappedWorkflows, setMappedWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState({});
  const actionOpen = Boolean(actionEl);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const open = Boolean(anchorEl);

  const handleActionMenuClick = (event) => {
    setActionEl(event.currentTarget);
  };
  const handleActionMenuClose = () => {
    setActionEl(null);
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsSwitchOn(props.value);
  }, [props.value]);

  const handleToggle = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  useEffect(() => {
    setFetchSuggestedMatches(false);
  }, []);

  const {
    showFormObject,
    delinkButtonClicked,
    linkButtonClicked,
    setLinkButtonClicked,
    setDelinkButtonClicked,
    sentToResolveX,
    workflowEnabledReconUnit,
    reconStatus,
    rowDetailFormObject,
    reconUnitData,
    tableLinksData,
    setTableLinksData,
    setTableLinksColumns,
    validateDataSourceMetaData,
    pagination,
    tableLinksData2,
    setTableLinksData2,
    setTableLinksColumns2,
    auditInfoHandler,
    pageDetails,
    setFetchSuggestedMatches,
    showATC,
    setShowATC,
    setFetchData,
  } = props;

  const UserAuthContext = useContext(UserAuthDetailsContext);
  const { roleId, userId, loginName } = UserAuthContext.state;

  const ReconXContextData = useContext(ReconXContext);
  const {
    selectedReconUnit,
    selectedReconStatus,
    tenorPeriod,
    selectedRecData,
    globalMetaDataSrcColNames,
    globalMetaDataSrcColIds,
    globalDataSrcData,
    selectedReconUnitName,
    globalDropDownData,
    globalReconColumns,
  } = ReconXContextData.state;

  const gmd = JSON.parse(JSON.stringify(globalMetaDataSrcColNames));
  const gmc = JSON.parse(JSON.stringify(globalMetaDataSrcColIds));

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  let ruleHeaderData = [];
  let ruleDataValues = [];

  const ruleTableOptions = {
    pageSizeOptions: [20, 30, 50],
    pageSize: 10,
    doubleHorizontalScroll: false,
    search: true,
    showtitle: true,
    //headerStyle: { backgroundColor: '#1F2540',color: '#FFF'},
    headerStyle: {
      backgroundColor: "#e3e3e3",
      color: "#000000",
    },
    loadingType: "linear",
    //fixedColumns: {left:2, right:0},

    toolbar: true,
  };

  useEffect(() => {
    if (selectedReconUnit) {
      fetchmappedFlowUnits(selectedReconUnit, (res) => {
        if (res?.length) {
          const updatedRes = res.map((r) => {
            return {
              label: r.flowDefnName,
              value: r.flowDefnKeyName,
            };
          });
          setMappedWorkflows(updatedRes);
          setSelectedWorkflow(updatedRes[0]);
        } else {
          setMappedWorkflows([]);
          setSelectedWorkflow({});
        }
      });
    }
  }, [selectedReconUnit]);

  const handleWorkflow = (e) => {
    const res = mappedWorkflows.filter(
      (mwf) => +mwf.value === +e.target.value
    )[0];
    setSelectedWorkflow(res);
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const callbackReconOptimizer = (apiStatus) => {
    if (apiStatus) {
      showSuccessNotification(
        API_RECON_OPTIMIZE_SUBMITTED_SUCCESS,
        callbackNotification
      );
    } else {
      showFailureNotification(API_RECON_OPTIMIZE_FAILED, callbackNotification);
    }
    setReconOptimizerRunning(false);
  };

  const triggerReconOptimizer = () => {
    setReconOptimizerRunning(true);
    showAlertNotification(API_RECON_OPTIMIZE_SUBMITTED, callbackNotification);
    triggerReconOptimizerJob(
      selectedReconUnit,
      userId,
      loginName,
      callbackReconOptimizer
    );
  };

  const callbackReconciliation = (apiStatus) => {
    if (apiStatus) {
      showSuccessNotification(API_RECON_SUCCESS, callbackNotification);
      //need to check and handle below 2 lines deepu
      // ruleUnlabelledDTO = [];
      // setLabelledRuleDTO([]);
      props.setCount(props.count + 1);
    } else {
      showFailureNotification(API_RECON_FAILED, callbackNotification);
    }
    setReconcileRunning(false);
  };

  const triggerReconciliation = () => {
    setReconcileRunning(true);
    showAlertNotification(API_RECON_SUBMITTED, callbackNotification);
    triggerReconciliationJob(selectedReconUnit, callbackReconciliation);
  };

  const callbackATC = (res) => {
    if (res.responseStatus === "SUCCESS") {
      showSuccessNotification(res.responseMessage, callbackNotification);
    } else if (res.responseStatus === "FAILURE") {
      showFailureNotification(res.responseMessage, callbackNotification);
    } else {
      showFailureNotification(
        "Something went wrong!! Please contact Administrator",
        callbackNotification
      );
    }
    setToleranceCalibratorRunning(false);
  };

  const triggerATC = () => {
    showAlertNotification(ATC_SUBMITTED, callbackNotification);
    setToleranceCalibratorRunning(true);
    triggerAutonomousToleranceCalibrator(
      selectedReconUnit,
      userId,
      loginName,
      callbackATC
    );
  };

  const triggerATCDetails = () => {
    getAIEthics(selectedReconUnit, userId, loginName, (res) => {
      setTTS({
        ...tts,
        isEnable: true,
        text: res.responseMessage,
      });
    });
  };

  const hideATCDetailsHandler = () => {
    setTTS({
      ...tts,
      isEnable: false,
    });
  };

  const boolenStyles = (bool) => {
    return {
      border: `1px solid ${bool === 1 ? "#bbe19e" : "#f7d2d2"}`,
      padding: "3px 10px",
      background: bool === 1 ? "#edf9eb" : "#ffefef",
      borderRadius: "5px",
      fontSize: "13px",
      textTransform: "capitalize",
      color: bool === 1 ? "#7acd5a" : "#ff9f9f",
      textAlign: "center",
      display: "inline-block",
      minWidth: "54px",
    };
  };

  const handleRuleTransformation = (
    globalMetaDataSrcColNames,
    globalMetaDataSrcColIds
  ) => {
    const dataTypes = ["STRING", "NUMERIC", "DATE"];
    let Column1_DropDownList =
      globalMetaDataSrcColNames[globalDataSrcData[0].value]; //all the values possible from data source 1 metadata
    let Column2_DropDownList =
      globalMetaDataSrcColNames[globalDataSrcData[1].value]; //all the values possible from data source 2 metadata

    let booleanDropDownList = [false, true];
    let booleanDropDownViewList = ["false", "true"];
    //these are hardcoded for now .. based on the server object recieved. Here the first 2 columns in rule
    // source1 and source 2 column names which we are allowing to modify. Immediately it follwed by col for multumatch
    // that is also modifiable, and 9th in the column list is hex score true or false
    ruleHeaderData[0].lookup = Column1_DropDownList;
    ruleHeaderData[1].lookup = Column2_DropDownList;
    ruleHeaderData[0].editable = "always";
    ruleHeaderData[1].editable = "always";

    ruleHeaderData[2].lookup = booleanDropDownViewList;
    ruleHeaderData[8].lookup = booleanDropDownViewList;
    //ruleHeaderData[2].editable = "always";
    // ruleHeaderData[8].editable = "always";
    ruleHeaderData[2].editable =
      globalDropDownData.filter((dd) => dd.value === selectedReconUnit)[0]
        ?.multimatchReqd === true
        ? "always"
        : "never";
    ruleHeaderData[8].editable =
      globalDropDownData.filter((dd) => dd.value === selectedReconUnit)[0]
        ?.mlEnabled === true
        ? "always"
        : "never";
    if (ruleHeaderData[2].editable === "always") {
      ruleHeaderData[2].editComponent = (props) => (
        <ToggleSwitch
          isOn={props.value === 1}
          handleToggle={(event) => props.onChange(event.target.checked ? 1 : 0)}
          value={props.value}
        />
        //<ToggleSwitch isOn={isSwitchOn} handleToggle={handleToggle} />

        // <CustomSwitch
        //   id={`mlenabled-${props.rowData.id}`}
        //   handleToggle={(event) => props.onChange(event.target.checked ? 1 : 0)}
        //   value={props.value}
        //   checked={props.value}
        //   isDisabled={false}
        // />
      );
    } else {
      ruleHeaderData[2].editComponent = null;
    }
    if (ruleHeaderData[8].editable === "always") {
      ruleHeaderData[8].editComponent = (props) => (
        <ToggleSwitch
          isOn={props.value === 1}
          handleToggle={(event) => props.onChange(event.target.checked ? 1 : 0)}
          value={props.value}
        />
        // <CustomSwitch
        //   id={`multimatchReqd-${props.rowData.id}`}
        //   handleToggle={(event) => props.onChange(event.target.checked ? 1 : 0)}
        //   value={props.value}
        //   checked={props.value}
        //   isDisabled={false}
        // />
      );
    } else {
      ruleHeaderData[8].editComponent = null;
    }
    ruleHeaderData[0].showInUi = true;
    ruleHeaderData[1].showInUi = true;
    ruleHeaderData[2].showInUi = true;
    ruleHeaderData[8].showInUi = true;

    ruleHeaderData[10].showInUi = true;
    ruleHeaderData[11].showInUi = true;
    ruleHeaderData[12].showInUi = true;
    ruleHeaderData[13].showInUi = true;

    ruleHeaderData[10].lookup = booleanDropDownViewList;
    ruleHeaderData[12].lookup = dataTypes;

    ruleHeaderData[10].editable =
      globalDropDownData.filter((dd) => dd.value === selectedReconUnit)[0]
        ?.mlEnabled === true
        ? "always"
        : "never";
    if (ruleHeaderData[10].editable === "always") {
      ruleHeaderData[10].editComponent = (props) => (
        <ToggleSwitch
          isOn={props.value === 1}
          handleToggle={(event) => props.onChange(event.target.checked ? 1 : 0)}
          value={props.value}
        />
        // <CustomSwitch
        //   id={`autonomousTolerance-${props.rowData.id}`}
        //   handleToggle={(event) => props.onChange(event.target.checked ? 1 : 0)}
        //   value={props.value}
        //   checked={props.value}
        //   isDisabled={false}
        // />
      );
    } else {
      ruleHeaderData[10].editComponent = null;
    }

    ruleHeaderData[10].render = (rowData) => (
      <span style={boolenStyles(rowData?.autonomousTolerance)}>
        {rowData?.autonomousTolerance === 1 ? "true" : "false"}
      </span>
    );
    ruleHeaderData[8].render = (rowData) => (
      <span style={boolenStyles(rowData?.hexScoreUse)}>
        {rowData?.hexScoreUse === 1 ? "true" : "false"}
      </span>
    );
    ruleHeaderData[2].render = (rowData) => (
      <span style={boolenStyles(rowData?.colForMultiMatch)}>
        {rowData?.colForMultiMatch === 1 ? "true" : "false"}
      </span>
    );
    ruleHeaderData[11].editable = "always";
    ruleHeaderData[12].editable = "always";

    ruleHeaderData[0].titleToShow = globalDataSrcData[0]?.label;
    ruleHeaderData[1].titleToShow = globalDataSrcData[1]?.label;
    ruleHeaderData[2].titleToShow = "Multi Match";
    ruleHeaderData[8].titleToShow = "ML Enabled";

    // let i = 0;
    let data = [];
    data = ruleDataValues;
    //setting the data for column1 and column2 ellements in the drop down
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < Column1_DropDownList.length; j++) {
        if (data[i].columnIdSrc1 === Column1_DropDownList[j]) {
          data[i].columnIdSrc1 = j;
          j = Column1_DropDownList.length + 1;
        }
      }
      for (let k = 0; k < Column2_DropDownList.length; k++) {
        if (data[i].columnIdSrc2 === Column2_DropDownList[k]) {
          data[i].columnIdSrc2 = k;
          k = Column1_DropDownList.length + 1;
        }
      }

      if (
        data[i].colForMultiMatch === booleanDropDownList[0] ||
        data[i].colForMultiMatch === 0
      ) {
        data[i].colForMultiMatch = 0;
      } else {
        data[i].colForMultiMatch = 1; //setting to index 1
      }

      if (
        data[i].hexScoreUse === booleanDropDownList[0] ||
        data[i].hexScoreUse === 0
      ) {
        data[i].hexScoreUse = 0;
      } else {
        data[i].hexScoreUse = 1; //setting to index 1
      }

      data[i].dataType =
        dataTypes.indexOf(data[i].dataType) === -1
          ? data[i].dataType
          : dataTypes.indexOf(data[i].dataType);
      data[i].toleranceValue = data[i].toleranceValue;
      data[i].autonomousTolerance =
        data[i].autonomousTolerance === false ||
        data[i].autonomousTolerance === 0
          ? 0
          : 1;
    }

    setRulesData(data);

    setRulesHeader(ruleHeaderData);
  };

  const callbackFetchRules = (
    metaDataSrcColNames,
    metaDataSrcColIds,
    dataSourceValue,
    selectedReconUnit,
    dummy,
    apiStatus
  ) => {
    gmd[dataSourceValue] = metaDataSrcColNames;
    gmc[dataSourceValue] = metaDataSrcColIds;

    const payload = {
      globalMetaDataSrcColNames: gmd,
      globalMetaDataSrcColIds: gmc,
    };
    ReconXContextData.saveReconProcessesData(payload);
    if (validateDataSourceMetaData(gmd, gmc)) {
      //here we need to populate the ruleDTO which is having the business Names instead of technical columns in the DTO
      handleRuleTransformation(gmd, gmc);
    } else {
      console.log("Validation meta datasource populated:::-FAILED");
    }
  };

  const callbackFetchRulesData = (headerData, ruleDataFetched, apiStatus) => {
    ruleHeaderData = [...rulesHeader];
    ruleDataValues = [...rulesData];
    ruleHeaderData = headerData;
    ruleDataValues = ruleDataFetched;
    if (apiStatus) {
      //ensure we only call the rest API if the global MetaDataSrcColNames are null or without any elements
      if (
        globalMetaDataSrcColNames[globalDataSrcData[0].value] === undefined ||
        globalMetaDataSrcColNames[globalDataSrcData[0].value].length === 0
      ) {
        fetchMetaDataSrc(
          selectedReconUnit,
          globalDataSrcData[0].value,
          [],
          callbackFetchRules
        );
      } else {
        handleRuleTransformation(
          globalMetaDataSrcColNames,
          globalMetaDataSrcColIds
        );
      }
      if (
        globalMetaDataSrcColNames[globalDataSrcData[1].value] === undefined ||
        globalMetaDataSrcColNames[globalDataSrcData[1].value].length === 0
      ) {
        fetchMetaDataSrc(
          selectedReconUnit,
          globalDataSrcData[1].value,
          [],
          callbackFetchRules
        );
      } else {
        handleRuleTransformation(
          globalMetaDataSrcColNames,
          globalMetaDataSrcColIds
        );
      }
      setShowRuleWindow(true);
    } else {
      showFailureNotification(API_FETCH_RULEDTO_FAILURE, callbackNotification);
      setShowRuleWindow(true);
    }
  };

  const handleCloseRules = () => {
    setRulesData([]);
    setRulesHeader([]);
    setShowRuleWindow(false);
  };

  const handleShowRulesButtonClick = () => {
    fetchRulesData(selectedReconUnit, callbackFetchRulesData);
  };

  const callbackHandleDelinkRecords = (apiStatus) => {
    if (apiStatus) {
      auditInfoHandler(rowDetailFormObject.txnDataDTO[0]?.rawDataId, userId);
      closeOverlay(false);
      setTableLinksData([]);
      setTableLinksColumns([]);
      setTableLinksData2([]);
      setTableLinksColumns2([]);
      showSuccessNotification(API_DELINK_SUCCESS, callbackNotification);
      props.handleBackToReconSpace();
    } else {
      showFailureNotification(API_DELINK_FAILURE, callbackNotification);
    }
  };

  const handleDelinkRecords = () => {
    if (userNotes.trim() === "") {
      showFailureNotification("Please fill User Notes", callbackNotification);
      return;
    }
    const payload = {
      reconDataKeyElements: [...tableLinksData, ...tableLinksData2],
      reconNotes: userNotes,
      loggedInUserId: userId,
      initiatedLoginName: loginName,
      roleId: roleId,
    };
    setDelinkButtonClicked(true);
    triggerDelinkRecords(payload, callbackHandleDelinkRecords);
  };

  const callbackHandleLinkRecords = (apiStatus) => {
    if (apiStatus) {
      auditInfoHandler(rowDetailFormObject.txnDataDTO[0]?.rawDataId, userId);
      closeOverlay(false);
      showSuccessNotification(API_LINK_SUCCESS, callbackNotification);
      props.handleBackToReconSpace();
    } else {
      showFailureNotification(API_LINK_FAILURE, callbackNotification);
    }
  };

  const handleLinkRecords = () => {
    const { suggestedMatchesFinal } = ReconXContextData.state;
    if (suggestedMatchesFinal.length === 0) {
      //this means the link records called without even going to suggest matches.
      //this is to be treated purely as one sided match so setting the formdata as the suggestedMatchesFinal Object
      suggestedMatchesFinal[0] = selectedRecData;
    }
    if (userNotes.trim() === "") {
      showFailureNotification("Please fill User Notes", callbackNotification);
      return;
    }
    const payload = {
      reconDataKeyElements: suggestedMatchesFinal,
      reconNotes: userNotes,
      loggedInUserId: userId,
      initiatedLoginName: loginName,
      roleId: roleId,
    };
    setLinkButtonClicked(true);
    triggerLinkRecords(payload, callbackHandleLinkRecords);
  };

  const handleBackButton = () => {
    props.handleBackToReconSpace();
  };

  const callbackSendToResolveX = (apiStatus) => {
    // setSendingToResolveX(apiStatus);
    closeOverlay(false);
    if (apiStatus) {
      showSuccessNotification(API_SENDTORESOLVEX_SUCCESS, callbackNotification);
      props.handleBackToReconSpace();
    } else {
      showFailureNotification(API_SENDTORESOLVEX_FAILED, callbackNotification);
    }
  };

  const sentToSSFL = () => {
    if (userNotes.trim() === "") {
      showFailureNotification("Please fill User Notes", callbackNotification);
      return;
    }
    if (selectedWorkflow?.value === "") {
      showFailureNotification("Please Select Workflow", callbackNotification);
      return;
    }

    const payload = {
      rawDataId: rowDetailFormObject.txnDataDTO[0]?.rawDataId,
      flowDefnName: selectedWorkflow?.label, //reconUnitData?.reconUnitFlowName,
      flowDefnKeyName: +selectedWorkflow?.value, //reconUnitData?.reconUnitFlowKey,
      loggedInUserId: userId,
      loggedInUserName: loginName,
      flowDefnId: selectedWorkflow?.value, //reconUnitData?.reconUnitFlowKey,
      roleId: roleId,
      userNotes: userNotes,
      reconUnitId: rowDetailFormObject.txnDataDTO[0]?.reconUnitId,
      dataSrcConfigId: rowDetailFormObject.txnDataDTO[0]?.dataSrcConfigId,
    };
    sendToResolveX(payload, callbackSendToResolveX);
  };

  const handleUserNotes = (ele) => {
    selectedItem.current.ele = ele;
    setOverlay(true);
  };

  const closeOverlay = () => {
    selectedItem.current.ele = "";
    setOverlay(false);
    setUserNotes("");
  };

  const downloadData = (type) => {
    const payload = {
      reconUnitId: selectedReconUnit,
      reconStatus: selectedReconStatus,
      tenorPeriod: tenorPeriod.tenorPeriodVal,
      fromDate: tenorPeriod.startDate,
      toDate: tenorPeriod.endDate,
      pageNum: 0, //pagination?.pageIndex,
      rowsPerPage: pageDetails?.fetchedRecords, //pagination?.pageSize,
      loggedInUser: JSON.parse(sessionStorage.userData).userId,
      selectedReconUnitName,
    };
    downloadRowsData(payload, type);
    handleDownloadClose();
  };

  const callbackSaveOrModifyRule = (apiStatus) => {
    if (apiStatus) {
      setFetchSuggestedMatches(true);
      if (showFormObject) {
        props.loadFormData(selectedRecData); // Reinitiating To Update Column header colors with new rules
      }
      showSuccessNotification(
        API_SAVE_MODIFY_RULE_SUCCESS,
        callbackNotification
      );
      handleCloseRules();
    } else {
      setFetchSuggestedMatches(false);
      showFailureNotification(API_SENDTORESOLVEX_FAILED, callbackNotification);
    }
  };

  const handleSaveRule = () => {
    let revisedRule = [];

    let localAutonomousTolerance = false;
    let countForMultiMatch = 0;
    for (let i = 0; i < rulesData.length; i++) {
      let incrementIndex1 = parseInt(rulesData[i].columnIdSrc1);
      //incrementIndex1++;
      let incrementIndex2 = parseInt(rulesData[i].columnIdSrc2);
      //incrementIndex2++;
      let localColumn1Name =
        globalMetaDataSrcColIds[globalDataSrcData[0].value][incrementIndex1];
      let localColumn2Name =
        globalMetaDataSrcColIds[globalDataSrcData[1].value][incrementIndex2];
      let localHexScore = false;
      let localColForMultiMatch = false;
      if (parseInt(rulesData[i].colForMultiMatch) === 1) {
        localColForMultiMatch = true;
        countForMultiMatch++;
      } else {
        localColForMultiMatch = false;
      }
      if (parseInt(rulesData[i].hexScoreUse) === 1) {
        localHexScore = true;
      } else {
        localHexScore = false;
      }

      if (+rulesData[i].autonomousTolerance === 1) {
        localAutonomousTolerance = true;
      }

      const localDataType = ["STRING", "NUMERIC", "DATE"][
        +rulesData[i].dataType
      ];

      //console.log(localColumn1Name)
      // let localColumn1Name = "column" + incrementIndex1;
      // let localColumn2Name = "column" + incrementIndex2;
      let revisedRuleInfoObjLocal = {
        columnIdSrc1: localColumn1Name,
        columnIdSrc2: localColumn2Name,
        hexScoreUse: localHexScore,
        colForMultiMatch: localColForMultiMatch,
        dataType: localDataType, //rulesData[i].dataType,
        autonomousTolerance: localAutonomousTolerance, //rulesData[i].autonomousTolerance,
        toleranceValue: rulesData[i].toleranceValue,
      };
      revisedRule[i] = revisedRuleInfoObjLocal;
    }

    setShowATC(localAutonomousTolerance);

    //here save only if one column is selected for MultiMatch aggregation
    if (countForMultiMatch <= 1) {
      let revisedRuleObj = {
        reconUnitId: selectedReconUnit,
        ruleInfo: revisedRule,
        userId: userId, //6 //TODO to change to refer the userId context
      };

      saveOrModifyRule(revisedRuleObj, callbackSaveOrModifyRule);
    } else {
      showFailureNotification(MULTI_MATCH_EXCEPTION, callbackNotification);
    }
  };

  const setRuleDataCallback = (postPromiseRuleData) => {
    let localData = [...rulesData];
    localData = postPromiseRuleData;
    setRulesData(localData);
  };

  const handleOverlayAction = () => {
    const ele = selectedItem.current.ele;
    if (ele === "link") {
      handleLinkRecords();
    } else if (ele === "delink") {
      handleDelinkRecords();
    } else if (ele === "send to resolvex") {
      sentToSSFL();
    }
  };

  const floatAnimation = `
  .ethics-wrapper{
    position: relative;
    z-index: 99;
  }
  .ai-btn{
    position: relative;
    display: inline-flex;
  }
  .ai-btn::before {
    content: '';
      border-radius: 50px;
      min-width: calc(300px + 12px);
      min-height: calc(60px + 12px);
      border: 3px solid ${theme.palette.primary.main};
      box-shadow: 0 0 60px rgba(0,255,203,.64);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all .3s ease-in-out 0s;
    }
    .ai-btn::after {
      content: '';
      width: 18px; height: 18px;
      border-radius: 100%;
      border: 3px solid ${theme.palette.primary.main};
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 1.5s infinite;
    }
  @keyframes ring {
    0% {
      width: 18px;
      height: 18px;
      opacity: 0.5;
    }
    100% {
      width: 50px;
      height: 50px;
      opacity: 0;
    }
  }
`;

  return (
    <>
      <Box
        height="100%"
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        style={{ marginTop: !showFormObject ? "7px" : 0 }}
        className={`${classes.actionWrapper} action-buttons`}
      >
        <SayaEthics
          isModal={tts.isEnable}
          hideModalHandler={hideATCDetailsHandler}
          text={tts.text}
        />

        <style>{floatAnimation}</style>
        {!showFormObject && (
          <CheckAccess
            {...props}
            action={"ai ethics"}
            elemClass={"ethics-wrapper"}
          >
            <Tooltip title="Saya Ethics">
              {/* <LyricsOutlinedIcon
              className="mr-4 MuiSvgIconRoot"
              style={{ cursor: "pointer" }}
              fontSize="large"
              onClick={triggerATCDetails}
            /> */}

              <Button
                variant="outlined"
                className="ai-ethics-btn"
                style={{
                  minWidth: "180px",
                  minHeight: "40px",
                  marginTop: "0px",
                  position: "relative",
                  zIndex: 9,
                  overflow: "hidden",
                }}
                onClick={triggerATCDetails}
                startIcon={
                  <span className="ai-btn">
                    <SmartToyOutlinedIcon />
                  </span>
                }
              >
                <span className="ai-btn-text">Saya AI Ethics</span>
              </Button>
            </Tooltip>
          </CheckAccess>
        )}

        {!showFormObject && <ReconXUpload {...{ ...props, setFetchData }} />}

        {!showFormObject && (
          <>
            {showATC && (
              <CheckAccess {...props} action={"atc"} wrapEnabled>
                {isToleranceCalibratorRunning ? (
                  <Tooltip title="SAYA Optimization in Progress">
                    <BlurLinear
                      color="disabled"
                      className={`${classes.inprogressBtn} mr-4`}
                      style={{
                        cursor: "default",
                        opacity: 0.2,
                        pointerEvents: "none",
                      }}
                      fontSize="large"
                      //onClick={triggerATC}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Autonomous Tolerance Calibrator">
                    <BrightnessAutoOutlinedIcon
                      className="mr-4 MuiSvgIconRoot"
                      style={{ cursor: "pointer" }}
                      fontSize="large"
                      onClick={triggerATC}
                    />
                  </Tooltip>
                )}
              </CheckAccess>
            )}

            <CheckAccess {...props} action={"recon optimizer"} wrapEnabled>
              {isReconOptimizerRunning ? (
                <Tooltip title="SAYA Optimization in Progress">
                  <BlurLinear
                    color="disabled"
                    className={`${classes.inprogressBtn} mr-4`}
                    style={{
                      cursor: "default",
                      opacity: 0.2,
                      pointerEvents: "none",
                    }}
                    fontSize="large"
                    //onClick={triggerReconOptimizer}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Recon Optimization">
                  <BlurLinear
                    className="mr-4 MuiSvgIconRoot"
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                    onClick={triggerReconOptimizer}
                  />
                </Tooltip>
              )}
            </CheckAccess>

            <CheckAccess
              {...props}
              action={"reconcillation process"}
              wrapEnabled
            >
              {isReconcileRunning ? (
                <Tooltip title="Reconciliation is in Progress">
                  <PublishedWithChangesOutlined
                    color="disabled"
                    className={`${classes.inprogressBtn} mr-4`}
                    style={{
                      cursor: "default",
                      opacity: 0.2,
                      pointerEvents: "none",
                    }}
                    fontSize="large"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Reconcile">
                  <FactCheckOutlined
                    className="mr-4 MuiSvgIconRoot"
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                    onClick={triggerReconciliation}
                  />
                </Tooltip>
              )}
            </CheckAccess>
          </>
        )}

        <CheckAccess {...props} action={"rules view"} wrapEnabled>
          <Tooltip title="View & Edit Recon Rule">
            <AppRegistrationOutlined
              className="mr-4 MuiSvgIconRoot"
              style={{ cursor: "pointer" }}
              fontSize="large"
              onClick={handleShowRulesButtonClick}
            />
          </Tooltip>
        </CheckAccess>

        <CheckAccess {...props} action={"send to resolvex"} wrapEnabled>
          {mappedWorkflows.length > 0 && //workflowEnabledReconUnit
          showFormObject &&
          selectedRecData.reconStatus === "OPEN" ? (
            !sentToResolveX ? (
              <Tooltip title="Send To ResolveX">
                <AddToQueue
                  className="mr-4 MuiSvgIconRoot"
                  style={{ cursor: "pointer" }}
                  fontSize="large"
                  onClick={() => handleUserNotes("send to resolvex")}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Sent To ResolveX">
                <AddToQueue
                  className="mr-4"
                  color="disabled"
                  style={{
                    cursor: "default",
                    opacity: 0.2,
                    pointerEvents: "none",
                  }}
                  fontSize="large"
                />
              </Tooltip>
            )
          ) : null}
        </CheckAccess>
        <CheckAccess {...props} action={"delink records"} wrapEnabled>
          {showFormObject === true &&
          selectedRecData.reconStatus === "RECONCILED" ? (
            delinkButtonClicked ? (
              <Tooltip title="Delink Records">
                <LayersClearOutlined
                  className="mr-4 MuiSvgIconRoot"
                  color="disabled"
                  style={{
                    cursor: "default",
                    opacity: 0.2,
                    pointerEvents: "none",
                  }}
                  fontSize="large"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Delink Records">
                <LayersClearOutlined
                  className="mr-4 MuiSvgIconRoot"
                  style={{ cursor: "pointer" }}
                  fontSize="large"
                  onClick={() => handleUserNotes("delink")}
                />
              </Tooltip>
            )
          ) : null}
        </CheckAccess>

        <CheckAccess {...props} action={"link records"} wrapEnabled>
          {showFormObject === true && selectedRecData.reconStatus === "OPEN" ? (
            linkButtonClicked ? (
              <Tooltip title="Link Records">
                <AddLinkOutlined
                  className="mr-4 MuiSvgIconRoot"
                  color="disabled"
                  style={{
                    cursor: "default",
                    opacity: 0.2,
                    pointerEvents: "none",
                  }}
                  fontSize="large"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Link Records">
                <AddLinkOutlined
                  className="mr-4 MuiSvgIconRoot"
                  style={{ cursor: "pointer" }}
                  fontSize="large"
                  onClick={() => handleUserNotes("link")}
                />
              </Tooltip>
            )
          ) : null}
        </CheckAccess>

        {showFormObject === true ? (
          <div className={classes.backBtn}>
            <Tooltip title="Back to Recon Workspace">
              <KeyboardBackspace
                className="MuiSvgIconRoot"
                style={{ cursor: "pointer" }}
                fontSize="large"
                onClick={handleBackButton}
              />
            </Tooltip>
          </div>
        ) : (
          <Box>
            <div>
              <Tooltip title="Download">
                <IconButton
                  id="download-button"
                  aria-controls={open ? "download-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleDownloadClick}
                  className={classes.downloadButton}
                >
                  <DownloadForOfflineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Menu
              id="download-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleDownloadClose}
              MenuListProps={{
                "aria-labelledby": "download-button",
              }}
            >
              <MenuItem
                onClick={() => downloadData("page")}
                sx={{ fontSize: 14 }}
              >
                <ListItemIcon>
                  <InsertDriveFileOutlinedIcon sx={{ color: "#d4186d" }} />
                </ListItemIcon>
                Download Current Page
              </MenuItem>
              <MenuItem
                onClick={() => downloadData("all")}
                sx={{ fontSize: 14 }}
              >
                <ListItemIcon>
                  <FileCopyOutlinedIcon sx={{ color: "#d4186d" }} />
                </ListItemIcon>
                Download All
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
      {ReactDOM.createPortal(
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
        />,
        document.body
      )}
      <SayaReconRuleDialog
        showRulesWindow={showRuleWindow}
        handleCloseReconRules={handleCloseRules}
        dialogTitle={"View/ Modify Recon Rules - " + selectedReconUnitName}
        tableTitle={""}
        ruleColumns={rulesHeader}
        rulesData={rulesData}
        ruleTableOptions={ruleTableOptions}
        setRuleDataCallback={setRuleDataCallback}
        handleSaveRule={handleSaveRule}
        {...props}
      />
      {showOverlay && (
        <Overlay
          {...{
            isOverlayOpen: showOverlay,
            closeOverlay,
            title: "User Notes",
            actions: true,
            handler: handleOverlayAction,
          }}
        >
          <div style={{ minWidth: "450px" }}>
            {selectedItem.current.ele &&
              selectedItem.current.ele === "send to resolvex" && (
                <div className={classes.opsCombo}>
                  <SAYAComboBox
                    optionsData={mappedWorkflows}
                    value={selectedWorkflow?.value}
                    defaultValue={selectedWorkflow?.value}
                    labelText="Select Workflow1"
                    groupText="Selected  Workflow"
                    name="workflow"
                    handleChange={handleWorkflow}
                    className="SelectSS"
                    height="30px"
                    headerCombo
                  />
                </div>
              )}
            <FormControl fullWidth sx={{ mt: 2 }} variant="filled">
              <TextareaAutosize
                style={{
                  fontFamily: "inherit",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                }}
                required
                id="outlined-required"
                label="User Notes"
                variant="outlined"
                size="large"
                name="userNotes"
                placeholder="Enter User Notes"
                minRows={5}
                maxLength={500}
                value={userNotes}
                onChange={(e) => setUserNotes(e.target.value)}
              />
            </FormControl>
          </div>
        </Overlay>
      )}
    </>
  );
}

export default React.memo(ReconXOptions);
