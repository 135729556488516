import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AddAlert, AddBusinessOutlined, BorderColorOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import {
  showAlertNotification,
  showFailureNotification
} from "../../action-handlers/NotificationsHandler";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { CircularProgress } from "@mui/material";
import { createOrModifyNWayReconProcess, deleteNWayReconProcess, fetchNWayReconProcess } from "action-handlers/NWayReconProcessHandler";
import CardFooter from "components/Card/CardFooter";
import HttpApiRenderer, { httpApiTester } from "components/JSONForms/HttpApiRenderer";
import paperBg from "../../assets/img/rainbow-bg.png";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import ConfirmationOverlay from "../../components/ConfirmOverlay/ConfirmationOverlay";
import Button from "../../components/CustomButtons/Button";
import Snackbar from "../../components/Snackbar/Snackbar";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import {
  API_CALL_FAILED,
  API_DELETE_API_WARNING
} from "../../messages/DashboardMessages";
import { notificationParams } from "../../variables/general";

const styles = (theme) => ({
  cardWrapper: {
    borderRadius: "10px",
    //backgroundImage: `url(${paperBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    boxShadow: "50px 50px 150px inset #ffffff",
    backgroundColor: "#fff",
    paddingTop: '10px',
    paddingBottom: '20px'
  },
  formWrapper: {
    border: "5px solid rgb(255 255 255)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px",
    background:
      theme.palette.type === "dark"
        ? "transparent"
        : "linear-gradient(138.18deg, #eae8fd 0%, #fce5e6 94.44%)",
    borderRadius: "10px",
    "& label": {
      color: "#9c9c9c",
    },
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#DC3C7C",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    paddingLeft: '20px',
    paddingTop: '5px',
    "&:before, &:after": {
      left: '0 !important'
    },
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  inputText: {
    border: "1px solid " + theme.palette.grey[500],
    borderRadius: "6px",
    marginTop: "15px !important",
    backgroundColor:
      theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    "& input": {
      padding: 10,
    },
    "&:before, &:after": {
      display: "none",
    },
  },
  inputWrapper: {
    "& label": {
      marginLeft: "-14px",
      color: "#dd3c7c !important",
    },
  },
  selectFieldInput: {
    marginTop: "5px",
    marginBottom: "10px",
    "& label": {
      marginLeft: "0px",
      color: "#dd3c7c !important",
    },
  },
  selectInput: {
    marginTop: "-11px",
    "& fieldset": {
      borderColor: "transparent !important",
    },
    "& > label": {
      fontSize: 12,
      color: "#dd3c7c !important",
    },
    "& > div": {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  },
  selectItem: {
    padding: 0,
    backgroundColor:
      theme.palette.type === "dark"
        ? "transparent"
        : "rgb(246 235 250) !important",
    borderBottom:
      theme.palette.type === "dark" ? "1px solid #000" : "1px solid #f2c2d6",
  },
  selectItemText: {
    "& > span": {
      fontSize: 13,
    },
  },
  multimatchWrapper: {
    "& .match-input": {
      backgroundColor:
        theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    },
    "& label": {
      position: "relative",
      top: "20px",
      left: "-14px",
      width: "180px",

      "&+div": {
        marginTop: "10px",
        width: "130px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        paddingLeft: "10px",
      },
    },
  },
  opsCombo: {
    "@media (min-width: 0px) and (max-width: 599.95px)": {
      marginTop: "15px",
    },
    "& label": {
      color: "#dd3c7c !important",
      marginTop: "-7px",
    },
    "& > div": {
      width: "100%",
    },
    "& > div > div": {
      minWidth: "100% !important",
      position: "relative",
      border: "1px solid #919EAB",
      top: "-2px",
      "& > div": {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
  },
  opsSelect: {
    backgroundColor:
      theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    border: "1px solid #919EAB",
  },
  toggleForm: {
    padding: "25px 5px",
    borderRadius: "10px",
    backgroundColor:
      theme.palette.type === "dark" ? "transparent" : "rgb(251 251 251 / 37%)",
    margin: "0px 14px",
    border: "1px dashed #ccc",
  },
  btnWrapper: {
    "& button": {
      boxShadow: "rgb(208 52 125 / 36%) 0px 8px 22px",
      borderRadius: "8px",
    },
  },
  opsHeading: {
    color: "#dd3c7c !important",
    marginTop: "15px",
    fontWeight: "500",
    paddingLeft: "30px",
    borderBottom: "1px dashed #dd3c7338",
    paddingBottom: "10px",
    marginLeft: "-15px",
    marginRight: "-15px",
  },
});

export default function NWayReconProcessCrud(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [showConfirmOverlay, setConfirmOverlay] = useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const organisationId = parseInt(UserAuthContext.state.clientOrgId);
  const userId = parseInt(UserAuthContext.state.userId);

  const { id } = useParams();
  const [isEditNWayReconProcess, setEditNWayReconProcess] = useState(id && id !== "" && id !== "new");

  const INIT_FORM_DATA = { organisationId: organisationId, shortName: "", longName: "", dataFormatIds: [] };
  const [nWayReconProcessId, setNWayReconProcessId] = useState("");

  let allowedPermissions = [];
  try {
    allowedPermissions =
      location.state === null
        ? JSON.parse(sessionStorage.selectedItem)?.allowedPermissions
        : JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];
  } catch (ex) {
    console.log("Error while reading location.state===>", ex);
  }

  const [formData, setFormData] = useState({ nwayReconProcess: { parentOrgId: organisationId, nwayReconName: "" }, loggedInUserId: userId, departmentIdList: [], reconUnitIdList: [] });
  const [formErrors, setFormErrors] = useState([]);

  const schema = {
    "title": "N-Way Recon Process",
    "type": "object",
    "properties": {
      "nwayReconProcess": {
        "type": "object",
        "properties": {
          "nwayReconProcessId": {
            "type": "string",
            "description": "The name of the n-way recon process",
            "minLength": 1,
            "maxLength": 128
          },
          "nwayReconName": {
            "type": "string",
            "description": "The name of the n-way recon process",
            "minLength": 1,
            "maxLength": 128
          },
          "parentOrgId": {
            "type": "integer",
            "description": "The id of the organisation",
            "minLength": 1
          }
        },
        "required": ["nwayReconName", "parentOrgId"]
      },
      "loggedInUserId": {
        "type": "integer",
        "description": "The id of the user",
        "minLength": 1
      },
      "departmentIdList": {
        "type": "array",
        "apiUrl": `/saya-user-cortex/department/id-values/${userId}`,
        "items": {
          "type": "integer"
        },
        "uniqueItems": true,
        "minItems": 1,
        "description": "The list of departments",
        "default": []
      },
      "reconUnitIdList": {
        "type": "array",
        "apiUrl": `/saya-recon/reconunits/nwayrecon/fetchMappableReconProcs/${userId}?nWayReconProcessId=${id === "new" ? "" : id}`,
        "labelProperty": "clientReconName",
        "valueProperty": "reconUnitId",
        "items": {
          "type": "integer"
        },
        "uniqueItems": true,
        "minItems": 2,
        "description": "The list of recon processes",
        "default": []
      }
    },
    "required": ["nwayReconProcess", "loggedInUserId", "reconUnitIdList", "departmentIdList"],
  }

  const uischema = {
    type: "VerticalLayout",
    elements: [
      {
        "type": "HorizontalLayout",
        "elements": [
          {
            type: "Control",
            label: "N-Way Recon Name",
            scope: "#/properties/nwayReconProcess/properties/nwayReconName",
          }
        ]
      },
      {
        "type": "HorizontalLayout",
        "elements": [
          {
            "type": "Control",
            "label": "Departments",
            "dataSourceType": "http",
            "isMultiSelect": true,
            //"visibleOptions": true,
            "scope": "#/properties/departmentIdList",
            "options": {
              "selectAll": true
            }

          },
          {
            "type": "Control",
            "label": "Ops Processes",
            "dataSourceType": "http",
            "isMultiSelect": true,
            "scope": "#/properties/reconUnitIdList",
            "visibleOptions": true
          }
        ]
      }
    ]
  };


  const renderers = [
    ...materialRenderers,
    { tester: httpApiTester, renderer: HttpApiRenderer }
  ];

  const handleChange = ({ errors, data }) => {
    console.log("Form data and errors", data, errors);

    setFormErrors(errors);

    setFormData(data);
  };

  /**
   * Invoked on form submit
   * @param {event} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    createOrModifyNWayReconProcess(formData, nWayReconProcessId, createOrModifyCallback)
  };

  const createOrModifyCallback = (body) => {
    setLoading(false);
    if (Object.keys(body).length > 0) {
      notifyHandler(
        `N-way recon process ${isEditNWayReconProcess ? "updated" : "created"} successfully`
      );
      setFormData(INIT_FORM_DATA);
      navigateToNWayReconProcesssHome();
    } else {
      notifyHandler(
        `Unable to ${isEditNWayReconProcess ? "update" : "create"
        } n-way recon process, please contact support!`,
        "danger"
      );
    }
  };

  const notifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  useEffect(() => {
    if (id === "new") {
      setLoading(false);
      return;
    }

    let nWayReconProcess = location.state?.entity;

    const populateFormData = (nWayReconProcess) => {
      const formData = {
        nwayReconProcess: {
          nWayReconProcessId: nWayReconProcess.nwayReconProcess.nwayReconProcessId,
          nwayReconName: nWayReconProcess.nwayReconProcess.nwayReconName,
          parentOrgId: nWayReconProcess.nwayReconProcess.parentOrgId,
        },
        loggedInUserId: nWayReconProcess.loggedInUserId,
        reconUnitIdList: nWayReconProcess.reconUnitIdList,
        departmentIdList: nWayReconProcess.departmentIdList,
      };

      setNWayReconProcessId(id)
      setFormData(formData);
      setLoading(false);
    }

    // If the state contains the n-way recon process, populat form data and id from url is available, fetch the n-way recon process
    if (nWayReconProcess && Object.keys(nWayReconProcess).length > 0) {
      populateFormData(nWayReconProcess);
    }
    else if (id) {
      fetchNWayReconProcess(id, (status, nWayReconProcess) => {
        if (status === 200) {
          populateFormData(nWayReconProcess);
        }
        else {
          showFailureNotification(`Unable to get n-way recon process for id ${id}`, callbackNotification);
          navigateToNWayReconProcesssHome();
        }
      });
    }
    else {
      console.error("Navigating to n-way recon processs when neither n-way recon process nor id is available!");
      navigateToNWayReconProcesssHome();
    }

    populateFormData(nWayReconProcess);
  }, [id]);

  const navigateToNWayReconProcesssHome = () => {
    setTimeout(() => {
      navigate("/saya-platform/admin/nway-recon-processes", {
        state: {
          selectedItem:
            location?.state?.selectedItem || sessionStorage.selectedItem,
        },
      });
    }, 1500);
  }

  const handleDelete = () => {
    if (nWayReconProcessId) {
      deleteNWayReconProcess(nWayReconProcessId, userId, deleteCallback);
    } else {
      showAlertNotification(API_DELETE_API_WARNING, callbackNotification);
    }
  };

  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      closeOverlay();
      navigateToNWayReconProcesssHome();
    } else {
      showFailureNotification(API_CALL_FAILED, callbackNotification);
    }
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const openOverlay = () => {
    setConfirmOverlay(true);
  };

  const closeOverlay = () => {
    setConfirmOverlay(false);
  };

  const handleCancel = () => {
    navigateToNWayReconProcesssHome();
  };

  return (
    <div style={{ margin: '0 -18px' }}>
      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Box className={classes.cardWrapper}>
              <Card plain>
                <CardHeader color="primary" plain>
                  <h4
                    className={
                      classes.cardTitleWhite + " modal-title-flag"
                    }
                  >

                    {/* {isEditNWayReconProcess ? (
                      <BorderColorOutlined
                        className={classes.iconSpacing}
                      />
                    ) : (
                      <AddBusinessOutlined
                        className={classes.iconSpacing}
                      />
                    )} */}
                    {isEditNWayReconProcess ? "Edit" : "Create"} N-way recon process

                  </h4>
                </CardHeader>
                <CardBody>
                  <Box>
                  <GridContainer sx={{justifyContent: 'center'}}>
                      <GridItem xs={12} sm={6} md={6}>
                        {
                          isLoading ? <CircularProgress className={classes.loaderImg} />
                            : <JsonForms
                              schema={schema}
                              uischema={uischema}
                              data={formData}
                              renderers={renderers}
                              cells={materialCells}
                              onChange={handleChange}
                            />
                        }
                      </GridItem>
                    </GridContainer>
                  </Box>
                </CardBody>
                <CardFooter>
                  <Grid container justifyContent={"center"} spacing={1}>

                    <GridItem style={{ marginLeft: "-10px" }}>
                      {
                        (allowedPermissions.includes("modify") ||
                          allowedPermissions.includes("add")) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{ xs: { padding: "10px 25px" } }}
                            disabled={formErrors.length > 0}
                          >
                            Submit
                          </Button>
                        )}

                      {
                        allowedPermissions.includes("delete") && nWayReconProcessId && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => openOverlay()}
                            sx={{ xs: { padding: "10px 25px" } }}
                          >
                            DELETE
                          </Button>
                        )}

                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ padding: { xs: "10px 25px" } }}
                        className={classes.closeButton}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </GridItem>

                  </Grid>
                </CardFooter>
              </Card>
            </Box>
          </GridItem>
        </GridContainer>
      </form>
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={AddAlert}
        message={notificationObj.message}
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
      {
        showConfirmOverlay && (
          <ConfirmationOverlay
            {...{
              isOverlayOpen: showConfirmOverlay,
              closeOverlay,
              handler: handleDelete,
              title: "DELETE",
              message: `Are you sure you want to delete? Once you delete you will loss all the data associated to the record.`,
            }}
          />
        )
      }
    </div >
  );
}
