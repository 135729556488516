import {
  dangerColor,
  successColor,
  grayColor,
  roseColor,
  defaultFont,
  whiteColor,
  infoColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";
import { useTheme } from "@mui/material/styles";
export default function customComboStyle() {
  const theme = useTheme();
  return {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      backgroundColor: theme.palette.type === "dark" ? "transparent" : "#fff", //dangerColor[0]+" !important",
      //color: "rgba(" + hexToRgb(whiteColor) + ", 1)",
      fontWeight: 300,

      // match with the menu
      //borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      borderRadius: 3,
      // Overwrittes the different states of border
      //borderColor: state.isFocused ? "yellow" : "green",
      // borderColor: roseColor[0],
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: roseColor[0],
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      padding: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),

    disabled: {
      "&:before": {
        backgroundColor: grayColor[0] + " !important",
      },
    },
    underline: {
      "&:hover:not($disabled):before,&:before": {
        borderColor: roseColor[0] + " !important",
        borderWidth: "1px !important",
      },
      "&:after": {
        borderColor: roseColor[0],
      },
    },
    underlineError: {
      "&:after": {
        borderColor: dangerColor[0],
      },
    },
    underlineSuccess: {
      "&:after": {
        borderColor: successColor[0],
      },
    },
    labelRoot: {
      ...defaultFont,
      //color: "rgba(" + hexToRgb(whiteColor) + ", 1) !important",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset",
    },
    labelRootError: {
      color: dangerColor[0],
    },
    labelRootSuccess: {
      color: successColor[0],
    },
    feedback: {
      position: "absolute",
      top: "18px",
      right: "0",
      zIndex: "2",
      display: "block",
      width: "24px",
      height: "24px",
      textAlign: "center",
      pointerEvents: "none",
    },
    marginTop: {
      marginTop: "0px",
    },
    marginLeft: {
      marginLeft: "10px",
    },
    formControl: {
      paddingBottom: "0px",
      margin: "0px 10px 0px 10px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
      },
      position: "relative",
      verticalAlign: "unset",
      "&:hover": {
        "& fieldset": {
          borderColor: infoColor[0] + "!important",
          opacity: 0.9,
        },
      },
      "& svg": {
        color: theme.palette.grey[500],
        opacity: 1,
        backgroundColor: theme.palette.type === "dark" ? "#1a1546" : "#fff",
      },
      "& fieldset": {
        borderColor: infoColor[0],
        opacity: 0.7,
        transition: "0.3s",
      },
    },
    headerComboWrapper: {
      marginRight: "20px !important",
      marginLeft: "0px !important",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      "& label":{
        fontSize: '0.75rem !important',
        color: '#637381 !important',
        transform: 'translate(0, -5.5px) scale(1) !important',
        fontFamily: 'Inter !important',
        fontWeight: 'noraml !important'
      },
      '& label.period-input':{
        transform: 'translate(0, -0.5px) scale(1) !important'
      },
      "& > div": {
        "& span svg": {
          color: theme.palette.primary.main + " !important",
          verticalAlign: "top !important",
        },
        "&:before": {
          border: "none",
        },
      },
      "& fieldset": {
        borderColor: theme.palette.grey[700],
      },
    },
    headerCombobox: {
      backgroundColor: theme.palette.background.card,
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 5,
      // [theme.breakpoints.down("md")]: {
      //   maxWidth: "125px",
      //   //minWidth: "auto"
      // },
      // [theme.breakpoints.down("sm")]: {
      //   maxWidth: "140px",
      //   //minWidth: "auto"
      // },
      // [theme.breakpoints.up("md")]: {
      //   maxWidth: "190px",
      //   //minWidth: "auto"
      // },
      // [theme.breakpoints.up("lg")]: {
      //   maxWidth: "240px !important",
      //   //minWidth: "220px"
      // },
      "& > div": {
        display: "flex",
        padding: "8px 5px",
        maxHeight: "22px",
        "& > div": {
          height: "22px",
          minWidth: "35px",
        },
        [theme.breakpoints.down("lg")]: {
          padding: "8px 5px",
        },
        // [theme.breakpoints.down("md")]: {
        //   padding: "6px 5px",
        // },
        "&:focus": {
          background: "transparent",
        },
        "&:hover:not($disabled):before,&:before": {
          border: "none",
        },
      },
      "&:after": {
        border: "none !important",
      },
      "&:hover": {
        "& fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    formComboWrapper: {
      width: "100%",
      margin: 0,
      "& label[class*='labelRoot']": {
        color: "rgba(" + hexToRgb(theme.palette.grey[700]) + ", 1) !important",
      },
    },
    formCombobox: {
      width: "100% !important",
      background: theme.palette.common.white,
      "& svg": {
        color: theme.palette.grey[700],
      },
      "& > div": {
        padding: "15px 5px",
        color: theme.palette.grey[700],
      },
    },
    iconOption: {
      "& svg": {
        color: theme.palette.primary.main,
        verticalAlign: "top !important",
      },
    },
  };
}
