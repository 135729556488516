import {
  drawerWidth,
  transition,
  container,
  drawerCollpaseWidth
} from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "68px",
    padding: "0px 15px 5px",
    minHeight: "calc(100vh - 120px)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: "40px",
    }
  },
  container,
  map: {
    marginTop: "70px"
  },
  contentExpand: {
    // [theme.breakpoints.up("xs")]: {
    //   width: `calc(100% - ${drawerCollpaseWidth}px)`
    // },
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - 60px)`
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    width: "100%"
  },
  loginWrapper: {
    [theme.breakpoints.up("xs")]: {
      padding: 20
    },
    "& > div": {
      width: "100% !important"
    }
  },
  menuIcon: {
    position: 'relative',
    zIndex: 9998,
    display: 'inline-block',
    cursor: 'pointer',
    left: '28px',
    top: '35px',
    [theme.breakpoints.up("sm")]: {
      top: 20
    },
  }
});

export default appStyle;
