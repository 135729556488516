import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import {
  InsertChartOutlinedOutlined,
  KeyboardBackspace,
  TimelineOutlined,
  AddAlert,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import {
  USER_SESSION_TIME_OUT,
  API_FETCH_ANALYTIX_CONFIG_FAILED,
} from "../../messages/DashboardMessages";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import {
  showAlertNotification,
  showFailureNotification,
} from "../../action-handlers/NotificationsHandler";
import CardFooter from "../../components/Card/CardFooter";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import Snackbar from "../../components/Snackbar/Snackbar";
import CardBody from "../../components/Card/CardBody";
import {
  fetchAnalytiXData,
  getGuestToken,
} from "../../action-handlers/AnalytiXActionHandler";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import { notificationParams } from "../../variables/general";
import CustomiFrame from "../../components/CustomSayaComponents/CustomiFrame";
import BasicSelect from "../../components/v2/Select";

const useStyles = makeStyles(styles);

export default function AnalytiXHome() {
  const classes = useStyles();
  const [isAnalytiXHomeLoading, setAnalytiXHomeLoading] = useState(true);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(false);
  let initialTitle = "Analytix Configured for your Organization    ";
  const UserAuthContext = React.useContext(UserAuthDetailsContext);
  const [viewTitle, setViewTitle] = useState(initialTitle);
  const [iframeLink, setIframeLink] = useState();

  const [analytixConfigList, setAnalytixConfigList] = useState([]);
  const [selectedAnalytix, setSelectedAnalytix] = useState("");
  const [notificationObj, setNotificationObj] = useState(notificationParams);

  const location = useLocation();
  const embededId = location.state ? location.state.embededId : "";

  useEffect(() => {
    if (embededId && analytixConfigList.length > 0) {
      handleDashboard(embededId);
    }
  }, [embededId, analytixConfigList]);

  useEffect(() => {
    setTimeout(() => {
      setShowDetailPage(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isAnalytiXHomeLoading) {
      let userId = UserAuthContext.state.userId; //userDetailsInitialState.userId;;//useContext and pass the userId from login
      let orgId = UserAuthContext.state.clientOrgId;
      let roleId = UserAuthContext.state.roleId;
      if (
        UserAuthContext.state.userId === undefined ||
        UserAuthContext.state.userId === 0
      ) {
        //this means refresh or session of browser got refreshed. So lets fetch the user details from Database
        /// for doing lets get the loginName from sessionStorage see if it is available, try fetching the details

        if (
          UserAuthContext.state.loginName !== undefined ||
          UserAuthContext.state.loginName !== ""
        ) {
          //this means user had logged in earlier try fetching from sessionStorage loginName
          fetchUserDetailsByLogin(
            UserAuthContext.state.loginName,
            callBackFetchUserDetailsByLogin
          );
        }
      } else {
        userId = UserAuthContext.state.userId;
        orgId = UserAuthContext.state.clientOrgId;
        roleId = UserAuthContext.state.roleId;

        loadInitialPageData(userId, orgId, roleId);
      }
    }
    return () => {
      console.log(
        "Component AnalytiXHome Home unloading cleanup to be done here!!!"
      );
    };
  }, []);

  const loadInitialPageData = (userId, orgId, roleId) => {
    fetchAnalytiXData(userId, orgId, roleId, callbackFetchAnalytiXData);

    setAnalytiXHomeLoading(false); //To move to callback success
  };

  const callbackFetchAnalytiXData = (fetchedAnalytixConfig, apiStatus) => {
    if (apiStatus) {
      setAnalytixConfigList(fetchedAnalytixConfig);
    } else {
      showFailureNotification(
        API_FETCH_ANALYTIX_CONFIG_FAILED,
        callbackNotification
      );
    }
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    if (apiStatus) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
      loadInitialPageData(
        userDetailsState.userId,
        userDetailsState.clientOrgId,
        userDetailsState.roleId
      );
    } else {
      //failure could be due to token expiry, raise an alert saying Token Expired and route to login page
      showAlertNotification(USER_SESSION_TIME_OUT, callbackNotification);
      UserAuthContext.clearUserDetailsAuthState();
      window.location.assign("/");
    }
  };

  const handleAnalytiXClick = (analytixConfigData) => {
    setSelectedAnalytix(analytixConfigData.embeddedId);
    document.getElementsByTagName("iframe")[0].src = "";
    try {
      const ud = JSON.parse(sessionStorage.userData);
      const payload = {
        user: {
          userId: ud?.userId,
        },
        resources: [
          {
            type: "dashboard",
            id: analytixConfigData.embeddedId,
          },
        ],
        rls: [],
      };

      getGuestToken(payload, (res) => {
        embedDashboard({
          // id: "e4622e43-5f20-4b1b-8213-7b865ac07e07",
          id: res?.dashboardId, // given by the Superset embedding UI
          supersetDomain: res?.domainName,
          mountPoint: document.getElementById("superset"),
          // fetchGuestToken: () => fetchGuestTokenFromBackend(),
          fetchGuestToken: () => res?.guestToken,
          dashboardUiConfig: {
            hideTitle: true,
            hideTab: true,
            hideChartControls: true,
          }, // dashboard UI config: hideTitle, hideTab, hideChartControls (optional)
        });
        // setViewTitle(analytixName);
        // setIframeLink(analytixLink);
        setShowDashboard(true);
      });
    } catch (ex) {
      console.error("Exception while embeded dashboard", ex.message);
    }
  };

  const handleDashboard = (value) => {
    setSelectedAnalytix(value);
    const selectedDashboard = analytixConfigList.filter(
      (ac) => ac.embeddedId === value
    )[0];
    handleAnalytiXClick(selectedDashboard);
  };

  const handleBackToAnalytiX = () => {
    setViewTitle(initialTitle);
    setShowDashboard(false);
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader
            color="primary"
            pageHeader
            style={{ padding: 0, margin: 0 }}
          >
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="flex justify-between w-full">
                {/* <h4>{viewTitle} </h4> */}
                {/* <p className={classes.cardCategoryWhite}></p> */}
                {showDashboard === true ? (
                  <>
                    <div className="">
                      <BasicSelect
                        {...{
                          label: initialTitle,
                          name: initialTitle,
                          value: selectedAnalytix,
                          selectData: analytixConfigList,
                          optionLabel: "analytixName",
                          optionValue: "embeddedId",
                          onChange: handleDashboard,
                        }}
                      />
                    </div>
                  </>
                ) : null}
                {showDashboard && (
                  <Tooltip title="Back to AnalytiX Home">
                    <KeyboardBackspace
                      className="mr-4 MuiSvgIconRoot"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      fontSize="large"
                      onClick={handleBackToAnalytiX}
                    />
                  </Tooltip>
                )}
              </div>
            </GridContainer>
          </CardHeader>
          <CardBody className={`${classes.analytixCardBody} !pt-0`} style={{margin: !showDashboard === true ? '0 -15px' : '0'}}>
            {isAnalytiXHomeLoading ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <CircularProgress />
                <span
                  style={{
                    justifyContent: "center",
                    position: "fixed",
                    top: "55%",
                  }}
                >
                  Loading...please wait
                </span>
              </div>
            ) : showDashboard ? (
              <GridContainer>
                {/* <CustomiFrame src={iframeLink} id="superset"></CustomiFrame> */}
              </GridContainer>
            ) : (
              <GridContainer>
                {showDetailPage &&
                analytixConfigList &&
                analytixConfigList.length > 0 ? (
                  analytixConfigList.map((analytixConfigData, index) => (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAnalytiXClick(analytixConfigData)}
                    >
                      <Card borderVariant>
                        <CardHeader color="primary" stats icon>
                          <CardIcon color="primary" plain>
                            <TimelineOutlined />
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            AnalytiX Id: {analytixConfigData.analytixId}
                          </p>
                          <h3
                            className={classes.cardTitle}
                            style={{
                              maxWidth: "280px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {analytixConfigData.analytixName}
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            <InsertChartOutlinedOutlined />
                            Click to view the Dashboard...
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  ))
                ) : (
                  <>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        height: "100vh",
                        width: "100vw",
                      }}
                    >
                      <CircularProgress />
                      <span
                        style={{
                          justifyContent: "center",
                          position: "fixed",
                          top: "55%",
                        }}
                      >
                        Loading...please wait
                      </span>
                    </div>
                    {/* {[1, 2, 3].map((index) => (
                      <GridItem xs={12} sm={6} md={4} key={index}>
                        <Card style={{ width: "100%", marginRight: 20 }}>
                          <CardHeader color="primary" stats icon>
                            <CardIcon
                              color="primary"
                              style={{ background: "transparent" }}
                            >
                              <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            </CardIcon>

                            <p className={classes.cardCategory}>
                              <Skeleton
                                animation="wave"
                                style={{ float: "right" }}
                                width={140}
                              />
                            </p>
                            <h3 className={classes.cardTitle}>
                              <Skeleton animation="wave" width={50} />
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <Skeleton animation="wave" width={150} />
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))} */}
                  </>
                )}
              </GridContainer>
            )}
            <GridContainer>
              {/* <CustomiFrame id="superset"></CustomiFrame> */}
              {
                <div
                  id="superset"
                  style={{
                    width: "100%",
                    bottom: 0,
                    position: !showDashboard ? "absolute" : "static",
                    visibility: showDashboard ? "visible" : "hidden",
                  }}
                >
                  <iframe
                    title="myiframe"
                    style={{ width: "100%", height: "600px" }}
                  />
                </div>
              }
            </GridContainer>
          </CardBody>
        </Card>

        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={AddAlert}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
        />
      </Grid>
    </GridContainer>
  );
}
