import { DATAX } from "URL";
import axios from "axios";

export const fetchDataSources = async (organisationId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-sources?organisationId=${organisationId}`);

    if (response.status === 200) {
      callback(response.data);
    }
    else {
      callback([]);
    }
  } catch (error) {
    console.error("Unable to fetch data sources for organisation", organisationId, error.message);
    callback(false);
  }
};

export const fetchDataSource = async (dataSourceId, orgId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-sources/${dataSourceId}?organisationId=${orgId}`);

    callback(response.status, response.data);
  } catch (error) {
    console.error("Unable to fetch data source for id", dataSourceId, error.message);
    callback(false);
  }
};

export const createOrModifyDataSource = async (dataSource, dataSourceId, callback) => {
  try {
    var response;
    if (dataSourceId && dataSourceId !== "") {
      response = await axios.put(`${DATAX}/data-sources/${dataSourceId}`, dataSource);
    }
    else {
      response = await axios.post(`${DATAX}/data-sources`, dataSource);
    }

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error("Unable to create/modify data source", error.message);
    callback({});
  }
};

export const deleteDataSource = async (dataSourceId, organisationId, callback) => {
  try {
    const response = await axios.delete(`${DATAX}/data-sources/${dataSourceId}?organisationId=${organisationId}`);
    if (response.status === 204) {
      callback(true);
    }
  } catch (error) {
    console.error("Unable to delete data source ", dataSourceId, error.message);
    callback(false);
  }
};

export const fetchDataSourcesIdValues = async (organisationId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-sources/id-values?organisationId=${organisationId}`);

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error("Unable to fetch data sources id-values for organisation", organisationId, error.message);
    callback([]);
  }
};
