import React, { useEffect } from "react";
// @material-ui/core components
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { RiRobot2Line } from "react-icons/ri";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import PropTypes from "prop-types";

const CustomSwitch = styled((props) => (
  <Switch
    focusVisibleClassName="focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.65,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    backgroundColor: "#fff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const styles = (theme) => ({
  "@global": {
    ".body-no-scroll": {
      overflow: "auto !important",
    },
    ".ml-flag": {
      position: "relative",
      paddingLeft: "5px",
      cursor: "pointer"
    },
    ".ml-flag::after": {
      content: '"Machine Learning Enabled"',
      position: "absolute",
      padding: "5px 10px",
      borderRadius: "4px",
      top: "-40px",
      left: "50%",
      transform: "translateX(-15%)",
      whiteSpace: "nowrap",
      opacity: 0,
      pointerEvents: "none",
      transition: "opacity 0.3s ease",
      zIndex: 9999,
      color: "#d0347d",
      backgroundColor: "#fbedf3 !important",
      boxShadow: "0 0 2px 0 #ebdfed, 0 6px 12px -4px #ebdfed",
      border: "3px solid rgb(255, 255, 255)",
    },
    ".ml-flag:hover::after": {
      opacity: 1,
    },
  },
  topDialog: {
    top: "-95px",
  },
  titleWrapper: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      "& button": {
        padding: 0,
      },
    },
  },
  tableWrapper: {
    minWidth: 500,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderCollapse: "unset",
  },
});

const useStyles = makeStyles(styles);

export default function SayaDialogSuggestMatchRules(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    dialogTitle,
    labelledRuleDTO,
    handleRuleChange,
    loadingState,
  } = props;

  useEffect(() => {
    return () => {
      console.log(
        "Component Suggest Match unloading cleanup to be done here!!!"
      );
    };
  }, []);

  useEffect(() => {
    // Add the 'body-no-scroll' class to the body when the Dialog is open
    if (open) {
      document.body.classList.add("body-no-scroll");
    } else {
      // Remove the 'body-no-scroll' class when the Dialog is closed
      document.body.classList.remove("body-no-scroll");
    }
    console.log("labelledRuleDTO", labelledRuleDTO);
    // Cleanup the class when the component unmounts
    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [open]);

  return (
    <Dialog
      //fullScreen
      classes={{
        paper: classes.topDialog,
      }}
      open={open}
      scroll={"body"}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
      style={{ minHeight: 250 }}
      hideBackdrop
    >
      <DialogTitle
        id="draggable-dialog-title"
        style={{ cursor: "move" }}
        className={classes.titleWrapper}
      >
        {dialogTitle}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ float: "right", color: "red" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ minHeight: "120px" }}>
        {/* <DialogContent style={{ height: "550px", width: "550px"}}> */}
        <Table className={classes.tableWrapper}>
          <TableBody>
            {labelledRuleDTO?.length > 0 ? (
              labelledRuleDTO?.map((item, index) => (
                <TableRow
                  key={index}
                  className="tblFont"
                  style={{ height: "2px", padding: "8px 4px" }}
                >
                  <TableCell
                    scope="row"
                    style={{ height: "2px", padding: "8px 4px" }}
                  >
                    <div className="flex space-x-1 items-center">
                      <span>{item.columnIdSrc1}</span>
                      <span className="pl-2 inline-block ml-flag">
                        {item.hexScoreUse && (
                          <RiRobot2Line color="rgb(221 60 124 / 62%)" />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell style={{ height: "2px", padding: "8px 4px" }}>
                    {item.columnIdSrc2}
                  </TableCell>
                  <TableCell style={{ height: "2px", padding: "8px 4px" }}>
                    <CustomSwitch
                      key={index}
                      //    checked={this.state.switchState['switch-' + index]}
                      checked={item.matchCriteria}
                      onChange={(event, checked) =>
                        handleRuleChange(index, event, checked)
                      }
                      value="checkedB"
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center", padding: "20px" }}>
                  {loadingState ? "Loading..." : ""}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

SayaDialogSuggestMatchRules.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  labelledRuleDTO: PropTypes.arrayOf(PropTypes.object),
  handleRuleChange: PropTypes.func,
};
