import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import Loader from "../../components/Loader/Loader";
import classNames from "classnames";
import {
  Grow,
  TextareaAutosize,
  Typography,
  Button,
  Tab,
  Tabs,
  TabContext,
  TabList,
  TabPanel,
} from "@mui/material";
import Box from "@mui/material/Box";
// core components
import { useLocation, useSearchParams } from "react-router-dom";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import SayaCombo from "../../components/CustomComboBox/SAYAComboBox";
import DynamicTable from "./DynamicTable";
import { ReconXContext } from "context/ReconX/ReconXContext";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext";
import { fetchReconUnitsData } from "action-handlers/DashboardActionHandler";
import { showFailureNotification } from "action-handlers/NotificationsHandler";
import { API_RECON_PROCESS_FAILED } from "messages/DashboardMessages";
import { saveFormLayout } from "action-handlers/ResolveXActionHandler";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ConfigureMetaData() {
  const [activeTab, setActiveTab] = React.useState(0);
  const [dropDownData, setDropDownData] = useState([]);
  const [selectedReconUnit, setSelectedReconUnit] = useState("");
  const [selectedFormLayout, setFormLayout] = useState("3-COLUMN");
  const [dataSrcData, setDataSrcData] = useState([]);
  const [showTick, setShowTick] = useState(false);
  const metaDataRef = useRef({ selectedRecon: {} });

  const ReconXContextData = useContext(ReconXContext);
  const { globalDropDownData, globalDataSrcData } = ReconXContextData.state;

  const UserAuthContext = useContext(UserAuthDetailsContext);
  const { userId, reconUnitId } = UserAuthContext.state;
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // useEffect(() => {
  //   const ruid = searchParams.get('ruid');
  //   if (ruid) {

  //   }
  // }, [location.search, searchParams]);

  const callbackFetchReconUnitData = (
    userId,
    assignedReconUnitData,
    reconUnitDropDownData,
    apiStatus
  ) => {
    if (apiStatus) {
      const gds = JSON.parse(JSON.stringify(globalDataSrcData));
      gds[0].label = reconUnitDropDownData[0].dataSrcConfigId1 || gds[0]?.label;
      gds[1].label = reconUnitDropDownData[0].dataSrcConfigId2 || gds[1]?.label;
      setDataSrcData(gds);
      const dd = reconUnitDropDownData.filter((gdd) => gdd.workflowEnabled);
      const reconUnitSelected = Number(dd[0]?.value);
      setDropDownData(dd);
      setSelectedReconUnit(reconUnitSelected);
      metaDataRef.current.selectedRecon = dd[0];
    }
  };

  useEffect(() => {
    setFormLayout(metaDataRef.current.selectedRecon?.formLayout);
  }, [selectedReconUnit]);
  useEffect(() => {
    if (globalDropDownData.length === 0 && userId !== 0) {
      fetchReconUnitsData(userId, callbackFetchReconUnitData);
    } else {
      const dd = globalDropDownData.filter((gdd) => gdd.workflowEnabled);
      const reconUnitSelected = +dd[0]?.value || "";
      setSelectedReconUnit(reconUnitSelected);
      setDropDownData(dd);
      metaDataRef.current.selectedRecon = dd[0];

      const gds = JSON.parse(JSON.stringify(globalDataSrcData));
      if (gds?.length > 0) {
        gds[0].label = dd[0]?.dataSrcConfigId1;
        gds[1].label = dd[0]?.dataSrcConfigId2;
        setDataSrcData(gds);
      }
    }
  }, [globalDropDownData, userId]);

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };

  const handleReconUnitChange = (event) => {
    setSelectedReconUnit(event.target.value);
    setActiveTab(0);
    const selectedItem = dropDownData.filter(
      (dd) => dd.value === event.target.value
    )[0];

    const updatedPayload = dataSrcData;
    updatedPayload[0].label = selectedItem?.dataSrcConfigId1;
    updatedPayload[1].label = selectedItem?.dataSrcConfigId2;
    setDataSrcData(updatedPayload);
    metaDataRef.current.selectedRecon = selectedItem;
  };

  const tabsStyles = {
    minHeight: "38px",
    "& button": {
      fontSize: "14px",
      padding: "5px 16px",
      minHeight: "36px",
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
    },
    "& button.Mui-selected": {
      border: "1px solid #C4CDD5",
    },
  };

  const formLayoutOptions = [
    {
      value: "1-COLUMN",
      label: "1-COLUMN",
    },
    {
      value: "2-COLUMN",
      label: "2-COLUMN",
    },
    {
      value: "3-COLUMN",
      label: "3-COLUMN",
    },
  ];

  const callbackFormlayout = (res, flag) => {
    if (flag) {
      setShowTick(true);
      setTimeout(() => {
        setShowTick(false);
      }, 2000);
    } else {
      setShowTick(false);
    }
  };
  const handleOpsProcessUpdate = (recId, formLayoutName) => {
    saveFormLayout(recId, formLayoutName, callbackFormlayout);
  };

  const handleFormLayout = (event) => {
    setFormLayout(event.target.value);
    const reconObj = dropDownData.filter(
      (ops) => ops.reconUnitId === selectedReconUnit
    );
    handleOpsProcessUpdate(reconObj[0]?.reconUnitId, event.target.value);
    //console.log("handleFormLayout", reconObj[0].reconUnitId, event.target.value);
  };

  return (
    <GridContainer style={{ margin: "0 -25px 0 -15px" }}>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <GridContainer
            spacing={0}
            style={{ marginBottom: "20px", alignItems: "center" }}
          >
            <GridItem xs={6} sm={4} md={2} disableSpacing={true}>
              <SayaCombo
                optionsData={dropDownData.filter((ops) => ops.workflowEnabled)}
                value={selectedReconUnit}
                defaultValue={selectedReconUnit}
                labelText="Ops Processes"
                groupText="Selected Ops Process"
                name="selectedReconUnit"
                handleChange={handleReconUnitChange}
                className="Select"
                height="20px"
                headerCombo
              />
            </GridItem>
            <GridItem xs={6} sm={4} md={2}>
              <SayaCombo
                optionsData={formLayoutOptions}
                value={selectedFormLayout}
                defaultValue={selectedFormLayout}
                labelText="Form Layout"
                groupText="Form Layout"
                name="formLayout"
                handleChange={handleFormLayout}
                className="Select"
                height="20px"
                headerCombo
              />
            </GridItem>
            <GridItem xs={3} sm={3} md={3}>
              {showTick && (
                <div class="tick-wrapper">
                  <svg
                    class="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                    width="30px"
                    height="30px"
                  >
                    <circle
                      class="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      class="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
              )}
            </GridItem>
          </GridContainer>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={tabsStyles}
              >
                {dataSrcData.map((menu, index) => {
                  if (
                    dropDownData.filter(
                      (rec) => rec.reconUnitId === selectedReconUnit
                    )[0]?.reconEnabled === false &&
                    dropDownData.filter(
                      (rec) => rec.reconUnitId === selectedReconUnit
                    )[0]?.workflowEnabled === true &&
                    index === 1
                  ) {
                    return null;
                  }
                  return (
                    <Tab
                      iconPosition="start"
                      label={menu.label}
                      {...a11yProps(0)}
                      key={`${menu.label}${menu.value}`}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <CustomTabPanel value={activeTab} index={0}>
              <DynamicTable
                {...{
                  activeTab,
                  dropDownData,
                  selectedReconUnit,
                  reconObj: metaDataRef.current.selectedRecon,
                }}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <DynamicTable
                {...{
                  activeTab,
                  dropDownData,
                  selectedReconUnit,
                  reconObj: metaDataRef.current.selectedRecon,
                }}
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
