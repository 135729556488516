import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from "react";
import randomColor from "randomcolor";

import { Box, Tabs, Tab, Typography, Grid, ListItemIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RiRobot2Line } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import {
  AssignmentLateOutlined,
  SimCardAlertOutlined,
  AssignmentTurnedInOutlined,
  FileCopyOutlined,
  KeyboardArrowDown,
  ArrowDropDownOutlined,
  LightbulbOutlined,
  KeyboardBackspace,
} from "@mui/icons-material";

import { MaterialReactTable } from "material-react-table";
import { useInfiniteQuery } from "@tanstack/react-query";
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext";
import { ReconXContext } from "context/ReconX/ReconXContext";
import { fetchReconUnitsData } from "action-handlers/DashboardActionHandler";
import {
  fetchReconQueryDataInfinite,
  fetchNwayReconDataInfinite,
} from "action-handlers/ReconXActionHandler";
import {
  showFailureNotification,
  showSuccessNotification,
} from "action-handlers/NotificationsHandler";
import {
  API_RECON_PROCESS_FAILED,
  API_LOADFORM_FAILED,
} from "messages/DashboardMessages";
import { notificationParams } from "../../variables/general";

import styles from "./ReconStyles";
import { fetchReconQueryDataCount } from "action-handlers/ReconXActionHandler";
// import { fetchReconQueryData } from "action-handlers/ReconXActionHandler";
import Skeleton from "@mui/material/Skeleton";

function a11yProps(index) {
  return {
    id: `reg-tab-${index}`,
    "aria-controls": `reg-tabpanel-${index}`,
  };
}

import DaysDatePicker from "../../components/DaysDatePicker";
import NWayReconOptions from "./NWayReconOptions";
import { fetchFormData } from "action-handlers/ReconXActionHandler";
import ReconXMatches from "./ReconXMatches";
import { fetchReconWorkflowDetails } from "action-handlers/ReconXActionHandler";
import { fetchReconLinksData } from "action-handlers/ReconXActionHandler";
import { API_RECON_QUERY_FAILED } from "messages/DashboardMessages";
import ReconXUpload from "./ReconXUpload";
import {
  cellHandler,
  changeColor,
  reconStatusHandler,
  statusHandler,
  statusTextHandler,
  transformToUpperCase,
} from "../../components/utils";
import Snackbar from "components/Snackbar/Snackbar";
import { fetchAudioInfo } from "action-handlers/ReconXActionHandler";
import { fetchRulesData } from "action-handlers/ReconXActionHandler";
import TableCellPopover from "components/Table/TableCellPopover";

import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { API } from "URL";
import { fetchNWayReconProcesses } from "action-handlers/NWayReconProcessHandler";
import { getUserData } from "views/Dashboard/DashboardUtil";
import { fetchDataSetsIdValues } from "action-handlers/DataSetHandler";
import { fetchDatasetsByNWayReconProcess } from "action-handlers/NWayReconProcessHandler";
import { getNWayFormData } from "action-handlers/NWayReconProcessHandler";
import NWayReconXMatches from "./NWayReconXMatches";

function ReconXWorkspace(props) {
  const theme = useTheme();
  const [dropDownData, setDropDownData] = useState([]);
  const [tenorPeriod, setTenorPeriod] = useState({});
  const [selectedReconUnit, setSelectedReconUnit] = useState("");
  const [dataSrcData, setDataSrcData] = useState([]);
  const [dataSetVal, setDataSetVal] = useState("");
  // const [totalRowCount, setTotalRowCount] = useState(0);
  const [dataCounts, setDataCounts] = useState("");
  const [sorting, setSorting] = useState([]);
  //store pagination state in your own state
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 500, //customize the default page size
  });

  // const [reconUnitFlowKey, setReconUnitFlowKey] = useState("");
  // const [reconUnitFlowName, setReconUnitFlowName] = useState("");
  const [reconUnitData, setReconUnitData] = useState({
    reconUnitFlowKey: "",
    reconUnitFlowName: "",
  });
  const [showFormObject, setShowFormObject] = useState(false);
  const [reconItemLabel, setReconItemLabel] = useState("Suggested Matches");
  const [rowDetailFormObject, setRowDetailFormObject] = useState({});
  const [delinkButtonClicked, setDelinkButtonClicked] = useState(false);
  const [linkButtonClicked, setLinkButtonClicked] = useState(false);
  const [sentToResolveX, setSendingToResolveX] = useState(false);
  const [workflowEnabledReconUnit, setWorkflowEnabledReconUnit] =
    useState(false);
  const [tableLinksColumns, setTableLinksColumns] = useState([]);
  const [tableLinksColumns2, setTableLinksColumns2] = useState([]);
  const [tableLinksData, setTableLinksData] = useState([]);
  const [tableLinksData2, setTableLinksData2] = useState([]);
  const [canFetchData, setFetchData] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [auditData, setAuditData] = useState([]);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [rulesData, setRulesData] = useState([]);
  const [formToggle, setFormToggle] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const rowVirtualizerInstanceRef = useRef(null);
  const tableContainerRef = useRef(null);
  const pageDetailsRef = useRef({ page: 0, fetchedRecords: 0 });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [count, setCount] = useState(0);
  const [canFetchSuggestedMatches, setFetchSuggestedMatches] = useState(true);
  const [showATC, setShowATC] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const ReconXContextData = useContext(ReconXContext);
  const [dataSets, setDatasets] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedNwayTblRowData, setNwayTblRowData] = useState("");

  const activeRowRef = useRef(null);

  const { reconUnitId } = UserAuthContext.state;
  const { userId, orgId } = getUserData();
  const {
    globalDataSrcData,
    reconData,
    selectedReconUnitName = "",
    selectedRecData,
    globalReconColumns,
    selectedReconStatus: srs,
    selectedReconUnit: sruid,
    tenorPeriod: tp,
  } = ReconXContextData.state;

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const options = [
    { label: "All Time", value: "All" },
    { label: "Last 1 Day", value: "P-1D" },
    { label: "Last 7 Days", value: "P-7D" },
    { label: "Last 30 Days", value: "P-30D" },
    { label: "Last 90 Days", value: "P-90D" },
    { label: "Last 180 Days", value: "P-180D" },
    { label: "Last 1 Year", value: "P-1Y" },
  ];

  const [reconStatus, setReconStatus] = useState(srs || "");
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const columnRef = useRef({ columns: {} });
  let reconOptionsData = reconData;

  reconOptionsData.forEach((item) => {
    if (item.value.toLowerCase() === "all") {
      item.icon = <FileCopyOutlined />;
    } else if (item.value.toLowerCase() === "open") {
      item.icon = <AssignmentLateOutlined />;
    } else if (item.value.toLowerCase() === "reconciled") {
      item.icon = <AssignmentTurnedInOutlined />;
    }
  });

  // useEffect(() => {
  //   if (selectedReconUnit !== "") {
  //     console.log(
  //       "=================>>>>>>>>>>>>>>>> useeffect",
  //       selectedReconUnit
  //     );
  //     fetchRulesData(
  //       selectedReconUnit,
  //       (headerData, ruleDataFetched = [], apiStatus) => {
  //         const hasATC =
  //           ruleDataFetched.filter((rule) => rule.autonomousTolerance).length >
  //           0;
  //         setShowATC(hasATC);
  //       }
  //     );
  //   }
  // }, [selectedReconUnit]);

  useEffect(() => {
    // setSelectedReconUnit(reconUnitId || sruid);
    setReconStatus(srs);
    setTenorPeriod(tp);
  }, [sruid, srs, tp]);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  useEffect(() => {
    //do something when the pagination state changes
    // console.log("ttttttt====================>>>>", pagination);
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleReconUnitChange = (event) => {
    setSelectedReconUnit(event.target.value);
    setActiveTab(0);
    const selectedItem = dropDownData.filter(
      (dd) => dd.value === event.target.value
    )[0];
    // const updatedPayload = dataSrcData;
    // updatedPayload[0].label = selectedItem?.dataSrcConfigId1;
    // updatedPayload[1].label = selectedItem?.dataSrcConfigId2;

    //setDataSrcData(updatedPayload);
    const payload = {
      //globalDataSrcData: [...updatedPayload],
      selectedReconUnit: event.target.value,
      selectedReconUnitName: selectedItem?.clientReconName,
      globalMetaDataSrcColNames: [],
      ruleUnlabelledDTO: [],
      globalMetaDataSrcColIds: [],
    };
    ReconXContextData.saveReconProcessesData(payload);
    UserAuthContext.setReconUnitId(event.target.value);
  };

  const handleDataSetChange = (event) => {
    //setActiveTab(0);
    const selectedDSItem = dataSets?.filter(
      (dd) => dd.value === event.target.value
    )[0];

    setDataSetVal(event.target.value);
    // const updatedPayload = dataSrcData;
    // updatedPayload[0].label = selectedItem?.dataSrcConfigId1;
    // updatedPayload[1].label = selectedItem?.dataSrcConfigId2;

    //setDataSrcData(updatedPayload);
    const payload = {
      //globalDataSrcData: [...updatedPayload],
      selectedReconUnit: event.target.value,
      selectedReconUnitName: selectedDSItem?.value,
      globalMetaDataSrcColNames: [],
      ruleUnlabelledDTO: [],
      globalMetaDataSrcColIds: [],
    };
    ReconXContextData.saveReconProcessesData(payload);
    //UserAuthContext.setReconUnitId(event.target.value);
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleReconStatusChange = (event) => {
    setReconStatus(event.target.value);
    // setActiveTab(0);
    const payload = {
      selectedReconStatus: event.target.value,
    };
    ReconXContextData.saveReconProcessesData(payload);
  };

  const callbackFetchReconWorkflowDetails = (
    reconWorkflowFlag,
    flowDefnKeyName,
    flowDefnName,
    apiStatus
  ) => {
    if (apiStatus) {
      setWorkflowEnabledReconUnit(reconWorkflowFlag);
      setReconUnitData({
        reconUnitFlowKey: flowDefnKeyName,
        reconUnitFlowName: flowDefnName,
      });
    } else {
      setWorkflowEnabledReconUnit(reconWorkflowFlag);
      setReconUnitData({
        reconUnitFlowKey: "",
        reconUnitFlowName: "",
      });
      showFailureNotification(API_RECON_WORKFLOW_FAILED, callbackNotification);
    }
  };

  const callbackFetchNWayReconUnitData = (data = []) => {
    const nWayReconProcesses = data.map((d) => {
      return {
        ...d,
        label: d.nwayReconProcess?.nwayReconName,
        value: d.nwayReconProcess?.nwayReconProcessId,
      };
    });
    // let reconUnitDropDownData = reconUnitDropDownData1.filter(
    //   (data) => data.reconEnabled
    // );

    // const gds = JSON.parse(JSON.stringify(globalDataSrcData));

    // const record = reconUnitDropDownData.filter(
    //   (data) => data.reconUnitId === reconUnitId
    // );

    // const preSelectedVal = reconUnitDropDownData.filter(
    //   (op) => op.reconUnitId === +id
    // );

    // let reconUnitSelected = "";
    // let selectedReconUnitName = "";
    // if (preSelectedVal && preSelectedVal.length > 0) {
    //   reconUnitSelected = preSelectedVal[0]?.reconUnitId;
    //   gds[0].label = preSelectedVal[0]?.dataSrcConfigId1;
    //   gds[1].label = preSelectedVal[0]?.dataSrcConfigId2;
    //   selectedReconUnitName = preSelectedVal[0]?.clientReconName;
    // } else if (record && record.length > 0) {
    //   reconUnitSelected = record[0]?.reconUnitId;
    //   gds[0].label = record[0]?.dataSrcConfigId1;
    //   gds[1].label = record[0]?.dataSrcConfigId2;
    //   selectedReconUnitName = record[0]?.clientReconName;
    // } else {
    //   reconUnitSelected = reconUnitDropDownData[0]?.reconUnitId;
    //   gds[0].label = reconUnitDropDownData[0]?.dataSrcConfigId1;
    //   gds[1].label = reconUnitDropDownData[0]?.dataSrcConfigId2;
    //   selectedReconUnitName = reconUnitDropDownData[0]?.clientReconName;
    // }

    // const payload = {
    //   globalDropDownData: reconUnitDropDownData1,
    //   selectedReconUnit: reconUnitSelected,
    //   selectedReconUnitName,
    //   globalDataSrcData: gds,
    // };
    setDropDownData(nWayReconProcesses);

    setSelectedReconUnit(nWayReconProcesses[0]?.value);
    // setDataSrcData(gds);
    // ReconXContextData.saveReconProcessesData(payload);
    // UserAuthContext.setReconUnitId(reconUnitSelected);

    // //here we are calling fetch the workflow details attached to reconUnit
    // fetchReconWorkflowDetails(
    //   reconUnitSelected,
    //   callbackFetchReconWorkflowDetails
    // );
  };

  const loadInitialPageData = async (userId) => {
    fetchNWayReconProcesses(userId, callbackFetchNWayReconUnitData);
  };

  useEffect(() => {
    if (userId !== 0) {
      loadInitialPageData(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (selectedReconUnit && orgId) {
      fetchDatasetsByNWayReconProcess(selectedReconUnit, orgId, (data) => {
        setDatasets(data.map((d) => ({ label: d.value, value: d.id })));
        setDataSetVal(data[0]?.id);
      });
    }
  }, [orgId, selectedReconUnit]);

  const fetchTableData = async () => {
    const loggedInUser = JSON.parse(sessionStorage.userData).userId;
    const bodyPayload = {}; //need to add table filter here deepu
    // for (let i = 1; i < 3; i++) {
    let url = `${API}/reconquery/nwayrecon/fetchRowCount?nwayReconProcessId=${selectedReconUnit}&nwayReconStatus=${reconStatus}&dataSetId=${dataSetVal}&loggedInUser=${loggedInUser}`;
    if (tenorPeriod.tenorPeriodVal === "CUSTOM") {
      url = `${API}/reconquery/nwayrecon/fetchRowCount?nwayReconProcessId=${selectedReconUnit}&nwayReconStatus=${reconStatus}&fromDate=${tenorPeriod.startDate}&toDate=${tenorPeriod.endDate}&dataSetId=${dataSetVal}&loggedInUser=${loggedInUser}`;
    } else if (tenorPeriod.tenorPeriodVal !== "All") {
      url = `${API}/reconquery/nwayrecon/fetchRowCount?nwayReconProcessId=${selectedReconUnit}&nwayReconStatus=${reconStatus}&tenorPeriod=${tenorPeriod.tenorPeriodVal}&dataSetId=${dataSetVal}&loggedInUser=${loggedInUser}`;
    }
    const response = await axios.post(`${url}`, bodyPayload);
    if (response.status === 200) {
      setDataCounts(response.data.totalRows);
      // if (i === 2) {
      //   setFetchData(false);
      //   // try {
      //   //   rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
      //   // } catch (error) {
      //   //   console.error("Error moving in scroll position =", error);
      //   // }
      // }
    }
    // }
  };

  useEffect(() => {
    if (
      reconStatus &&
      tenorPeriod?.tenorPeriodVal &&
      selectedReconUnit &&
      dataSetVal
    ) {
      setGlobalFilter("");
      fetchTableData();
    }
  }, [
    reconStatus,
    tenorPeriod.startDate,
    tenorPeriod.endDate,
    tenorPeriod.tenorPeriodVal,
    selectedReconUnit,
    // activeTab,
    pagination.pageIndex,
    pagination.pageSize,
    dataSetVal,
  ]);

  useEffect(() => {
    if (canFetchData) {
      fetchTableData();
      setCount(count + 1);
    }
  }, [canFetchData]);

  // const handleTabChange = (e, value) => {
  //   setActiveTab(value);
  //   setSelectedRowId(null);
  //   UserAuthContext.setReconDataSource(+value === 0 ? 1 : 2);
  // };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onDateChange = useCallback(
    async (date) => {
      // setActiveTab(0);
      setTenorPeriod(date);
      const payload = {
        tenorPeriod: date,
      };
      ReconXContextData.saveReconProcessesData(payload);
    },
    [tenorPeriod]
  );

  const ruleStyles = {
    padding: "0px 10px",
    width: "100%",
    minWidth: "140px",
    justifyContent: "space-between",
    height: "35px",
    display: "inline-flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "& b": {
      color: "red",
    },
  };

  const mlStyles = {
    top: "2px",
    display: "inline-flex",
    marginLeft: "8px",
    position: "relative",
  };

  const [activeCell, setActiveCell] = useState(null);
  const [zoomedColumn, setZoomedColumn] = useState(null);

  // Function to handle clicks on table header cells
  const handleHeaderCellClick = (cell) => {
    setActiveCell(cell);
  };

  // Use useEffect to scroll to the active header cell when it changes
  useEffect(() => {
    scrollToActiveCell(activeCell);
  }, [activeCell]);

  const scrollToActiveCell = (cell) => {
    if (!cell) return; // Exit if cell is null or undefined

    if (activeCell === cell) {
      // Escape special characters for valid CSS selector
      const safeCell = CSS.escape(cell);

      const headerCell1 = document.querySelector(
        `table th.reconMatchCell1[data-cell="${safeCell}"]`
      );
      const headerCell2 = document.querySelector(
        `table th.reconMatchCell2[data-cell="${safeCell}"]`
      );

      if (headerCell1 && headerCell2) {
        const reconMatchCellTag = document.querySelectorAll(
          "table th.reconMatchCell1"
        );

        reconMatchCellTag.forEach((element) => {
          element.classList.remove("activeCell");
        });
        headerCell1.classList.add("activeCell");

        if (zoomedColumn) {
          zoomedColumn.classList.remove("activeCell");
        }

        headerCell2.scrollIntoView({ behavior: "smooth", inline: "center" });
        setTimeout(() => {
          headerCell2.classList.add("activeCell");
          setZoomedColumn(headerCell2);
        }, 800);
      }
    }
  };

  const tableLinkDataHandler = (reconColumns, reconRuleData, dataSource) => {
    let updatedRuleColData = [];
    let columnsData = reconColumns;

    if (reconRuleData?.length > 0) {
      updatedRuleColData = reconRuleData.map((item) => ({
        ...item,
        matchingId: `${item.columnIdSrc1} - ${item.columnIdSrc2}`,
      }));

      if (updatedRuleColData?.length > 0) {
        columnsData.forEach((col) => {
          col.options = {
            customHeadRender: (columnMeta) => {
              const columnName = columnMeta.label;

              const matchingRule = updatedRuleColData.find(
                (rule) =>
                  rule[`columnIdSrc${dataSource}`].toLowerCase() ===
                  columnName.toLowerCase()
              );

              if (matchingRule) {
                return (
                  <th
                    className={`reconMatchCell${dataSource}`}
                    onClick={() =>
                      dataSource === 1
                        ? handleHeaderCellClick(matchingRule.matchingId)
                        : null
                    }
                    data-cell={matchingRule.matchingId}
                  >
                    <div
                      //className={classes.ruleHeader}
                      style={{
                        ...ruleStyles,
                        position: "relative",
                        backgroundColor: randomColor({
                          seed: matchingRule.matchingId,
                          luminosity: "light",
                          format: "rgba",
                          alpha: 0.3,
                        }),
                      }}
                    >
                      {columnName}
                      {matchingRule.hexScoreUse && (
                        <span>
                          {matchingRule.hexScoreUse ? (
                            <div className="popover-container">
                              <b className="popover-button" style={mlStyles}>
                                <RiRobot2Line color="rgb(221 60 124 / 62%)" />
                              </b>
                              <div className="popover-content">
                                Machine Learning Enabled
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      )}
                      <ArrowDropDownOutlined className={"cellIcon"} />
                    </div>
                  </th>
                );
              }

              // Default header cell rendering
              return (
                <th>
                  <div
                    // className={`${classes.ruleHeader} ${classes.ruleDefaultHeader}`}
                    style={{
                      ...ruleStyles,
                    }}
                  >
                    {columnName}
                  </div>
                </th>
              );
            },
          };
        });
      }
    }

    columnsData?.forEach((col) => {
      col.options = {
        ...col.options,
        customBodyRender: (value, tableMeta, updateValue) =>
          value?.length > 30 ? (
            <div className="desc" style={{ maxWidth: "250px" }}>
              <TableCellPopover limit={30} content={value} />
            </div>
          ) : (
            value
          ),
      };
    });

    return columnsData;
  };

  const callbackReconLinksData = (
    reconColumns,
    reconData,
    ds,
    apiStatus,
    rld = []
  ) => {
    if (apiStatus) {
      if (ds === 1) {
        setTableLinksData(reconData);
        let linkTableColumns1 = "";
        if (rld?.length > 0) {
          linkTableColumns1 = tableLinkDataHandler(reconColumns, rld, ds);
        } else {
          linkTableColumns1 = reconColumns;
        }
        setTableLinksColumns(linkTableColumns1);
      } else if (ds === 2) {
        setTableLinksData2(reconData);

        let linkTableColumns2 = "";
        if (rld?.length > 0) {
          linkTableColumns2 = tableLinkDataHandler(reconColumns, rld, ds);
        } else {
          linkTableColumns2 = reconColumns;
        }

        setTableLinksColumns2(linkTableColumns2);
      }
      columnRef.current.columns = reconColumns;
      const payload = {
        globalReconColumns: reconColumns,
      };
      ReconXContextData.saveReconProcessesData(payload);
      // linkOptions.count = reconData.length;
      // setLinkTableOptions(linkOptions);
    } else {
      if (ds === 1) {
        setTableLinksData([]);
        setTableLinksColumns([]);
      } else if (ds === 2) {
        setTableLinksData2([]);
        setTableLinksColumns2([]);
      }
      showFailureNotification(API_RECON_QUERY_FAILED, callbackNotification);
    }
    // scrollTop();
  };

  const callbackLoadFormData = (
    rawDataId,
    reconUnitId,
    dataSrcConfigId,
    reconLinkId,
    formObject,
    apiStatus
  ) => {
    if (apiStatus) {
      setRowDetailFormObject(formObject);
      setShowFormObject(apiStatus);
      if (sessionStorage.reconStatus.toLowerCase() !== "open") {
        fetchRulesData(
          reconUnitId,
          (headerData, ruleDataFetched, apiStatus) => {
            setRulesData(ruleDataFetched);
            fetchReconLinksData(
              reconUnitId,
              1,
              reconLinkId,
              ruleDataFetched,
              callbackReconLinksData
            );
            fetchReconLinksData(
              reconUnitId,
              2,
              reconLinkId,
              ruleDataFetched,
              callbackReconLinksData
            );
          }
        );
        //calling only if reconstatus selected in frontend is OPEN
      }
    } else {
      setShowFormObject(apiStatus);
      showFailureNotification(API_LOADFORM_FAILED, callbackNotification);
    }
  };

  const loadFormData = (selectedRecData) => {
    fetchFormData(
      selectedRecData.rawDataId,
      selectedRecData.reconUnitId,
      selectedRecData.dataSrcConfigId,
      selectedRecData.reconLinkId,
      callbackLoadFormData
    );
  };

  const transformRowData = (rowData) => {
    //since in the mui table data the functional component way implementation is having issue where it cannot refere the
    //component state variables. Hence using the globalReconColumns object
    const grc = columnRef.current.columns || globalReconColumns;
    const selectedRowData = {};
    let index = grc
      .map(function (d) {
        return d["label"];
      })
      .indexOf("reconUnitId");
    selectedRowData["reconUnitId"] = rowData[index];
    index = grc
      .map(function (d) {
        return d["label"];
      })
      .indexOf("dataSrcConfigId");
    selectedRowData["dataSrcConfigId"] = rowData[index];
    index = grc
      .map(function (d) {
        return d["label"];
      })
      .indexOf("reconLinkId");
    selectedRowData["reconLinkId"] = rowData[index];
    index = grc
      .map(function (d) {
        return d["label"];
      })
      .indexOf("rawDataId");
    selectedRowData["rawDataId"] = rowData[index];
    index = grc
      .map(function (d) {
        return d["label"];
      })
      .indexOf("reconStatusId");
    selectedRowData["reconStatusId"] = rowData[index];
    return selectedRowData;
  };

  const transformRowDataToSayaRecData = (row) => {
    if (!row._valuesCache) {
      return transformRowData(row);
    }
    if (+row._valuesCache.reconStatusId === 0) {
      setLinkButtonClicked(false);
      setSendingToResolveX(false);
      setReconItemLabel("Suggested Matches");
    } else {
      setLinkButtonClicked(true);
      setSendingToResolveX(true);
      setReconItemLabel("Linked Records");
    }
    return row._valuesCache;
  };

  const callbackAuditInfo = (audit_data) => {
    setAuditData(audit_data);
  };

  const auditInfoHandler = (rawDataIdVal, userIdVal) => {
    fetchAudioInfo(rawDataIdVal, userIdVal, callbackAuditInfo);
  };

  const handleRowDoubleClick = (rowData) => {
    console.log("========>>>> rowData", rowData);
    setNwayTblRowData(rowData);
    if (showFormObject || rowData.original.reconStatusId === 0) {
      setFormToggle(true);
    } else {
      setFormToggle(false);
    }

    // if(rowData.original.reconStatusId === 0){
    //   setFormToggle(true);
    // }

    const res = transformRowDataToSayaRecData(rowData);
    sessionStorage.setItem(
      "reconStatus",
      +res.reconStatusId === 0 ? "OPEN" : "RECONCILED"
    );

    const payload = {
      selectedRecData: {
        reconUnitId: res.reconUnitId,
        dataSrcConfigId: res.dataSrcConfigId,
        reconLinkId: res.reconLinkId,
        rawDataId: res.rawDataId,
        reconStatusId: res.reconStatusId,
        reconStatus: +res.reconStatusId === 0 ? "OPEN" : "RECONCILED",
      },
    };
    console.log("===========>>>> rowData", rowData);
    getNWayFormData(
      selectedReconUnit,
      dataSetVal,
      rowData?.original?.rowFingerprint,
      (data) => {
        console.log("========>>> data", data);
        // if (data.length > 0) {
        setShowFormObject(true);
        setRowDetailFormObject(data);
        // }
      }
    );
    // fetchAudioInfo(res.rawDataId, userId, callbackAuditInfo);
    // ReconXContextData.saveReconProcessesData(payload);
    // loadFormData(res);
  };

  const handleBackToReconSpace = () => {
    setShowFormObject(false);
    setTableLinksColumns([]);
    setTableLinksColumns2([]);
    setTableLinksData([]);
    setTableLinksData2([]);
    setDelinkButtonClicked(false);
    setFetchData(true);
    setAuditData([]);
    setGlobalFilter("");
    const payload = {
      globalReconSuggestTableData: [],
      suggestedMatchesFinal: [],
    };
    ReconXContextData.saveReconProcessesData(payload);
  };

  const validateDataSourceMetaData = (
    globalMetaDataSrcColNames,
    globalMetaDataSrcColIds
  ) => {
    const gds = ReconXContextData.state.globalDataSrcData;
    let validateDataSourceMetaData = false;
    if (
      globalMetaDataSrcColNames[gds[0].value] !== "" &&
      globalMetaDataSrcColNames[gds[0].value] !== undefined &&
      globalMetaDataSrcColIds[gds[0].value] !== "" &&
      globalMetaDataSrcColIds[gds[0].value] !== undefined &&
      globalMetaDataSrcColNames[gds[1].value] !== "" &&
      globalMetaDataSrcColNames[gds[1].value] !== undefined &&
      globalMetaDataSrcColIds[gds[1].value] !== "" &&
      globalMetaDataSrcColIds[gds[1].value] !== undefined
    ) {
      validateDataSourceMetaData = true;
    }
    return validateDataSourceMetaData;
  };

  const toggleFormHandler = () => {
    setFormToggle(!formToggle);
  };

  const handleRowClick = (row) => {
    setSelectedRowId(row.id);
  };

  function debounce(func, timeout = 1000) {
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this);
    }, timeout);
  }

  const {
    data,
    fetchNextPage,
    isError,
    isFetching,
    isLoading,
    isRefetching,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      "table-data",
      reconStatus,
      selectedReconUnit,
      tenorPeriod,
      dataSetVal,
      count,
    ],
    queryFn: ({ pageParam = 0 }) =>
      fetchNwayReconDataInfinite(pageParam, {
        ...{
          reconStatus,
          selectedReconUnit,
          tenorPeriod,
          dataSetVal,
        },
      }),
    enabled:
      tenorPeriod.tenorPeriodVal !== undefined &&
      selectedReconUnit !== "" &&
      dataSetVal !== "",
    getNextPageParam: (_lastGroup, pages) => {
      pageDetailsRef.current.page = pages.length - 1;
      return pages.length;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  let reconColumns = [];
  let rd = [];
  let tc = [];

  const allData = useMemo(() => {
    data &&
      data.pages[0]?.headerNames?.forEach((item) => {
        reconColumns.push({
          id: item.columnName,
          name: item.columnName,
          label: item.columnValue,
          value: item.columnValue,
          dataType: item.dataType,
          accessorKey: item.columnName,
          header: transformToUpperCase(item.columnValue) || item.columnValue,
        });
      });
    data &&
      data.pages[0]?.txnDataDTO?.forEach((data) => {
        rd.push(data);
      });

    const reconStatusIndex = reconColumns.findIndex(
      (item) => item.id === "reconStatusId"
    );
    if (reconStatusIndex !== -1) {
      const column = JSON.parse(JSON.stringify(reconColumns[reconStatusIndex]));
      column.header = "N-Way Recon Status";
      column.accessorKey = "r_s";
      column.name = "r_s";
      column.id = "r_s";
      reconColumns.unshift(column);
    }

    let reconColData = reconColumns; // reconStatusHandler(reconColumns);
    reconColData.forEach((item) => {
      item.options = {
        sort: true,
      };
    });
    const payload = {
      globalReconColumns: reconColData,
    };
    columnRef.current.columns = reconColData;
    ReconXContextData.saveReconProcessesData(payload);
    return { headerNames: reconColData, txnDataDTO: rd };
  }, [data]);

  const totalDBRowCount = dataCounts; //totalRowCount;
  tc = allData?.headerNames || [];

  let flatData = useMemo(
    () => (data ? data?.pages.flatMap((page) => page?.txnDataDTO) ?? [] : []),
    [data]
  );

  flatData = flatData.map((fd) => ({
    ...fd,
    r_s: cellHandler(fd.reconStatusId),
  }));

  let totalFetched = flatData.length;
  pageDetailsRef.current.fetchedRecords = totalFetched;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          !isRefetching &&
          totalFetched < totalDBRowCount
        ) {
          debounce(fetchNextPage, 1000);
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting, columnFilters, globalFilter]);

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    if (hasNextPage) {
      fetchMoreOnBottomReached(tableContainerRef.current);
    }
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    // Apply the overflow hidden style to the body
    document.body.style.overflow =
      window.innerWidth > 900 && !showFormObject ? "hidden" : "";

    return () => {
      // Clean up the style when the component unmounts
      document.body.style.overflow = "";
    };
  }, [showFormObject]);

  // Scroll to the active row on component mount
  useEffect(() => {
    const scrollToActiveRow = () => {
      const activeRow = document.querySelector(".recon-row-active");
      if (activeRow) {
        activeRow.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    // Delay to ensure the table is fully rendered before scrolling
    const timeoutId = setTimeout(scrollToActiveRow, 500); // Adjust delay if required

    return () => clearTimeout(timeoutId); // Cleanup on unmount
  }, [flatData, selectedRowId]);

  const handleBackToNway = () => {
    setShowFormObject(false);
  };

  const tabItems = useMemo(() => {
    const seen = new Set();
    const uniqueTabs = [];
    flatData.forEach((row) => {
      const tabKey = `${row.reconUnitId}-${row.clientReconName}`;
      if (!seen.has(tabKey)) {
        seen.add(tabKey);
        uniqueTabs.push({
          reconUnitId: row.reconUnitId,
          clientReconName: row.clientReconName,
        });
      }
    });
    return uniqueTabs;
  }, [flatData]);

  const currentReconUnitId = tabItems[selectedTab]?.reconUnitId;
  const filteredData = useMemo(() => {
    if (currentReconUnitId == null) return [];
    return flatData.filter((item) => item.reconUnitId === currentReconUnitId);
  }, [flatData, currentReconUnitId]);

  useEffect(() => {
    if (selectedTab >= tabItems.length) {
      setSelectedTab(0);
    }
  }, [tabItems, selectedTab]);

  return (
    <Grid container>
      <Card plain>
        <CardHeader
          color="primary"
          plain
          pageHeader
          className={classes.actionHeader}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              sx={{ display: "flex", zIndex: 9 }}
              disableSpacing={true}
            >
              {!showFormObject && (
                <Box
                  height="100%"
                  width="100%"
                  className={`${classes.reconComboWrapper} ${
                    showFormObject ? classes.disabledstate : ""
                  }`}
                  display="flex"
                  justifyContent="start"
                >
                  {dropDownData && dropDownData.length > 0 ? (
                    <SAYAComboBox
                      optionsData={dropDownData}
                      value={selectedReconUnit}
                      defaultValue={selectedReconUnit}
                      labelText="N-Way Recon Process"
                      groupText="N-Way Recon Process"
                      handleChange={handleReconUnitChange}
                      height="20px"
                      headerCombo
                      inputProps={{ readOnly: showFormObject }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      width={350}
                      sx={{
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        bgcolor:
                          theme.palette.type === "dark" ? "#1a1546" : "#fff",
                        marginTop: "18px",
                        marginRight: "20px",
                      }}
                      height={37}
                      animation="wave"
                    />
                  )}

                  <SAYAComboBox
                    optionsData={dataSets}
                    value={dataSetVal}
                    defaultValue={dataSetVal}
                    labelText="Data Set"
                    groupText="Data Set"
                    handleChange={handleDataSetChange}
                    height="20px"
                    headerCombo
                    inputProps={{ readOnly: showFormObject }}
                  />

                  {dropDownData && dropDownData.length > 0 && (
                    <div className={classes.datePicker}>
                      <DaysDatePicker
                        onDateChange={onDateChange}
                        showFormObject={showFormObject}
                        tenorPeriod={tenorPeriod}
                        options={options}
                      />
                    </div>
                  )}
                  {reconOptionsData && reconOptionsData.length > 0 && (
                    <SAYAComboBox
                      optionsData={reconOptionsData}
                      value={reconStatus}
                      defaultValue={reconStatus}
                      labelText="Recon Status"
                      groupText="Recon Status"
                      handleChange={handleReconStatusChange}
                      height="20px"
                      headerCombo
                      inputProps={{ readOnly: showFormObject }}
                      // iconProp = {AssignmentLateOutlined}
                    />
                  )}
                </Box>
              )}

              {/* {!showFormObject && (
                <ReconXUpload {...{ ...props, setFetchData }} />
              )} */}
              {showFormObject && (
                <Box
                  className={classes.formObjectHeader}
                  style={{
                    width:
                      rowDetailFormObject?.txnDataDTO[0]?.reconStatusId !== 0
                        ? "76%"
                        : "77%",
                  }}
                  display="flex"
                  justifyContent="start"
                >
                  <div>
                    N-Way Recon Status :
                    {statusTextHandler(
                      selectedNwayTblRowData?.original?.reconStatusId
                    )}
                  </div>
                  <span className="mr-3 opacity-50">|</span>
                  <div>
                    Data Set :
                    <span>
                      {dataSets?.filter((dd) => dd.value === dataSetVal)[0]
                        .label || dataSetVal}
                    </span>
                  </div>
                  <span className="mr-3 opacity-50">|</span>
                  <div>
                    Recon Name:
                    <span>
                      {selectedNwayTblRowData?.original?.clientReconName}
                    </span>
                  </div>
                  <span className="mr-3 opacity-50">|</span>
                  <div>
                    Raw Data ID :
                    <span>{selectedNwayTblRowData?.original?.rawDataId}</span>
                  </div>

                  {/* 
                  <div>
                    Data Source :<span>{dataSrcData[activeTab]?.label}</span>
                  </div> */}
                  {/* 
                  {showFormObject && (
                    <div
                      className={classes.formObjectBtn}
                      onClick={toggleFormHandler}
                    >

                      <span className={formToggle ? "active" : ""}>
                        <KeyboardArrowDown />
                      </span>
                    </div>
                  )} */}
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className={classes.actionGrid}
              justifyContent="flex-end"
            >
              {/* {showFormObject && (
                  <Tooltip title="Go Back">
                    <KeyboardBackspace
                      className="mr-4 MuiSvgIconRoot"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      fontSize="large"
                      onClick={handleBackToNway}
                    />
                  </Tooltip>
                )} */}
              <NWayReconOptions
                {...{
                  ...props,
                  count,
                  setCount,
                  handleBackToReconSpace,
                  showFormObject,
                  delinkButtonClicked,
                  linkButtonClicked,
                  setLinkButtonClicked,
                  setDelinkButtonClicked,
                  sentToResolveX,
                  workflowEnabledReconUnit,
                  reconStatus,
                  rowDetailFormObject,
                  reconUnitData,
                  tableLinksData,
                  setTableLinksData,
                  setTableLinksColumns,
                  validateDataSourceMetaData,
                  pagination,
                  tableLinksData2,
                  setTableLinksData2,
                  setTableLinksColumns2,
                  auditInfoHandler,
                  loadFormData,
                  pageDetails: pageDetailsRef.current,
                  setFetchSuggestedMatches,
                  showATC,
                  setShowATC,
                  setFetchData,
                  selectedNwayReconUnit: selectedReconUnit,
                }}
              />
            </Grid>
          </Grid>
        </CardHeader>
        {showFormObject && <NWayReconXMatches data={rowDetailFormObject} />}
      </Card>

      {!showFormObject && (
        <Box
          className={classes.reconTableWrapper}
          id="recon-action-header"
          sx={{ width: "100%", borderBottom: 0, borderColor: "divider" }}
        >
          <Tabs
            className={classes.tabsWrapper}
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Dynamic Recon Unit Tabs"
          >
            {tabItems.map((tab, index) => (
              <Tab
                key={tab.reconUnitId}
                label={tab.clientReconName}
                value={index}
              />
            ))}
          </Tabs>
          {/* <Tabs
            className={classes.tabsWrapper}
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Tab"
          >
            {dataSrcData.map((menu, index) => (
              <Tab
                iconPosition="start"
                label={`${menu.label} - (${dataCounts[index + 1] || 0})`}
                {...a11yProps(0)}
                key={`${menu.label}${menu.value}`}
              />
            ))}
          </Tabs> */}
          {/* <Box className="bg-white flex justify-between">
            <Typography className={classes.reconTitle}>
              <span className="capitalize"> Recon Workspace - </span>
              <span className="capitalize">{selectedReconUnitName}</span>
            </Typography>
          </Box> */}
          <Box sx={{ marginTop: "0px" }}>
            {reconStatus && tenorPeriod && selectedReconUnit && (
              <MaterialReactTable
                columns={tc}
                data={filteredData}
                enablePagination={false}
                enableColumnActions={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                muiTopToolbarProps={{
                  sx: {
                    "&.MuiToolbar-root": {
                      minHeight: "44px",
                      height: "44px",
                      position: "absolute",
                      marginTop: "-45px",
                      right: "31px",
                      width: "45%",
                      backgroundColor: "transparent !important",
                      "& > .MuiBox-root": {
                        padding: "0px",
                        height: "44px",
                        alignItems: "center",
                      },
                      "& .MuiIconButton-root": {
                        padding: "0.3rem",
                        borderRadius: "4px",
                        border: "1px solid #e4e4e7",
                        marginLeft: "10px",
                        background: "#fff",
                        "&:hover svg": {
                          color: "#d0347d",
                        },
                        "& svg": {
                          width: "18px",
                          height: "18px",
                        },
                      },
                    },
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    "&.MuiToolbar-root": {
                      minHeight: "44px",
                      height: "44px",
                    },
                  },
                }}
                // renderTopToolbarCustomActions={({ table }) => (
                //   <p className="text-sm">
                //     <span className="capitalize"> ReconX Workspace - </span>
                //     <span className="capitalize">{selectedReconUnitName}</span>
                //   </p>
                // )}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                  className: "bg-white", // Tailwind background
                  ref: tableContainerRef, //get access to the table container element
                  sx: {
                    maxHeight: "calc(78vh - 120px)",
                    border: "1px solid #e5e7eb", // light border like ShadCN theme
                    boxShadow: "0",
                  }, //give the table a max height
                  onScroll: (
                    event //add an event listener to the table container element
                  ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                        color: "error",
                        children: "Error loading data",
                      }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                renderBottomToolbarCustomActions={() => (
                  <Typography className="text-sm">
                    Fetched {totalFetched} of {totalDBRowCount} total rows.
                  </Typography>
                )}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  showAlertBanner: isError,
                  showProgressBars: isFetching,
                  sorting,
                }}
                rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
                rowVirtualizerProps={{ overscan: 4 }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => handleRowClick(row),
                  onDoubleClick: (event) => {
                    setCurrentRowData(row);
                    handleRowDoubleClick(row);
                  },
                  className: `cprop hover:bg-gray-100 transition-colors ${
                    selectedRowId === row.id ? "recon-row-active" : ""
                  }`,
                  // sx: {
                  //   cursor: "pointer",
                  // },
                  sx: {
                    cursor: "pointer",
                    "&:hover td": {
                      backgroundColor: "#f5f5f8 !important", // Background color on hover
                    },
                    "& td": {
                      backgroundColor:
                        selectedRowId === row.id
                          ? "#f5f5f8 !important"
                          : "inherit",
                    },
                    backgroundColor:
                      selectedRowId === row.id ? "#f5f5f8" : "inherit", // Background color when row is clicked
                  },
                })}
                muiTableBodyCellProps={({ cell, column, row, table }) =>
                  cell.column.id !== "r_s"
                    ? {
                        sx: {
                          padding: "12px",
                          borderBottom: "1px solid #f3f4f6", // subtle row separation
                        },
                        className: "text-gray-800 hover:bg-gray-50 mui-cell",
                        title: cell.getValue(),
                      }
                    : changeColor(cell.getValue())
                }
                renderEmptyRowsFallback={() => (
                  <Typography
                    sx={{
                      textAlign: "center",
                      position: "absolute",
                      left: tc?.length > 10 ? "20%" : "40%",
                      top: "35%",
                      opacity: "0.5",
                    }}
                  >
                    No Records to display
                  </Typography>
                )}
                // muiTableBodyCellProps={({ cell, column, row, table, className ='custim' }) => {
                //   console.log('cell==', cell);
                //   const cv = cell.getValue();
                //   return changeColor(cv);
                // }}

                muiTableHeadCellProps={{
                  sx: {
                    fontWeight: "bold",

                    letterSpacing: "0.05em",
                    borderBottom: "1px solid #d1d5db",
                    "& .Mui-TableHeadCell-Content-Wrapper": {
                      whiteSpace: "nowrap",
                    },
                  },
                  className: "text-gray-700 bg-gray-100", // ShadCN-like header style
                }}

                // muiTableBodyRowProps={{

                // }}
              />
            )}
          </Box>
        </Box>
      )}
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message}
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </Grid>
  );
}

export default ReconXWorkspace;
