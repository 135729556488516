"use client";

import { AddAlert, PanoramaFishEye, RemoveRedEyeOutlined, ViewAgenda, ViewCarousel } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem, Typography } from "@mui/material";
import { DATAX } from "URL";
import { deleteExecutionData, fetchExecutionConfiguration } from "action-handlers/IngestionExecutionsHandler";
import { showFailureNotification, showSuccessNotification } from "action-handlers/NotificationsHandler";
import axios from "axios";
import DaysDatePicker from "components/DaysDatePicker";
import {
  FileCog,
  FolderCog,
  FolderInput,
  Layers,
  RefreshCw,
  Upload
} from "lucide-react";
import { MaterialReactTable } from "material-react-table";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notificationParams } from "variables/general";
import { getUserData, periodData } from "views/Dashboard/DashboardUtil";
import Snackbar from "../../components/Snackbar/Snackbar";
import IconButton from "../../components/v2/Button/IconButton";
import Popper from "../../components/v2/Popper";
import BasicSelect from "../../components/v2/Select";
import {
  bottomToolbarStyles,
  tableHeadCellProps,
  topToolbarStyles,
} from "../../theme/MUITableStyles";

// Sample status options
const statusOptions = [
  { value: "ALL", label: "All" },
  { value: "SUCCESS", label: "Successful" },
  { value: "FAILURE", label: "Failed" },
  { value: "PARTIAL", label: "Partially Successful" },
  { value: "DUPLICATE", label: "Duplicated" },
];

const IngestionHistory = () => {
  const [dataSets, setDataSets] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState("");
  const [ingestionData, setIngestionData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState({
    startDate: null,
    endDate: null,
    tenorPeriodVal: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [ingestionStatus, setIngestionStatus] = useState("ALL");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const tableContainerRef = useRef(null);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [anchorElAction2, setAnchorElAction2] = useState(null);

  const [searchParams] = useSearchParams();
  const dataSetId = parseInt(searchParams.get("dataSetId"));
  const [count, setCount] = useState(0);
  const [executionId, setExecutionId] = useState();
  const [notificationObj, setNotificationObj] = useState(notificationParams);


  const handleOpenExecutionActionMenu = (event, row) => {
    setExecutionId(row._valuesCache.executionId);
    setAnchorElAction(event.currentTarget);
  };

  const handleOpenActionMenu2 = (event) => {
    setAnchorElAction2(event.currentTarget);
  };

  const handleCloseExecutionActionMenu = () => {
    setAnchorElAction(null);
  };

  const handleCloseActionMenu2 = () => {
    setAnchorElAction2(null);
  };

  const viewConfigurationAction = () => {
    fetchExecutionConfiguration(executionId, (data) => {

      if (Object.entries(data) != 0) {
        navigate(
          `/saya-platform/admin/data-formats/${data.dataFormatId}`, {
          state: {
            entity: data,
            selectedItem: {},
            forms: {
              readOnly: true
            }
          },
        }
        )
      }
      else {
        handleCloseExecutionActionMenu();
        showFailureNotification(`Failed to fetch execution configuration for ${executionId}`, (notificationObj) => {
          setNotificationObj(notificationObj);
        });
      }

    });
  };

  const deleteExecutionDataAction = () => {
    deleteExecutionData(executionId, (isSuccessful) => {
      handleCloseExecutionActionMenu();

      if (isSuccessful) {
        showSuccessNotification(`Successfully deleted execution data for ${executionId}`, (notificationObj) => {
          setNotificationObj(notificationObj);
        });
      }
      else {
        showFailureNotification(`Failed to delete execution data for ${executionId}`, (notificationObj) => {
          setNotificationObj(notificationObj);
        });
      }

    });
    handleCloseExecutionActionMenu();
  };

  const { orgId, userId } = getUserData();

  const fetchDatasets = async (orgId) => {
    try {
      const response = await axios.get(
        `${DATAX}/data-sets/id-values?organisationId=${orgId}`
      );
      if (response.status === 200) {
        setDataSets(response.data);
        let prevDataSetId;
        if (dataSetId) {
          prevDataSetId = response.data.filter(
            (d) => d.id === dataSetId
          )[0]?.id;
        }
        setSelectedDatasets(
          prevDataSetId ? +prevDataSetId : response.data[0]?.id || ""
        );
      }
    } catch (ex) {
      console.error("Exception in fetchDatasets", ex.message);
    }
  };

  const fetchIngestionHistory = async (
    dataSetId,
    ingestionStatus,
    selectedPeriod,
    orgId,
    userId
  ) => {
    try {
      setLoading(true);
      let url = `${DATAX}/ingestions/executions?organisationId=${orgId}&userId=${userId}&ingestionStatus=${ingestionStatus}&dataSetId=${dataSetId}`;
      if (selectedPeriod.tenorPeriodVal) {
        if (selectedPeriod.tenorPeriodVal === "All") {
        } else if (selectedPeriod.tenorPeriodVal !== "CUSTOM") {
          url += `&period=${selectedPeriod.tenorPeriodVal}`;
        } else {
          url += `&startDate=${selectedPeriod.startDate}&endDate=${selectedPeriod.endDate}`;
        }
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        setLoading(false);
        setIngestionData(response.data);
      }
    } catch (ex) {
      console.error("Exception in fetchIngestionHistory", ex.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchDatasets(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    if (selectedDatasets) {
      if (orgId && userId && ingestionStatus && selectedPeriod) {
        fetchIngestionHistory(
          selectedDatasets,
          ingestionStatus,
          selectedPeriod,
          orgId,
          userId
        );
      }
    }
  }, [orgId, userId, selectedDatasets, ingestionStatus, selectedPeriod, count]);

  const columns = [
    {
      header: "Execution Id",
      accessorKey: "executionId",
    },
    {
      header: "Source Identifier",
      accessorKey: "sourceIdentifier",
    },
    {
      header: "Ingestion Status",
      accessorKey: "ingestionStatus",
      Cell: ({ row }) => {
        const status = row.original.ingestionStatus;
        return (
          <span
            className={
              status?.includes("Successful")
                ? "text-green-600"
                : status?.includes("Failed")
                  ? "text-red-600"
                  : "text-yellow-600"
            }
          >
            {status}
          </span>
        );
      },
    },
    {
      header: "Record Count",
      accessorKey: "successRecordCount",
    },
    {
      header: "Errors",
      accessorKey: "errors.recordCount",
      Cell: ({ row }) => {
        return (
          // <button
          //   className="text-pink-600 underline hover:text-pink-800"
          //   onClick={() =>
          //     alert(`Show Errors overlay for ${row.original.executionId}`)
          //   }
          // >
          <span>{row?.original?.errors?.recordCount || 0}</span>
          // </button>
        );
      },
    },
    // {
    //   header: "Data Format", // Column title
    //   accessorKey: "dataFormat.value", // Access the nested value
    //   Cell: ({ row }) => (
    //     <span>
    //       {row.original.dataFormat?.value || "N/A"} {/* Render the value or fallback */}
    //     </span>
    //   ),
    // },
    {
      header: "Data Format",
      accessorKey: "dataFormat.value",
      Cell: ({ row }) => (
        <button
          className="text-pink-600 underline hover:text-pink-800"
          onClick={() =>
            navigate(
              `/saya-platform/admin/data-formats/${row.original.dataFormat?.id}`
            )
          }
        >
          {row.original.dataFormat?.value}
        </button>
      ),
    },
    {
      header: "Data Source",
      accessorKey: "dataSource.value",
      Cell: ({ row }) => (
        <button
          className="text-pink-600 underline hover:text-pink-800"
          onClick={() => {
            navigate(
              `/saya-platform/admin/data-sources/${row.original.dataSource?.id}`
            );
          }}
        >
          {row.original.dataSource?.value}
        </button>
      ),
    },

    {
      header: "Ingestion Type",
      accessorKey: "ingestionType",
    },

    {
      header: "Time Taken",
      accessorKey: "timeTaken",
    },

    {
      header: "Actions",
      accessorKey: "actions",
      Cell: ({ row }) => (
        <div className="relative">
          <Typography
            key={"actions"}
            className="action-menu"
            onClick={(event) => handleOpenExecutionActionMenu(event, row)}
            noWrap
            sx={{
              mr: 2,
              cursonr: "pointer",
              flexGrow: 1,
              fontWeight: 300,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <MoreHorizIcon sx={{ color: "#000" }} />
          </Typography>
        </div>
      ),
    },
  ];

  const handleDelete = () => {
    if (selectedRows.length === 0) {
      alert("No rows selected");
      return;
    }
    // Confirm and delete
    const confirmed = window.confirm(
      `Are you sure you want to delete ${selectedRows.length} items?`
    );
    if (confirmed) {
      // Perform delete action (soft delete)
      const remaining = ingestionData.filter(
        (row) => !selectedRows.includes(row)
      );
      setIngestionData(remaining);
      setSelectedRows([]);
      alert("Selected execution data deleted successfully!");
    }
  };

  const handleManualUpload = () => {
    navigate(`/saya-platform/datax/data-upload?dataSetId=${selectedDatasets}`);
  };

  const handleRefresh = () => {
    setCount(count + 1);
  };

  const handleIC = (event) => {
    handleOpenActionMenu2(event);
  };

  const handleVC = () => {
    alert("VC Button Clicked");
  };

  const handleDatasets = (values) => {
    if (values.length > 0) {
      setSelectedDatasets(values.filter((v) => v !== "All"));
    } else {
      setSelectedDatasets(values);
    }
  };

  const handlePeriod = (value) => {
    setSelectedPeriod(value);
  };

  const redirect = (path) => {
    //navigate(`/saya-platform/admin/${path}`);
    navigate(`/saya-platform/admin/${path}`, {
      state: { selectedItem: "" },
    });
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  return (
    <div className="space-y-4">
      {/* <h1 className="text-xl font-bold">Ingestion History</h1> */}
      <div className="flex items-top justify-between">
        {/* Filters Section */}
        <div className="flex flex-wrap items-top gap-4">
          <div className="w-56">
            <BasicSelect
              {...{
                label: "Data Set",
                name: "Dataset",
                value: selectedDatasets,
                selectData: dataSets,
                optionLabel: "value",
                optionValue: "id",
                onChange: handleDatasets,
                isMulti: false,
              }}
            />
          </div>

          <div className="w-56 ">
            <DaysDatePicker
              onDateChange={handlePeriod}
              showFormObject={false}
              selectedPeriod={selectedPeriod}
              options={periodData}
            />
          </div>

          <div className="w-56">
            <BasicSelect
              label="Ingestion Status"
              name="ingestionStatus"
              value={ingestionStatus}
              placeholder="Select Status"
              selectData={statusOptions}
              optionValue="value"
              optionLabel="label"
              onChange={(val) => setIngestionStatus(val)}
              isMulti={false}
              showSearch={false}
            />
          </div>
        </div>

        {/* Action Buttons */}

        <div className="flex items-center gap-2">
          <Popper message="Manual Upload" placement="bottom">
            <IconButton icon={<Upload />} onClick={handleManualUpload} />
          </Popper>
          <Popper message="DataX Configurations" placement="bottom">
            <IconButton icon={<FolderCog />} onClick={handleIC} />
          </Popper>
          <Popper message="Refresh" placement="bottom">
            <IconButton icon={<RefreshCw />} onClick={handleRefresh} />
          </Popper>
          {/* <Popper message="Delete Execution Data" placement="bottom">
            <IconButton
              icon={<Trash2 />}
              onClick={handleDelete}
              variant="danger"
              placement="top"
            />
          </Popper>
          <Popper message="View Configuration" placement="bottom">
            <IconButton icon={<FileSliders />} onClick={handleVC} />
          </Popper> */}
        </div>
      </div>

      <div className="pt-0">
        {/* Data Grid */}
        <MaterialReactTable
          columns={columns}
          data={ingestionData}
          enableStickyHeader
          state={{ isLoading: loading }}
          enablePinning
          renderTopToolbarCustomActions={({ table }) => (
            <p className="text-base">
              <span className="font-medium"> Ingestion History </span>
            </p>
          )}
          //enableRowSelection
          enablePagination={false}
          enableColumnActions={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          muiTopToolbarProps={topToolbarStyles}
          muiBottomToolbarProps={bottomToolbarStyles}
          muiTableHeadCellProps={tableHeadCellProps}
          // onRowSelectionChange={(selected) => {
          //   console.log("==================>>>>>", selected);
          //   // selected is an object with keys: { rowid: boolean }
          //   // Convert that to an array of row objects
          //   const selectedRowIds = Object.keys(selected).filter(
          //     (key) => selected[key]
          //   );
          //   const selectedRowsData = selectedRowIds.map(
          //     (id) => ingestionData[id]
          //   );
          //   setSelectedRows(selectedRowsData);
          // }}
          initialState={{
            showGlobalFilter: false,
            columnPinning: { right: ["actions"] },
          }}
          muiTableBodyCellProps={{
            className: "text-sm !p-[0.5rem] text-gray-800 !pl-[18px]",
          }}
          muiTablePaperProps={{
            sx: {
              borderTopLeftRadius: "10px",
            },
          }}
          muiTableContainerProps={{
            className: "border border-t",
            ref: tableContainerRef,
            sx: {
              maxHeight: "calc(78vh - 120px)",
              border: "1px solid #e5e7eb",
              boxShadow: "0",
            },
          }}
        />

        <Menu
          sx={{ mt: "14px" }}
          id="menu-appbar"
          anchorEl={anchorElAction}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElAction)}
          onClose={handleCloseExecutionActionMenu}
        >
          <MenuItem key={"view-ingestion-configuration"} onClick={viewConfigurationAction}>
            <div
              style={{
                color: "#000",
                cursor: "pointer",
                display: "flex",
              }}
            >
              <RemoveRedEyeOutlined sx={{ width: "16px" }} />
              <span style={{ marginLeft: "5px", marginTop: "2px" }}>
                View Execution Configuration
              </span>
            </div>
          </MenuItem>
          <MenuItem key={"delete-execution-data"} onClick={deleteExecutionDataAction}>
            <div
              style={{
                color: "#000",
                cursor: "pointer",
                display: "flex",
              }}
            >
              <DeleteIcon sx={{ width: "16px" }} />
              <span style={{ marginLeft: "5px", marginTop: "2px" }}>Delete Execution's Data</span>
            </div>
          </MenuItem>
        </Menu>

        <Menu
          sx={{ mt: "14px" }}
          id="menu-appbar"
          anchorEl={anchorElAction2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElAction2)}
          onClose={handleCloseActionMenu2}
        >
          <MenuItem
            key={"data-source-configurations"}
            onClick={() => {
              redirect("data-sources");
            }}
          >
            <div
              style={{
                color: "#000",
                cursor: "pointer",
                display: "flex",
              }}
            >
              <FolderInput style={{ width: "20px" }} />
              <span style={{ marginLeft: "5px" }}>Data Sources</span>
            </div>
          </MenuItem>
          <MenuItem
            key={"data-format-configurations"}
            onClick={() => {
              redirect("data-formats");
            }}
          >
            <div
              style={{
                color: "#000",
                cursor: "pointer",
                display: "flex",
              }}
            >
              <FileCog style={{ width: "20px" }} />
              <span style={{ marginLeft: "5px" }}>Data Formats</span>
            </div>
          </MenuItem>
          <MenuItem
            key={"data-set-configurations"}
            onClick={() => {
              redirect("data-sets");
            }}
          >
            <div
              style={{
                color: "#000",
                cursor: "pointer",
                display: "flex",
              }}
            >
              <Layers style={{ width: "20px" }} />
              <span style={{ marginLeft: "5px" }}>Data Sets</span>
            </div>
          </MenuItem>
        </Menu>
      </div>

      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={AddAlert}
        message={notificationObj.message}
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </div>
  );
};

export default IngestionHistory;
