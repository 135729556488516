// muiTableStyles.js
import { Typography } from "@mui/material";

export const topToolbarStyles = {
  sx: {
    "&.MuiToolbar-root": {
      minHeight: "44px",
      height: "44px",
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      "& > .MuiBox-root": {
        padding: "0px",
        height: "44px",
        alignItems: "center",
      },
      "& .MuiIconButton-root": {
        padding: "0.3rem",
        borderRadius: "4px",
        border: "1px solid #e4e4e7",
        marginLeft: "10px",
        background: "#fff",
        "&:hover svg": {
          color: "#d0347d",
        },
        "& svg": {
          width: "18px",
          height: "18px",
        },
      },
    },
  },
};

export const bottomToolbarStyles = {
  sx: {
    "&.MuiToolbar-root": {
      minHeight: "44px",
      height: "44px",
    },
  },
};


export const tableHeadCellProps = {
  sx: {
    fontWeight: "bold",
    letterSpacing: "0.05em",
    borderBottom: "1px solid #d1d5db",
    "& .Mui-TableHeadCell-Content-Wrapper": {
      whiteSpace: "nowrap",
    },
  },
  className: "text-gray-700 bg-gray-100",
};



