import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useNavigate, Link } from "react-router-dom";
import { withRouter } from "../../utils/withRouter";
import { alpha } from "@mui/material/styles";

const styles = (theme) => ({
  breadcrumbWrapper: {
    padding: "10px 16px",
    "& ol li": {
      fontSize: 16,
      color: alpha(theme.palette.primary.main, 0.5),
      "&:last-child": {
        color: alpha(theme.palette.primary.main, 0.8),
      },
      "@media (min-width: 0px) and (max-width: 767px)": {
        fontSize: 12,
      },
      "& a": {
        cursor: "pointer",
        textTransform: "capitalize",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& p": {
      fontSize: 16,
      textTransform: "capitalize",
      "@media (min-width: 0px) and (max-width: 767px)": {
        fontSize: 12,
      },
    },
    "& ol li:first-child": {
      display: "none",
    },
    "& ol li:first-child + li": {
      display: "none",
    },
  },
});

const BreadcrumbsComp = (props) => {
  const {
    location: { pathname },
  } = props.router;

  const navigate = useNavigate();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  let pathnames = pathname.split("/").filter((x) => x);
  pathnames.splice(pathnames.indexOf("admin"), 1, "saya-admin");

  return (
    pathnames &&
    pathnames.length > 2 && (
      <Breadcrumbs
        aria-label="breadcrumb"
        className={classes.breadcrumbWrapper}
      >
        {/* {pathnames.length > 0 ? (
        <Link onClick={() => history.push("/")}>Home</Link>
      ) : (
        <Typography> Home </Typography>
      )} */}
        {pathnames.map((name, index) => {
          let routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

          const isLast = index === pathnames.length - 1;
          let linkName = name;
          console.log("linkName", linkName);
          if (name === "saya-admin") {
            if (pathname.includes("data-upload")) {
              linkName = "Manual Upload";
            } else if (pathname.includes("n-way")) {
              linkName = "N Way";
            } else if (pathname.includes("1-way")) {
              linkName = "1 Way";
            }
            else {
              linkName = "Admin";
            }
          } else if (name === "saya-platform") {
            linkName = "Dashboard";
            routeTo = "/saya-platform/dashboard";
          } else if (name === "ops-home") {
            linkName = "ops process";
            routeTo = "/saya-platform/admin/ops-home";
          } else if (name === "ops-crud") {
            linkName = "ops setup";
          } else if (name === "workflows") {
            routeTo = "/saya-platform/admin/workflows";
          } else if (name === "datax") {
            linkName = "DataX";
            routeTo = "/saya-platform/datax";
          } else if (name === "data-formats") {
            linkName = "Data Formats";
            routeTo = "/saya-platform/admin/data-formats";
          } else if (name === "data-sets") {
            linkName = "Data Sets";
            routeTo = "/saya-platform/admin/data-sets";
          } else if (name === "data-sources") {
            linkName = "Data Sources";
            routeTo = "/saya-platform/admin/data-sources";
          }else if (name === "nway-recon-processes") {
            linkName = "N-Way Recon Processes";
            routeTo = "/saya-platform/admin/nway-recon-processes";
          }
          else if (name === "nway-recon-processes") {
            linkName = "N-way Recon Processes";
            routeTo = "/saya-platform/admin/nway-recon-processes";
          }
          else if (name === "saya-reconx") {
            linkName = "ReconX";
          }
    





          return isLast ? (
            <Typography key={name}>{linkName}</Typography>
          ) : (
            <Link to={routeTo} key={name}>
              {linkName}
            </Link>
          );
        })}
      </Breadcrumbs>
    )
  );
};

export default withRouter(BreadcrumbsComp);
