import React, { useState, useEffect } from "react";

export const CheckAccess = (props) => {
  const { children, action, wrapEnabled, elemClass } = props;
  const { allowedPermissions = [] } = props && props.routeData;

  const [render, setRender] = useState(false);

  useEffect(() => {
    if (allowedPermissions.includes(action)) {
      setRender(true);
    } else {
      setRender(false);
    }
  }, [action, allowedPermissions]);

  try {
    if (render) {
      return wrapEnabled && children ? (
        <div className={elemClass || ""}>
          <div>{children}</div>
        </div>
      ) : (
        <div className={elemClass || ""}>{children}</div>
      );
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
