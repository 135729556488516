import { DATAX } from "URL";
import axios from "axios";

export const fetchDataSets = async (organisationId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-sets?organisationId=${organisationId}`);

    if (response.status === 200) {
      callback(response.data);
    }
    else {
      callback([]);
    }
  } catch (error) {
    console.error("Unable to fetch data sets for organisation", organisationId, error.message);
    callback(false);
  }
};

export const fetchDataSet = async (dataSetId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-sets/${dataSetId}`);

    callback(response.status, response.data);
  } catch (error) {
    console.error("Unable to fetch data set for id", dataSetId, error.message);
    callback(false);
  }
};

export const createOrModifyDataSet = async (dataSet, dataSetId, callback) => {
  try {
    var response;
    if (dataSetId && dataSetId !== "") {
      response = await axios.put(`${DATAX}/data-sets/${dataSetId}`, dataSet);
    }
    else {
      response = await axios.post(`${DATAX}/data-sets`, dataSet);
    }

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error("Unable to create/modify data set", error.message);
    callback({});
  }
};

export const deleteDataSet = async (dataSetId, organisationId, callback) => {
  try {
    const response = await axios.delete(`${DATAX}/data-sets/${dataSetId}?organisationId=${organisationId}`);
    if (response.status === 204) {
      callback(`Deleted data set with id ${dataSetId} successfully`);
    }
  } catch (error) {
    console.error("Unable to delete data set ", dataSetId, error.message);
    callback(`Unable to delete data set with id ${dataSetId}`);
  }
};

export const fetchDataSetsIdValues = async (organisationId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-sets/id-values?organisationId=${organisationId}`);

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error("Unable to fetch data sets id-values for organisation", organisationId, error.message);
    callback([]);
  }
};
