import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate } from "react-router-dom";

// Register the components of Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const baseColors = [
  "rgb(255, 99, 132)", // Red-Orange
  "rgb(255, 159, 64)", // Orange
  "rgb(255, 205, 86)", // Yellow
  "rgb(75, 192, 192)", // Green
  "rgb(54, 162, 235)", // Blue
  "rgb(153, 102, 255)", // Purple
  "rgb(255, 99, 71)", // Pink
];

const LineChart = ({
  chartData = [],
  label,
  value,
  datasetLabel,
  groupBy,
  selectedPeriod,
}) => {
  const lineRef = useRef(null);
  const navigate = useNavigate();

  // Group data by dataSetId
  const groupedData = chartData.reduce((acc, curr) => {
    if (!acc[curr[groupBy]]) acc[curr[groupBy]] = [];
    acc[curr[groupBy]].push(curr);
    return acc;
  }, {});

  // Extract unique dates for the X-axis
  const labels = Array.from(
    new Set(chartData.map((item) => item[label]))
  ).sort();

  // Create datasets for each dataSetId
  const datasets = Object.entries(groupedData).map(([dataSetId, data], index) => {
    const ingestionCounts = labels.map((date) => {
      const entry = data.find((item) => item[label] === date);
      return entry ? entry[value] : null;
    });

    const color = baseColors[index % baseColors.length];

    return {
      label: `${data[0].dataSetName}`,
      name: dataSetId,
      data: ingestionCounts,
      borderColor: color,
      backgroundColor: color,
      tension: 0.3,
      pointRadius: 4,
      pointHoverRadius: 6,
    };
  });

  // Chart.js configuration
  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
          padding: 10,
          font: {
            size: 12,
            family: "'Inter', sans-serif",
          },
        },
      },
      title: {
        display: false,
        text: datasetLabel,
        font: {
          size: 16,
          weight: "bold",
          family: "'Inter', sans-serif",
        },
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleFont: {
          size: 14,
          family: "'Inter', sans-serif",
        },
        bodyFont: {
          size: 12,
          family: "'Inter', sans-serif",
        },
        padding: 12,
        cornerRadius: 4,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: "'Inter', sans-serif",
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
        },
        ticks: {
          font: {
            size: 12,
            family: "'Inter', sans-serif",
          },
          stepSize: 30,
        },
      },
    },
  };

  const handleLineClick = (event) => {
    const chart = lineRef.current;
    const elements = chart.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );

    if (elements.length > 0) {
      const element = elements[0];
      const datasetIndex = element.datasetIndex;

      const clickedDataset = datasets[datasetIndex];
      const dataSetId = clickedDataset?.name;

      if (dataSetId) {
        const period = selectedPeriod.tenorPeriodVal;
        navigate(`/saya-platform/datax?dataSetId=${dataSetId}&period=${period}`, {
          state: { selectedPeriod },
        });
      }
    }
  };

  if (chartData.length === 0) {
    return (
      <div className="w-full h-[400px] bg-white rounded-lg shadow-md flex items-center justify-center">
        <p className="text-gray-500 text-sm">
          No data available for the selected criteria.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white w-full h-[420px] rounded-lg shadow-lg overflow-hidden">
      <div className="flex flex-row items-center justify-between px-6 py-3.5 border-b border-gray-200">
        <h2 className="leading-4 sm:leading-normal text-sm font-medium text-gray-800">
          Ingestions per day
        </h2>
      </div>
      <div className="h-[calc(100%-4rem)] p-4">
        <Line
          ref={lineRef}
          data={data}
          options={options}
          onClick={handleLineClick}
        />
      </div>
    </div>
  );
};

export default LineChart;
