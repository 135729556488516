import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { showInfo } from "./DashboardUtil";
import ChartSkeltion from "./ChartSkeltion";
import { API } from "URL";

function ReconXDashboard({
  userId,
  orgId,
  selectedOPSProcess,
  selectedPeriod,
  selectedSorting,
}) {
  const [isLoading, setLoading] = useState(false);
  const [reconxMetrixDetails, setReconMetrixDetails] = useState({});

  const fetchReconxMetrix = async (
    userId,
    selectedOPSProcess,
    selectedPeriod,
    orgId
  ) => {
    try {
      let url = `${API}/recon/metrics/detail?userId=${userId}&organisationId=${orgId}`;
      const ops = selectedOPSProcess.filter((ops) => ops !== "All");
      if (ops.length > 0) {
        setLoading(true);
        url += `&opsProcessIds=${ops}`;
      }

      if (selectedPeriod.tenorPeriodVal) {
        if (selectedPeriod.tenorPeriodVal === "All") {
        } else if (selectedPeriod.tenorPeriodVal !== "CUSTOM") {
          url += `&period=${selectedPeriod.tenorPeriodVal}`;
        } else {
          url += `&startDate=${selectedPeriod.startDate}&endDate=${selectedPeriod.endDate}`;
        }
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        setReconMetrixDetails(response.data);
        setLoading(false);
      }
    } catch (ex) {
      console.error("========>>>>Exception in fetchOPSProcesses", ex.message);
      setReconMetrixDetails({});
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      userId !== 0 &&
      selectedOPSProcess.length > 0 &&
      selectedPeriod &&
      orgId
    ) {
      fetchReconxMetrix(userId, selectedOPSProcess, selectedPeriod, orgId);
    }
  }, [userId, orgId, selectedOPSProcess, selectedPeriod]);

  const getCharts = () => {
    if (Object.keys(reconxMetrixDetails).length > 0) {
      return (
        <div className="grid flex-1 scroll-mt-20 items-start gap-6 md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:gap-6">
          <div className="bg-white themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out">
            <BarChart
              {...{
                chartData: reconxMetrixDetails?.averageReconPercentage || [],
                label: "reconProcessName",
                value: "reconPercentage",
                datasetLabel: "Recon Percentage",
                title: "Average Recon Percentage",
                sortKey: "reconPercentage",
                selectedSorting,
                selectedPeriod,
              }}
            />
          </div>

          <div className=" themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out">
            <PieChart
              {...{
                chartData: reconxMetrixDetails?.reconRecordCount || [],
                label: "reconProcessName",
                value: "recordCount",
                title: "Open status records per ops process",
                sortKey: "recordCount",
                selectedSorting,
                selectedPeriod,
                source: "ReconX",
              }}
            />
          </div>
        </div>
      );
    }
    return showInfo(`No data available for the selected criteria.`);
  };

  if (isLoading) {
    return <ChartSkeltion />;
  }
  return getCharts();
}

export default React.memo(ReconXDashboard);
