import { KeyboardBackspace } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import ToastMessage from "components/UtilComponents/ToastMessage";
import BasicSelect from "components/v2/Select";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API, DATAX } from "URL";
import { getUserData } from "views/Dashboard/DashboardUtil";
import DataIngestionHistory from "./DataIngestionHistory";
//import Popper from "components/v2/Popper";

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    minHeight: "38px",
    "& button": {
      fontSize: "14px",
      padding: "5px 16px",
      minHeight: "36px",
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
    },
    "& button.Mui-selected": {
      border: "1px solid #C4CDD5",
    },
  },
}));

export default function ManualUpload() {
  const classes = useStyles();
  const [dataSets, setDataSets] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState("");
  const [opsProcesses, setOPSProcesses] = useState([]);
  const [selectedOPSProcess, setSelectedOPSProcess] = useState("");
  const [auditData, setAuditData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filesA, setFilesA] = useState([]);
  const [filesB, setFilesB] = useState([]);
  const [toastData, setToastData] = useState({
    isOpen: false,
    message: "",
    color: "",
  });
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const { userId, reconUnitId } = UserAuthContext.state;
  const [count, setCount] = useState(0);
  const filesRef = useRef({ filesA: [] });
  const [searchParams] = useSearchParams();
  const dataSetId = parseInt(searchParams.get("dataSetId"));

  const { orgId } = getUserData();
  const validateFiles = async (files = []) => {
    let apiResponse;
    let files_names = [];
    for (const file of files) {
      files_names.push(file.name);
    }

    try {
      const response = await axios.post(
        `${DATAX}/data-sets/${selectedDatasets}/validate`,
        {
          organisationId: orgId,
          sourceIdentifiers: files_names,
        }
      );
      if (response.status === 200) {
        apiResponse = response.data;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      apiResponse = { response: "Error" };
    }

    const updatedFiles = files.map((f) => {
      const matchedResponse = apiResponse.find(
        (response) => `./${response.fileName}` === f.relativePath
      );

      return {
        f,
        ...matchedResponse,
      };
    });

    filesRef.current.filesA = updatedFiles;
    setCount(count + 1);
  };

  useEffect(() => {
    if (filesA.length > 0 && selectedDatasets) {
      validateFiles(filesA);
    }
  }, [filesA.length, selectedDatasets]);

  const onDropA = useCallback((acceptedFiles) => {
    setFilesA((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const onDropB = useCallback((acceptedFiles) => {
    setFilesB((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps: getRootPropsA, getInputProps: getInputPropsA } =
    useDropzone({
      onDrop: onDropA,
      multiple: true,
    });

  const { getRootProps: getRootPropsB, getInputProps: getInputPropsB } =
    useDropzone({
      onDrop: onDropB,
      multiple: true,
    });

  const fileUploadHandler = async (ds, sds, inputFiles) => {
    setUploading(true);
    try {
      const res = await axios.post(
        `${DATAX}/data-sets/${sds}/uploads?userId=${userId}&organisationId=${orgId}`,
        inputFiles,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "*/*",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (res.status === 200) {
        // fetchAuditData(selectedOPSProcess);
        ds === 1 ? setFilesA([]) : setFilesB([]);
        filesRef.current.filesA = [];
        setToastData({
          isOpen: true,
          message: res.message || "File upload success",
          color: "success",
        });
      }
    } catch (ex) {
      console.error("Exception in handleUpload", ex.message);
      setToastData({
        isOpen: true,
        message: ex.message || "File upload unsuccessful",
        color: "error",
      });
    } finally {
      setUploading(false);
      setProgress(0);
    }
  };

  const fetchDatasets = async (orgId) => {
    try {
      const response = await axios.get(
        `${DATAX}/data-sets/id-values?organisationId=${orgId}`
      );
      if (response.status === 200) {
        setDataSets(response.data);

        if (dataSetId) {
          setSelectedDatasets(dataSetId);
        }
        else {
          setSelectedDatasets(response.data[0]?.id || "");
        }
      }
    } catch (ex) {
      console.error("Exception in fetchDatasets", ex.message);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchDatasets(orgId);
    }
  }, [orgId]);

  const handleDatasets = (values) => {
    if (values.length > 0) {
      setSelectedDatasets(values.filter((v) => v !== "All"));
    } else {
      setSelectedDatasets(values);
    }
  };

  const handleUpload = () => {
    if (filesA.length > 0) {
      const inputFilesA = new FormData();
      filesA.forEach((file) => inputFilesA.append("files", file));
      fileUploadHandler(1, selectedDatasets, inputFilesA);
    }
    // if (filesB.length > 0) {
    //   const inputFilesB = new FormData();
    //   filesB.forEach((file) => inputFilesB.append("files", file));
    //   fileUploadHandler(2, inputFilesB);
    // }
  };

  const removeFile = (setFiles, index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const showFileStatus = (f) => {
    const payload = filesRef.current.filesA.filter(
      (file) => file.fileName === f?.name
    )[0];
    if (payload?.validationStatus === "Failed") {
      return (
        <Box display="flex" alignItems="center" sx={{ mr: "10px" }}>
          <Tooltip
            title={payload?.validationMessage}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#ffa68a", // Change the background color
                  color: "white", // Change the text color
                  fontSize: "13px", // Optional: Adjust the font size
                },
              },
            }}
          >
            <IconButton edge="end" aria-label="Failure">
              <ErrorOutlineIcon sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    } else if (payload?.validationStatus === "Successful") {
      return (
        <Box display="flex" alignItems="center" sx={{ mr: "10px" }}>
          <Tooltip
            title={payload?.validationMessage}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#83cba5", // Change the background color
                  color: "white", // Change the text color
                  fontSize: "13px", // Optional: Adjust the font size
                },
              },
            }}
          >
            <IconButton edge="end" aria-label="Success">
              <CheckCircleOutlineIcon sx={{ color: "#4caf50" }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
    return null;
  };

  const renderFileList = (files, setFiles) => (
    <List sx={{ maxHeight: "250px", overflow: "auto" }}>
      {files.map((file, index) => (
        <ListItem
          key={index}
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 16px",
          }}
        >
          <Box display="flex" alignItems="center">
            <DescriptionIcon sx={{ mr: 2, color: "#4caf50" }} />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", fontSize: "14px" }}
              >
                {file.name}
              </Typography>
              <Typography
                variant="body2"
                color="#aaa"
                sx={{ fontSize: "14px" }}
              >
                {`${(file.size / 1024 / 1024).toFixed(2)} MB`}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            {showFileStatus(file)}
            <Box display="flex" alignItems="center">
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => removeFile(setFiles, index)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );

  const fetchOPSProcesses = async (userId) => {
    try {
      const res = await axios.get(`${API}/reconunits/byuser/${userId}`);
      if (res.status === 200) {
        const finalRes = res.data.map((ele) => {
          return {
            ...ele,
            label: ele.clientReconName,
            value: ele.reconUnitId,
          };
        });
        setOPSProcesses(finalRes);
        setSelectedOPSProcess(reconUnitId || finalRes[0]?.reconUnitId);
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
      setOPSProcesses([]);
    }
  };

  useEffect(() => {
    if (userId !== 0) {
      fetchOPSProcesses(userId);
    }
  }, [userId]);

  const handleOPSProcessChange = (event) => {
    const reconUnitSelected = Number(event.target.value);
    setSelectedOPSProcess(reconUnitSelected);
  };

  const fetchAuditData = async (dataSetId) => {
    try {
      const res = await axios.get(`${DATAX}/data-sets/${dataSetId}/audit`);
      if (res.status === 200) {
        setAuditData(res.data);
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
      setAuditData([]);
    }
  };

  useEffect(() => {
    if (selectedDatasets && filesA.length === 0) {
      fetchAuditData(selectedDatasets);
    }
  }, [selectedDatasets, filesA]);

  const handleToastClose = useCallback(() => {
    setToastData({ isOpen: false, message: "" });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleBackButton = () => {
    navigate("/saya-platform/datax");
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "250px",
            margin: "0px 0 20px",
          }}
        >
          {/* <SAYAComboBox
            optionsData={opsProcesses}
            value={selectedOPSProcess}
            defaultValue={selectedOPSProcess}
            labelText="Ops Processes"
            groupText="Selected Recon Process"
            name="selectedOPSProcess"
            handleChange={handleOPSProcessChange}
            className="Select"
            height="20px"
            headerCombo
          /> */}

          <BasicSelect
            {...{
              label: "Data Set",
              name: "Dataset",
              value: selectedDatasets,
              selectData: dataSets,
              optionLabel: "value",
              optionValue: "id",
              onChange: handleDatasets,
              isMulti: false,
            }}
          />
        </Box>
        <Tooltip title="Back to DataX Workspace">
          <KeyboardBackspace
            className="MuiSvgIconRoot"
            style={{ cursor: "pointer" }}
            fontSize="large"
            onClick={handleBackButton}
          />
        </Tooltip>
      </Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="standard"
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabsWrapper}
      >
        <Tab
          label="Upload Data"
          icon={<CloudUploadOutlinedIcon />}
          iconPosition="start"
          sx={{ minHeight: "auto", padding: "10px 15px" }}
        />
        <Tab
          label="Data Upload History"
          icon={<ListAltOutlinedIcon />}
          iconPosition="start"
          sx={{ minHeight: "auto", padding: "10px 15px" }}
        />
      </Tabs>
      {tabIndex === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="70vh"
          bgcolor="#fff"
          p={3}
        >
          <Box
            maxWidth={700}
            width="100%"
            p={3}
            boxShadow={3}
            borderRadius={2}
            bgcolor="#fbfbff"
            sx={{ border: "1px solid #dcdcdc" }}
          >
            <Box textAlign="center" mb={2}>
              <Typography variant="h5" component="h1" gutterBottom>
                Upload Data
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Supported formats:{" "}
                <span style={{ color: "#ce518c" }}>Excel, CSV, SWIFT</span>
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Box width="100%">
                <Box
                  {...getRootPropsA()}
                  p={2}
                  border="2px dashed #cccccc"
                  textAlign="center"
                  bgcolor="#fff"
                  borderRadius="4px"
                  sx={{ cursor: "pointer" }}
                >
                  <input {...getInputPropsA()} />
                  {/* <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Data Source 1
                  </Typography> */}
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontSize: "12px" }}
                  >
                    Drag & drop files here or click to select files
                  </Typography>
                </Box>
                {renderFileList(filesA, setFilesA)}
              </Box>
            </Box>
            <Divider sx={{ mb: 2 }} />
            {uploading && (
              <Box width="100%" p={2} pt={0}>
                <LinearProgress variant="determinate" value={progress} />
                <Box
                  sx={{ textAlign: "center", padding: "10px 10px 5px 10px" }}
                >
                  <span>{uploading ? `Uploading (${progress}%)` : ""}</span>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={
                  uploading ||
                  (!filesA.length && !filesB.length) ||
                  filesRef.current.filesA.filter(
                    (f) => f.validationStatus === "Failed"
                  ).length > 0
                }
              >
                <CloudUploadOutlinedIcon sx={{ marginRight: "10px" }} /> Upload
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box
          sx={{
            background: "#fff",
            paddingBottom: "20px",
            paddingTop: "20px",
            minHeight: "450px",
          }}
        >
          <DataIngestionHistory {...{ auditData }} />
        </Box>
      )}
      {toastData.isOpen && (
        <ToastMessage {...toastData} handleToastClose={handleToastClose} />
      )}
    </Box>
  );
}
