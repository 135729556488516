import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import {
  AddToPhotos,
  Delete,
  DeviceHub,
  EditRounded,
  BorderColorOutlined,
  AddBusinessOutlined,
  Close,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@mui/material/Skeleton";
// core components
import CardIcon from "../../components/Card/CardIcon";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import {
  getOrganizations,
  createModifyOrganization,
  deleteOrgnization,
  uploadImage,
} from "../../action-handlers/OrganizationHandler";
import { imageUpload } from "../../components/utils";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { notificationParams } from "../../variables/general";
import Snackbar from "../../components/Snackbar/Snackbar";
import ConfirmationOverlay from "../../components/ConfirmOverlay/ConfirmationOverlay";
import SAYAMUIDataTable from "components/Table/MuiDataTable";
import { ButtonGroup, Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SAYASearchBox from "components/CustomSearchBox/CustomSearchBox";
const styles = (theme) => ({
  cardCategoryWhite: {
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },

  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  stats: {
    "& svg": {
      marginLeft: "3px",
      marginRight: "3px",
      top: "0px",
    },
    display: "inline-flex",
    fontSize: "12px",
  },
  dialogWindow: {
    minWidth: "auto",
  },
  errorMessgage: {
    fontSize: 12,
    color: theme.palette.error.main,
    margin: "0px 0px 10px 0",
  },
  cardTitle: {
    color: theme.palette.text.primary,
  },
  imagePreview: {
    width: 100,
    "& img": {
      width: "100%",
    },
  },
  loaderImg: {
    position: "relative",
    left: "48%",
    top: 100,
  },
  logoImage: {
    float: "left",
    border: "1px solid " + theme.palette.grey[300],
  },
  iconSpacing: {
    marginRight: "8px",
    position: "relative",
    top: "5px",
  },
});

const useStyles = makeStyles(styles);

export default function Organizations() {
  const [orgnizationList, setOrganizationList] = useState([]);
  const [openOrgDialog, setOrgDialog] = useState(false);
  const [orgItem, setOrgItem] = useState(false);
  const [isEditOrg, setEditOrg] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgDesc, setOrgDesc] = useState("");
  const [orgnizationId, setOrgId] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [email, setEmail] = useState({});
  const [imageError, setImageError] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [loaderState, setLoaderState] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [serviceProviderId, setServiceProviderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [showConfirmOverlay, setConfirmOverlay] = React.useState(false);
  let itemId = React.useRef({ deleteId: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const location = useLocation();
  const allowedPermissions =
    JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];

  useEffect(() => {
    const { parentClientOrgId, loginName } = UserAuthContext.state;
    if (loginName) {
      fetchUserDetailsByLogin(loginName, fetchUserDetails);
    }
  }, []);

  const fetchUserDetails = (userList) => {
    setServiceProviderId(userList.parentClientOrgId);
    setUserId(userList.userId);
  };

  const setOrgListHandler = (orgList) => {
    if (orgList && orgList.length && orgList.length > 0) {
      setLoaderState(false);
      setOrganizationList(orgList);
    } else {
      setLoaderState(false);
      nofifyHandler("Unable to load the Organizations", "danger");
    }
  };

  const getOrgListHandler = (uId) => {
    getOrganizations(uId, setOrgListHandler);
  };

  useEffect(() => {
    if (
      userId &&
      (allowedPermissions.includes("view") ||
        allowedPermissions.includes("modify") ||
        allowedPermissions.includes("delete"))
    ) {
      getOrgListHandler(userId);
    }
  }, [userId]);

  const createModifyOrgHandler = (orgId) => {
    const orgItemObj = getOrgItem(orgId);
    setOrgItem(orgItemObj);
    setEditOrg(true);
    setOrgDialog(true);
    setOrgName(orgItemObj.organisationName);
    setOrgDesc(orgItemObj.organisationDesc);
    setOrgId(orgId);
    setEmail({ ...email, emailVal: orgItemObj.email });
    setLogoImage("");
    setImageFile("");
  };
  const handleCloseOrgDialog = () => {
    setOrgDialog(false);
    setOrgName("");
    setOrgDesc("");
    setEmail({ ...email, emailVal: "" });
    // setFieldError(false);
    // setEmail({ ...email, emailError: false });
  };

  const createNewOrgHandler = () => {
    setEditOrg(false);
    setOrgDialog(true);
  };

  const handleSubmit = async (event, oi = {}) => {
    event && event.preventDefault();
    var file = new FormData();
    if (orgName === "" || orgDesc === "" || email.emailVal === "") {
      setFieldError(true);
      return false;
    } else {
      setFieldError(false);
    }

    if (validateEmail(email.emailVal) && !fieldError) {
      let orgParams = {
        organisationName: orgName,
        organisationDesc: orgDesc,
        email: email.emailVal,
        serviceProviderId: serviceProviderId,
        logoContent: Object.keys(oi).length ? null : orgItem.logoContent,
      };
      orgParams = isEditOrg
        ? { ...orgParams, organisationId: orgnizationId }
        : orgParams;
      setLoaderState(true);
      createModifyOrganization(orgParams, isEditOrg, orgCallback);
      //const formData = new FormData();
      //formData.append("file", imageFile);

      if (imageFile) {
        file.append("organisationId", orgnizationId);
        file.append("logoContent", imageFile);
        await uploadImage(file, orgnizationId);
      }
    } else {
      console.log("Error");
    }
  };

  const deleteOrgnizationHandler = (orgId) => {
    itemId.current.deleteId = orgId;
    openOverlay(true);
  };
  const deleteOverlayHandler = () => {
    deleteOrgnization(itemId.current.deleteId, deleteConfirm);
  };

  const deleteConfirm = (response) => {
    //window.location.reload();
    if (response) {
      handleCloseOrgDialog();
      nofifyHandler("Organisation deleted successfully");
      closeOverlay();
      getOrgListHandler(userId);
    } else {
      handleCloseOrgDialog();
      nofifyHandler(
        "Unable to delete, please contact sales@3cortex.com !",
        "danger"
      );
    }
  };

  const orgCallback = (bool) => {
    setLoaderState(false);
    if (bool) {
      handleCloseOrgDialog();
      //window.location.reload();
      getOrgListHandler(userId);
      nofifyHandler(
        `Organisation ${isEditOrg ? "updated" : "created"} Successfully`
      );
    } else {
      handleCloseOrgDialog();
      nofifyHandler(
        `Unable to ${
          isEditOrg ? "update" : "create"
        } Organisation, please contact sales@3cortex.com !`,
        "danger"
      );
    }
  };

  const validateEmail = (val) => {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
    const result = pattern.test(val);
    if (result === true) {
      setEmail({ ...email, emailError: false });
      return true;
    } else {
      setEmail({ ...email, emailError: true });
      return false;
    }
  };

  const getOrgItem = (idVal) => {
    const getItem = orgnizationList.filter(
      (item) => item.organisationId === idVal
    );
    return getItem[0];
  };

  const onFileChange = (e) => {
    // Parameters
    /* event, callback,  width: 100, height: 100, widthHeight valiation flag, size validation flag*/
    imageUpload(e, imgUploadCallback, 100, 100, true, true);
  };

  //Callback Handler for Upload API
  const imgUploadCallback = (isSuccess, data, file) => {
    if (isSuccess) {
      setLogoImage(data); //
      setImageFile(file);
    } else {
      setImageError(data);
    }
  };

  //Set Image path for preview
  useEffect(() => {
    if (logoImage !== "") {
      setLogoImage(logoImage);
    }
  }, [logoImage]);

  //Clearing Old values when edit window open
  useEffect(() => {
    if (openOrgDialog) {
      setLogoImage("");
      setImageError("");
      if (isEditOrg) {
        if (
          (orgItem.organisationName &&
            orgItem.organisationName.length !== "") ||
          (orgItem.organisationDesc &&
            orgItem.organisationDesc.length !== "") ||
          (orgItem.email && orgItem.email !== "") ||
          validateEmail(email.emailVal)
        ) {
          setFieldError(false);
          setEmail({ ...email, emailError: false });
        }
      }
    }
  }, [openOrgDialog]);

  const nofifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const handleCloseNotification = () => {
    setNotificationObj({
      ...notificationParams,
      open: false,
    });
  };

  const openOverlay = () => {
    setConfirmOverlay(true);
  };

  const closeOverlay = () => {
    setConfirmOverlay(false);
  };

  const removeOrgIcon = async (event) => {
    let file = new FormData();
    file.append("organisationId", orgnizationId);
    file.append("logoContent", null);
    const result = await uploadImage(file, orgnizationId);
    if (result === "success") {
      const oi = { ...orgItem };
      oi.logoContent = "";
      setOrgItem(oi);
      handleCloseOrgDialog();
      getOrgListHandler(userId);
      // handleSubmit(event, oi);
    }
  };

  const [showTableView, setShowTableView] = useState(false);
  const handleButtonClick = (viewType) => {
    setShowTableView(viewType === "table");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, orgId) => {
    setAnchorEl(event.currentTarget);
    setOrgId(orgId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <div style={{ margin: '0 -18px' }}>
      <GridContainer style={{ marginBottom: "15px" }}>
        {allowedPermissions.includes("add") && (
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Button onClick={createNewOrgHandler} variant="contained">
              <AddToPhotos />
              Create new organisation
            </Button>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!showTableView ? (
              <div style={{ paddingRight: "10px" }}>
                <SAYASearchBox
                  searchText={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            ) : null}
            <ButtonGroup>
              <Button
                variant={showTableView ? "outlined" : "contained"}
                onClick={() => handleButtonClick("card")}
              >
                <Tooltip title="Card View">
                  <GridViewIcon />
                </Tooltip>
              </Button>

              <Button
                variant={showTableView ? "contained" : "outlined"}
                onClick={() => handleButtonClick("table")}
              >
                <Tooltip title="Table View">
                  <TableRowsIcon />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </div>
        </GridItem>
      </GridContainer>
      {showTableView ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <SAYAMUIDataTable
              title="Organisations Data"
              data={orgnizationList}
              options={{
                selectableRows: "none",
                filter: true,
                viewColumns: true,
                print: true,
                download: true,
              }}
              columns={[
                {
                  name: "logoContent",
                  label: "Logo",
                  options: {
                    sort: false,
                    filter: false,
                    customBodyRender: (value) =>
                      value ? (
                        <img
                          src={`data:image/png;base64,${value}`}
                          alt="Logo"
                          width="30"
                        />
                      ) : (
                        <DeviceHub />
                      ),
                  },
                },
                { name: "organisationId", label: "Organisation Id" },
                { name: "organisationName", label: "Organisation Name" },
                { name: "organisationDesc", label: "Organisation Description" },
                { name: "email", label: "Email" },
                ...(allowedPermissions.includes("modify") ||
                allowedPermissions.includes("delete")
                  ? [
                      {
                        name: "actions",
                        label: "Actions",
                        options: {
                          sort: false,
                          filter: false,
                          customBodyRender: (value, tableMeta) =>
                            allowedPermissions.includes("view") ||
                            allowedPermissions.includes("modify") ||
                            allowedPermissions.includes("delete") ? (
                              <div>
                                <IconButton
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleClick(e, tableMeta.rowData[1])
                                  }
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >
                                  {allowedPermissions.includes("modify") && (
                                    <MenuItem
                                      onClick={() => {
                                        createModifyOrgHandler(orgnizationId);
                                        handleClose();
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                  )}
                                  {allowedPermissions.includes("delete") && (
                                    <MenuItem
                                      className={classes.deletBtn}
                                      onClick={() => {
                                        handleClose();
                                        deleteOrgnizationHandler(orgnizationId);
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  )}
                                </Menu>
                              </div>
                            ) : null,
                        },
                      },
                    ]
                  : []),
              ]}
            />
          </GridItem>
          <Dialog
            // fullScreen={fullScreen}
            open={openOrgDialog}
            onClose={handleCloseOrgDialog}
            aria-labelledby="draggable-dialog-title"
            className={classes.dialogPaper}
            // aria-labelledby="responsive-dialog-title"
          >
            {/* <DialogTitle  id="responsive-dialog-title">Follow Up Email</DialogTitle> */}
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              {/* {dialogTitle} */}

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCloseOrgDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogWindow}>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                      <CardHeader color="primary" plain>
                        <h4
                          className={
                            classes.cardTitleWhite + " modal-title-flag"
                          }
                        >
                          {isEditOrg ? (
                            <BorderColorOutlined
                              className={classes.iconSpacing}
                            />
                          ) : (
                            <AddBusinessOutlined
                              className={classes.iconSpacing}
                            />
                          )}
                          {isEditOrg ? "Edit" : "Create"} Organization
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <Box>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              {fieldError && (
                                <p className={classes.errorMessgage}>
                                  Please enter Required fields
                                </p>
                              )}
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  required
                                  id="outlined-required"
                                  label="Organization Name"
                                  variant="outlined"
                                  size="large"
                                  defaultValue={
                                    isEditOrg ? orgItem.organisationName : ""
                                  }
                                  onInput={(e) => setOrgName(e.target.value)}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  required
                                  id="outlined-required"
                                  label="Organization Description"
                                  variant="outlined"
                                  size="large"
                                  defaultValue={
                                    isEditOrg ? orgItem.organisationDesc : ""
                                  }
                                  onInput={(e) => setOrgDesc(e.target.value)}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  required
                                  id="outlined-required"
                                  variant="outlined"
                                  label="Email"
                                  size="large"
                                  defaultValue={isEditOrg ? orgItem.email : ""}
                                  onInput={(e) =>
                                    setEmail({
                                      ...email,
                                      emailVal: e.target.value,
                                    })
                                  }
                                />
                                {email.emailError && (
                                  <p className={classes.errorMessgage}>
                                    Please enter valid email
                                  </p>
                                )}
                              </FormControl>
                            </GridItem>
                            {allowedPermissions.includes(
                              "upload client org Icon"
                            ) && (
                              <GridItem xs={12} sm={12} md={12}>
                                <FormControl
                                  fullWidth
                                  sx={{ m: 1 }}
                                  variant="filled"
                                  className={classes.uploadField}
                                >
                                  <TextField
                                    id="file"
                                    type="file"
                                    name="selectedFile"
                                    variant="outlined"
                                    inputProps={{ accept: "image/*" }}
                                    onChange={(event) => onFileChange(event)}
                                  />

                                  {!logoImage && imageError && (
                                    <p className={classes.errorMessgage}>
                                      {imageError}
                                    </p>
                                  )}
                                  <div className={classes.imagePreview}>
                                    {
                                      <img
                                        style={{
                                          opacity:
                                            orgItem.logoContent || logoImage
                                              ? 1
                                              : 0,
                                        }}
                                        src={
                                          isEditOrg
                                            ? logoImage ||
                                              "data:" +
                                                orgItem.imageType +
                                                ";base64," +
                                                orgItem.logoContent
                                            : logoImage
                                        }
                                      />
                                    }
                                    {isEditOrg && orgItem?.logoContent && (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={removeOrgIcon}
                                      >
                                        <Delete />
                                      </span>
                                    )}
                                  </div>
                                </FormControl>
                              </GridItem>
                            )}
                          </GridContainer>
                        </Box>
                      </CardBody>
                      <CardFooter>
                        <Grid container justifyContent={"center"} spacing={1}>
                          {(isEditOrg &&
                            allowedPermissions.includes("modify")) ||
                          (!isEditOrg && allowedPermissions.includes("add")) ? (
                            <GridItem style={{ marginLeft: "-10px" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{ xs: { padding: "10px 25px" } }}
                              >
                                SUBMIT
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{ padding: { xs: "10px 25px" } }}
                                className={classes.closeButton}
                                onClick={handleCloseOrgDialog}
                              >
                                CANCEL
                              </Button>
                            </GridItem>
                          ) : null}
                        </Grid>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </form>
            </DialogContent>
            {/* <DialogActions></DialogActions> */}
          </Dialog>
        </GridContainer>
      ) : (
        <>
          {allowedPermissions.includes("view") ||
          allowedPermissions.includes("modify") ||
          allowedPermissions.includes("delete") ? (
            <>
              <GridContainer>
                {orgnizationList && orgnizationList.length > 0 ? (
                  orgnizationList
                    .filter(
                      (orgItem) =>
                        orgItem.organisationName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        String(orgItem.organisationId)
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                    )
                    .map((orgItem, index) => (
                      <GridItem
                        xs={12}
                        sm={6}
                        md={4}
                        key={index}
                        /*style={{ cursor: "pointer" }}
                      onClick={() =>
                        createModifyOrgHandler(orgItem.organisationId)
                      }*/
                      >
                        <Card>
                          <CardHeader color="primary" stats icon>
                            {orgItem.logoContent ? (
                              <img
                                src={
                                  "data:" +
                                  orgItem.imageType +
                                  ";base64," +
                                  orgItem.logoContent
                                }
                                width="66px"
                                className={classes.logoImage}
                              />
                            ) : (
                              <CardIcon color="primary">
                                <DeviceHub />
                              </CardIcon>
                            )}
                            <p className={classes.cardCategory}>
                              Organization Id: {orgItem.organisationId}
                            </p>
                            <h3 className={classes.cardTitle}>
                              {orgItem.organisationName}
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <IconButton
                                onClick={() =>
                                  createModifyOrgHandler(orgItem.organisationId)
                                }
                                disableElevation
                              >
                                <EditRounded />
                              </IconButton>

                              {allowedPermissions.includes("delete") && (
                                <IconButton
                                  className={classes.deletBtn}
                                  onClick={() =>
                                    deleteOrgnizationHandler(
                                      orgItem.organisationId
                                    )
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))
                ) : (
                  <>
                    {[1, 2, 3].map((index) => (
                      <GridItem xs={12} sm={6} md={4} key={index}>
                        <Card style={{ width: "100%", marginRight: 20 }}>
                          <CardHeader color="primary" stats icon>
                            <CardIcon
                              color="primary"
                              style={{ background: "transparent" }}
                            >
                              <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            </CardIcon>

                            <p className={classes.cardCategory}>
                              <Skeleton
                                animation="wave"
                                style={{ float: "right" }}
                                width={140}
                              />
                            </p>
                            <h3 className={classes.cardTitle}>
                              <Skeleton animation="wave" width={50} />
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <Skeleton animation="wave" width={150} />
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))}
                  </>
                )}
                {loaderState && (
                  <GridItem xs={12} sm={12} md={12}>
                    <CircularProgress className={classes.loaderImg} />
                  </GridItem>
                )}
              </GridContainer>
              <Dialog
                // fullScreen={fullScreen}
                open={openOrgDialog}
                onClose={handleCloseOrgDialog}
                aria-labelledby="draggable-dialog-title"
                className={classes.dialogPaper}
                // aria-labelledby="responsive-dialog-title"
              >
                {/* <DialogTitle  id="responsive-dialog-title">Follow Up Email</DialogTitle> */}
                <DialogTitle
                  style={{ cursor: "move" }}
                  id="draggable-dialog-title"
                >
                  {/* {dialogTitle} */}

                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleCloseOrgDialog}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogWindow}>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                          <CardHeader color="primary" plain>
                            <h4
                              className={
                                classes.cardTitleWhite + " modal-title-flag"
                              }
                            >
                              {isEditOrg ? (
                                <BorderColorOutlined
                                  className={classes.iconSpacing}
                                />
                              ) : (
                                <AddBusinessOutlined
                                  className={classes.iconSpacing}
                                />
                              )}
                              {isEditOrg ? "Edit" : "Create"} Organization
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <Box>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  {fieldError && (
                                    <p className={classes.errorMessgage}>
                                      Please enter Required fields
                                    </p>
                                  )}
                                  <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant="filled"
                                  >
                                    <TextField
                                      required
                                      id="outlined-required"
                                      label="Organization Name"
                                      variant="outlined"
                                      size="large"
                                      defaultValue={
                                        isEditOrg
                                          ? orgItem.organisationName
                                          : ""
                                      }
                                      onInput={(e) =>
                                        setOrgName(e.target.value)
                                      }
                                    />
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant="filled"
                                  >
                                    <TextField
                                      required
                                      id="outlined-required"
                                      label="Organization Description"
                                      variant="outlined"
                                      size="large"
                                      defaultValue={
                                        isEditOrg
                                          ? orgItem.organisationDesc
                                          : ""
                                      }
                                      onInput={(e) =>
                                        setOrgDesc(e.target.value)
                                      }
                                    />
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant="filled"
                                  >
                                    <TextField
                                      required
                                      id="outlined-required"
                                      variant="outlined"
                                      label="Email"
                                      size="large"
                                      defaultValue={
                                        isEditOrg ? orgItem.email : ""
                                      }
                                      onInput={(e) =>
                                        setEmail({
                                          ...email,
                                          emailVal: e.target.value,
                                        })
                                      }
                                    />
                                    {email.emailError && (
                                      <p className={classes.errorMessgage}>
                                        Please enter valid email
                                      </p>
                                    )}
                                  </FormControl>
                                </GridItem>
                                {allowedPermissions.includes(
                                  "upload client org Icon"
                                ) && (
                                  <GridItem xs={12} sm={12} md={12}>
                                    <FormControl
                                      fullWidth
                                      sx={{ m: 1 }}
                                      variant="filled"
                                      className={classes.uploadField}
                                    >
                                      <TextField
                                        id="file"
                                        type="file"
                                        name="selectedFile"
                                        variant="outlined"
                                        inputProps={{ accept: "image/*" }}
                                        onChange={(event) =>
                                          onFileChange(event)
                                        }
                                      />

                                      {!logoImage && imageError && (
                                        <p className={classes.errorMessgage}>
                                          {imageError}
                                        </p>
                                      )}
                                      <div className={classes.imagePreview}>
                                        {
                                          <img
                                            style={{
                                              opacity:
                                                orgItem.logoContent || logoImage
                                                  ? 1
                                                  : 0,
                                            }}
                                            src={
                                              isEditOrg
                                                ? logoImage ||
                                                  "data:" +
                                                    orgItem.imageType +
                                                    ";base64," +
                                                    orgItem.logoContent
                                                : logoImage
                                            }
                                          />
                                        }
                                        {isEditOrg && orgItem?.logoContent && (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={removeOrgIcon}
                                          >
                                            <Delete />
                                          </span>
                                        )}
                                      </div>
                                    </FormControl>
                                  </GridItem>
                                )}
                              </GridContainer>
                            </Box>
                          </CardBody>
                          <CardFooter>
                            <Grid
                              container
                              justifyContent={"center"}
                              spacing={1}
                            >
                              {(isEditOrg &&
                                allowedPermissions.includes("modify")) ||
                              (!isEditOrg &&
                                allowedPermissions.includes("add")) ? (
                                <GridItem style={{ marginLeft: "-10px" }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    sx={{ xs: { padding: "10px 25px" } }}
                                  >
                                    SUBMIT
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.closeButton}
                                    onClick={handleCloseOrgDialog}
                                    sx={{ padding: { xs: "10px 25px" } }}
                                  >
                                    CANCEL
                                  </Button>
                                </GridItem>
                              ) : null}
                            </Grid>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </form>
                </DialogContent>
                {/* <DialogActions></DialogActions> */}
              </Dialog>
            </>
          ) : null}
        </>
      )}
      {orgnizationList.length > 0 &&
        orgnizationList.filter(
          (orgItem) =>
            orgItem.organisationName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            String(orgItem.organisationId)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        ).length === 0 && (
          <GridItem xs={12} sm={12} md={12}>
            <h4 style={{ textAlign: "center" }}>No results found</h4>
          </GridItem>
        )}
      {notificationObj && (
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
          autoHideDuration={3000}
        />
      )}
      {showConfirmOverlay && (
        <ConfirmationOverlay
          {...{
            isOverlayOpen: showConfirmOverlay,
            closeOverlay,
            handler: deleteOverlayHandler,
            title: "DELETE",
            message: `Are you sure you want to delete? Once you delete you will loss all the data associated to the record.`,
          }}
        />
      )}
    </div>
  );
}
