import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import { makeStyles } from "@mui/styles";

import {
  AppsOutlined,
  AccountBalance,
  Groups,
  DisplaySettings,
  ManageAccounts,
  AdminPanelSettingsOutlined,
  KeyboardDoubleArrowRight,
  AccountTreeOutlined  
} from "@mui/icons-material";
import {
  FolderInput,
  FileCog,
  Layers,
  GitCompareArrows
} from "lucide-react";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardFooter from "../../components/Card/CardFooter";
import { notificationParams } from "../../variables/general";
import Snackbar from "../../components/Snackbar/Snackbar";
import MuiIcon from "../../components/ICONS";

const useStyles = makeStyles(styles);

const ShowAdminTiles = ({ menuItem = {}, clickHandler }) => {
  const classes = useStyles();
  const navigationHandler = () => {
    clickHandler();
  };

  const viewAllowed = menuItem?.allowedPermissions?.includes("view");
  if (!viewAllowed) {
    return "";
  }

  const getIcon = (type) => {
    let iconName = "";
    switch (type) {
      case "organisations":
        return { icon: AccountBalance, useLucide: false };
      case "users":
        return { icon: ManageAccounts, useLucide: false };
      case "departments":
        return { icon: Groups, useLucide: false };
      case "ops process":
        return { icon: DisplaySettings, useLucide: false };
      case "service provider":
        return { icon: AdminPanelSettingsOutlined, useLucide: false };
      case "flow designer":
        return { icon: AccountTreeOutlined, useLucide: false };
      case "data set":
        return { icon: Layers, useLucide: true }; 
      case "data source":
        return { icon: FolderInput, useLucide: true }; 
      case "data format":
        return { icon: FileCog, useLucide: true }; 
      case "n-way recon process":
          return { icon: GitCompareArrows, useLucide: true };         
      default:
        return { icon: AppsOutlined, useLucide: false }; 
    }
    return iconName;
  };

  return (
    <GridItem
      xs={12}
      sm={6}
      md={3}
      key={menuItem.menuId}
      style={{ cursor: "pointer" }}
      onClick={navigationHandler}
    >
      <Card>
        <CardHeader color="primary" stats icon>
          <CardIcon color="primary" plain>
            <MuiIcon  {...getIcon(menuItem.menuIdentifier.toLowerCase())} />
            {/* {menuItem.menuIdentifier.toLowerCase() === "organisations" && (
              <MuiIcon icon={AccountBalance}/>
            )}
            {menuItem.menuIdentifier.toLowerCase() === "departments" && (
              <MuiIcon icon={Groups} />
            )}
            {menuItem.menuIdentifier.toLowerCase() === "recon process" && (
              <MuiIcon icon={DisplaySettings} />
            )}
            {menuItem.menuIdentifier.toLowerCase() === "users" && (
              <MuiIcon icon={ManageAccounts} />
            )}
            {menuItem.menuIdentifier.toLowerCase() === "service provider" && (
              <MuiIcon icon={AdminPanelSettingsOutlined} />
            )}
            {menuItem.menuIdentifier.toLowerCase() === "flow designer" && (
              <MuiIcon icon={AccountTreeOutlined} />
            )}
            {!(["organisations" , "departments" , "recon process" ,"users" ,"service provider" ,"flow designer"].includes(menuItem.menuIdentifier.toLowerCase())) && (
              <MuiIcon icon={AppsOutlined} />
            )} */}
          </CardIcon>
          {/* <p className={classes.cardCategory}>
            {menuItem.menuIdentifier} Entities
          </p> */}
          <h3 className={`${classes.cardTitle} ${classes.adminCardTitle} relative top-4`}>
            {menuItem.menuIdentifier}
          </h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <TuneIcon />
            View {menuItem.menuIdentifier}
          </div>
          <KeyboardDoubleArrowRight />
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function AdminHome(props) {
  const { subMenus } = props && props.routeData;
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [subMenuItems, setSubMenuItems] = useState([]);
  const navigate = useNavigate();
  let ref = React.useRef({
    selectedItem: {},
  });
  useEffect(() => {
    let subMenuList = subMenus;
    if (subMenuList) {
      setSubMenuItems(subMenuList);
    }

    return () => {
      console.log("Component Admin Home unloading cleanup to be done here!!!");
    };
  }, [subMenus]);

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const navigationHandler = (menuItem) => {
    const selectedItem =
      subMenus.filter(
        (menu) =>
          menu.menuIdentifier.toLowerCase() ===
          menuItem.menuIdentifier.toLowerCase()
      )[0] || {};
    ref.current.selectedItem = selectedItem;

    window.sessionStorage.setItem("selectedItem", JSON.stringify(selectedItem));
    let pathName = "";

    switch (selectedItem.menuIdentifier.toLowerCase()) {
      case "organisations":
        pathName = "organizations";
        break;
      case "users":
        pathName = "users";
        break;
      case "departments":
        pathName = "departments";
        break;
      case "ops process":
        pathName = "ops-home";
        break;
      case "service provider":
        pathName = "service-provider";
        break;
      case "flow designer":
        pathName = "workflows";
        break;
      case "meta data":
        pathName = "metadata";
        break;
      case "data set":
        pathName = "data-sets";
        break;
      case "data source":
        pathName = "data-sources";
        break;
      case "data format":
        pathName = "data-formats";
        break;
        case "n-way recon process":
          pathName = "nway-recon-processes";
          break;
    }

    navigate(`/saya-platform/admin/${pathName}`, {
      state: { selectedItem: JSON.stringify(ref.current.selectedItem) },
    });
  };

  //  const hardcodeFlowDesign = {
  //    menuId: 999,
  //    parentMenuId: 7,
  //    menuIdentifier: "Meta Data",
  //    subMenus: null,
  //    allowedPermissions: [
  //      "view",
  //      "modify"
  //    ],
  //  };

  //  const hardcodeNavigate = (data) => {
  //   navigate(`/saya-platform/admin/metadata`);
  //  }

  return (
    <div style={{ margin: '0 -20px' }}>
      <GridContainer>
        {subMenuItems &&
          subMenuItems.map((menuItem, index) => (
            <ShowAdminTiles
              menuItem={menuItem}
              key={index}
              clickHandler={() => navigationHandler(menuItem)}
            />
          ))}
        {/* <ShowAdminTiles
          menuItem={hardcodeFlowDesign}
          clickHandler={() => hardcodeNavigate('ddd')}
        /> */}

        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
        />
      </GridContainer>
    </div>
  );
  //}
}
