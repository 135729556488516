import { DATAX } from "URL";
import axios from "axios";

export const fetchDataFormats = async (organisationId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-formats?organisationId=${organisationId}`);

    if (response.status === 200) {
      callback(response.data);
    }
    else {
      callback([]);
    }
  } catch (error) {
    console.error("Unable to fetch data formats for organisation", organisationId, error.message);
    callback(false);
  }
};

export const fetchDataFormat = async (dataFormatId, orgId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-formats/${dataFormatId}?organisationId=${orgId}`);

    callback(response.status, response.data);
  } catch (error) {
    console.error("Unable to fetch data format for id", dataFormatId, error.message);
    callback(false);
  }
};

export const createOrModifyDataFormat = async (dataFormat, dataFormatId, callback) => {
  try {
    var response;
    if (dataFormatId && dataFormatId !== "") {
      response = await axios.put(`${DATAX}/data-formats/${dataFormatId}`, dataFormat);
    }
    else {
      response = await axios.post(`${DATAX}/data-formats`, dataFormat);
    }

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error("Unable to create/modify data format", error.message);
    callback({});
  }
};

export const deleteDataFormat = async (dataFormatId, organisationId, callback) => {
  try {
    const response = await axios.delete(`${DATAX}/data-formats/${dataFormatId}?organisationId=${organisationId}`);
    if (response.status === 204) {
      callback(true);
    }
  } catch (error) {
    console.error("Unable to delete data format ", dataFormatId, error.message);
    callback(false);
  }
};

export const fetchDataFormatsIdValues = async (organisationId, callback) => {
  try {
    const response = await axios.get(`${DATAX}/data-formats/id-values?organisationId=${organisationId}`);

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error("Unable to fetch data formats id-values for organisation", organisationId, error.message);
    callback([]);
  }
};

export const JOB_TYPES = {
  fileProcessingJob: "File Processing Job",
  databaseProcessingJob: "Database Processing Job"
};

export const READER_TYPES = {
  csvItemReader: "CSV Reader",
  excelItemReader: "XLS/XLSX Reader",
  swiftItemReader: "SWIFT Reader",
  grokItemReader: "Grok Reader",
  jsonItemReader: "JSON Reader",
  jdbcItemReader: "JDBC Reader"
};

export const PROCESSOR_TYPES = {
  aliasItemProcessor: "Add Field Alias",
  amountFormatterProcessor: "Format Amount",
  amountTypeDeriverProcessor: "Derive Amount Type",
  dateFormatProcessor: "Format Date/Time",
  defaultValueItemProcessor: "Add Default Value",
  fieldValueMapperItemProcessor: "Map to New Values",
  quotesItemProcessor: "Remove Quote(s)",
  replaceCharactersItemProcessor: "Replace Characters",
  scientificNumberProcessor: "Format Scientific Numbers",
  decimalScaleItemProcessor: "Scale Decimal Numbers",
  reconMetadataProcessor: "Add Recon Metadata",
  recordChecksumProcessor: "Compute Record Checksum",
  compositeFieldsItemProcessor: "Compose New Fields",
  fieldSplitterProcessor: "Split Fields"
};

export const WRITER_TYPES = {
  csvItemWriter: "CSV Writer",
  jdbcItemWriter: "JDBC Writer"
};

export const CONFIG_KEY_BY_BEAN_NAME = {
  job: {
    "fileProcessingJob": "FileProcessingJobConfig",
    "databaseProcessingJob": "DatabaseProcessingJobConfig"
  },
  reader: {
    "csvItemReader": "CsvReaderConfig",
    "excelItemReader": "ExcelReaderConfig",
    "grokItemReader": "GrokReaderConfig",
    "jsonItemReader": "JsonReaderConfig",
    "swiftItemReader": "SwiftReaderConfig",
    "jdbcItemReader": "JdbcReaderConfig"
  },
  processor: {
    "aliasItemProcessor": "AliasProcessorConfig",
    "amountFormatterProcessor": "AmountFormatterProcessorConfig",
    "amountTypeDeriverProcessor": "AmountTypeDeriverProcessorConfig",
    "dateFormatProcessor": "DateFormatterProcessorConfig",
    "defaultValueItemProcessor": "DefaultValueProcessorConfig",
    "fieldValueMapperItemProcessor": "FieldValueMapperProcesserConfig",
    "quotesItemProcessor": "QuoteProcessorConfig",
    "replaceCharactersItemProcessor": "ReplaceCharactersProcessorConfig",
    "scientificNumberProcessor": "ScientificProcessorConfig",
    "reconMetadataProcessor": "ReconMetadataProcessorConfig",
    "recordChecksumProcessor": "RecordChecksumProcessorConfig",
    "compositeFieldsItemProcessor": "CompositeFieldsProcessorConfig",
    "decimalScaleItemProcessor": "DecimalScaleProcessorConfig",
    "fieldSplitterProcessor": "FieldSplitterProcessorConfig"
  },
  writer: {
    "csvItemWriter": "CsvWriterConfig",
    "jdbcItemWriter": "JdbcWriterConfig"
  }
}