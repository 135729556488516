import { CONFIG_KEY_BY_BEAN_NAME } from "action-handlers/DataFormatHandler";
import { getUserData } from "views/Dashboard/DashboardUtil";

const { PROCESSOR_TYPES } = require("action-handlers/DataFormatHandler");
const { WRITER_TYPES } = require("action-handlers/DataFormatHandler");
const { READER_TYPES } = require("action-handlers/DataFormatHandler");
const { JOB_TYPES } = require("action-handlers/DataFormatHandler");

const { orgId: organisationId } = getUserData();

const skipLine = {
    "type": "object",
    "description": "The lines which are skippable",
    "required": ["line", "lineType"],
    "properties": {
        "lineType": {
            "type": "string",
            "oneOf": [
                {
                    "const": "DATA",
                    "title": "Data"
                },
                {
                    "const": "HEADER",
                    "title": "Header"
                }
            ]
        },
        "line": {
            "type": "object",
            "description": "The line pattern which is skippable",
            "properties": {
                "startsWith": {
                    "type": "array",
                    "description": "Skip all lines which starts with any of these words",
                    "items": {
                        "type": "string"
                    },
                    "default": []
                },
                "contains": {
                    "type": "array",
                    "description": "Skip all lines which contains any of these words",
                    "items": {
                        "type": "string"
                    },
                    "default": []
                }
            },

            "additionalProperties": false
        }
    },
    "additionalProperties": false
};

const SkipLines = {
    "type": "object",
    "description": "Skip all lines if they match, until they match or after they match the condition",
    "properties": {
        "linesIf": {
            "type": "array",
            "description": "Skip all lines if they match the condition",
            "items": skipLine,
            "default": []
        },
        "linesUntil": {
            "type": "array",
            "description": "Skip all lines until the line that matches the condition",
            "items": skipLine,
            "default": []
        },
        "linesAfter": {
            "type": "array",
            "description": "Skip all lines after the line that matches the condition",
            "items": skipLine,
            "default": []
        }
    }
};

const requiredLineTypeHeader = {
    "contains": {
        "type": "object",
        "properties": {
            "lineType": { "const": "HEADER" }
        }
    }
}

const requiredLinesIfHeader = {
    "properties": {
        "skipLines": {
            "properties": {
                "linesIf": requiredLineTypeHeader
            }
        }
    }
};

const requiredLinesUntilHeader = {
    "properties": {
        "skipLines": {
            "properties": {
                "linesUntil": requiredLineTypeHeader
            }
        }
    }
};

const requiredLinesWhenHeader = {
    "properties": {
        "skipLines": {
            "properties": {
                "linesWhen": requiredLineTypeHeader
            }
        }
    }
};


const schema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "title": "DataFormatCreateRequest",
    "type": "object",
    "definitions": {
        "FileProcessingJobConfig": {
            "type": "object",
            "description": "The file job configuration",
            "properties": {
                "gridSize": {
                    "type": "integer",
                    "format": "int32",
                    "description": "The number of parallel data processing steps",
                    "minimum": 1
                },
                "chunkSize": {
                    "type": "integer",
                    "format": "int32",
                    "description": "The data size of each step",
                    "minimum": 1,
                    "multipleOf": 10000
                }
            }
        },
        "DatabaseProcessingJobConfig": {
            "type": "object",
            "description": "The database job configuration",
            "properties": {
                "chunkSize": {
                    "type": "integer",
                    "format": "int32",
                    "description": "The data size of each step",
                    "minimum": 1,
                    "multipleOf": 5000
                }
            }
        },
        "SkipLine": skipLine,
    },
    "properties": {
        "organisationId": {
            "type": "integer",
            "description": "The id of the organisation"
        },
        "shortName": {
            "type": "string",
            "description": "The name of the data format",
            "minLength": 1,
            "maxLength": 128
        },
        "longName": {
            "type": "string",
            "description": "The description of the data format",
            "minLength": 1,
            "maxLength": 256
        },
        "dataSourceId": {
            "type": "integer",
            "apiUrl": `/datax/data-sources/id-values`,
            "description": "The id of the data source"
        },
        "dataConfig": {
            "type": "object",
            "description": "The configuration about data and conversions",
            "properties": {
                "fileType": {
                    "type": "string",
                    "oneOf": [
                        { "const": "PLAIN_TEXT", "title": "Plain Text" },
                        { "const": "ENCRYPTED", "title": "Encrypted" },
                        { "const": "COMPRESSED", "title": "Compressed" },
                        { "const": "DERIVED", "title": "Derive Automatically" }
                    ],
                    "description": "The type of file"
                },
                "nameConfig": {
                    "type": "object",
                    "description": "The configuration to extract metadata, resolve data format from the source storage names - file names, table names, etc",
                    "required": ["nameIdentifier"],
                    "properties": {
                        "nameIdentifier": {
                            "type": "string",
                            "description": "The identifier unique to the storage name",
                            "minLength": 1
                        },
                        "namePatterns": {
                            "type": "array",
                            "items": {
                                "type": "string",
                                "minLength": 1,
                                "description": "The pattern to match the storage name"
                            },
                            "description": "The list of patterns"
                            ,
                            "default": []
                        },
                        "patternType": {
                            "type": "string",
                            "enum": ["GROK"],
                            "description": "The pattern type",
                            "oneOf": [
                                { "const": "GROK", "title": "Grok" }
                            ],
                            "default": "GROK"
                        },
                        "allowedExtensions": {
                            "type": "array",
                            "items": {
                                "type": "string",
                                "minLength": 1,
                                "description": "The list of supported extensions. Special value 'NO_EXTENSION' can be used for files without extensions.",
                                "oneOf": [
                                    { "const": "NO_EXTENSION", "title": "No Extension" },
                                    { "const": "CSV", "title": "CSV" },
                                    { "const": "JSON", "title": "JSON" },
                                    { "const": "XLS", "title": "XLS" },
                                    { "const": "XLSX", "title": "XLSX" },
                                    { "const": "PDF", "title": "PDF" },
                                    { "const": "TXT", "title": "TXT" },
                                ]
                            },
                            "description": "The list of allowed extensions",
                            "default": []
                        }
                    }
                },
                "encryptionConfig": {
                    "type": ["object", "null"],
                    "description": "The configuration to decrypt data if encrypted",
                    "required": ["encryptionAlgorithm", "encryptionProperties"],
                    "properties": {
                        "encryptionAlgorithm": {
                            "type": "string",
                            "enum": ["PGP"],
                            "description": "The list of supported encryption algorithms"
                        },
                        "encryptionProperties": {
                            "type": "object",
                            "properties": {
                                "publicKey": {
                                    "type": "string",
                                    "description": "The public key",
                                    "minLength": 1
                                },
                                "keyPassword": {
                                    "type": "string",
                                    "description": "The key password",
                                    "minLength": 1
                                }
                            },
                            "required": ["publicKey", "keyPassword"],
                            "description": "The properties for data encryption/decryption"
                        }
                    },
                },
                "compressionConfig": {
                    "type": ["object", "null"],
                    "description": "The configuration to decompress data if decompressed",
                    "required": ["compressionAlgorithm", "compressionProperties"],
                    "properties": {
                        "compressionAlgorithm": {
                            "type": "string",
                            "enum": ["ZIP"],
                            "description": "The list of compression algorithms"
                        },
                        "compressionProperties": {
                            "type": "object",
                            "properties": {
                                "password": {
                                    "type": "string",
                                    "minLength": 1,
                                    "description": "The password used for compression"
                                }
                            },
                            "required": ["password"],
                            "description": "The properties for data compression/decompression"
                        }
                    },
                }
            },
            "required": ["fileType", "nameConfig"],
            "oneOf": [
                {
                    "properties": {
                        "fileType": {
                            "enum": ["PLAIN_TEXT"]
                        }
                    },
                    "required": ["nameConfig"]
                },
                {
                    "properties": {
                        "fileType": {
                            "enum": ["ENCRYPTED", "DERIVED"]
                        }
                    },
                    "required": ["encryptionConfig"]
                },
                {
                    "properties": {
                        "fileType": {
                            "enum": ["COMPRESSED", "DERIVED"]
                        }
                    },
                    "required": ["compressionConfig"]
                }
            ]
        },
        "jobConfig": {
            "type": "object",
            "description": "The job configuration",
            "properties": {
                "type": {
                    "type": "string",
                    "description": "The type of the batch component"
                },
                "components": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "parentCatalogId": {
                                "type": ["integer", "null"],
                                "format": "int64",
                                "description": "The parent catalog id",
                                "apiUrl": `/datax/data-formats/catalog/id-values?type=job`,
                            },
                            "beanName": {
                                "type": "string",
                                "description": "The component name",
                                "oneOf": Object.entries(JOB_TYPES).map(([key, value]) => ({
                                    const: key,
                                    title: value
                                }))
                            },
                            "config": {
                                "type": "object",
                                "description": "The job configuration",
                                "additionalProperties": true
                            },
                            "FileProcessingJobConfig": {
                                "$ref": "#/definitions/FileProcessingJobConfig"
                            },
                            "DatabaseProcessingJobConfig": {
                                "$ref": "#/definitions/DatabaseProcessingJobConfig"
                            }
                        },
                        "required": ["beanName"],
                        "oneOf": Object.entries(CONFIG_KEY_BY_BEAN_NAME["job"]).map(([key, value]) => ({
                            "properties": {
                                "beanName": {
                                    "const": key
                                }
                            },
                            "required": [value]
                        }))

                    }
                }
            }
        },
        "readerConfig": {
            "type": "object",
            "description": "The reader configuration",
            "properties": {
                "type": {
                    "type": "string",
                    "description": "The type of the batch component"
                },
                "components": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "parentCatalogId": {
                                "type": ["integer", "null"],
                                "format": "int64",
                                "description": "The parent catalog id",
                                "apiUrl": `/datax/data-formats/catalog/id-values?type=reader`,
                            },
                            "beanName": {
                                "type": "string",
                                "description": "The component name",
                                "oneOf": Object.entries(READER_TYPES).map(([key, value]) => ({
                                    const: key,
                                    title: value
                                }))
                            },
                            "config": {
                                "type": "object",
                                "description": "The reader configuration",
                                "additionalProperties": true
                            },
                            "CsvReaderConfig": {
                                "type": "object",
                                "description": "The CSV reader configuration",
                                "properties": {
                                    "headerLine": {
                                        "type": "integer",
                                        "format": "int32",
                                        "minimum": 1,
                                        "description": "The line number containing the headers"
                                    },
                                    "delimiter": {
                                        "type": "string",
                                        "description": "The delimiter of the line",
                                        "maxLength": 1,
                                        "minLength": 1
                                    },
                                    "skipLines": SkipLines,
                                    "delimitedHeaders": {
                                        "type": "string",
                                        "description": "The headers delimited by a comma",
                                        "minLength": 1
                                    }
                                },
                                "anyOf": [
                                    { "required": ["headerLine", "delimiter"] },
                                    { "required": ["delimitedHeaders", "delimiter"] },
                                    {
                                        "type": "object",
                                        "required": ["delimiter"],
                                        "anyOf": [
                                            requiredLinesIfHeader,
                                            requiredLinesUntilHeader,
                                            requiredLinesWhenHeader
                                        ]
                                    }
                                ]
                            },
                            "ExcelReaderConfig": {
                                "type": "object",
                                "description": "The XLS/XLSX reader configuration",
                                "properties": {
                                    "sheetNames": {
                                        "type": "array",
                                        "description": "The list of sheets for processing. Empty processes all sheets",
                                        "items": {
                                            "type": "string",
                                            "description": "The name of the sheet",
                                            "minLength": 1
                                        }
                                    },
                                    "headerLine": {
                                        "type": "integer",
                                        "description": "The line number containing the headers",
                                        "format": "int32"
                                    },
                                    "skipLines": SkipLines
                                },
                                "oneOf": [
                                    { "required": ["headerLine"] },
                                    {
                                        "required": ["skipLines"],
                                        "anyOf": [
                                            requiredLinesIfHeader,
                                            requiredLinesUntilHeader,
                                            requiredLinesWhenHeader
                                        ]
                                    }
                                ]
                            },
                            "GrokReaderConfig": {
                                "type": "object",
                                "description": "The Grok reader configuration",
                                "properties": {
                                    "headerLine": {
                                        "type": "integer",
                                        "description": "The line number containing the headers",
                                        "format": "int32"
                                    },
                                    "grokPattern": {
                                        "type": "string",
                                        "description": "The grok pattern",
                                        "minLength": 1
                                    }
                                },
                                "required": ["headerLine", "grokPattern"]
                            },
                            "JsonReaderConfig": {
                                "type": "object",
                                "description": "The JSON reader configuration",
                                "properties": {
                                    "flattenNestedObjects": {
                                        "type": "boolean",
                                        "description": "Click to flatten nested objects"
                                    },
                                    "flattenNestedArrays": {
                                        "type": "boolean",
                                        "description": "Click to flatten nested objects"
                                    }
                                },
                                "required": ["flattenNestedObjects", "flattenNestedArrays"]
                            },
                            "JdbcReaderConfig": {
                                "type": "object",
                                "description": "The JDBC reader configuration",
                                "properties": {
                                    "selectQuery": {
                                        "type": "string",
                                        "description": "The SQL select query for fetching the data",
                                        "minLength": 8
                                    },
                                    "maxRowCount": {
                                        "type": "integer",
                                        "description": "The maximum number of rows in each query execution"
                                    },
                                    "filterValues": {
                                        "type": "object",
                                        "description": "The values for parameters used in the SQL query",
                                        "additionalProperties": {
                                            "type": "string",
                                            "description": "The parameter value"
                                        },
                                        "key": {
                                            "type": "string",
                                            "description": "The parameter name used in the query",
                                            "minLength": 1
                                        }
                                    }
                                },
                                "required": ["selectQuery"]
                            },
                            "SwiftReaderConfig": {
                                "description": "The SWIFT reader configuration",
                                "type": "object",
                                "required": ["messageType", "messageDelimiter", "tokenizerConfig"],
                                "properties": {
                                    "messageType": {
                                        "type": "string",
                                        "description": "The type of SWIFT message",
                                        "enum": [
                                            "MT950"
                                        ]
                                    },
                                    "messageDelimiter": {
                                        "type": "string",
                                        "description": "The delimiter separating individual SWIFT messages"
                                    },
                                    "tokenizerConfig": {
                                        "type": "object",
                                        "description": "The configuration for SWIFT message tokenization",
                                        "required": ["includeFields", "fieldMapping"],
                                        "properties": {
                                            "includeFields": {
                                                "type": "array",
                                                "description": "The list of fields for inclusion",
                                                "items": {
                                                    "type": "string",
                                                    "description": "The field name",
                                                    "minLength": 1
                                                },
                                                "description": "List of fields to include"
                                            },
                                            "fieldMapping": {
                                                "type": "object",
                                                "description": "The mapping of SWIFT fields to normalised fields",
                                                "additionalProperties": {
                                                    "type": "object",
                                                    "additionalProperties": {
                                                        "type": "array",
                                                        "items": {
                                                            "type": "string"
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "additionalProperties": false
                                    }
                                },
                                "additionalProperties": false
                            }
                        },
                        "required": ["beanName", "config"],
                        "oneOf": Object.entries(CONFIG_KEY_BY_BEAN_NAME["reader"]).map(([key, value]) => ({
                            "properties": {
                                "beanName": {
                                    "const": key
                                }
                            },
                            "required": [value]
                        }))
                    }
                }
            }
        },
        "processorConfig": {
            "type": "object",
            "description": "The transformer configurations",
            "properties": {
                "type": {
                    "type": "string",
                    "description": "The type of the batch component"
                },
                "components": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "parentCatalogId": {
                                "type": ["integer", "null"],
                                "format": "int64",
                                "description": "The parent catalog id",
                                "apiUrl": `/datax/data-formats/catalog/id-values?type=processor`,
                            },
                            "beanName": {
                                "type": "string",
                                "description": "The component name",
                                "oneOf": Object.entries(PROCESSOR_TYPES).map(([key, value]) => ({
                                    const: key,
                                    title: value
                                }))
                            },
                            "config": {
                                "type": "object",
                                "description": "The reader configuration",
                                "additionalProperties": true
                            },
                            "AliasProcessorConfig": {
                                "description": "The field aliaser configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "string",
                                    "description": "The alias field name"
                                },
                            },
                            "FieldSplitterProcessorConfig": {
                                "description": "The field splitter configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "string",
                                    "description": "The named regex pattern to split field"
                                },
                            },
                            "DecimalScaleProcessorConfig": {
                                "description": "The decimal scaler configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "string",
                                    "description": "The scaling value"
                                },
                            },
                            "AmountFormatterProcessorConfig": {
                                "description": "The amount formatter configuration",
                                "type": "object",
                                "properties": {
                                    "sourceFields": {
                                        "type": "array",
                                        "items": {
                                            "type": "string",
                                            "description": "The source field name containing amounts",
                                            "minLength": 1,
                                        },
                                        "minItems": 1,
                                        "description": "The list of field names containing amount values for formatting",
                                        "default": []
                                    }
                                },
                                "required": ["sourceFields"]
                            },
                            "AmountTypeDeriverProcessorConfig": {
                                "description": "The amount type deriver configuration",
                                "type": "object",
                                "properties": {
                                    "enabled": {
                                        "type": "boolean",
                                        "description": "Enable or disable the processor"
                                    },
                                    "creditAmountColumnName": {
                                        "type": "string",
                                        "description": "The column name containing credit amounts",
                                        "minLength": 1
                                    },
                                    "debitAmountColumnName": {
                                        "type": "string",
                                        "description": "The column name containing debit amounts",
                                        "minLength": 1
                                    },
                                    "totalAmountColumnName": {
                                        "type": "string",
                                        "description": "The column name containing total amount with sign",
                                        "minLength": 1
                                    },
                                    "derivedTotalAmountColumnName": {
                                        "type": "string",
                                        "description": "The column name to store the derived total amount",
                                        "default": "Derived Amount"
                                    },
                                    "isDebitAmountSigned": {
                                        "type": "boolean",
                                        "description": "Click if debit amounts are signed"
                                    }
                                },
                                "oneOf": [
                                    {
                                        "required": ["creditAmountColumnName", "debitAmountColumnName"]
                                    },
                                    {
                                        "required": ["totalAmountColumnName"]
                                    }
                                ]
                            },
                            "CompositeFieldsProcessorConfig": {
                                "description": "The field composer configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "object",
                                    "properties": {
                                        "sourceFields": {
                                            "type": "array",
                                            "items": {
                                                "type": "string",
                                                "description": "The source field name",
                                                "minLength": 1
                                            },
                                            "description": "The source field names for composition in the order of pattern",
                                            "minItems": 1
                                        },
                                        "outputPattern": {
                                            "type": "string",
                                            "description": "The pattern using %s placeholders for combining source fields",
                                            "minLength": 1
                                        }
                                    },
                                    "required": ["sourceFields", "outputPattern"]
                                },
                            },
                            "DateFormatterProcessorConfig": {
                                "description": "The date formatter configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "object",
                                    "properties": {
                                        "trimSize": {
                                            "type": "integer",
                                            "description": "The number of characters to keep from start of value"
                                        },
                                        "timeInputFormat": {
                                            "type": "string",
                                            "description": "The format pattern for parsing time values (max 8 chars)"
                                        },
                                        "timeOutputFormat": {
                                            "type": "string",
                                            "description": "The format pattern for outputting time values"
                                        },
                                        "inputFormat": {
                                            "type": "string",
                                            "description": "The format pattern for parsing date/datetime values"
                                        },
                                        "outputFormat": {
                                            "type": "string",
                                            "description": "The format pattern for outputting date/datetime values"
                                        },
                                        "inputPattern": {
                                            "type": "string",
                                            "description": "The regex pattern for parsing dates"
                                        },
                                        "grokPatterns": {
                                            "type": "array",
                                            "items": {
                                                "type": "string",
                                                "description": "The grok pattern"
                                            },
                                            "description": "The list of Grok patterns to try parsing the date"
                                        },
                                        "multipleDatePatterns": {
                                            "type": "array",
                                            "items": {
                                                "type": "string",
                                                "description": "The date pattern"
                                            },
                                            "description": "List of date patterns to try in order"
                                        },
                                        "inputFormats": {
                                            "type": "array",
                                            "items": {
                                                "type": "string"
                                            },
                                            "description": "The list of date formats to try parsing"
                                        },
                                        "outputTimezone": {
                                            "type": "string",
                                            "description": "The timezone to use for output"
                                        }
                                    }
                                },
                                "key": {
                                    "type": "string",
                                    "description": "The source field name"
                                }
                            },
                            "DefaultValueProcessorConfig": {
                                "description": "The default values configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "string",
                                    "description": "Value to use when field is empty or not available"
                                }
                            },
                            "FieldValueMapperProcesserConfig": {
                                "description": "The field value mapper configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "object",
                                    "required": ["targetField", "valueMappings"],
                                    "properties": {
                                        "targetField": {
                                            "type": "string",
                                            "description": "The target field name for the new value"
                                        },
                                        "valueMappings": {
                                            "type": "object",
                                            "description": "The mapping of original value to target value",
                                            "additionalProperties": {
                                                "type": "string",
                                                "description": "The target value",
                                                "minLength": 1
                                            }
                                        }
                                    },
                                },
                                "key": {
                                    "type": "string",
                                    "description": "The source field name"
                                }
                            },
                            "ScientificProcessorConfig": {
                                "description": "The scientific notation formatter configuration",
                                "type": "object",
                                "properties": {
                                    "sourceFields": {
                                        "type": "array",
                                        "items": {
                                            "type": "string",
                                            "description": "The source field name to replace characters",
                                            "minLength": 1
                                        },
                                        "description": "The list of source fields containing scientific notation numbers"
                                    }
                                }
                            },
                            "ReconMetadataProcessorConfig": {
                                "description": "The recon metadata configuration",
                                "type": "object",
                                "properties": {
                                    "dbToSourceFieldMapping": {
                                        "type": "object",
                                        "description": "The mapping of source field to db column",
                                        "additionalProperties": {
                                            "type": "string",
                                            "description": "The source field name"
                                        },
                                        "key": {
                                            "type": "string",
                                            "description": "The db column name",
                                        },
                                    }
                                }
                            },
                            "RecordChecksumProcessorConfig": {
                                "description": "The record checksum configuration",
                                "type": "object",
                                "properties": {
                                    "algorithm": {
                                        "type": "string",
                                        "description": "The algorithm for checksum",
                                        "oneOf": [
                                            {
                                                "const": "SHA_256",
                                                "title": "SHA 256"
                                            }
                                        ]
                                    },
                                    "sourceFields": {
                                        "type": "array",
                                        "description": "The list of fields for checksum",
                                        "items": {
                                            "type": "string",
                                            "description": "The source field name to consider for checksum"
                                        }
                                    }
                                }
                            },
                            "QuoteProcessorConfig": {
                                "description": "The quote remover configuration",
                                "type": "object",
                                "properties": {
                                    "sourceFields": {
                                        "type": "array",
                                        "items": {
                                            "type": "string"
                                        },
                                        "description": "List of fields to process. Empty processes all fields",
                                        "default": []
                                    },
                                    "quoteCharacters": {
                                        "type": "array",
                                        "items": {
                                            "type": "string"
                                        },
                                        "description": "List of quote characters to remove",
                                        "default": ["\"", "'"]
                                    },
                                    "enabled": {
                                        "type": "boolean",
                                        "description": "Whether to enable quote removal",
                                        "default": true
                                    }
                                }
                            },
                            "RemoveCharactersProcessorConfig": {
                                "description": "The charcater remover configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "array",
                                    "items": {
                                        "type": "string",
                                        "description": "The regex pattern",
                                        "minLength": 1
                                    },
                                    "description": "The list regex patterns to remove from the field value"
                                }
                            },
                            "ReplaceCharactersProcessorConfig": {
                                "description": "The characters replacer configuration",
                                "type": "object",
                                "additionalProperties": {
                                    "type": "object",
                                    "required": ["pattern", "replacement"],
                                    "properties": {
                                        "pattern": {
                                            "type": "string",
                                            "description": "The regex pattern to match characters for replacement",
                                            "minLength": 1
                                        },
                                        "replacement": {
                                            "type": "string",
                                            "description": "The value to replace matched patterns with",
                                            "minLength": 1
                                        }
                                    },
                                    "key": "The source field name to replace characters"
                                }
                            }
                        },
                        "required": ["beanName", "config"]
                    }
                }
            }
        },
        "writerConfig": {
            "type": "object",
            "description": "The writer configuration",
            "properties": {
                "type": {
                    "type": "string",
                    "description": "The type of the batch component"
                },
                "components": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "parentCatalogId": {
                                "type": ["integer", "null"],
                                "format": "int64",
                                "description": "The parent catalog id",
                                "apiUrl": `/datax/data-formats/catalog/id-values?type=writer`,
                            },
                            "beanName": {
                                "type": "string",
                                "description": "The component name",
                                "oneOf": Object.entries(WRITER_TYPES).map(([key, value]) => ({
                                    const: key,
                                    title: value
                                }))
                            },
                            "config": {
                                "type": "object",
                                "description": "The writer configuration",
                                "additionalProperties": true,
                            },
                            "CsvWriterConfig": {
                                "description": "The CSV file writer configuration",
                                "type": "object",
                                "properties": {
                                    "headerRequired": {
                                        "type": "boolean",
                                        "description": "Check to include headers in the output",
                                        "default": "false"
                                    },
                                    "delimiter": {
                                        "type": "string",
                                        "description": "The delimiter to use between fields in output",
                                        "default": ",",
                                        "enum": [
                                            ",",
                                        ]
                                    },
                                    "delimitedHeaders": {
                                        "type": "string",
                                        "description": "The list of header names delimited by comma",
                                        "default": "",
                                        "examples": ["First Name,Last Name,Email,Phone"]
                                    }
                                }
                            },
                            "JdbcWriterConfig": {
                                "type": "object",
                                "description": "The JDBC writer configuration",
                                "required": ["dataInsertQuery"],
                                "properties": {
                                    "dataInsertQuery": {
                                        "type": "string",
                                        "description": "SQL query for inserting data",
                                        "minLength": 1
                                    },
                                    "metadataMapping": {
                                        "type": "object",
                                        "description": "The mapping of source field to target fields",
                                        "additionalProperties": {
                                            "targetField": {
                                                "type": "string",
                                                "description": "The target field name"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "required": ["beanName"],
                        "oneOf": Object.entries(CONFIG_KEY_BY_BEAN_NAME["writer"]).map(([key, value]) => ({
                            "properties": {
                                "beanName": {
                                    "const": key
                                }
                            },
                            "required": [value]
                        }))

                    }
                }
            }
        }
    },
    "required": [
        "organisationId",
        "shortName",
        "longName",
        "dataSourceId",
        "dataConfig",
        "jobConfig",
        "readerConfig",
        "processorConfig",
        "writerConfig"
    ]
};

const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/organisationId",
                    "label": "Organisation",
                    "options": {
                        "readonly": true
                    }
                },
                {
                    "type": "Control",
                    "label": "Data Format Name",
                    "scope": "#/properties/shortName"
                }
            ]
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/longName",
                    "label": "Data Format Description",
                    "options": {
                        "readonly": true
                    }
                },
                {
                    "type": "Control",
                    "label": "Data Source",
                    "scope": "#/properties/dataSourceId",
                    "dataSourceType": "http"
                }
            ]
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Categorization",
                    "elements": [
                        {
                            "type": "Category",
                            "label": "Data Details",
                            "elements": [
                                {
                                    "type": "VerticalLayout",
                                    "scope": "#/properties/dataConfig",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/dataConfig/properties/fileType"
                                        },
                                        {
                                            "type": "Categorization",
                                            "elements": [
                                                {
                                                    "type": "Category",
                                                    "label": "Encryption Details",
                                                    "elements": [
                                                        {
                                                            "type": "HorizontalLayout",
                                                            "scope": "#/properties/dataConfig/properties/encryptionConfig",
                                                            "elements": [
                                                                {
                                                                    "type": "Control",
                                                                    "scope": "#/properties/dataConfig/properties/encryptionConfig/properties/encryptionAlgorithm"
                                                                },
                                                                {
                                                                    "type": "Control",
                                                                    "scope": "#/properties/dataConfig/properties/encryptionConfig/properties/encryptionProperties/properties/publicKey"
                                                                },
                                                                {
                                                                    "type": "Control",
                                                                    "scope": "#/properties/dataConfig/properties/encryptionConfig/properties/encryptionProperties/properties/keyPassword"
                                                                }
                                                            ]
                                                        }

                                                    ],
                                                    "rule": {
                                                        "effect": "SHOW",
                                                        "condition": {
                                                            "scope": "#/properties/dataConfig/properties/fileType",
                                                            "schema": {
                                                                "enum": ["ENCRYPTED", "DERIVED"]
                                                            },
                                                            "failWhenUndefined": true
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "Category",
                                                    "label": "Compression Details",
                                                    "elements": [
                                                        {
                                                            "type": "HorizontalLayout",
                                                            "scope": "#/properties/dataConfig/properties/compressionConfig",
                                                            "elements": [
                                                                {
                                                                    "type": "Control",
                                                                    "scope": "#/properties/dataConfig/properties/compressionConfig/properties/compressionAlgorithm"
                                                                },
                                                                {
                                                                    "type": "Control",
                                                                    "scope": "#/properties/dataConfig/properties/compressionConfig/properties/compressionProperties/properties/password"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "rule": {
                                                        "effect": "SHOW",
                                                        "condition": {
                                                            "scope": "#/properties/dataConfig/properties/fileType",
                                                            "schema": {
                                                                "enum": ["COMPRESSED", "DERIVED"]
                                                            },
                                                            "failWhenUndefined": true
                                                        }
                                                    }
                                                }
                                            ],
                                            "rule": {
                                                "effect": "SHOW",
                                                "condition": {
                                                    "scope": "#/properties/dataConfig/properties/fileType",
                                                    "schema": {
                                                        "not": {
                                                            "const": "PLAIN_TEXT"
                                                        }
                                                    },
                                                    "failWhenUndefined": true
                                                }
                                            }
                                        },
                                        {
                                            "type": "Group",
                                            "label": "Name Details",
                                            "scope": "#/properties/dataConfig/properties/nameConfig",
                                            "elements": [
                                                {
                                                    "type": "HorizontalLayout",
                                                    "elements": [
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/dataConfig/properties/nameConfig/properties/nameIdentifier"
                                                        },
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/dataConfig/properties/nameConfig/properties/patternType"
                                                        }
                                                    ]
                                                },
                                                {
                                                    "type": "HorizontalLayout",
                                                    "elements": [
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/dataConfig/properties/nameConfig/properties/allowedExtensions"
                                                        },
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/dataConfig/properties/nameConfig/properties/namePatterns"
                                                        }
                                                    ]
                                                },
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "type": "Category",
                            "label": "Job Details",
                            "elements": [
                                {
                                    "type": "VerticalLayout",
                                    "scope": "#/properties/jobConfig",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/jobConfig/properties/type",
                                            "options": {
                                                "readonly": true
                                            }
                                        },
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/jobConfig/properties/components",
                                            "label": "Jobs",
                                            "options": {
                                                "elementLabelProp": "beanName",
                                                "detail": {
                                                    "type": "VerticalLayout",
                                                    "elements": [
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/beanName"
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/FileProcessingJobConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "fileProcessingJob"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/FileProcessingJobConfig/properties/gridSize"
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/FileProcessingJobConfig/properties/chunkSize"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/DatabaseProcessingJobConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "databaseProcessingJob"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/DatabaseProcessingJobConfig/properties/chunkSize"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "type": "Category",
                            "label": "Reader Details",
                            "elements": [
                                {
                                    "type": "VerticalLayout",
                                    "scope": "#/properties/readerConfig",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/readerConfig/properties/type",
                                            "options": {
                                                "readonly": true
                                            }
                                        },
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/readerConfig/properties/components",
                                            "label": "Readers",
                                            "options": {
                                                "elementLabelProp": "beanName",
                                                "detail": {
                                                    "type": "VerticalLayout",
                                                    "elements": [
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/beanName"
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/CsvReaderConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "csvItemReader"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvReaderConfig/properties/delimiter"
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvReaderConfig/properties/headerLine"
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvReaderConfig/properties/delimitedHeaders"
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "Group",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvReaderConfig/properties/skipLines/properties/linesUntil",
                                                                            "label": "Skip Lines Until",
                                                                            "options": {
                                                                                "detail": {
                                                                                    "type": "VerticalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/lineType"
                                                                                        },
                                                                                        {
                                                                                            "type": "HorizontalLayout",
                                                                                            "elements": [
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/startsWith"
                                                                                                },
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/contains"
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "Group",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvReaderConfig/properties/skipLines/properties/linesIf",
                                                                            "label": "Skip Lines If",
                                                                            "options": {
                                                                                "detail": {
                                                                                    "type": "VerticalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/lineType"
                                                                                        },
                                                                                        {
                                                                                            "type": "HorizontalLayout",
                                                                                            "elements": [
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/startsWith"
                                                                                                },
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/contains"
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "Group",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvReaderConfig/properties/skipLines/properties/linesAfter",
                                                                            "label": "Skip Lines After",
                                                                            "options": {
                                                                                "detail": {
                                                                                    "type": "VerticalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/lineType"
                                                                                        },
                                                                                        {
                                                                                            "type": "HorizontalLayout",
                                                                                            "elements": [
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/startsWith"
                                                                                                },
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/contains"
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/ExcelReaderConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "excelItemReader"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/ExcelReaderConfig/properties/sheetNames"
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/ExcelReaderConfig/properties/headerLine"
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "Group",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/ExcelReaderConfig/properties/skipLines/properties/linesUntil",
                                                                            "label": "Skip Lines Until",
                                                                            "options": {
                                                                                "detail": {
                                                                                    "type": "VerticalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/lineType"
                                                                                        },
                                                                                        {
                                                                                            "type": "HorizontalLayout",
                                                                                            "elements": [
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/startsWith"
                                                                                                },
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/contains"
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "Group",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/ExcelReaderConfig/properties/skipLines/properties/linesIf",
                                                                            "label": "Skip Lines If",
                                                                            "options": {
                                                                                "detail": {
                                                                                    "type": "VerticalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/lineType"
                                                                                        },
                                                                                        {
                                                                                            "type": "HorizontalLayout",
                                                                                            "elements": [
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/startsWith"
                                                                                                },
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/contains"
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "Group",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/ExcelReaderConfig/properties/skipLines/properties/linesAfter",
                                                                            "label": "Skip Lines After",
                                                                            "options": {
                                                                                "detail": {
                                                                                    "type": "VerticalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/lineType"
                                                                                        },
                                                                                        {
                                                                                            "type": "HorizontalLayout",
                                                                                            "elements": [
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/startsWith"
                                                                                                },
                                                                                                {
                                                                                                    "type": "Control",
                                                                                                    "scope": "#/properties/line/properties/contains"
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/SwiftReaderConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "swiftItemReader"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "VerticalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/SwiftReaderConfig/properties/messageType"
                                                                                },
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/SwiftReaderConfig/properties/messageDelimiter"
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/SwiftReaderConfig/properties/tokenizerConfig/properties/includeFields"
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "scope": "#/properties/SwiftReaderConfig/properties/tokenizerConfig",
                                                                    "label": "Tag Normalization",
                                                                    "elements": [
                                                                        {
                                                                            "type": "AdditionalPropertiesLayout",
                                                                            "label": "Field Mapping",
                                                                            "scope": "#/properties/SwiftReaderConfig/properties/tokenizerConfig/properties/fieldMapping",
                                                                            "options": {
                                                                                "keyLabel": "Tag Name",
                                                                                "detail": {
                                                                                    "type": "AdditionalPropertiesLayout",
                                                                                    "scope": "#",
                                                                                    "label": false,
                                                                                    "options": {
                                                                                        "keyLabel": "Component Name",
                                                                                        "valueLabel": "Field Name"
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/JsonReaderConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "jsonItemReader"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/JsonReaderConfig/properties/flattenNestedObjects"
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/JsonReaderConfig/properties/flattenNestedArrays"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/GrokReaderConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "grokItemReader"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/GrokReaderConfig/properties/headerLine"
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/GrokReaderConfig/properties/grokPattern"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/JdbcReaderConfig",
                                                            "label": "Configuration",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "jdbcItemReader"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/JdbcReaderConfig/properties/selectQuery",
                                                                            "options": {
                                                                                "multi": true
                                                                            }
                                                                        },
                                                                        {
                                                                            "type": "AdditionalPropertiesLayout",
                                                                            "scope": "#/properties/JdbcReaderConfig/properties/filterValues",
                                                                            "options": {
                                                                                "keyLabel": "Parameter Name"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/JdbcReaderConfig/properties/maxRowCount"
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/JdbcReaderConfig/properties/maxRowCount",
                                                                            "rule": {
                                                                                "effect": "HIDE",
                                                                                "condition": {
                                                                                    "schema": {}
                                                                                }
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "type": "Category",
                            "label": "Transformation Details",
                            "elements": [
                                {
                                    "type": "VerticalLayout",
                                    "scope": "#/properties/processorConfig",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/processorConfig/properties/type",
                                            "options": {
                                                "readonly": true
                                            }
                                        },
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/processorConfig/properties/components",
                                            "label": "Transformers",
                                            "options": {
                                                "elementLabelProp": "beanName",
                                                "detail": {
                                                    "type": "VerticalLayout",
                                                    "elements": [
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/beanName"
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/AliasProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "aliasItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/AliasProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "valueLabel": "Alias Field"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/FieldSplitterProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "fieldSplitterProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/FieldSplitterProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "valueLabel": "Named Regex Pattern"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Control",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/AmountFormatterProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "amountFormatterProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/ReplaceCharactersProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "replaceCharactersItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/ReplaceCharactersProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "detail": {
                                                                            "type": "HorizontalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/pattern"
                                                                                },
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/replacement"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/CompositeFieldsProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "compositeFieldsItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/CompositeFieldsProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Target Field",
                                                                        "detail": {
                                                                            "type": "HorizontalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/outputPattern"
                                                                                },
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/sourceFields"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/RemoveCharactersProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "removeCharactersItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/RemoveCharactersProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "valueLabel": "Removable Characters"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/DateFormatterProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "dateFormatProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/DateFormatterProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "detail": {
                                                                            "type": "VerticalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "HorizontalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/trimSize",
                                                                                        },
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/outputTimezone",
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "type": "HorizontalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/inputFormat",
                                                                                        },
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/outputFormat",
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "type": "HorizontalLayout",
                                                                                    "elements": [
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/timeInputFormat",
                                                                                        },
                                                                                        {
                                                                                            "type": "Control",
                                                                                            "scope": "#/properties/timeOutputFormat",
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "scope": "#/properties/DefaultValueProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "defaultValueItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/DefaultValueProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "valueLabel": "Default Value"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/FieldValueMapperProcesserConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "fieldValueMapperItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/FieldValueMapperProcesserConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "detail": {
                                                                            "type": "VerticalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/targetField",
                                                                                },
                                                                                {
                                                                                    "type": "AdditionalPropertiesLayout",
                                                                                    "scope": "#/properties/valueMappings",
                                                                                    "options": {
                                                                                        "keyLabel": "Original Value",
                                                                                        "valueLabel": "New Value"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Control",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/ScientificProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "scientificNumberProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            }
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/ReconMetadataProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "reconMetadataProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/ReconMetadataProcessorConfig/properties/dbToSourceFieldMapping",
                                                                    "options": {
                                                                        "valueLabel": "Source Field",
                                                                        "keyLabel": "DB Column Name"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/RecordChecksumProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "recordChecksumProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/RecordChecksumProcessorConfig/properties/algorithm"
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/RecordChecksumProcessorConfig/properties/sourceFields"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Control",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/QuoteProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "quotesItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            }
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/AmountTypeDeriverProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "amountTypeDeriverProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "VerticalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "HorizontalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "Control",
                                                                                    "label": "Debit Amounts Are Signed",
                                                                                    "scope": "#/properties/AmountTypeDeriverProcessorConfig/properties/isDebitAmountSigned"
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "VerticalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "HorizontalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/AmountTypeDeriverProcessorConfig/properties/creditAmountColumnName"
                                                                                },
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/AmountTypeDeriverProcessorConfig/properties/debitAmountColumnName"
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "type": "VerticalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "HorizontalLayout",
                                                                            "elements": [
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/AmountTypeDeriverProcessorConfig/properties/totalAmountColumnName"
                                                                                },
                                                                                {
                                                                                    "type": "Control",
                                                                                    "scope": "#/properties/AmountTypeDeriverProcessorConfig/properties/derivedTotalAmountColumnName"
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/DecimalScaleProcessorConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "decimalScaleItemProcessor"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "AdditionalPropertiesLayout",
                                                                    "scope": "#/properties/DecimalScaleProcessorConfig",
                                                                    "options": {
                                                                        "keyLabel": "Source Field",
                                                                        "valueLabel": "Scale By"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "type": "Category",
                            "label": "Writer Details",
                            "elements": [
                                {
                                    "type": "VerticalLayout",
                                    "scope": "#/properties/writerConfig",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/writerConfig/properties/type",
                                            "options": {
                                                "readonly": true
                                            }
                                        },
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/writerConfig/properties/components",
                                            "label": "Writers",
                                            "options": {
                                                "elementLabelProp": "beanName",
                                                "detail": {
                                                    "type": "VerticalLayout",
                                                    "elements": [
                                                        {
                                                            "type": "Control",
                                                            "scope": "#/properties/beanName"
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/CsvWriterConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "csvItemWriter"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "HorizontalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvWriterConfig/properties/headerRequired",
                                                                            "options": {
                                                                                "multi": true,
                                                                            }
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvWriterConfig/properties/delimiter",
                                                                            "options": {
                                                                                "multi": true,
                                                                            }
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/CsvWriterConfig/properties/delimitedHeaders",
                                                                            "options": {
                                                                                "multi": true,
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "Group",
                                                            "label": "Configuration",
                                                            "scope": "#/properties/JdbcWriterConfig",
                                                            "rule": {
                                                                "effect": "SHOW",
                                                                "condition": {
                                                                    "scope": "#/properties/beanName",
                                                                    "schema": {
                                                                        "const": "jdbcItemWriter"
                                                                    },
                                                                    "failWhenUndefined": true
                                                                }
                                                            },
                                                            "elements": [
                                                                {
                                                                    "type": "VerticalLayout",
                                                                    "elements": [
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/JdbcWriterConfig/properties/dataInsertQuery",
                                                                            "options": {
                                                                                "multi": true,
                                                                            }
                                                                        },
                                                                        {
                                                                            "type": "Control",
                                                                            "scope": "#/properties/JdbcWriterConfig/properties/metadataMapping",
                                                                            "options": {
                                                                                "multi": true,
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};

export const dataFormatJsonForms = () => { return { schema, uischema } }