import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery, useTheme } from "@mui/material";

function a11yProps(index) {
  return {
    id: `reg-tab-${index}`,
    "aria-controls": `reg-tabpanel-${index}`,
  };
}

const tabStyles = (theme) => ({
  tabsWrapper: {
    minHeight: "38px !important",
    "& .MuiTabs-flexContainer": {
      flexWrap: "wrap",
      height: "38px !important",
      "& button": {
        padding: "4px 16px",
        fontSize: "13px",
        minHeight: "36px",
        color: "#5b5b5b",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
        border: `2px solid transparent`,
      },
      "& .tab-active": {
        color: theme.palette.primary.main,
        border: "1px solid #b8b8b8 !important",
        borderBottom: `2px solid ${theme.palette.primary.main} !important`,
      },
    },
  },
  tabContainer: {
    background: "white",
    paddingTop: "20px",
    paddingLeft: "16px",
  },
  tabIndicator: {
    display: "none",
    top: 0,
    height: 3,
  },
});

function MetadataTabs({
  tabsData = {},
  handleDSTabChange,
  changeTab,
  activeTab,
  selectedQueueId,
  totalRowCount,
}) {
  const count1 = totalRowCount[1]
    ? totalRowCount[1][selectedQueueId]
      ? totalRowCount[1][selectedQueueId]
      : 0
    : 0;
  const count2 = totalRowCount[2]
    ? totalRowCount[2][selectedQueueId]
      ? totalRowCount[2][selectedQueueId]
      : 0
    : 0;

  const theme = useTheme();
  const styles = tabStyles(theme);

  return (
    <Box sx={styles.tabContainer}>
      <Tabs
        sx={styles.tabsWrapper}
        className="tabsWrapper"
        value={activeTab}
        onChange={handleDSTabChange}
        aria-label="message center status"
        TabIndicatorProps={{ sx: styles.tabIndicator }}
      >
        {tabsData?.reconEnabled && tabsData?.workflowEnabled ? (
          <>
            <Tab
              iconPosition="start"
              label={`${tabsData.dataSrcConfigId1} (${count1})`}
              {...a11yProps(0)}
              onClick={() => changeTab(tabsData.dataSrcConfigId1, 0)}
              key={"0"}
              index={0}
              value={0}
              className={`${activeTab === 0 ? "tab-active" : ""}`}
            />
            <Tab
              iconPosition="start"
              label={`${tabsData.dataSrcConfigId2} (${count2})`}
              {...a11yProps(1)}
              onClick={() => changeTab(tabsData.dataSrcConfigId2, 1)}
              key={"1"}
              index={1}
              value={1}
              className={`${activeTab === 1 ? "tab-active" : ""}`}
            />
          </>
        ) : (
          <Tab
            iconPosition="start"
            label={`${tabsData.dataSrcConfigId1} (${count1})`}
            {...a11yProps(0)}
            onClick={() => changeTab(tabsData.dataSrcConfigId1, 0)}
            key={"0"}
            value={0}
            className={`${activeTab === 0 ? "tab-active" : ""}`}
          />
        )}
      </Tabs>
    </Box>
  );
}
export default React.memo(MetadataTabs);
