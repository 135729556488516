import {
    and,
    createControlElement,
    rankWith
} from '@jsonforms/core';
import { JsonFormsDispatchRenderer, withJsonFormsControlProps } from '@jsonforms/react';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
    Button,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { useMemo, useState } from 'react';
import { renderers } from './SayaMaterialRenderers';

const resolveSchema = (schema, path) => {
    console.debug('Resolving schema for path %s', path);
    if (!path) return schema;

    const segments = path.split('/').filter(Boolean);
    let currentSchema = schema;

    for (const segment of segments) {
        if (segment === '#') {
            currentSchema = currentSchema;
        }
        else if (segment === 'properties') {
            if (currentSchema.properties) {
                currentSchema = currentSchema;
            }
            else {
                currentSchema = currentSchema;
                break;
            }
        }
        else if (currentSchema.properties && currentSchema.properties[segment]) {
            currentSchema = currentSchema.properties[segment];
        } else if (currentSchema.items) {
            currentSchema = currentSchema.items;
        } else if (currentSchema.additionalProperties) {
            currentSchema = typeof currentSchema.additionalProperties === 'object'
                ? currentSchema.additionalProperties
                : {};
        }
    }

    return currentSchema;
};

export const additionalPropertiesTester = rankWith(
    10,
    and(
        (uischema, schema) => {
            const path = uischema.scope;
            if (!path) return false;

            if (uischema.type !== 'AdditionalPropertiesLayout') {
                return false;
            }

            const resolvedSchema = resolveSchema(schema, path);

            const isApplicable = uischema.type === 'AdditionalPropertiesLayout' && (resolvedSchema.additionalProperties === true ||
                (typeof resolvedSchema.additionalProperties === 'object' &&
                    resolvedSchema.additionalProperties !== null));

            console.info('Additional properties renderer %s for scope %s', isApplicable ? "is applicable" : "is not applicable", path);
            return isApplicable;
        }
    )
);

const AdditionalPropertiesRenderer = ({
    data,
    handleChange,
    path,
    schema,
    uischema
}) => {
    const [propertyName, setPropertyName] = useState("");
    const resolvedSchema = useMemo(() => resolveSchema(schema, uischema.scope), [uischema.scope]);

    console.debug("Provided schema %o, uischema %o, path %s and data %o", schema, uischema, path, data);

    // Extract existing additional properties
    const additionalProps = Object.entries(data || {}).filter(
        ([key]) => (!resolvedSchema.properties || !(key in resolvedSchema.properties)) && key !== propertyName
    );

    console.debug('Extracted additional properties %o', additionalProps);

    const handleAddProperty = () => {
        console.info('Adding the property %s with data %o', propertyName, data);
        if (!propertyName) return;

        // Create a new dispatch renderer for the value based on additionalProperties schema
        handleChange(path, {
            ...data
        });

        setPropertyName("");
    };

    const handlePropertyNameChange = (e) => {
        setPropertyName(e.target.value);
    };

    return (
        <Grid container>
            {
                uischema.label && <Grid container>
                    <Grid item xs={6}>
                        <Typography paragraph variant="subtitle1">{uischema.label !== '' ? uischema.label : "Additional Properties"}
                        </Typography>
                    </Grid>
                </Grid>
            }
            <Grid container>
                {additionalProps.map(([key, value]) => (
                    <Grid container style={{ paddingBottom: "1%" }}>
                        <Grid item xs={4} key={"property-name-" + key}>
                            <TextField
                                required
                                disabled
                                value={key}
                                size="medium"
                                label={uischema.options?.keyLabel && uischema.options?.keyLabel !== '' ? uischema.options?.keyLabel : "Property Name"}
                            />
                        </Grid>
                        <Grid item xs={6} key={"property-value-" + data[key]} >
                            <JsonFormsDispatchRenderer
                                schema={typeof resolvedSchema.additionalProperties === 'object' ? (resolvedSchema.additionalProperties.type === "array" ? resolvedSchema.additionalProperties.items : resolvedSchema.additionalProperties) : resolvedSchema}
                                uischema={uischema.options?.detail && typeof uischema.options.detail === 'object' ? uischema.options.detail : uischema.options.valueLabel ? {
                                    "type": "Control",
                                    "scope": `${key}`,
                                    "label": `${uischema.options.valueLabel}`
                                } : createControlElement(key)}
                                renderers={renderers}
                                path={(!path && path === "") ? `${key}` : `${path}.${key}`}
                                data={data}
                            />
                        </Grid>
                        <Grid item xs={2} key={"property-delete-" + key} style={{ alignContent: "center", paddingLeft: "5%" }}>
                            <Button
                                variant='contained'
                                size="small"
                                onClick={() => {
                                    const newData = { ...data };
                                    delete newData[key];
                                    handleChange(path, newData);
                                }}
                                color="error"
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                ))
                }
            </Grid>

            <Grid container>
                <Grid xs={4} item key={"property-name-edit"}>
                    {resolvedSchema.additionalProperties?.enum ? (
                        <Select
                            value={propertyName}
                            onChange={handlePropertyNameChange}
                            size="medium"
                            displayEmpty
                        >
                            <MenuItem value="">Select Property</MenuItem>
                            {resolvedSchema.additionalProperties?.enum.map((prop) => (
                                <MenuItem key={prop} value={prop}>
                                    {prop}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <TextField
                            value={propertyName}
                            onChange={handlePropertyNameChange}
                            size="medium"
                            label={uischema.options?.keyLabel && uischema.options?.keyLabel !== '' ? uischema.options?.keyLabel : "Property Name"}
                        />

                    )
                    }
                </Grid>
                <Grid xs={6} item key={"property-value-edit"}>
                    {(propertyName !== "") ?
                        < JsonFormsDispatchRenderer
                            schema={typeof resolvedSchema.additionalProperties === 'object' ? resolvedSchema.additionalProperties : resolvedSchema}
                            uischema={uischema.options?.detail && typeof uischema.options.detail === 'object' ? uischema.options.detail : uischema.options.valueLabel ? {
                                "type": "Control",
                                "scope": `${propertyName}`,
                                "label": `${uischema.options.valueLabel}`
                            } : createControlElement(propertyName)}
                            path={(!path && path === "") ? `${propertyName}` : `${path}.${propertyName}`}
                            renderers={renderers}
                            data={data}
                        />
                        : <div />
                    }
                </Grid>
                <Grid xs={2} item key={"property-add"} style={{ alignContent: "center", paddingLeft: "5%" }}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleAddProperty}
                        disabled={!propertyName}
                        startIcon={<AddIcon />}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>

        </Grid >
    );
};

export default withJsonFormsControlProps(AdditionalPropertiesRenderer);