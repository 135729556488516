import React from 'react';
import PropTypes from 'prop-types';

const Popper = ({ message, children, placement = 'top' }) => {
  const positionClasses = {
    top: 'bottom-full mb-2 left-1/2 -translate-x-1/2',
    bottom: 'top-full mt-2 left-1/2 -translate-x-1/2',
    left: 'right-full mr-2 top-1/2 -translate-y-1/2',
    right: 'left-full ml-2 top-1/2 -translate-y-1/2',
  };

  const arrowPositionClasses = {
    top: 'top-full left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-800',
    bottom: 'bottom-full left-1/2 -translate-x-1/2 border-4 border-transparent border-b-gray-800',
    left: 'right-0 top-1/2 -translate-y-1/2 border-4 border-transparent border-l-gray-800',
    right: 'left-0 top-1/2 -translate-y-1/2 border-4 border-transparent border-r-gray-800',
  };

  return (
    <div className="relative group inline-block">
      {/* Wrapped Component */}
      {children}

      {/* Popper Message */}
      <div
        className={`absolute whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-800 text-white text-xs rounded py-1 px-2 shadow-lg z-10 ${positionClasses[placement]}`}
      >
        {message}
        {/* Popper Arrow */}
        <div className={`absolute ${arrowPositionClasses[placement]}`}></div>
      </div>
    </div>
  );
};

Popper.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

export default Popper;
