import axios from "axios";
import { DATAX } from "URL";

export const fetchExecutionConfiguration = async (executionId, callback) => {
    try {
        const response = await axios.get(`${DATAX}/ingestions/executions/${executionId}/configurations`);

        if (response.status === 200) {
            callback(response.data);
        }
        else {
            callback({});
        }
    } catch (error) {
        console.error("Unable to fetch execution configuration", executionId, error.message);
        callback({});
    }
};

export const deleteExecutionData = async (executionId, callback) => {
    try {
        const response = await axios.delete(`${DATAX}/ingestions/executions/${executionId}/data`);
        if (response.status === 204) {
            callback(true);
        }
    } catch (error) {
        console.error("Unable to delete executions data", executionId, error.message);
        callback(false);
    }
};