import {
  AppsOutlined,
  AccountBalance,
  Groups,
  DisplaySettings,
  ManageAccounts,
  AdminPanelSettingsOutlined,
  AccountTreeOutlined,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Card from "components/Card/Card";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MuiIcon from "components/ICONS";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  FolderInput,
  FileCog,
  Layers,
  GitCompareArrows,
  Command,
} from "lucide-react";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";

const useStyles = makeStyles(styles);

function ReconXHome({ routeData }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const reconXTypes = [
    {
      type: "1 way",
      title: "1-way Recon",
      route: "/saya-platform/saya-reconx/1-way",
      isVisible: false,
      isDisabled: false,
    },
    {
      type: "ResolveX",
      title: "n-way Recon",
      route: "/saya-platform/saya-reconx/n-way",
      isVisible: false,
      isDisabled: false,
    },
  ];

  const navigationHandler = (route) => {
    navigate(route, {
      state: { allowedPermissions: routeData.allowedPermissions },
    });
  };

  const getIcon = (type) => {
    let iconName = "";
    switch (type) {
      case "1-way recon":
        return { icon: Command, useLucide: true };
      case "n-way recon":
        return { icon: GitCompareArrows, useLucide: true };
      default:
        return { icon: AppsOutlined, useLucide: false };
    }
    return iconName;
  };

  return (
    <div style={{ margin: "0 -18px" }}>
      <GridContainer>
        {reconXTypes.map((recontype, index) => (
          <GridItem
            xs={12}
            sm={6}
            md={3}
            key={recontype.title}
            style={{ cursor: "pointer" }}
            onClick={() => navigationHandler(recontype.route)}
          >
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary" plain>
                  <MuiIcon {...getIcon(recontype.title.toLowerCase())} />
                </CardIcon>
                <h3
                  className={`${classes.cardTitle} ${classes.adminCardTitle} relative top-4`}
                >
                  {recontype.title}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>View {recontype.title}</div>
                <KeyboardDoubleArrowRight />
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
}

export default ReconXHome;
