import { API } from "URL";
import axios from "axios";

export const fetchNWayReconProcesses = async (userId, callback) => {
  try {
    const response = await axios.get(
      `${API}/reconunits/nwayrecon/byuser/${userId}`
    );

    if (response.status === 200) {
      callback(response.data);
    } else {
      callback([]);
    }
  } catch (error) {
    console.error(
      "Unable to fetch n-way recon processes for organisation",
      userId,
      error.message
    );
    callback(false);
  }
};

export const fetchNWayReconProcess = async (nWayReconProcessId, callback) => {
  try {
    const response = await axios.get(
      `${API}/reconunits/nwayrecon/${nWayReconProcessId}`
    );

    callback(response.status, response.data);
  } catch (error) {
    console.error(
      "Unable to fetch n-way recon process for id",
      nWayReconProcessId,
      error.message
    );
    callback(false);
  }
};

export const createOrModifyNWayReconProcess = async (
  nWayReconProcess,
  nWayReconProcessId,
  callback
) => {
  try {
    var response;
    if (nWayReconProcessId && nWayReconProcessId !== "") {
      response = await axios.post(
        `${API}/reconunits/nwayrecon/create`,
        nWayReconProcess
      );
    } else {
      response = await axios.post(
        `${API}/reconunits/nwayrecon/create`,
        nWayReconProcess
      );
    }

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error("Unable to create/modify n-way recon process", error.message);
    callback({});
  }
};

export const deleteNWayReconProcess = async (
  nWayReconProcessId,
  userId,
  callback
) => {
  try {
    const response = await axios.delete(
      `${API}/reconunits/nwayrecon/close/${nWayReconProcessId}/${userId}`
    );
    if (response.status === 200) {
      callback(
        `Deleted n-way recon process with id ${nWayReconProcessId} successfully`
      );
    }
  } catch (error) {
    console.error(
      "Unable to delete n-way recon process ",
      nWayReconProcessId,
      error.message
    );
    callback(
      `Unable to delete n-way recon process with id ${nWayReconProcessId}`
    );
  }
};

export const fetchNWayReconProcessesIdValues = async (
  organisationId,
  callback
) => {
  try {
    const response = await axios.get(
      `${API}/reconunits/nwayrecon/id-values?organisationId=${organisationId}`
    );

    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error(
      "Unable to fetch n-way recon processes id-values for organisation",
      organisationId,
      error.message
    );
    callback([]);
  }
};

export const fetchDatasetsByNWayReconProcess = async (
  nWayReconProcessId,
  organisationId,
  callback
) => {
  try {
    const response = await axios.get(
      `${API}/reconunits/nwayrecon/${nWayReconProcessId}/data-sets?organisationId=${organisationId}`
    );

    callback(response.data, response.data);
  } catch (error) {
    console.error(
      "Unable to fetch data-sets by n-way recon process id",
      nWayReconProcessId,
      error.message
    );
    callback([]);
  }
};

export const getNWayFormData = async (
  nWayReconProcessId,
  dataSetId,
  rowFingerprint,
  callback
) => {
  try {
    const response = await axios.get(
      `${API}/reconquery/getNwaylinks/${nWayReconProcessId}/${dataSetId}/${rowFingerprint}`
    );

    callback(response.data, response.data);
  } catch (error) {
    console.error(
      "Unable to fetch data-sets by n-way recon process id",
      nWayReconProcessId,
      error.message
    );
    callback([]);
  }
};
