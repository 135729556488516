import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import AnalytixTile from "components/v2/AnalytixTile";
import Tile from "components/v2/Tile";
import { API } from "URL";
import { showInfo } from "./DashboardUtil";

function AnalyticsDashboard({ userId, orgId, roleId }) {
  const [analytixData, setSetAnalytixData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchAnalytixData = async (userId, orgId, roleId) => {
    try {
      setLoading(true);
      if (orgId && roleId && userId) {
        const response = await axios.get(
          `${API}/reconanalytix/fetchanalytixconfig?userId=${userId}&orgId=${orgId}&roleId=${roleId}`
        );
        if (response.status === 200) {
          setSetAnalytixData(response.data);
        }
      } else {
        console.error(
          "======>>> userId, roleId, orgId is not available",
          userId,
          roleId,
          orgId
        );
      }
      setLoading(false);
    } catch (ex) {
      console.log("====>>> Exception in fetchAnalytiXData = ", ex.message);
      setSetAnalytixData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId !== 0 && orgId && roleId) {
      fetchAnalytixData(userId, orgId, roleId);
    }
  }, [userId, orgId, roleId]);

  const redirectToAnalytixDashboard = (value) => {
    navigate("/saya-platform/saya-analytix", { state: { embededId: value } });
  };

  if (isLoading) {
    return showInfo("Data Loading... Please wait...");
  }
  if (analytixData.length === 0) {
    return showInfo("No data available for the selected criteria.");
  }
  return (
    <AnalytixTile
      data={analytixData}
      redirectToAnalytixDashboard={redirectToAnalytixDashboard}
    />
  );
}

export default React.memo(AnalyticsDashboard);
