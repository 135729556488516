"use client";

import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, Check, Info } from "lucide-react";

const CoolSelect = ({
  label,
  name,
  value = [],
  placeholder = "Please select",
  isRequired,
  selectData = [],
  optionValue,
  optionLabel,
  onChange,
  isMulti = false,
  errorMessage = "",
  showSearch = true,
  shrinkLabel = false,
  // Prop to show/hide the info icon & tooltip
  isVisibleOptions = false,
  size = "small", //or large
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = selectData.filter((item) =>
    item[optionLabel]?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  const handleSelect = (selectedValue) => {
    if (isMulti) {
      const updatedValues = value.includes(selectedValue)
        ? value.filter((v) => v !== selectedValue)
        : [...value, selectedValue];
      onChange(updatedValues);
    } else {
      onChange(selectedValue);
      setIsOpen(false);
    }
  };

  const renderValue = () => {
    if (selectData.length === 0) {
      return placeholder; // Show placeholder if no options are available
    }

    if (isMulti) {
      if (value.length === 0) return placeholder;
      if (value.length === selectData.length) return "All selected";
      return `${value.length} selected`;
    } else {
      const selectedItem = selectData.find(
        (item) => item[optionValue] === value
      );
      return selectedItem ? selectedItem[optionLabel] : placeholder;
    }
  };

  // Renders the floating or static label depending on shrinkLabel
  const renderLabel = () => {
    if (shrinkLabel) {
      return (
        <label
          className={`
            absolute
            left-3
            transition-all
            duration-200
            pointer-events-none
            bg-white
            px-1
            ${
              isOpen || selectData?.length > 0
                ? "top-[-7px] text-xs text-pink-500"
                : "top-1/2 -translate-y-1/2 text-sm text-gray-700"
            }
          `}
        >
          {label}
          {isRequired && <span className="text-red-500">*</span>}
        </label>
      );
    }
    // Non-floating label
    return (
      <label className="block text-xs text-gray-700 mb-1">
        {label}
        {isRequired && <span className="text-red-500">*</span>}
      </label>
    );
  };

  // Tooltip with selected items, triggered by hovering the info icon
  const renderInfoTooltip = () => {
    // Show the info icon only if:
    // - isVisibleOptions is true
    // - isMulti
    // - There's at least one selected value
    if (!isVisibleOptions || !isMulti || value.length === 0) return null;

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative flex items-center ml-2"
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <Info className="w-4 h-4 text-gray-500 cursor-pointer" />

        {/* Tooltip */}
        {isTooltipOpen && (
          <div className="absolute top-[100%] right-0 mt-1 p-3 bg-gradient-to-b
from-[#ffffff]
to-[#ffffff]  border border-[#46b2d3] rounded-md shadow-[-1px_1px_12px_2px_rgba(0,_0,_0,_0.1)] z-20 text-sm w-80">
            <ul className="flex flex-wrap list-inside">
              {value.map((val) => {
                const selectedItem = selectData.find(
                  (item) => item[optionValue] === val
                );
                return (
                  <li key={val} className="mb-1 border border-[#3e9ab6] text-[#3e9ab6] bg-white rounded-xl p-0.5 px-2 text-[11px] mr-1">
                    {selectedItem ? selectedItem[optionLabel] : val}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const renderSelectedValues = () => {
    // Show the info icon only if:
    // - isVisibleOptions is true
    // - isMulti
    // - There's at least one selected value
    if (!isVisibleOptions || !isMulti || value.length === 0) return placeholder;

    return (
      <div className="flex max-w-[250px] truncate">

          {value.map((val, index) => {
            const selectedItem = selectData.find(
              (item) => item[optionValue] === val
            );
            return (
              <span key={val}>
                {selectedItem ? selectedItem[optionLabel] : val}{value.length -1 !== index && <b className="mr-1">,</b>}
              </span>
            );
          })}

      </div>
    );
  };

  return (
    <div
      className={`w-full relative ${shrinkLabel ? "relative" : ""}`}
      ref={dropdownRef}
    >
      {renderLabel()}

      {/* Select Box */}
      <div
        className={`bg-white px-3 border border-gray-300 rounded-md
          flex items-center justify-between
          hover:border-pink-500 transition-colors duration-200
          ${shrinkLabel ? "pt-5 pb-2" : "py-2"}
        `}
        // Clicking anywhere on this container toggles the dropdown, except the info icon area
        onClick={() => setIsOpen((prev) => !prev)}
        style={{
          paddingTop: size === "large" ? "17px" : "10px",
          paddingBottom: size === "large" ? "17px" : "10px",
        }}
      >
        {!isVisibleOptions ? (
          <span className="truncate text-sm">{renderValue()}</span>
        ) : (
          <div className="truncate text-sm">{renderSelectedValues()}</div>
        )}

        {/* Right-hand side icons: dropdown chevron + info icon */}
        <div className="flex items-center">
          <ChevronDown
            className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
              isOpen ? "rotate-180" : ""
            }`}
          />
          {/* Info icon & tooltip */}
          {renderInfoTooltip()}
        </div>
      </div>

      {/* Error message */}
      <div className="text-red-500 text-xs pt-1">{errorMessage}</div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {showSearch && (
            <div className="sticky top-0 bg-white p-2">
              <input
                type="text"
                className="w-full px-3 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}
          {filteredOptions.map((item, index) => (
            <div
              key={`${item[optionValue]}-${index}`}
              className={`px-4 py-2 cursor-pointer hover:bg-slate-50 flex items-center justify-between ${
                isMulti && value.includes(item[optionValue])
                  ? "bg-slate-100"
                  : ""
              }`}
              onClick={() => handleSelect(item[optionValue])}
            >
              <span className="text-sm">{item[optionLabel]}</span>
              {isMulti && value.includes(item[optionValue]) && (
                <Check className="w-4 h-4 text-pink-600" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(CoolSelect);
