import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Chip,
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { DATAX } from "URL";

// Define styles using makeStyles
const useStyles = makeStyles({
  tableContainer: {
    boxShadow: "none",
    borderRadius: "0px",
    border: "1px solid #f5f5f5",
  },
  headerCell: {
    padding: "8px 16px",
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: "#f5f5f5",
  },
  bodyCell: {
    padding: "3px 10px",
    fontSize: "14px",
  },
  chipSuccess: {
    backgroundColor: "#ebf8e8",
    color: "#5ec615",
  },
  chipFailure: {
    backgroundColor: "#fbebeb",
    color: "#e64142",
  },
  chipPending: {
    backgroundColor: "#fdf9e7",
    color: "#edbe15",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    padding: "16px",
    boxShadow: 24,
    borderRadius: "4px",
  },
  closeIcon: {
    position: "absolute",
    top: 8,
    right: 8,
    color: "#aaa",
  },
});

// Helper function to capitalize words
const capitalize = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

// Function to render status chips
const getStatusChip = (status, classes) => {
  switch (status) {
    case "Failed":
      return <Chip label="Failed" className={classes.chipFailure} />;
    case "Successful":
      return <Chip label="Successful" className={classes.chipSuccess} />;
    case "In-Progress":
      return <Chip label="In-Progress" className={classes.chipPending} />;
    default:
      return <Chip label={status} />;
  }
};

const DataIngestionTable = ({ auditData }) => {
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const classes = useStyles();

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalData(null);
  };

  const fetchExecutionData = async (executionId) => {
    try {
      const response = await axios.get(`${DATAX}/ingestions/executions/${executionId}`);
      setModalData(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching execution data:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   if (auditData && Array.isArray(auditData) && auditData.length > 0) {
  //     const dynamicColumns = Object.keys(auditData[0] || {}).map((key) => ({
  //       name: key,
  //       label: capitalize(key),
  //       options: {
  //         filter: true,
  //         sort: true,
  //         setCellProps: () => ({
  //           className: classes.bodyCell,
  //           style: key === "eventId" ? { minWidth: "400px" } : {},
  //         }),
  //         setCellHeaderProps: () => ({
  //           className: classes.headerCell,
  //           style: key === "executionId" ? { width: "150px" } : {},
  //         }),
  //         ...(key === "upload_status"
  //           ? {
  //               customBodyRender: (value) => getStatusChip(value, classes),
  //               filterType: "dropdown",
  //             }
  //           : {}),
  //         ...(key === "executionId"
  //           ? {
  //               customBodyRender: (value) =>
  //                 value ? (
  //                   <Button onClick={() => fetchExecutionData(value)} color="primary">
  //                     {value}
  //                   </Button>
  //                 ) : (
  //                   <Button disabled>N/A</Button>
  //                 ),
  //             }
  //           : {}),
  //       },
  //     }));
  //     setColumns(dynamicColumns);
  //   }
  // }, [auditData, classes]);

  useEffect(() => {
    if (auditData && Array.isArray(auditData) && auditData.length > 0) {
      const columnOrder = [
        "uploadId",
        "uploadStatus",
        "executionId",
        "sourceIdentifier",
        "uploadedPath",
        "userId",
        "dataSourceId",
        "dataSetId",
        "eventId",
      ];

      const dynamicColumns = columnOrder
        .filter((key) => Object.keys(auditData[0]).includes(key)) // Ensure the key exists in the data
        .map((key) => ({
          name: key,
          label: capitalize(key),
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              className: classes.bodyCell,
              style: key === "eventId" ? { minWidth: "400px" } : {},
            }),
            setCellHeaderProps: () => ({
              className: classes.headerCell,
              style: key === "executionId" ? { width: "150px" } : {},
            }),
            ...(key === "uploadStatus"
              ? {
                customBodyRender: (value) => getStatusChip(value, classes),
                filterType: "dropdown",
              }
              : {}),
            ...(key === "executionId"
              ? {
                customBodyRender: (value) =>
                  value ? (
                    <Button onClick={() => fetchExecutionData(value)} color="primary">
                      {value}
                    </Button>
                  ) : (
                    <Button disabled>N/A</Button>
                  ),
              }
              : {}),
          },
        }));

      setColumns(dynamicColumns);
    }
  }, [auditData, classes]);


  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 10,
    download: false,
    print: false,
    search: true,
    textLabels: {
      body: {
        noMatch: loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2">Loading data...</Typography>
          </Box>
        ) : (
          "No data to display"
        ),
      },
    },
  };

  const formattedData = Array.isArray(auditData) ? auditData : [];
  const sanitizedData = formattedData.map((row) =>
    Object.fromEntries(
      Object.entries(row).map(([key, value]) => [key, value ?? "N/A"])
    )
  );

  return (
    <>
      <MUIDataTable
        title={"Upload History"}
        data={loading ? [] : sanitizedData}
        columns={columns}
        options={options}
      />
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box className={classes.modal}>
          <IconButton className={classes.closeIcon} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" gutterBottom>
            Execution Details
          </Typography>
          {modalData ? (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Execution ID</TableCell>
                    <TableCell>{modalData.executionId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>{
                      <span
                        className={
                          modalData.executionStatus?.includes("Successful")
                            ? "text-green-600"
                            : modalData.executionStatus?.includes("Failed")
                              ? "text-red-600"
                              : "text-yellow-600"
                        }
                      >
                        {modalData.executionStatus}
                      </span>}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Record Count</TableCell>
                    <TableCell>{modalData.successRecordCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Error Count</TableCell>
                    <TableCell>{modalData.errorCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Time Taken</TableCell>
                    <TableCell>{modalData.timeTaken}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DataIngestionTable;
