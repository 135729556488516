import React, { useState, useEffect } from "react";
import axios from "axios";
import PieChart from "./PieChart";
import { showInfo } from "./DashboardUtil";
import ChartSkeltion from "./ChartSkeltion";
import LineChart from "./LineChart";
import { DATAX } from "URL";

function DataXDashboard({ userId, orgId, selectedDatasets, selectedPeriod }) {
  const [isLoading, setLoading] = useState(false);
  const [dataxMetrixDetails, setDataxMetrixDetails] = useState({});

  const fetchDataxMetrix = async (
    userId,
    orgId,
    selectedDatasets,
    selectedPeriod
  ) => {
    try {
      setLoading(true);
      let url = `${DATAX}/ingestions/metrics/detail?userId=${userId}&organisationId=${orgId}`;
      const ops = selectedDatasets.filter((ops) => ops !== "All");
      if (ops.length > 0) {
        setLoading(true);
        url += `&dataSetIds=${ops}`;
      }

      if (selectedPeriod.tenorPeriodVal) {
        if (selectedPeriod.tenorPeriodVal === "All") {
        } else if (selectedPeriod.tenorPeriodVal !== "CUSTOM") {
          url += `&period=${selectedPeriod.tenorPeriodVal}`;
        } else {
          url += `&startDate=${selectedPeriod.startDate}&endDate=${selectedPeriod.endDate}`;
        }
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        setDataxMetrixDetails(response.data);
        setLoading(false);
      }
    } catch (ex) {
      console.error("========>>>>Exception in fetchDataxMetrix", ex.message);
      setDataxMetrixDetails({});
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId !== 0 && selectedPeriod && orgId) {
      fetchDataxMetrix(userId, orgId, selectedDatasets, selectedPeriod);
    }
  }, [userId, orgId, selectedDatasets, selectedPeriod]);

  const getCharts = () => {
    if (Object.keys(dataxMetrixDetails).length > 0) {
      return (
        <div className="grid flex-1 scroll-mt-20 items-start gap-6 md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:gap-6">
          <div className="bg-white themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out ">
            <LineChart
              {...{
                chartData: dataxMetrixDetails?.dailyIngestionCount,
                label: "ingestionDate",
                value: "ingestionCount",
                datasetLabel: "Ingestions processed per Day",
                groupBy: "dataSetId",
                selectedPeriod,
              }}
            />
          </div>

          <div className="bg-white themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out">
            <PieChart
              {...{
                chartData: dataxMetrixDetails?.failedIngestionCount || [],
                label: "dataSetName",
                value: "ingestionCount",
                title: "Failed ingestions per dataset",
                selectedPeriod,
                source: "DataX",
              }}
            />
          </div>
        </div>
      );
    }
    return showInfo(`No data available for the selected criteria.`);
  };

  if (isLoading) {
    return <ChartSkeltion />;
  }
  return getCharts();
}

export default React.memo(DataXDashboard);
