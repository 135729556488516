import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery, useTheme } from "@mui/material";

function a11yProps(index) {
  return {
    id: `reg-tab-${index}`,
    "aria-controls": `reg-tabpanel-${index}`,
  };
}

const containerStyles = {
  borderTop: 0,
  borderColor: "divider",
};

const tabsStyles = (theme) => ({
  minHeight: "38px !important",
  "& .MuiTabs-flexContainer": {
    flexWrap: "wrap",
    height: "38px !important",
    "& button": {
      padding: "5px 16px",
      fontSize: "14px",
      minHeight: "36px",
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      border: `2px solid transparent`,
    },
    "& .tab-active": {
      color: theme.palette.primary.main,
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      border: `2px solid #e6bdd0`,
      borderBottom: "0px",
    },
  },
});

const tabStyles = (theme) => ({
  padding: "5px 16px",
  fontSize: "14px",
  minHeight: "36px",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  border: `2px solid transparent`,
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    border: '1px solid #C4CDD5 !important',
    borderBottom: `2px solid ${theme.palette.primary.main} !important`,
  },
});

const tabIndicatorStyles = {
  display: "none",
  top: 0,
  height: 3,
};

function ResolveXQueues({
  queuesData,
  handleTabChange,
  changeTab,
  activeTabName,
  activeTab,
  totalRowCount,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sx"));

  return (
    <Box sx={containerStyles}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="message center status"
        TabIndicatorProps={{
          sx: tabIndicatorStyles,
        }}
        sx={tabsStyles(theme)}
      >
        {queuesData.map((menu, index) => {
          const count1 = totalRowCount[1]
            ? totalRowCount[1][menu.queueId] || 0
            : 0;
          const count2 = totalRowCount[2]
            ? totalRowCount[2][menu.queueId] || 0
            : 0;
          const count = +count1 + +count2;
          return (
            <Tab
              key={index}
              iconPosition="start"
              label={`${menu.queueName} - (${count})`}
              {...a11yProps(0)}
              onClick={() => changeTab(menu)}
              sx={tabStyles(theme)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

export default React.memo(ResolveXQueues);
