import { grayColor } from "assets/jss/material-dashboard-react.js";
import { useTheme } from '@mui/material/styles';

export default function cardFooterStyle() {
  const theme = useTheme();
return{

  cardFooter: {
    padding: "0",
    paddingTop: "10px",
    margin: "0 15px 10px",
    borderRadius: "0",
    justifyContent: "space-between",
    alignItems: "flex-start",
    display: "flex",
    backgroundColor: "transparent",
    border: "0",
    paddingTop: 5,
    [theme.breakpoints.down('lg')]: {
      margin: "5px",
    },
  },
  cardFooterProfile: {
    marginTop: "-15px"
  },
  cardFooterPlain: {
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: "transparent"
  },
  cardFooterStats: {
    borderTop: "1px solid " + theme.palette.border.primary,
    color: theme.palette.text.primary,
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "2px",
      paddingTop: "2px"
    },
    "& svg": {
      position: "relative",
      top: "4px",
      color: theme.palette.secondary.main,
      marginLeft: "3px",
      width: "16px",
      height: "16px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "16px",
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& button": {
      borderRadius: '5px',
      padding: '5px',
      marginRight: '5px',
      "& svg": {
        top: 0
      }
    }
  },
  cardFooterChart: {
    borderTop: "1px solid " + grayColor[10]
  }
}};

