export const configNameByDataSourceType = {
    "DATABASE": "dbConnectionConfig",
    "API": "apiConnectionConfig",
    "EMAIL": "emailConnectionConfig",
    "SFTP": "sftpConnectionConfig",
    "OBJECT_STORAGE": "objectStorageConnectionConfig",
}

export const configNameByAuthenticationType = {
    "BASIC": "basicAuthenticationConfig",
    "OAUTH": "oauthAuthenticationConfig",
    "CERTIFICATE": "certificateAuthenticationConfig",
    "AWS_CREDENTIALS": "awsAuthenticationConfig"
}

const schema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "title": "DataSourceCreateRequest",
    "type": "object",
    "properties": {
        "organisationId": {
            "type": "integer",
            "description": "The id of the organisation"
        },
        "shortName": {
            "type": "string",
            "description": "The name of the data source",
            "minLength": 1,
            "maxLength": 128
        },
        "longName": {
            "type": "string",
            "description": "The description of the data source",
            "minLength": 1,
            "maxLength": 256
        },
        "dataSourceType": {
            "type": "string",
            "oneOf": [
                {
                    "const": "DATABASE",
                    "title": "Database"
                },
                {
                    "const": "EMAIL",
                    "title": "Email"
                },
                {
                    "const": "SFTP",
                    "title": "SFTP"
                },
                {
                    "const": "API",
                    "title": "API"
                },
                {
                    "const": "OBJECT_STORAGE",
                    "title": "Object Storage"
                }
            ],
            "description": "The type of the data source"
        },
        "connectionConfig": {
            "type": "object",
            "description": "The connection details",
            "additionalProperties": true
        },
        "dbConnectionConfig": {
            "type": "object",
            "properties": {
                "host": {
                    "type": "string",
                    "description": "The host address of the database"
                },
                "port": {
                    "type": "integer",
                    "format": "int32",
                    "description": "The port of the database"
                },
                "database": {
                    "type": "string",
                    "minLength": 1,
                    "description": "The name of the database"
                }
            },
            "required": [
                "host",
                "port",
                "database"
            ]
        },
        "apiConnectionConfig": {
            "type": "object",
            "properties": {
                "url": {
                    "type": "string",
                    "format": "uri",
                    "description": "The url of the API"
                },
                "method": {
                    "type": "string",
                    "enum": [
                        "GET",
                        "POST"
                    ],
                    "description": "The method of the API"
                },
                "headers": {
                    "type": "array",
                    "description": "The headers of the API",
                    "items": {
                        "type": "object",
                        "properties": {
                            "headerName": {
                                "type": "string",
                                "description": "The name of the header",
                                "minLength": 1
                            },
                            "headerValue": {
                                "type": "string",
                                "description": "The value of the header",
                                "minLength": 1
                            }
                        }
                    }
                }
            },
            "required": [
                "url",
                "method"
            ]
        },
        "emailConnectionConfig": {
            "type": "object",
            "properties": {
                "host": {
                    "type": "string",
                    "description": "The host address of the email server",
                },
                "port": {
                    "type": "integer",
                    "description": "The port of the email server",
                    "format": "int32"
                },
                "emailAddress": {
                    "type": "string",
                    "description": "The email address",
                    "format": "email"
                },
                "labels": {
                    "type": "array",
                    "description": "The labels of the email address",
                    "items": {
                        "type": "string",
                        "minLength": 1
                    }
                }
            },
            "required": [
                "host",
                "port",
                "emailAddress"
            ]
        },
        "sftpConnectionConfig": {
            "type": "object",
            "properties": {
                "host": {
                    "type": "string",
                    "description": "The host address of the SFTP server"
                },
                "port": {
                    "type": "integer",
                    "format": "int32",
                    "description": "The port of the SFTP server"
                },
                "rootDirectory": {
                    "type": "string",
                    "description": "The root directory of the SFTP server",
                    "minLength": 1
                }
            },
            "required": [
                "host",
                "port",
                "rootDirectory"
            ]
        },
        "objectStorageConnectionConfig": {
            "type": "object",
            "properties": {
                "bucketName": {
                    "type": "string",
                    "description": "The name of the bucket",
                    "minLength": 1,
                    "maxLength": 64
                },
                "prefix": {
                    "type": "string",
                    "description": "The prefix of the bucket",
                    "minLength": 1
                },
                "region": {
                    "type": "string",
                    "description": "The region of the bucket",
                    "enum": [
                        "us-east-1",
                        "us-west-1",
                        "us-west-2",
                        "eu-west-1",
                        "eu-central-1",
                        "ap-southeast-1",
                        "ap-southeast-2",
                        "ap-northeast-1",
                        "ap-northeast-2",
                        "sa-east-1"
                    ]
                }
            },
            "required": [
                "bucketName",
                "prefix",
                "region"
            ]
        },
        "fetchConfig": {
            "type": "object",
            "description": "Specify data fetching details",
            "properties": {
                "cronExpression": {
                    "type": "string",
                    "description": "The cron expression for fetching the data",
                    "minLength": 11
                }
            }
        },
        "authentications": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "shortName": {
                        "type": "string",
                        "description": "The name of the authentication",
                        "minLength": 1,
                        "maxLength": 128
                    },
                    "longName": {
                        "type": "string",
                        "description": "The description of the authentication",
                        "minLength": 1,
                        "maxLength": 256
                    },
                    "authenticationType": {
                        "type": "string",
                        "enum": [
                            "BASIC",
                            "OAUTH",
                            "CERTIFICATE",
                            "AWS_CREDENTIALS"
                        ],
                        "oneOf": [
                            {
                                "const": "BASIC",
                                "title": "Basic"
                            },
                            {
                                "const": "OAUTH",
                                "title": "OAuth"
                            },
                            {
                                "const": "CERTIFICATE",
                                "title": "Certificate"
                            },
                            {
                                "const": "AWS_CREDENTIALS",
                                "title": "AWS Credentials"
                            }
                        ],
                        "description": "The type of the authentication"
                    },
                    "authenticationConfig": {
                        "type": "object",
                        "description": "The authentication details",
                        "additionalProperties": true
                    },
                    "basicAuthenticationConfig": {
                        "type": "object",
                        "properties": {
                            "username": {
                                "type": "string",
                                "minLength": 1,
                                "description": "The username of the database"
                            },
                            "password": {
                                "type": "string",
                                "format": "password",
                                "minLength": 1,
                                "description": "The password of the database"
                            }
                        },
                        "required": [
                            "username",
                            "password"
                        ]
                    },
                    "oauthAuthenticationConfig": {
                        "type": "object",
                        "properties": {
                            "clientId": {
                                "type": "string",
                                "description": "The client id",
                                "minLength": 1
                            },
                            "clientSecret": {
                                "type": "string",
                                "description": "The client secret",
                                "minLength": 1,
                                "format": "password"
                            },
                            "tokenUrl": {
                                "type": "string",
                                "description": "The token url",
                                "minLength": 1,
                                "format": "uri"
                            },
                            "scopes": {
                                "type": "array",
                                "description": "The scopes",
                                "items": {
                                    "type": "string",
                                    "minLength": 1
                                },
                                "minItems": 1
                            }
                        },
                        "required": [
                            "clientId",
                            "clientSecret",
                            "tokenUrl",
                            "scopes"
                        ]
                    },
                    "certificateAuthenticationConfig": {
                        "type": "object",
                        "properties": {
                            "certificate": {
                                "type": "string",
                                "description": "The certificate"
                            },
                            "publicKey": {
                                "type": "string",
                                "format": "password",
                                "description": "The public key",
                                "minLength": 1
                            }
                        },
                        "required": [
                            "certificate",
                            "publicKey"
                        ]
                    },
                    "awsAuthenticationConfig": {
                        "type": "object",
                        "properties": {
                            "accessKeyId": {
                                "type": "string",
                                "description": "The access key id",
                                "minLength": 1
                            },
                            "secretAccessKey": {
                                "type": "string",
                                "description": "The secret access key",
                                "minLength": 1,
                                "format": "password"
                            }
                        },
                        "required": [
                            "accessKeyId",
                            "secretAccessKey"
                        ]
                    }
                },
                "required": [
                    "shortName",
                    "longName",
                    "authenticationType",
                    "authenticationConfig"
                ],
                "oneOf": Object.entries(configNameByAuthenticationType).map(([key, value]) => ({
                    "properties": {
                        "authenticationType": {
                            "const": key
                        }
                    },
                    "required": [value]
                }))
            },
            "description": "The list of authentications"
        }
    },
    "required": [
        "organisationId",
        "shortName",
        "longName",
        "dataSourceType",
        "connectionConfig",
        "authentications"
    ],
    "oneOf": Object.entries(configNameByDataSourceType).map(([key, value]) => ({
        "properties": {
            "dataSourceType": {
                "const": key
            }
        },
        "required": [value]
    }))
};

const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/organisationId",
                    "label": "Organisation",
                    "options": {
                        "readonly": true
                    }
                },
                {
                    "type": "Control",
                    "label": "Data Source Name",
                    "scope": "#/properties/shortName"
                }
            ]
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/longName",
                    "label": "Data Source Description",
                    "options": {
                        "readonly": true
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/dataSourceType"
                }
            ]
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "VerticalLayout",
                    "elements": [
                        {
                            "type": "Control",
                            "scope": "#/properties/dbConnectionConfig",
                            "label": "Connection Details",
                            "rule": {
                                "effect": "SHOW",
                                "condition": {
                                    "schema": {
                                        "properties": {
                                            "dataSourceType": {
                                                "const": "DATABASE"
                                            }
                                        }
                                    },
                                    "failWhenUndefined": true
                                }
                            }
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/apiConnectionConfig",
                            "label": "Connection Details",
                            "rule": {
                                "effect": "SHOW",
                                "condition": {
                                    "schema": {
                                        "properties": {
                                            "dataSourceType": {
                                                "const": "API"
                                            }
                                        }
                                    },
                                    "failWhenUndefined": true
                                }
                            }
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/sftpConnectionConfig",
                            "label": "Connection Details",
                            "rule": {
                                "effect": "SHOW",
                                "condition": {
                                    "schema": {
                                        "properties": {
                                            "dataSourceType": {
                                                "const": "SFTP"
                                            }
                                        }
                                    },
                                    "failWhenUndefined": true
                                }
                            }
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/objectStorageConnectionConfig",
                            "label": "Connection Details",
                            "rule": {
                                "effect": "SHOW",
                                "condition": {
                                    "schema": {
                                        "properties": {
                                            "dataSourceType": {
                                                "const": "OBJECT_STORAGE"
                                            }
                                        }
                                    },
                                    "failWhenUndefined": true
                                }
                            }
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/emailConnectionConfig",
                            "label": "Connection Details",
                            "rule": {
                                "effect": "SHOW",
                                "condition": {
                                    "schema": {
                                        "properties": {
                                            "dataSourceType": {
                                                "const": "EMAIL"
                                            }
                                        }
                                    },
                                    "failWhenUndefined": true
                                }
                            }
                        }
                    ],
                    "rule": {
                        "effect": "HIDE",
                        "condition": {
                            "scope": "#/properties/dataSourceType",
                            "schema": {
                                "const": ""
                            },
                            "failWhenUndefined": false
                        }
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/fetchConfig",
                    "label": "Fetch Details"
                }
            ]
        },
        {
            "type": "Group",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/authentications",
                    "title": "Authentications",
                    "options": {
                        "label": false,
                        "hideLabel": true,
                        "elementLabelProp": "shortName",
                        "detail": {
                            "type": "VerticalLayout",
                            "elements": [
                                {
                                    "type": "HorizontalLayout",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/shortName",
                                            "label": "Authentication Name"
                                        },
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/longName",
                                            "label": "Authentication Description"
                                        },
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/authenticationType"
                                        }
                                    ]
                                },
                                {
                                    "type": "HorizontalLayout",
                                    "elements": [
                                        {
                                            "type": "VerticalLayout",
                                            "elements": [
                                                {
                                                    "type": "Control",
                                                    "scope": "#/properties/basicAuthenticationConfig",
                                                    "label": "Authentication Details",
                                                    "rule": {
                                                        "effect": "SHOW",
                                                        "condition": {
                                                            "scope": "#/properties/authenticationType",
                                                            "schema": {
                                                                "const": "BASIC"
                                                            },
                                                            "failWhenUndefined": true
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "Control",
                                                    "scope": "#/properties/oauthAuthenticationConfig",
                                                    "label": "Authentication Details",
                                                    "rule": {
                                                        "effect": "SHOW",
                                                        "condition": {
                                                            "scope": "#/properties/authenticationType",
                                                            "schema": {
                                                                "const": "OAUTH"
                                                            },
                                                            "failWhenUndefined": true
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "Control",
                                                    "scope": "#/properties/certificateAuthenticationConfig",
                                                    "label": "Authentication Details",
                                                    "rule": {
                                                        "effect": "SHOW",
                                                        "condition": {
                                                            "scope": "#/properties/authenticationType",
                                                            "schema": {
                                                                "const": "CERTIFICATE"
                                                            },
                                                            "failWhenUndefined": true
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "Control",
                                                    "scope": "#/properties/awsAuthenticationConfig",
                                                    "label": "Authentication Details",
                                                    "rule": {
                                                        "effect": "SHOW",
                                                        "condition": {
                                                            "scope": "#/properties/authenticationType",
                                                            "schema": {
                                                                "const": "AWS_CREDENTIALS"
                                                            },
                                                            "failWhenUndefined": true
                                                        }
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/authenticationType",
                                            "rule": {
                                                "effect": "HIDE",
                                                "condition": {
                                                    "schema": {}
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                }
            ]
        }
    ]
};

export const dataSourceJsonForms = () => { return { schema, uischema } }