import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AddAlert } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import {
  showAlertNotification,
  showFailureNotification
} from "../../action-handlers/NotificationsHandler";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import { materialCells } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { CircularProgress } from "@mui/material";
import { CONFIG_KEY_BY_BEAN_NAME, createOrModifyDataFormat, deleteDataFormat, fetchDataFormat } from "action-handlers/DataFormatHandler";
import CardFooter from "components/Card/CardFooter";
import { renderers } from "components/JSONForms/SayaMaterialRenderers";
import { getUserData } from "views/Dashboard/DashboardUtil";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import ConfirmationOverlay from "../../components/ConfirmOverlay/ConfirmationOverlay";
import Button from "../../components/CustomButtons/Button";
import Snackbar from "../../components/Snackbar/Snackbar";
import {
  API_CALL_FAILED,
  API_DELETE_API_WARNING
} from "../../messages/DashboardMessages";
import { notificationParams } from "../../variables/general";
import { dataFormatJsonForms } from "./DataFormatSchema";
const styles = (theme) => ({
  cardWrapper: {
    borderRadius: "10px",
    //backgroundImage: `url(${paperBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    boxShadow: "50px 50px 150px inset #ffffff",
    backgroundColor: "#fff",
    paddingTop: '10px',
    paddingBottom: '20px'
  },
  formWrapper: {
    border: "5px solid rgb(255 255 255)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px",
    background:
      theme.palette.type === "dark"
        ? "transparent"
        : "linear-gradient(138.18deg, #eae8fd 0%, #fce5e6 94.44%)",
    borderRadius: "10px",
    "& label": {
      color: "#9c9c9c",
    },
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#DC3C7C",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    paddingLeft: '20px',
    paddingTop: '5px',
    "&:before, &:after": {
      left: '0 !important'
    },
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  inputText: {
    border: "1px solid " + theme.palette.grey[500],
    borderRadius: "6px",
    marginTop: "15px !important",
    backgroundColor:
      theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    "& input": {
      padding: 10,
    },
    "&:before, &:after": {
      display: "none",
    },
  },
  inputWrapper: {
    "& label": {
      marginLeft: "-14px",
      color: "#dd3c7c !important",
    },
  },
  selectFieldInput: {
    marginTop: "5px",
    marginBottom: "10px",
    "& label": {
      marginLeft: "0px",
      color: "#dd3c7c !important",
    },
  },
  selectInput: {
    marginTop: "-11px",
    "& fieldset": {
      borderColor: "transparent !important",
    },
    "& > label": {
      fontSize: 12,
      color: "#dd3c7c !important",
    },
    "& > div": {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  },
  selectItem: {
    padding: 0,
    backgroundColor:
      theme.palette.type === "dark"
        ? "transparent"
        : "rgb(246 235 250) !important",
    borderBottom:
      theme.palette.type === "dark" ? "1px solid #000" : "1px solid #f2c2d6",
  },
  selectItemText: {
    "& > span": {
      fontSize: 13,
    },
  },
  multimatchWrapper: {
    "& .match-input": {
      backgroundColor:
        theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    },
    "& label": {
      position: "relative",
      top: "20px",
      left: "-14px",
      width: "180px",

      "&+div": {
        marginTop: "10px",
        width: "130px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        paddingLeft: "10px",
      },
    },
  },
  opsCombo: {
    "@media (min-width: 0px) and (max-width: 599.95px)": {
      marginTop: "15px",
    },
    "& label": {
      color: "#dd3c7c !important",
      marginTop: "-7px",
    },
    "& > div": {
      width: "100%",
    },
    "& > div > div": {
      minWidth: "100% !important",
      position: "relative",
      border: "1px solid #919EAB",
      top: "-2px",
      "& > div": {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
  },
  opsSelect: {
    backgroundColor:
      theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    border: "1px solid #919EAB",
  },
  toggleForm: {
    padding: "25px 5px",
    borderRadius: "10px",
    backgroundColor:
      theme.palette.type === "dark" ? "transparent" : "rgb(251 251 251 / 37%)",
    margin: "0px 14px",
    border: "1px dashed #ccc",
  },
  btnWrapper: {
    "& button": {
      boxShadow: "rgb(208 52 125 / 36%) 0px 8px 22px",
      borderRadius: "8px",
    },
  },
  opsHeading: {
    color: "#dd3c7c !important",
    marginTop: "15px",
    fontWeight: "500",
    paddingLeft: "30px",
    borderBottom: "1px dashed #dd3c7338",
    paddingBottom: "10px",
    marginLeft: "-15px",
    marginRight: "-15px",
  },
  tabWrapper: {
    "& header.MuiAppBar-root + div": {
      padding: '15px'
    }
  }
});


export default function DataFormatCrud(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [showConfirmOverlay, setConfirmOverlay] = useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const { orgId: organisationId } = getUserData();

  const { id } = useParams();
  const [isEditDataFormat, setEditDataFormat] = useState(id && id !== "" && id !== "new");

  const INIT_FORM_DATA = {
    organisationId: organisationId, shortName: "", longName: "", dataSourceId: '', dataConfig: {
    }, jobConfig: {
      type: "job",
      "components": []
    },
    readerConfig: {
      type: "reader",
      "components": []
    },
    processorConfig: {
      type: "processor",
      "components": []
    },
    writerConfig: {
      type: "writer",
      "components": []
    }
  };
  const [dataFormatId, setDataFormatId] = useState("");

  let allowedPermissions = [];
  try {
    allowedPermissions =
      location.state === null
        ? JSON.parse(sessionStorage.selectedItem)?.allowedPermissions
        : JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];
  } catch (ex) {
    console.log("Error while reading location.state===>", ex);
  }

  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(INIT_FORM_DATA)));
  const [formErrors, setFormErrors] = useState([]);
  const [readOnly, setReadOnly] = useState(false);

  const { schema: schema, uischema: uischema } = dataFormatJsonForms();

  const handleChange = ({ errors, data }) => {
    console.log("Form data and errors", data, errors);
    setFormErrors(errors);

    // Set desc if empty
    if (data.longName || data.longName === "" || data.longName.startsWith(data.shortName)) {
      data.longName = data.shortName;
    }

    ['job', 'reader', 'writer', 'processor'].forEach(type => {
      const configKey = `${type}Config`;
      if (data[configKey]?.components) {
        data[configKey].components.forEach(c => c.config = c[CONFIG_KEY_BY_BEAN_NAME[type][c.beanName]]);
      }
    });

    const nameIdentifier = data.dataConfig?.nameConfig?.nameIdentifier;
    const namePatterns = data.dataConfig?.nameConfig?.namePatterns;

    if (nameIdentifier !== undefined) {
      if (!namePatterns) {
        if (data.dataConfig && data.dataConfig.nameConfig) {
          data.dataConfig.nameConfig.namePatterns = [`%{GREEDYDATA:FilePrefix}${nameIdentifier}%{GREEDYDATA:FileSuffix}`];
        }
      }
    }

    setFormData(data);
  };

  /**
   * Invoked on form submit
   * @param {event} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    createOrModifyDataFormat(formData, dataFormatId, createOrModifyCallback)
  };

  const createOrModifyCallback = (body) => {
    setLoading(false);
    if (Object.keys(body).length > 0) {
      notifyHandler(
        `Data format ${isEditDataFormat ? "updated" : "created"} successfully`
      );
      setFormData(INIT_FORM_DATA);
      navigateToDataFormatsHome();
    } else {
      notifyHandler(
        `Unable to ${isEditDataFormat ? "update" : "create"
        } data format, please contact support!`,
        "danger"
      );
    }
  };

  const notifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  useEffect(() => {
    if (id === "new") {
      setLoading(false);
      return;
    }

    let dataFormat = location.state?.entity;

    const populateFormData = (dataFormat) => {
      const formData = {
        organisationId: dataFormat?.organisationId,
        dataFormatId: dataFormat?.dataFormatId,
        dataSourceId: dataFormat?.dataSource.id,
        shortName: dataFormat?.shortName,
        longName: dataFormat?.longName,
        dataConfig: dataFormat?.dataConfig,
        jobConfig: dataFormat?.jobConfig,
        readerConfig: dataFormat?.readerConfig,
        processorConfig: dataFormat?.processorConfig,
        writerConfig: dataFormat?.writerConfig
      };

      ['job', 'reader', 'writer', 'processor'].forEach(type => {
        const configKey = `${type}Config`;
        if (formData[configKey]?.components) {
          formData[configKey].components.forEach(c => c[CONFIG_KEY_BY_BEAN_NAME[type][c.beanName]] = c.config);
        }
      });

      if (location.state?.forms?.readOnly) {
        setReadOnly(true);
      }

      setDataFormatId(id)
      setFormData(formData);
      setLoading(false);
    }

    // If the state contains the data format, populat form data and id from url is available, fetch the data format
    if (dataFormat && Object.keys(dataFormat).length > 0) {
      populateFormData(dataFormat);
    }
    else if (id) {
      fetchDataFormat(id, organisationId, (status, dataFormat) => {
        if (status === 200) {
          populateFormData(dataFormat);
        }
        else {
          showFailureNotification(`Unable to get data format for id ${id}`, callbackNotification);
          navigateToDataFormatsHome();
        }
      });
    }
    else {
      console.error("Navigating to data formats when neither data format nor id is available!");
      navigateToDataFormatsHome();
    }

    // populateFormData(dataFormat);
  }, [id]);

  const navigateToDataFormatsHome = () => {
    setTimeout(() => {
      navigate("/saya-platform/admin/data-formats", {
        state: {
          selectedItem:
            location?.state?.selectedItem || sessionStorage.selectedItem,
        },
      });
    }, 1500);
  }

  const handleDelete = () => {
    if (dataFormatId) {
      deleteDataFormat(dataFormatId, organisationId, deleteCallback);
    } else {
      showAlertNotification(API_DELETE_API_WARNING, callbackNotification);
    }
  };

  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      closeOverlay();
      navigateToDataFormatsHome();
    } else {
      showFailureNotification(API_CALL_FAILED, callbackNotification);
    }
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const openOverlay = () => {
    setConfirmOverlay(true);
  };

  const closeOverlay = () => {
    setConfirmOverlay(false);
  };

  const handleCancel = () => {
    navigateToDataFormatsHome();
  };

  return (
    <div style={{ margin: '0 -18px' }}>
      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Box className={classes.cardWrapper}>
              <Card plain>
                <CardHeader color="primary" plain>
                  <h4
                    className={
                      classes.cardTitleWhite + " modal-title-flag"
                    }
                  >
                    {isEditDataFormat ? "Edit" : "Create"} Data Format
                  </h4>
                </CardHeader>
                <CardBody>
                  <Box className={classes.tabWrapper}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {
                          isLoading ? <CircularProgress className={classes.loaderImg} />
                            : <JsonForms
                              schema={schema}
                              uischema={uischema}
                              data={formData}
                              renderers={renderers}
                              cells={materialCells}
                              onChange={handleChange}
                              readonly={readOnly}
                            />
                        }
                      </GridItem>
                    </GridContainer>
                  </Box>
                </CardBody>
                <CardFooter>
                  <Grid container justifyContent={"center"} spacing={1}>

                    <GridItem style={{ marginLeft: "-10px" }}>
                      {
                        (allowedPermissions.includes("modify") ||
                          allowedPermissions.includes("add")) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{ xs: { padding: "10px 25px" } }}
                            disabled={formErrors.length > 0}
                          >
                            Submit
                          </Button>
                        )}

                      {
                        allowedPermissions.includes("delete") && dataFormatId && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => openOverlay()}
                            sx={{ xs: { padding: "10px 25px" } }}
                          >
                            DELETE
                          </Button>
                        )}

                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ padding: { xs: "10px 25px" } }}
                        className={classes.closeButton}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </GridItem>

                  </Grid>
                </CardFooter>
              </Card>
            </Box>
          </GridItem>
        </GridContainer>
      </form>
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={AddAlert}
        message={notificationObj.message}
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
      {
        showConfirmOverlay && (
          <ConfirmationOverlay
            {...{
              isOverlayOpen: showConfirmOverlay,
              closeOverlay,
              handler: handleDelete,
              title: "DELETE",
              message: `Are you sure you want to delete? Once you delete you will loss all the data associated to the record.`,
            }}
          />
        )
      }
    </div >
  );
}
