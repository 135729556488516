import { Typography } from "@mui/material";

export const showInfo = (text = "") => {
  return <Typography>{text}</Typography>;
};

export const sortData = (data = [], key, mode = "DE") => {
  if (key) {
    if (mode === "AS") {
      return data.sort((a, b) => a[key] - b[key]);
    }
    return data.sort((a, b) => b[key] - a[key]);
  }
  return data;
};

export const getUserData = () => {
  try {
    const ud = JSON.parse(sessionStorage.userData);
    const orgId = ud.mstOrganisation?.organisationId;
    const roleId = ud.mstRole?.roleId;
    const userId = ud?.userId;
    return { orgId, roleId, userId };
  } catch (ex) {
    console.log("Exception while parsing user data getUserData", ex.message);
    return {};
  }
};

export const renderText = (type = "") => {
  if (type === "ReconX" || type === "ResolveX") {
    return `Please select any one of the Ops Process
                from the above options`;
  } else if (type === "DataX") {
    return `Please select any one of the Dataset`;
  }
  return "Please select Dropdown";
};

export const periodData = [
  { label: "All Time", value: "All" },
  { label: "Last 1 Day", value: "P-1D" },
  { label: "Last 7 Days", value: "P-7D" },
  { label: "Last 30 Days", value: "P-30D" },
  { label: "Last 90 Days", value: "P-90D" },
  { label: "Last 180 Days", value: "P-180D" },
  { label: "Last 1 Year", value: "P-1Y" },
];
