import { API, FlowAPI, ReconResolveAPI } from "URL.js";
import axios from "axios";

// export const fetchReconQueryDataCount = (reqPayload, payload, callback) => {
//   let reconRowsCount = 0;
//   const {
//     reconStatus,
//     tenorPeriod,
//     reconUnitId,
//     dataSrcConfigId,
//     loggedInUser,
//   } = reqPayload;

//   let url = `${API}/reconquery/fetchRowCount?reconUnitId=${reconUnitId}&reconStatus=${reconStatus}&tenorPeriod=${tenorPeriod.tenorPeriodVal}&dataSrcConfigId=${dataSrcConfigId}&loggedInUser=${loggedInUser}`;
//   if (tenorPeriod.tenorPeriodVal === "CUSTOM") {
//     url = `${API}/reconquery/fetchRowCount?reconUnitId=${reconUnitId}&reconStatus=${reconStatus}&tenorPeriod=${tenorPeriod?.tenorPeriodVal}&fromDate=${tenorPeriod.startDate}&toDate=${tenorPeriod.endDate}&dataSrcConfigId=${dataSrcConfigId}&loggedInUser=${loggedInUser}`;
//   }

//   axios
//     .post(url, payload)
//     .then((response) => {
//       if (response.status === 200) {
//         reconRowsCount = response.data.totalRows;
//       }

//       callback(reconRowsCount, true);
//     })
//     .catch((error) => {
//       console.log("Exception occured::{}", error);
//       callback(reconRowsCount, false);
//     });
// };

export const sendToResolveX = (payload, callback) => {
  axios
    .post(`${ReconResolveAPI}/flowaction/sendtossfl`, payload)
    .then((response) => {
      if (response.status === 200) {
        callback(true, response.data);
      } else {
        callback(false, response.data);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const fetchSuggestedMatches = (
  rawDataId,
  reconUnitId,
  dataSrcConfigId,
  unlabelledRuleDTO,
  callback
) => {
  let reconColumns = [],
    reconData = [];
  axios
    .post(
      API +
        "/recon/getmatches/" +
        reconUnitId +
        "/" +
        rawDataId +
        "/" +
        dataSrcConfigId,
      unlabelledRuleDTO
    )
    .then((response) => {
      response.data.headerNames.forEach((item) => {
        reconColumns.push({
          id: item.columnName,
          name: item.columnName,
          label: item.columnValue,
          value: item.columnValue,
          //options:{filter: true,sort: true,filterType: 'multiselect'}
        });
      });

      response.data.txnDataDTO.forEach((data) => {
        reconData.push(data);
      });
      callback(reconColumns, reconData, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(reconColumns, reconData, false);
    });
};

export const fetchFormData = (
  rawDataId,
  reconUnitId,
  dataSrcConfigId,
  reconLinkId,
  callback
) => {
  let formObject = {};
  axios
    .get(
      API +
        "/reconquery/flowunitbyid?rawDataId=" +
        rawDataId +
        "&reconUnitId=" +
        reconUnitId
    )
    .then((response) => {
      if (response.status === 200) {
        formObject = response.data;
      }

      callback(
        rawDataId,
        reconUnitId,
        dataSrcConfigId,
        reconLinkId,
        formObject,
        true
      );
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(rawDataId, formObject, false);
    });
};

export const triggerDelinkRecords = (delinkReconObjects, callback) => {
  axios
    .post(API + "/recon/delinkRecordsWithAudit", delinkReconObjects)
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const triggerLinkRecords = (suggestedMatchesFinal, callback) => {
  axios
    .post(API + "/recon/linkRecordsWithAudit", suggestedMatchesFinal)
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const fetchMetaDataSrc = (
  selectedReconUnit,
  dataSourceValue,
  ruleDTO,
  callback
) => {
  let ruleColumnNames = [];
  let ruleColumnIds = [];
  axios
    .get(API + "/recon/header/" + selectedReconUnit + "/" + dataSourceValue)
    .then((response) => {
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          ruleColumnNames[i] = response.data[i].columnName;
          ruleColumnIds[i] = response.data[i].columnId;
        }
      }
      callback(
        ruleColumnNames,
        ruleColumnIds,
        dataSourceValue,
        selectedReconUnit,
        ruleDTO,
        true
      );
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(
        ruleColumnNames,
        ruleColumnIds,
        dataSourceValue,
        selectedReconUnit,
        ruleDTO,
        false
      );
    });
};

export const fetchRulesData = (selectedReconUnit, callback) => {
  let ruleHeader = [];
  let ruleData = [];
  axios
    .get(API + "/reconquery/rule/" + selectedReconUnit)
    .then((response) => {
      if (response.status === 200) {
        response.data.headerNames.forEach((item) => {
          ruleHeader.push({
            title: item,
            field: item,
            editable: "never",
          });
        });
        ruleData = response.data.reconRuleDTO;
      }
      callback(ruleHeader, ruleData, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(ruleHeader, ruleData, false);
    });
};

export const saveOrModifyRule = (revisedRuleObj, callback) => {
  axios
    .post(API + "/reconrule/create", revisedRuleObj)
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const fetchRuleDTO = (selectedReconUnit, callback) => {
  let reconRuleDTO = [];
  axios
    .get(API + "/reconrule/fetchruledto/" + selectedReconUnit)
    .then((response) => {
      if (response.status === 200) {
        reconRuleDTO = response.data;
      }
      callback(reconRuleDTO, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(reconRuleDTO, false);
    });
};

export const fetchReconWorkflowDetails = (selectedReconUnit, callback) => {
  let reconWorkflowFlag = false;
  let flowDefnKeyName = "";
  let flowDefnName = "";
  axios
    .get(
      ReconResolveAPI +
        "/flowquery/fetchflowunit/byreconunit/" +
        selectedReconUnit
    )
    .then((response) => {
      console.log("::::::::::::::::::::", { response });
      if (response.status === 200) {
        reconWorkflowFlag = true;
        flowDefnKeyName = response.data.flowDefnKeyName;
        flowDefnName = response.data.flowDefnName;
      }
      callback(reconWorkflowFlag, flowDefnKeyName, flowDefnName, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(reconWorkflowFlag, flowDefnKeyName, flowDefnName, false);
    });
};

export const fetchReconLinksData = (
  reconUnitId,
  dataSource,
  reconLinkId,
  fetchReconLinksData,
  callback
) => {
  let reconColumns = [];
  let reconData = [];

  if (reconLinkId !== undefined && reconLinkId !== "") {
    axios
      .get(
        API +
          "/recon/getlinks/" +
          reconUnitId +
          "/" +
          dataSource +
          "/" +
          reconLinkId
      )
      .then((response) => {
        response.data.headerNames.forEach((item) => {
          reconColumns.push({
            id: item.columnName,
            name: item.columnName,
            label: item.columnValue,
            value: item.columnValue,
            //options:{filter: true,sort: true,filterType: 'multiselect'}
          });
        });

        response.data.txnDataDTO.forEach((data) => {
          reconData.push(data);
        });
        callback(
          reconColumns,
          reconData,
          dataSource,
          true,
          fetchReconLinksData
        );
      })
      .catch((error) => {
        console.log("Exception occured::{}", error);
        callback(reconColumns, reconData, false, fetchReconLinksData);
      });
  }
};

export const fetchReconQueryData = (reqPayload, payload, callback) => {
  const {
    reconStatus,
    tenorPeriod,
    reconUnitId,
    dataSrcConfigId,
    loggedInUser,
    pageNum,
    rowsPerPage,
  } = reqPayload;
  let url = `${API}/reconquery/fetchData?reconUnitId=${reconUnitId}&reconStatus=${reconStatus}&tenorPeriod=${tenorPeriod.tenorPeriodVal}&dataSrcConfigId=${dataSrcConfigId}&loggedInUser=${loggedInUser}&pageNum=${pageNum}&rowsPerPage=${rowsPerPage}`;
  if (tenorPeriod.tenorPeriodVal === "CUSTOM") {
    url = `${API}/reconquery/fetchData?reconUnitId=${reconUnitId}&reconStatus=${reconStatus}&tenorPeriod=${tenorPeriod.tenorPeriodVal}&fromDate=${tenorPeriod.startDate}&toDate=${tenorPeriod.endDate}&dataSrcConfigId=${dataSrcConfigId}&loggedInUser=${loggedInUser}&pageNum=${pageNum}&rowsPerPage=${rowsPerPage}`;
  }

  let reconColumns = [];
  let reconData = [];
  let rowDataBool = true;
  axios
    .post(url, payload)
    .then((response) => {
      response.data.headerNames.forEach((item) => {
        reconColumns.push({
          id: item.columnName,
          name: item.columnName,
          label: item.columnValue,
          value: item.columnValue,
          dataType: item.dataType,
          accessorKey: item.columnName,
          header: item.columnValue,
          //options:{filter: true,sort: true,filterType: 'multiselect'}
        });
      });

      response.data.txnDataDTO.forEach((data) => {
        reconData.push(data);
      });
      if (response?.data?.txnDataDTO?.length === 0) {
        rowDataBool = false;
      }
      const colArray = reconColumns;
      for (let i = 0; i < colArray.length; i++) {
        colArray[i].header = colArray[i].header.toUpperCase();
      }
      callback(colArray, reconData, true, rowDataBool);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(reconColumns, reconData, false);
    });
};

export const triggerReconOptimizerJob = (
  reconUnitId,
  userId,
  userName,
  callback
) => {
  axios
    .post(
      `${ReconResolveAPI}/flowaction/reconoptimize/?reconUnitId=${reconUnitId}&userId=${userId}&userName=${userName}`
    )
    .then((response) => {
      //if (response.status === 200) {
      callback(true);
      //}
    })
    .catch((error) => {
      console.log("Exception occured triggerReconOptimizerJob::", error);
      if (
        error?.response?.status === 500 ||
        error?.response?.status === 502 ||
        error?.response?.status === 503
      ) {
        callback(true);
      } else {
        callback(false);
      }
    });
};

export const triggerReconciliationJob = (reconUnitId, callback) => {
  axios
    .post(API + "/reconcile/" + reconUnitId, reconUnitId)
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const triggerNwayReconciliationJob = (nWayReconProcessId, callback) => {
  axios
    .post(API + "/reconunits/nwayrecon/" + nWayReconProcessId + "/reconcile")
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const uploadCSVFile = (
  fileObjectSrc,
  reconUnitId,
  dataSrc,
  callback
) => {
  const formData = new FormData();
  formData.append("file", fileObjectSrc);
  axios
    .post(API + "/recon/upload/" + reconUnitId + "/" + dataSrc, formData)
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

const downloadFile = (data, filename = "file") => {
  const href = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadRowsData = async (params, type) => {
  try {
    let fileName = "";
    let url = "";
    if (type === "page") {
      fileName = `${params.selectedReconUnitName}- Recon Status [${
        params.reconStatus
      }]-page ${params.pageNum + 1}`;
      url = `${API}/reconquery/downloadCurrentPage?reconUnitId=${params.reconUnitId}&reconStatus=${params.reconStatus}&tenorPeriod=${params.tenorPeriod}&pageNum=${params.pageNum}&rowsPerPage=${params.rowsPerPage}&loggedInUser=${params.loggedInUser}`;
      if (params.tenorPeriod === "CUSTOM") {
        url = `${API}/reconquery/downloadCurrentPage?reconUnitId=${params.reconUnitId}&reconStatus=${params.reconStatus}&tenorPeriod=${params.tenorPeriod}&pageNum=${params.pageNum}&rowsPerPage=${params.rowsPerPage}&loggedInUser=${params.loggedInUser}&fromDate=${params.fromDate}&toDate=${params.toDate}`;
      }
    } else {
      fileName = `${params.selectedReconUnitName}- Recon Status [${params.reconStatus}]-All Pages`;
      url = `${API}/reconquery/downloadFullData?reconUnitId=${params.reconUnitId}&reconStatus=${params.reconStatus}&tenorPeriod=${params.tenorPeriod}&loggedInUser=${params.loggedInUser}`;
    }
    const res = await axios.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      downloadFile(res.data, fileName);
    }
  } catch (error) {
    console.log(
      "========>>>>>>something went wrong while download report",
      error
    );
  }
};

export const fetchAudioInfo = (rawDataId, userId, callback) => {
  axios
    .get(`${API}/recon/getAuditInfo/${rawDataId}/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback({});
    });
};

export const fetchReconQueryDataInfinite = async (
  pageNum,
  queryParam,
  payload = {}
) => {
  const {
    reconStatus,
    tenorPeriod,
    selectedReconUnit,
    activeTab,
    pageSize = 1000,
  } = queryParam;

  const dataSrcConfigId = activeTab === 0 ? 1 : 2;
  const loggedInUser = JSON.parse(sessionStorage.userData).userId;
  const rowsPerPage = pageSize; //500;
  let url = `${API}/reconquery/fetchData?reconUnitId=${selectedReconUnit}&reconStatus=${reconStatus}&dataSrcConfigId=${dataSrcConfigId}&loggedInUser=${loggedInUser}&pageNum=${pageNum}&rowsPerPage=${rowsPerPage}`;

  if (tenorPeriod.tenorPeriodVal === "CUSTOM") {
    url = `${API}/reconquery/fetchData?reconUnitId=${selectedReconUnit}&reconStatus=${reconStatus}&fromDate=${tenorPeriod.startDate}&toDate=${tenorPeriod.endDate}&dataSrcConfigId=${dataSrcConfigId}&loggedInUser=${loggedInUser}&pageNum=${pageNum}&rowsPerPage=${rowsPerPage}`;
  } else if (tenorPeriod.tenorPeriodVal !== "All") {
    url = `${API}/reconquery/fetchData?reconUnitId=${selectedReconUnit}&reconStatus=${reconStatus}&tenorPeriod=${tenorPeriod.tenorPeriodVal}&dataSrcConfigId=${dataSrcConfigId}&loggedInUser=${loggedInUser}&pageNum=${pageNum}&rowsPerPage=${rowsPerPage}`;
  }
  const response = await axios.post(url, payload);
  return response.data;
};

export const fetchNwayReconDataInfinite = async (
  pageNum,
  queryParam,
  payload = {}
) => {
  const {
    dataSetVal,
    selectedReconUnit,  // required
    tenorPeriod,         // optional
    reconStatus = "ALL", // optional
    pageSize = 1000
  } = queryParam;


  const loggedInUser = JSON.parse(sessionStorage.userData).userId;


  const rowsPerPage = pageSize;


  let url = `${API}/reconquery/nwayrecon/fetchData?` +
    `nwayReconProcessId=${selectedReconUnit}&` +
    `dataSetId=${dataSetVal}&` +
    `nwayReconStatus=${reconStatus}&` +
    `pageNum=${pageNum}&` +
    `rowsPerPage=${rowsPerPage}&` +
    `loggedInUser=${loggedInUser}`;


  if (tenorPeriod?.tenorPeriodVal === "CUSTOM") {
    url += `&fromDate=${tenorPeriod.startDate}&toDate=${tenorPeriod.endDate}`;
  } 
 
  else if (tenorPeriod?.tenorPeriodVal && tenorPeriod.tenorPeriodVal !== "All") {
    url += `&tenorPeriod=${tenorPeriod.tenorPeriodVal}`;
  }

  const response = await axios.post(url, payload);
  return response.data;
};

export const triggerAutonomousToleranceCalibrator = (
  reconUnitId,
  userId,
  userName,
  callback
) => {
  axios
    .post(
      `${API}/recon/atc?reconUnitId=${reconUnitId}&userId=${userId}&userName=${userName}`
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
      } else {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(error);
    });
};

export const getAIEthics = (reconUnitId, userId, userName, callback) => {
  axios
    .post(
      `${API}/recon/AIEthics?reconUnitId=${reconUnitId}&userId=${userId}&userName=${userName}`
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.log("Exception occured::", error);
      callback({ responseMessage: error.message });
    });
};

export const getMetaData = (dataSrcConfigId, reconUnitId, callback) => {
  axios
    .get(`${API}/recon/header/${reconUnitId}/${dataSrcConfigId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.log("Exception occured::", error);
      callback({ responseMessage: error.message });
    });
};

export const saveMetaDataAPI = (payload, callback) => {
  axios
    .post(`${API}/recon/header`, payload)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.log("Exception occured::", error);
      callback({ responseMessage: error.message });
    });
};

export const fetchmappedFlowUnits = (reconUnitId, callback) => {
  axios
    .get(
      `${ReconResolveAPI}/flowquery/fetchmappedFlowUnits/byreconunit/${reconUnitId}`
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.log("Exception occured triggerReconOptimizerJob::", error);
      if (
        error?.response?.status === 500 ||
        error?.response?.status === 502 ||
        error?.response?.status === 503
      ) {
        callback(true);
      } else {
        callback(true);
      }
    });
};
