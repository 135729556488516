import { getUserData } from "views/Dashboard/DashboardUtil";

const { orgId: organisationId } = getUserData();

const schema = {
    "title": "Data Set",
    "type": "object",
    "properties": {
        "organisationId": {
            "type": "integer",
            "description": "The id of the organisation",
            "minLength": 1
        },
        "shortName": {
            "type": "string",
            "description": "The name of the data set",
            "minLength": 1,
            "maxLength": 128
        },
        "longName": {
            "type": "string",
            "description": "The description of the data set",
            "minLength": 1,
            "maxLength": 256
        },
        "dataFormatIds": {
            "type": "array",
            "apiUrl": `/datax/data-formats/id-values`,
            "items": {
                "type": "integer"
            },
            "uniqueItems": true,
            "minItems": 1,
            "description": "The list of data formats",
            "default": []
        }
    },
    "required": ["organisationId", "shortName", "longName", "dataFormatIds"],
};

const uischema = {
    type: "VerticalLayout",
    elements: [
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    type: "Control",
                    scope: "#/properties/organisationId",
                    options: {
                        readonly: true,
                    }
                },
                {
                    type: "Control",
                    scope: "#/properties/shortName"
                }
            ]
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    type: "Control",
                    scope: "#/properties/longName"
                },
                {
                    "type": "Control",
                    "label": "Data Formats",
                    "dataSourceType": "http",
                    "isMultiSelect": true,
                    "scope": "#/properties/dataFormatIds"
                }
            ]
        }
    ]
};

export const dataSetJsonForms = () => { return { schema, uischema } }