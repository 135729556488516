"use client";

import React, { useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const generateColors = (count) => {
  const baseColors = [
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(75, 192, 192)",
    "rgb(153, 102, 255)",
    "rgb(255, 159, 64)",
    "rgb(231, 233, 237)",
  ];

  return Array.from({ length: count }, (_, i) => {
    const color = baseColors[i % baseColors.length];
    return color.replace("rgb", "rgba").replace(")", ", 0.5)");
  });
};

export default function StackedBarChart({
  chartData,
  label,
  value,
  title,
  isQueue = false,
}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedSorting, setSelectedSorting] = useState("Descending");
  const itemsPerPage = 5;

  const labels = useMemo(
    () => [...new Set(chartData.map((item) => item[label]))],
    [chartData, label]
  );
  const values = useMemo(
    () => [...new Set(chartData.map((item) => item[value]))],
    [chartData, value]
  );

  const backgroundColors = generateColors(values.length);

  const sortedLabels = useMemo(() => {
    const labelTotals = labels.map((label) => {
      const totalCount = chartData.reduce((sum, item) => {
        if (item.reconProcessName === label) {
          const count = isQueue ? item.itemCount : item.dayCount;
          return sum + (count || 0);
        }
        return sum;
      }, 0);
      return { label, totalCount };
    });

    labelTotals.sort((a, b) => {
      return selectedSorting === "Ascending"
        ? a.totalCount - b.totalCount
        : b.totalCount - a.totalCount;
    });

    return labelTotals.map((item) => item.label);
  }, [labels, chartData, isQueue, selectedSorting]);

  const paginatedLabels = useMemo(() => {
    const start = currentPage * itemsPerPage;
    return sortedLabels.slice(start, start + itemsPerPage);
  }, [sortedLabels, currentPage]);

  const datasets = useMemo(
    () =>
      values.map((val, index) => {
        const dataWithMeta = paginatedLabels.map((label) => {
          const filteredItems = chartData.filter(
            (item) => item.reconProcessName === label && item[value] === val
          );
          const total = isQueue
            ? filteredItems.reduce(
                (sum, item) => sum + (item.itemCount || 0),
                0
              )
            : filteredItems.reduce(
                (sum, item) => sum + (item.dayCount || 0),
                0
              );
          const wfProcessNames = [
            ...new Set(filteredItems.map((item) => item.wfProcessName)),
          ];
          return {
            total,
            wfProcessNames,
          };
        });
        return {
          label: val,
          data: dataWithMeta.map((item) => item.total),
          backgroundColor: backgroundColors[index],
          borderColor: backgroundColors[index].replace("0.5", "1"),
          borderWidth: 1,
          borderRadius: 6,
          metaData: dataWithMeta.map((item) => item.wfProcessNames),
        };
      }),
    [values, paginatedLabels, chartData, value, isQueue, backgroundColors]
  );

  const data = {
    labels: paginatedLabels,
    datasets: datasets,
  };

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: "y",
      scales: {
        x: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: "Poppins",
              size: 12,
            },
          },
        },
        y: {
          stacked: true,
          ticks: {
            font: {
              family: "Poppins",
              size: 12,
            },
            callback: function (value, index, ticks) {
              const label = this.chart.data.labels[index];
              const totalCount = chartData.reduce((sum, item) => {
                if (item.reconProcessName === label) {
                  const count = isQueue ? item.itemCount : item.dayCount;
                  return sum + (count || 0);
                }
                return sum;
              }, 0);
              return `${label}`;
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            boxWidth: 25,
            padding: 15,
            usePointStyle: true,
            pointStyle: "rect",
            font: {
              family: "Poppins",
              size: 12,
            },
          },
        },
        tooltip: {
          titleFont: {
            family: "Poppins",
          },
          bodyFont: {
            family: "Poppins",
          },
          callbacks: {
            label: function (context) {
              const { dataset, raw } = context;
              return `${dataset.label}: ${raw}`;
            },
            afterLabel: function (context) {
              if (!isQueue) return;
              const { dataset, dataIndex } = context;
              const wfProcessNames = dataset.metaData[dataIndex];
              const wfNamesString = wfProcessNames.join(", ");
              return [`Workflow(s): ${wfNamesString}`];
            },
          },
        },
      },
      barThickness: 25,
      maxBarThickness: 50,
      layout: {
        padding: {
          top: 10,
          right: 20,
          bottom: 0,
          left: 20,
        },
      },
    }),
    [chartData, isQueue]
  );

  const totalPages = Math.ceil(sortedLabels.length / itemsPerPage);

  const chartRef = useRef(null);

  const handleBarClick = (event) => {
    const { current: chart } = chartRef;
    if (!chart) return;

    const elements = getElementAtEvent(chart, event);

    if (elements.length > 0) {
      const element = elements[0];
      const datasetIndex = element.datasetIndex;
      const index = element.index;

      const dataset = data.datasets[datasetIndex];
      const value = dataset.data[index];
      const label = data.labels[index];

      const metaData = dataset.metaData[index];

      navigate(
        `/saya-platform/saya-resolvex?ops=${label}&wf=${metaData[0]}&queue=${dataset?.label}`
      );
    }
  };

  const handleSort = (sortOrder) => {
    setSelectedSorting(sortOrder);
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-md">
      <div className="flex flex-row items-center justify-between space-y-0 p-3 border-b">
        <h2 className="font-medium leading-4 sm:leading-normal  tracking-tight text-gray-800 text-sm text-left">
          {title}
        </h2>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => handleSort("Ascending")}
            className={`px-2 py-1 text-xs font-medium rounded-md border ${
              selectedSorting === "Ascending"
                ? "text-pink-600 border-pink-600"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline-block mr-0 sm:mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="hidden sm:inline">Asc</span>
          </button>
          <button
            onClick={() => handleSort("Descending")}
            className={`px-2 py-1 text-xs font-medium rounded-md border ${
              selectedSorting === "Descending"
                ? "text-pink-600 border-pink-600"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline-block mr-0 sm:mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="hidden sm:inline">Desc</span>
          </button>
          <button
            className="p-1 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
            disabled={currentPage === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <span className="text-xs sm:text-sm text-gray-600">
            Page {currentPage + 1} of {totalPages}
          </span>
          <button
            className="p-1 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() =>
              setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1))
            }
            disabled={currentPage === totalPages - 1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="p-4">
        <div className="h-[400px]">
          <Bar
            ref={chartRef}
            data={data}
            options={options}
            onClick={handleBarClick}
          />
        </div>
      </div>
    </div>
  );
}
