import React, { useContext, useRef } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
// core components
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import { fetchMenuHierarchyById } from "../action-handlers/UserEntitlementActionHandler";
import { fetchUserDetailsByLogin } from "../action-handlers/LoginActionsHandler.js";
import routes from "../routes";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "../assets/img/sidebar-3.jpg";
import logo from "../assets/img/SAYA.svg";
import logoSmall from "../assets/img/saya-logo-letter-vector.svg";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import SAYARTL from "../layouts/SayaRTLLayout";
import useResponsive from "../hooks/useResponsive";
import { fetchServiceProvidersById } from "../action-handlers/ServiceProviderHandler";
import { useLocation } from "react-router-dom";
import backgroundImg from "../assets/img/background.png";
let ps;

const useStyles = makeStyles(styles);

export default function SAYALayout({ ...rest }) {
  const ref = useRef();
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const [menuHierarchyData, setmenuHierarchy] = React.useState("");
  const { userId, loginName } = UserAuthContext.state;
  const [routesData, setRoutesData] = React.useState([]);
  const location = useLocation();
  const isDesktop = useResponsive("up", "lg");

  let logoimgType =
    JSON.parse(sessionStorage.getItem("spDetails"))?.logoimgType || "";
  let logoContent =
    JSON.parse(sessionStorage.getItem("spDetails"))?.logoContent || "";
  let faviconimgType =
    JSON.parse(sessionStorage.getItem("spDetails"))?.faviconimgType || "";
  let favicon = JSON.parse(sessionStorage.getItem("spDetails"))?.favicon || "";
  let copyrightClause =
    JSON.parse(sessionStorage.getItem("spDetails"))?.copyrightClause || "";
  let poweredBy =
    JSON.parse(sessionStorage.getItem("spDetails"))?.poweredBy || "";

  let callBackFetchMenuHierarchy = (resObj, apiStatus) => {
    UserAuthContext.saveMenuHierarchyState(resObj);
    UserAuthContext.saveOriginalMenuHierarchyState(resObj);
    setmenuHierarchy(resObj);
  };

  const updateFavicon = () => {
    const faviconUrl = `data:${faviconimgType};base64,${favicon}`; // Replace with your favicon's URL
    const head = document.querySelector("head");
    const existingFavicon = document.querySelector("link[rel*='icon']");

    existingFavicon.rel = "shortcut icon";
    existingFavicon.href = faviconUrl;

    head.appendChild(existingFavicon);
  };

  React.useEffect(() => {
    updateFavicon();
  }, [faviconimgType, favicon]);

  React.useEffect(() => {
    if (userId && window.location.pathname !== "/saya-platform/logout") {
      fetchMenuHierarchyById(userId, callBackFetchMenuHierarchy);
    } else if (
      loginName &&
      window.location.pathname !== "/saya-platform/logout"
    ) {
      let callBackFetchUserDetailsByLogin = (resObj, apiStatus) => {
        fetchMenuHierarchyById(resObj.userId, callBackFetchMenuHierarchy);
      };
      fetchUserDetailsByLogin(loginName, callBackFetchUserDetailsByLogin);
    } else {
      return;
    }
    updateFavicon();
  }, [userId]);

  //fetchServiceProvidersById(uId, setSPListHandler);

  React.useEffect(() => {
    routesHandler(menuHierarchyData);
  }, [menuHierarchyData]);

  React.useEffect(() => {
    if (userId) {
      fetchServiceProvidersById(userId, (res = []) => {
        sessionStorage.setItem("spDetails", JSON.stringify(res[0] || {}));
        localStorage.setItem("spDetails", JSON.stringify(res[0] || {}));
      });
    }
  }, [userId]);

  const routesHandler = (data) => {
    let routeResponse = routes;
    let entitleRes = data;

    if (entitleRes && entitleRes.length && entitleRes.length > 0) {
      // entitleRes.forEach(function (menuitem) {
      //   if (menuitem.subMenus) {
      //     entitleRes = [...entitleRes, ...menuitem.subMenus];
      //   }
      // });
      entitleRes.forEach(function (item) {
        routeResponse.forEach(function (item2) {
          if (item.menuIdentifier.toLowerCase() === item2.name.toLowerCase()) {
            item2.allowedPermissions = item.allowedPermissions;
            item2.invisible = item2.name === "Settings" ? true : false;
            item2.subMenus = item.subMenus;
            item2.menuId = item.menuId;
            item2.parentMenuId = item.parentMenuId;
            item2.res = item;
          } else if (
            item.menuIdentifier.toLowerCase() === "reconx" &&
            (item2.name === "1-way" || item2.name === "n-way")
          ) {
            item2.allowedPermissions = item.allowedPermissions;
          }
        });
      });
    }

    setRoutesData(routeResponse);
    UserAuthContext.saveMenuHierarchyState(routeResponse);
  };
  const isLogin = sessionStorage.getItem("loginName");
  const switchRoutes = (
    <>
      <Routes>
        <Route path="/saya-platform" element={<SAYALayout />} />
        <Route path="/saya-platform/login/*" element={<SAYALayout />} />
        <Route path="/rtl" element={<SAYARTL />} />
        <Route
          path="/"
          exact
          element={
            isLogin ? (
              <Navigate replace to="/saya-platform/dashboard" />
            ) : (
              <Navigate replace to="/saya-platform/login" />
            )
          }
        />

        {routesData &&
          routesData.length > 0 &&
          routesData.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                element={<prop.component routeData={prop} />}
                key={key}
              />
            );
          })}
        <Route
          path="*"
          element={<Navigate replace to="/saya-platform/login" />}
        />
      </Routes>
    </>
  );

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = (event) => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    if (window.innerWidth <= 768) {
      const checkIfClickedOutside = (e) => {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu

        if (mobileOpen && ref.current && ref.current.contains(e.target)) {
          setMobileOpen(false);
        }
      };

      document.addEventListener("mousedown", checkIfClickedOutside);

      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }
  }, [mobileOpen]);

  const getRoute = () => {
    if (
      window.location.pathname !== "/saya-platform/login" &&
      window.location.pathname !== "/saya-platform/forgot-password" &&
      window.location.pathname !== "/saya-platform/confirmforgotpassword" &&
      window.location.pathname !== "/saya-platform/logout"
    ) {
      return true;
    } else {
      return false;
    }
    //return window.location.pathname !== "/saya-platform/login";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (menuHierarchyData == null || menuHierarchyData === "") {
      return;
    }
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      //document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel, menuHierarchyData]);

  const isAccess =
    window?.location?.pathname === "/saya-platform/logout" ||
    window?.location?.pathname === "/saya-platform/login" ||
    window?.location?.pathname === "/saya-platform/forgot-password" ||
    window?.location?.pathname === "/saya-platform/change-temp-password" ||
    window?.location?.pathname === "/saya-platform/confirmforgotpassword";

  return (
    routesData &&
    routesData.length > 0 && (
      <div ref={ref} className={`${classes.wrapper}}`}>
        {!isAccess && (
          <Sidebar
            routes={routesData}
            logoText={""}
            logo={logo}
            logoSmall={logoSmall}
            image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={color}
            logoContent={logoContent}
            favicon={favicon}
            logoimgType={logoimgType}
            faviconimgType={faviconimgType}
            poweredBy={poweredBy}
            {...rest}
          />
        )}
        {isAccess && (
          <div
            style={{
              background: `url(${backgroundImg})`,
              position: "fixed",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              filter: "blur(2px)",
              opacity: "0.4",
              backgroundSize: "contain",
            }}
          ></div>
        )}
        {/* To suppress the mainpanel which has search, notifications etc when login is called*/}
        <div
          className={`${classes.mainPanel} ${
            mobileOpen ? "" : classes.contentExpand
          }`}
          ref={mainPanel}
        >
          {!isDesktop && !isAccess && (
            <span className={classes.menuIcon}>
              <MenuOutlinedIcon onClick={handleDrawerToggle} />
            </span>
          )}
          {/******* Nav Bar Header Starts *********/}
          {!isAccess && getRoute() ? (
            <Navbar
              routes={routes}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
          ) : null}
          {/******* Nav Bar Header Ends *********/}

          {/******* Content Wrapper Starts *********/}

          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div>
                <Breadcrumb />
              </div>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.login}> {switchRoutes}</div>
          )}

          {/******* Content Wrapper Starts *********/}

          {/******* Footer Starts*********/}
          {getRoute() ? (
            <div>
              <Footer {...{ copyrightClause }} />
            </div>
          ) : null}
          {/******* Footer Ends*********/}

          {/* this is to suppress the right side navigation plugin for preferences */}
          {/* {getRoute() ? <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> : null} */}
        </div>

        {getRoute() && <Outlet />}
      </div>
    )
  );
}
