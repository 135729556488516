import React from "react";
import { makeStyles } from "@mui/styles";
import SAYATable from "../../components/Table/MuiDataTable";
import { statusTextHandler } from "../../components/utils";

const useStyles = makeStyles((theme) => ({
  stickyColumnTbl: {
    "& tr.active td:first-child": {
      backgroundColor: "#fff !important",
    },
    "& tr.active td:first-child + td": {
      backgroundColor: "#fff !important",
    },
  },
}));


function NWayReconXMatches({ data }) {
  const classes = useStyles();
  const { headerNames = [], txnDataDTO = [] } = data || {};

  let columns = headerNames.map((header, index) => ({
    name: header.columnName,
    label:
      header.columnValue.charAt(0).toUpperCase() + header.columnValue.slice(1),
    options: {
      filter: header.filterable,
      sort: header.orderbyEnabled,
      display: header.visible ? true : false,
      customHeadRender: (columnMeta) => {
        return (
          <th
            key={columnMeta.index}
            style={{
              textAlign: "left",
              background: "#f5f5f5",
              borderBottom: "1px solid #ddd",
              whiteSpace: "nowrap",
              ...(header.columnName === "clientReconName" && {
                position: "static",
                left: 120,
                background: "#fff",
                zIndex: 101,
              }),
            }}
          >
            <span style={{ display: "block", padding: "5px 20px" }}>
              {columnMeta.label}
            </span>
          </th>
        );
      },
      setCellProps: () => {
        return {
          style: {
            padding: "10px 20px",
            borderBottom: "1px solid #ddd",
            whiteSpace: "nowrap",
            ...(header.columnName === "clientReconName" && {
              position: "static",
              left: 110,
              background: "#fff",
              zIndex: 100,
            }),
          },
        };
      },
    },
  }));

  // Add a custom duplicate column for Recon Status
  const reconStatusColumn = {
    name: "customReconStatus",
    label: "Recon Status",
    options: {
      filter: true,
      sort: true,
      display: true,
      customHeadRender: (columnMeta) => {
        return (
          <th
            key={columnMeta.index}
            style={{
              textAlign: "left",
              background: "#f5f5f5",
              borderBottom: "1px solid #ddd",
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              background: "#fff",
              zIndex: 103,
            }}
          >
            <span style={{ display: "block", padding: "5px 20px" }}>
              {columnMeta.label}
            </span>
          </th>
        );
      },
      setCellProps: () => {
        return {
          style: {
            padding: "10px 20px",
            borderBottom: "1px solid #ddd",
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#fff",
            zIndex: 102,
          },
        };
      },
    },
  };

  // Insert the custom Recon Status column at the first position
  columns.unshift(reconStatusColumn);

  // Move the clientReconName column to the second position after customReconStatus
  const clientReconIndex = columns.findIndex(
    (col) => col.name === "clientReconName"
  );
  if (clientReconIndex > 0) {
    const clientReconColumn = columns.splice(clientReconIndex, 1)[0];
    columns.splice(1, 0, clientReconColumn);
  }

  const options = {
    selectableRows: "none",
    filter: true,
    search: true,
    sort: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    responsive: "standard",
  };

  return (
    <div style={{ marginTop: "20px", overflowX: "auto" }}  className={classes.stickyColumnTbl}>
      <SAYATable
        title=""
        columns={columns}
        data={txnDataDTO.map((row) => ({
          ...row,
          customReconStatus: statusTextHandler(row.reconStatusId),
        }))}
        options={options}
        filterActive={false}

      />
    </div>
  );
}

export default NWayReconXMatches;
