import React from "react";
import PropTypes from "prop-types";

const IconButton = ({
  icon,
  label,
  onClick,
  variant = "default",
  className = "",
}) => {
  const baseClasses = `flex items-center gap-1 rounded-md transition w-10 h-10 inline-flex shadow-[0_0_2px_0_#ebdfed,0_12px_24px_-4px_#ebdfed] items-center p-[5px] rounded-md border-white border-solid border-2 ${
    label ? "" : "justify-center"
  }`;
  const variantClasses =
    variant === "danger"
      ? "bg-red-500 text-white hover:bg-red-600"
      : "bg-[#fbedf3] text-white hover:bg-white";

  return (
    <button
      onClick={onClick}
      className={`${baseClasses} ${variantClasses} ${className}`}
    >
      {React.cloneElement(icon, { className: `w-[22px] h-[22px] ${variant === "danger" ? 'text-white' : 'text-[#dd3c7c]'}` })}
      {label && <span>{label}</span>}
    </button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["default", "danger"]),
  className: PropTypes.string,
};

export default IconButton;
