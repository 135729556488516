import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import {
  Delete,
  DeviceHub,
  EditRounded,
  BorderColorOutlined,
  AddBusinessOutlined,
  Close,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";

import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

// core components
import CardIcon from "../../components/Card/CardIcon";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import {
  fetchServiceProvidersById,
  modifyServiceProvider,
  uploadImage,
} from "../../action-handlers/ServiceProviderHandler";
import { imageUpload } from "../../components/utils";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { notificationParams } from "../../variables/general";
import Snackbar from "../../components/Snackbar/Snackbar";
import PopoverComponent from "../../components/Popover/Popover";
import PopoverLogoImage from "../../assets/img/logo-preview-min.jpg";
import PopoverfaviconImage from "../../assets/img/favicon-preview-min.jpg";

import ColorPicker from "../../components/ColorPicker/ColorPicker";
import Skeleton from "@mui/material/Skeleton";

const styles = (theme) => ({
  cardCategoryWhite: {
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  stats: {
    "& svg": {
      marginLeft: "3px",
      marginRight: "3px",
    },
    display: "inline-flex",
    fontSize: "12px",
  },
  dialogWindow: {
    minWidth: "auto",
  },
  errorMessgage: {
    fontSize: 12,
    color: theme.palette.error.main,
    margin: "0px 0px 10px 0",
  },
  cardTitle: {
    color: theme.palette.text.primary,
  },
  imagePreview: {
    width: 100,
    paddingTop: 10,
    "& img": {
      // width: "100%",
      width: 40,
      height: 40,
    },
  },
  loaderImg: {
    position: "relative",
    left: "48%",
    top: 100,
  },
  logoImage: {
    float: "left",
    border: "1px solid " + theme.palette.grey[300],
    width: 160,
    height: 60,
  },
  logoimagePreview: {
    width: 200,
    paddingTop: 10,
    "& img": {
      width: "100%",
      maxHeight: 58,
    },
  },
  iconSpacing: {
    marginRight: "8px",
    position: "relative",
    top: "5px",
  },
  popoverWrap: {
    "& i": {
      color: "#000",
      cursor: "pointer",
      display: "inline-block",
      fontSize: "14px",
      borderRadius: "5px",
      backgroundColor: "#e7e6e6",
      width: "20px",
      height: "20px",
      textAlign: "center",
      lineHeight: "20px",
    },
  },
  uploadField: {
    border: "1px dashed #ccc",
    padding: "10px",
    "& fieldset": {
      display: "none",
    },
    "& input": {
      opacity: "0",
      cursor: "pointer",
    },
    "& div:first-child": {
      zIndex: 1,
      position: "absolute",
      height: 46,
      width: "100%",
      cursor: "pointer",
    },
    "& div:last-child": {
      marginTop: "-7px",
    },
  },
});

const useStyles = makeStyles(styles);

const ServiceProvider = () => {
  const [spList, setSPList] = useState([]);
  const [openSPDialog, setSPDialog] = useState(false);
  const [spItem, setSPItem] = useState({});
  const [isEditSP, setEditSP] = useState(false);
  const [spDetails, setSPDetails] = useState({
    spName: "",
    email: "",
    copyrightClause: "",
    poweredBy: "",
    primaryColor: "",
    secondaryColor: "",
    versionNo: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [logoImage, setLogoImage] = useState("");
  const [favImage, setFavImage] = useState("");
  const [favImageFile, setFavImageFile] = useState("");
  const [imageError, setImageError] = useState("");
  const [favImageError, setFavImageError] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [loaderState, setLoaderState] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [userId, setUserId] = useState("");
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const location = useLocation();

  const UserAuthContext = useContext(UserAuthDetailsContext);

  let allowedPermissions = [];
  if (location?.state?.selectedItem) {
    allowedPermissions =
      JSON.parse(location?.state?.selectedItem)?.allowedPermissions || [];
  } else {
    window.location.href = "/";
  }

  useEffect(() => {
    const { loginName } = UserAuthContext.state;
    if (loginName) {
      fetchUserDetailsByLogin(loginName, fetchUserDetails);
    }
  }, []);

  const fetchUserDetails = (userList) => {
    setUserId(userList.userId);
  };

  const setSPListHandler = (spList) => {
    if (spList && spList.length && spList.length > 0) {
      setLoaderState(false);
      setSPList(spList);
    } else {
      setLoaderState(false);
      nofifyHandler("Unable to load the Service Provider", "danger");
    }
  };

  const getSPListHandler = (uId) => {
    fetchServiceProvidersById(uId, setSPListHandler);
  };

  useEffect(() => {
    if (
      userId &&
      (allowedPermissions.includes("view") ||
        allowedPermissions.includes("modify") ||
        allowedPermissions.includes("delete"))
    ) {
      getSPListHandler(userId);
    }
  }, [userId]);

  const createModifySPHandler = (spId) => {
    const orgItemObj = getSPItem(spId);
    setSPDetails({
      spName: orgItemObj?.serviceProviderName,
      email: orgItemObj?.email,
      copyrightClause: orgItemObj?.copyrightClause,
      poweredBy: orgItemObj?.poweredBy,
      primaryColor: orgItemObj?.primaryColor || "",
      secondaryColor: orgItemObj?.secondaryColor || "",
      versionNo: orgItemObj?.versionNo || "",
    });
    setSPItem(orgItemObj);
    setEditSP(true);
    setSPDialog(true);
  };

  const handleCloseOrgDialog = () => {
    setSPDialog(false);
    setSPDetails({
      spName: "",
      email: "",
      copyrightClause: "",
      poweredBy: "",
      primaryColor: "",
      secondaryColor: "",
      versionNo: "",
    });
  };

  const createNewSPHandler = () => {
    setEditSP(false);
    setSPDialog(true);
  };

  const themeColorUpdate = () => {
    let sessionStorageSpData = JSON.parse(sessionStorage.getItem("spDetails"));
    let localStorageSpData = JSON.parse(localStorage.getItem("spDetails"));
    sessionStorageSpData = {
      ...sessionStorageSpData,
      primaryColor: spDetails?.primaryColor,
      secondaryColor: spDetails?.secondaryColor,
    };
    localStorageSpData = {
      ...localStorageSpData,
      primaryColor: spDetails?.primaryColor,
      secondaryColor: spDetails?.secondaryColor,
    };

    sessionStorage.setItem(
      "spDetails",
      JSON.stringify(sessionStorageSpData || {})
    );
    localStorage.setItem("spDetails", JSON.stringify(localStorageSpData || {}));
    window.location.reload(true);
  };

  function extractBase64Data(base64String) {
    // Split the string on comma to separate the MIME type part and the base64 data part
    const parts = base64String.split(",");
    // Return the part after the comma, which is the actual base64-encoded data
    if (parts.length > 1) {
      return parts[1];
    } else {
      return parts[0];
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (spDetails.spName.trim() === "") {
      setFieldError(true);
      return false;
    } else {
      setFieldError(false);
    }

    if (validateEmail(spDetails.email) && !fieldError) {
      let orgParams = spDetails;
      orgParams = isEditSP
        ? { ...orgParams, serviceProviderId: spItem.serviceProviderId }
        : orgParams;
      // setLoaderState(true);
      // createModifyServiceProvider(orgParams, isEditSP, orgCallback);
      let splistData = spList[0];
      splistData.logoContent = spList[0]?.logoContent
        ? extractBase64Data(spList[0]?.logoContent)
        : spList[0]?.logoContent;
      splistData.favicon = spList[0]?.favicon
        ? extractBase64Data(spList[0]?.favicon)
        : spList[0]?.favicon;
      await modifyServiceProvider(
        { ...splistData, ...spDetails },
        (error, msg) => {
          nofifyHandler(msg, error ? "danger" : "success");
          themeColorUpdate();
          // fetchServiceProvidersById(userId, (res = []) => {
          //   sessionStorage.setItem('spDetails', JSON.stringify(res[0] || {}))
          //   localStorage.setItem('spDetails', JSON.stringify(res[0] || {}))
          // });
          // setTimeout(()=>{
          //   window.location.reload(true);
          // },1000)
        }
      );
      if (imageFile) {
        const file = new FormData();
        file.append("serviceProviderId", spItem.serviceProviderId);
        file.append("logoContent", imageFile);
        uploadImage(file, "logo", spItem.serviceProviderId, (res, error) => {
          nofifyHandler(res, error ? "danger" : "success");
        });
      }
      if (favImageFile) {
        const file = new FormData();
        file.append("serviceProviderId", spItem.serviceProviderId);
        file.append("logoContent", favImageFile);
        uploadImage(file, "favicon", spItem.serviceProviderId, (res, error) => {
          nofifyHandler(res, error ? "danger" : "success");
        });
      }
    } else {
      console.log("=====>Error");
    }
  };

  const deleteOrgnizationHandler = () => {
    const orgParam = {
      organisationId: spItem.organisationId,
    };
    // deleteServiceProvider(spItem.organisationId, deleteConfirm);
  };

  const deleteConfirm = (response) => {
    //window.location.reload();
    if (response) {
      handleCloseOrgDialog();
      nofifyHandler("Organisation deleted successfully");
      getOrgListHandler(userId);
    } else {
      handleCloseOrgDialog();
      nofifyHandler(
        "Unable to delete, please contact sales@3cortex.com !",
        "danger"
      );
    }
  };

  const orgCallback = (bool) => {
    setLoaderState(false);
    if (bool) {
      handleCloseOrgDialog();
      //window.location.reload();
      getOrgListHandler(userId);
      nofifyHandler(
        `Organisation ${isEditSP ? "updated" : "created"} Successfully`
      );
    } else {
      handleCloseOrgDialog();
      nofifyHandler(
        `Unable to ${
          isEditSP ? "update" : "create"
        } Organisation, please contact sales@3cortex.com !`,
        "danger"
      );
    }
  };

  const validateEmail = (val = "") => {
    if (val === null || val.trim() === "") {
      return true;
    }
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
    const result = pattern.test(val);
    if (result === true) {
      setEmailError(false);
      return true;
    } else {
      setEmailError(true);
      return false;
    }
  };

  const getSPItem = (idVal) => {
    const getItem = spList.filter((item) => item.serviceProviderId === idVal);
    return getItem[0];
  };

  const onFileChange = (e) => {
    let imgLimitWidth = 100;
    let imgLimitHeight = 100;
    let whFlag, sizeFlag;
    if (e.target.name === "logo") {
      whFlag = false;
      sizeFlag = true;
    }
    if (e.target.name === "favicon") {
      whFlag = true;
      sizeFlag = true;
    }

    imageUpload(
      e,
      imgUploadCallback,
      imgLimitWidth,
      imgLimitHeight,
      whFlag,
      sizeFlag
    );
    // e.target.value = "";
  };

  //Callback Handler for Upload API
  const imgUploadCallback = (isSuccess, data, file, eleName) => {
    if (isSuccess) {
      if (eleName === "logo") {
        setLogoImage(data);
        setImageFile(file);
        spList[0].logoContent = data;
        setSPList(spList);
      } else if (eleName === "favicon") {
        setFavImage(data);
        setFavImageFile(file);
        spList[0].favicon = data;
        setSPList(spList);
      }
    } else {
      if (eleName === "logo") {
        setLogoImage("");
        setImageError(data);
      } else if (eleName === "favicon") {
        setFavImage("");
        setFavImageError(data);
      }
    }
  };

  //Set Image path for preview
  useEffect(() => {
    if (logoImage !== "") {
      setLogoImage(logoImage);
    }
  }, [logoImage]);

  useEffect(() => {
    if (favImage !== "") {
      setFavImage(favImage);
    }
  }, [favImage]);

  //Clearing Old values when edit window open
  useEffect(() => {
    if (openSPDialog) {
      setLogoImage("");
      setFavImage("");
      setImageError("");
      setFavImageError("");
      if (isEditSP) {
        if (
          (spItem.serviceProviderName &&
            spItem.serviceProviderName.length !== "") ||
          (spItem.email && spItem.email !== "") ||
          validateEmail(spDetails.email)
        ) {
          setFieldError(false);
        }
      }
    }
  }, [openSPDialog]);

  const nofifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const handleCloseNotification = () => {
    setNotificationObj({
      ...notificationParams,
      open: false,
    });
  };

  const setSPFormData = (e) => {
    setSPDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePrimaryColor = (color) => {
    setSPDetails({ ...spDetails, primaryColor: color.hex });
  };

  const handleSecondaryColor = (color) => {
    setSPDetails({ ...spDetails, secondaryColor: color.hex });
  };

  const removeIcon = async (type) => {
    if (type === "logo") {
      const file = new FormData();
      file.append("serviceProviderId", spItem.serviceProviderId);
      file.append("logoContent", " ");
      uploadImage(file, "logo", spItem.serviceProviderId, (res, error) => {
        nofifyHandler(res, error ? "danger" : "success");
        setSPItem({ ...spItem, logoContent: "" });
        setFavImage();
        spList[0].logoContent = "";
        setSPList(spList);
      });
    }
    if (type === "fav") {
      const file = new FormData();
      file.append("serviceProviderId", spItem.serviceProviderId);
      file.append("logoContent", " ");
      uploadImage(file, "favicon", spItem.serviceProviderId, (res, error) => {
        nofifyHandler(res, error ? "danger" : "success");
        setSPItem({ ...spItem, favicon: "" });
        setLogoImage("");
        spList[0].favicon = "";
        setSPList(spList);
      });
    }
  };

  const classes = useStyles();
  return (
    <div style={{ margin: '0 -18px' }}>
      {allowedPermissions.includes("add") && (
        <GridContainer>
          <GridItem
            xs={12}
            sm={6}
            md={4}
            lg={3}
            style={{ cursor: "pointer" }}
            onClick={createNewSPHandler}
          >
            <Card headerCard>
              <CardHeader color="primary" stats icon>
                <CardIcon color="white" style={{ float: "left" }}>
                  <Delete />
                </CardIcon>
                <p className={classes.cardCategory}></p>
                <h3 className={classes.cardTitle}>Create Service Provider</h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      {allowedPermissions.includes("view") ||
      allowedPermissions.includes("modify") ||
      allowedPermissions.includes("delete") ? (
        <>
          <GridContainer>
            {spList.length > 0 ? (
              spList.map((spItem, index) => (
                <GridItem
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    createModifySPHandler(spItem.serviceProviderId)
                  }
                >
                  <Card>
                    <CardHeader color="primary" stats icon>
                      {spItem.logoContent ? (
                        <img
                          src={
                            "data:" +
                            spItem.logoimgType +
                            ";base64," +
                            spItem.logoContent
                          }
                          width="66px"
                          className={classes.logoImage}
                        />
                      ) : (
                        <CardIcon color="primary">
                          <DeviceHub />
                        </CardIcon>
                      )}
                      <p className={classes.cardCategory}>
                        Service Provider Id: {spItem.serviceProviderId}
                      </p>
                      <h3 className={classes.cardTitle}>
                        {spItem.serviceProviderName}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <EditRounded />
                        View/ Modify Details...
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              ))
            ) : (
              <Card style={{ width: 350 }}>
                <CardHeader color="primary" stats icon>
                  <CardIcon
                    color="primary"
                    style={{ background: "transparent" }}
                  >
                    <Skeleton variant="circular" width={40} height={40} />
                  </CardIcon>

                  <p className={classes.cardCategory}>
                    <Skeleton animation="wave" />
                  </p>
                  <h3 className={classes.cardTitle}>
                    <Skeleton animation="wave" width={50} />
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Skeleton animation="wave" width={150} />
                  </div>
                </CardFooter>
              </Card>
            )}
            {loaderState && (
              <GridItem xs={12} sm={12} md={12}>
                <CircularProgress className={classes.loaderImg} />
              </GridItem>
            )}
          </GridContainer>
          <Dialog
            // fullScreen={fullScreen}
            open={openSPDialog}
            onClose={handleCloseOrgDialog}
            aria-labelledby="draggable-dialog-title"
            className={classes.dialogPaper}
            // aria-labelledby="responsive-dialog-title"
          >
            {/* <DialogTitle  id="responsive-dialog-title">Follow Up Email</DialogTitle> */}
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              {/* {dialogTitle} */}

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCloseOrgDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogWindow}>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                      <CardHeader color="primary" plain>
                        <h4
                          className={
                            classes.cardTitleWhite + " modal-title-flag"
                          }
                        >
                          {isEditSP ? (
                            <BorderColorOutlined
                              className={classes.iconSpacing}
                            />
                          ) : (
                            <AddBusinessOutlined
                              className={classes.iconSpacing}
                            />
                          )}
                          {isEditSP ? "Edit" : "Create"} Service Provider
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <Box>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              {fieldError && (
                                <p className={classes.errorMessgage}>
                                  Please enter Required fields
                                </p>
                              )}
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  required
                                  id="outlined-required"
                                  label="Service Provider Name"
                                  variant="outlined"
                                  size="large"
                                  name="spName"
                                  value={spDetails.spName}
                                  onChange={setSPFormData}
                                />
                              </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  id="outlined-required"
                                  variant="outlined"
                                  label="Email"
                                  size="large"
                                  value={spDetails.email}
                                  name="email"
                                  onChange={setSPFormData}
                                />
                                {emailError && (
                                  <p className={classes.errorMessgage}>
                                    Please enter valid email
                                  </p>
                                )}
                              </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  required
                                  id="outlined-required"
                                  label="Copyright Clause"
                                  variant="outlined"
                                  size="large"
                                  value={spDetails.copyrightClause}
                                  name="copyrightClause"
                                  onChange={setSPFormData}
                                />
                              </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  required
                                  id="outlined-required"
                                  label="Powered By"
                                  variant="outlined"
                                  size="large"
                                  name="poweredBy"
                                  value={spDetails.poweredBy}
                                  onChange={setSPFormData}
                                />
                              </FormControl>
                            </GridItem>

                            {allowedPermissions.includes(
                              "upload service provider logo"
                            ) && (
                              <GridItem xs={12} sm={12} md={6}>
                                <InputLabel style={{ paddingLeft: 10 }}>
                                  Logo{" "}
                                  <PopoverComponent
                                    wrapperWidth={400}
                                    wrapperHeight={300}
                                    className={classes.popoverWrap}
                                    imgSrc={PopoverLogoImage}
                                    imgWidth={450}
                                    msgTxt="Recommended size for logo is width : 180 and Height: 56 and image should be no larger than 1MB"
                                  />
                                  {isEditSP && spItem.logoContent && (
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        top: "6px",
                                      }}
                                      onClick={() => removeIcon("logo")}
                                    >
                                      <DeleteIcon />
                                    </span>
                                  )}
                                </InputLabel>
                                <FormControl
                                  fullWidth
                                  sx={{ m: 1 }}
                                  variant="filled"
                                  className={classes.uploadField}
                                >
                                  <TextField
                                    id="file"
                                    type="file"
                                    name="logo"
                                    variant="outlined"
                                    inputProps={{ accept: "image/*" }}
                                    onChange={(event) => onFileChange(event)}
                                  />

                                  <div className={classes.logoimagePreview}>
                                    {
                                      <img
                                        style={{
                                          opacity:
                                            spItem.logoContent || logoImage
                                              ? 1
                                              : 0,
                                        }}
                                        src={
                                          isEditSP
                                            ? logoImage ||
                                              "data:" +
                                                spItem.logoimgType +
                                                ";base64," +
                                                spItem.logoContent
                                            : logoImage
                                        }
                                      />
                                    }
                                  </div>
                                  {!logoImage && imageError && (
                                    <p className={classes.errorMessgage} style={{marginTop: '5px'}}>
                                      {imageError}
                                    </p>
                                  )}
                                </FormControl>
                              </GridItem>
                            )}

                            {allowedPermissions.includes("upload favicon") && (
                              <GridItem xs={12} sm={12} md={6}>
                                <InputLabel style={{ paddingLeft: 10 }}>
                                  Fav Icon{" "}
                                  <PopoverComponent
                                    wrapperWidth={400}
                                    wrapperHeight={300}
                                    className={classes.popoverWrap}
                                    imgSrc={PopoverfaviconImage}
                                    msgTxt="Width and Height should be 100 x 100 and image should be no larger than 1MB."
                                    imgWidth={550}
                                  />
                                  {isEditSP && spItem.favicon && (
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        top: "6px",
                                      }}
                                      onClick={() => removeIcon("fav")}
                                    >
                                      <DeleteIcon />
                                    </span>
                                  )}
                                </InputLabel>
                                <FormControl
                                  fullWidth
                                  sx={{ m: 1 }}
                                  variant="filled"
                                  className={classes.uploadField}
                                >
                                  <TextField
                                    id="favicon"
                                    type="file"
                                    name="favicon"
                                    variant="outlined"
                                    inputProps={{ accept: "image/*" }}
                                    onChange={(event) => onFileChange(event)}
                                  />

                                  <div className={classes.imagePreview}>
                                    {
                                      <img
                                        style={{
                                          opacity:
                                            spItem.favicon || favImage ? 1 : 0,
                                        }}
                                        src={
                                          isEditSP
                                            ? favImage ||
                                              "data:" +
                                                spItem.faviconimgType +
                                                ";base64," +
                                                spItem.favicon
                                            : favImage
                                        }
                                      />
                                    }
                                  </div>
                                  {!favImage && favImageError && (
                                    <p className={classes.errorMessgage} style={{marginTop: '5px'}}>
                                      {favImageError}
                                    </p>
                                  )}
                                </FormControl>
                              </GridItem>
                            )}

                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              style={{ marginTop: 10 }}
                            >
                              <InputLabel style={{ paddingLeft: 10 }}>
                                Primary Color
                              </InputLabel>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <ColorPicker
                                  colorVal={spDetails.primaryColor}
                                  onChangeComplete={handlePrimaryColor}
                                />
                              </FormControl>
                            </GridItem>

                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              style={{ marginTop: 10 }}
                            >
                              <InputLabel style={{ paddingLeft: 10 }}>
                                Secondary Color
                              </InputLabel>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <ColorPicker
                                  colorVal={spDetails.secondaryColor}
                                  onChangeComplete={handleSecondaryColor}
                                />
                              </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                              >
                                <TextField
                                  type="number"
                                  required
                                  id="outlined-required"
                                  label="Version Number"
                                  variant="outlined"
                                  size="large"
                                  name="versionNo"
                                  value={spDetails.versionNo}
                                  onChange={setSPFormData}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                        </Box>
                      </CardBody>
                      <CardFooter>
                        <Grid container justifyContent={"center"} spacing={1}>
                          {(isEditSP &&
                            allowedPermissions.includes("modify")) ||
                          (!isEditSP && allowedPermissions.includes("add")) ? (
                            <GridItem style={{ marginLeft: "-10px" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{ xs: { padding: "10px 25px" } }}
                              >
                                SUBMIT
                              </Button>
                            </GridItem>
                          ) : null}
                          {allowedPermissions.includes("delete") && isEditSP && (
                            <GridItem style={{ marginLeft: "-10px" }}>
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.deletBtn}
                                onClick={deleteOrgnizationHandler}
                                sx={{ xs: { padding: "10px 25px" } }}
                              >
                                DELETE
                              </Button>
                            </GridItem>
                          )}
                        </Grid>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </form>
            </DialogContent>
            {/* <DialogActions></DialogActions> */}
          </Dialog>
        </>
      ) : null}
      {notificationObj && (
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
          autoHideDuration={3000}
        />
      )}
    </div>
  );
};

export default React.memo(ServiceProvider);
