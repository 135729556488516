import { alpha } from '@mui/material/styles';

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // Equivalent of:
          // "@apply inline-flex items-center text-base border border-gray-300 rounded-md px-3 py-2"
          display: 'inline-flex',
          alignItems: 'center',
          fontSize: '1rem', // text-base
          //border: '1px solid #d1d5db', // border-gray-300
          borderRadius: '0.375rem', // .rounded-md
          // paddingTop: '0.5rem', // py-2 = 8px
          // paddingBottom: '0.5rem',
          // paddingLeft: '0.75rem', // px-3 = 12px
          // paddingRight: '0.75rem',

          // Hover => "border-gray-400" (#9ca3af)
          '&:hover': {
            borderColor: '#9ca3af',
          },

          // Disabled => "bg-gray-100 text-gray-400 cursor-not-allowed"
          '&.Mui-disabled': {
            backgroundColor: '#f3f4f6', // bg-gray-100
            color: '#9ca3af', // text-gray-400
            cursor: 'not-allowed',
            '& svg': {
              color: '#9ca3af',
            },
          },
        },
        input: {
          // Text styling + placeholder
          // For "medium" sizing, let's do slightly less padding than the outer root
          fontSize: '1rem', // text-base
          color: '#374151', // text-gray-700
          borderRadius: '0.375rem', // .rounded-md
          paddingTop: '0.5rem', // py-2
          paddingBottom: '0.5rem',
          paddingLeft: '0.75rem', // px-3
          paddingRight: '0.75rem',
          '::placeholder': {
            color: '#9ca3af', // placeholder-gray-400
          },

          // Dense variant override
          '&.MuiOutlinedInput-inputMarginDense': {
            // Something smaller if you want to differentiate “dense”
            // paddingTop: '0.25rem', // py-1
            // paddingBottom: '0.25rem',
            // paddingLeft: '0.5rem', // px-2
            // paddingRight: '0.5rem',
          },
        },
      },
    },

    // For <Select /> specifically
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px', // text-base
          // paddingTop: '0.5rem', // py-2
          // paddingBottom: '0.5rem',
           paddingLeft: '0.75rem', // px-3
          // paddingRight: '0.75rem',
        },
      },
    },

    // Standard (non-outlined) input
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:hover': {
            '&:before': {
              borderBottomColor: `${alpha(theme.palette.primary.main, 0.56)} !important`,
            },
          },
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },

    // Filled input
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f3f4f6', // bg-gray-100
          '&:hover': {
            backgroundColor: '#e5e7eb', // bg-gray-200
          },
          '&.Mui-focused': {
            // ring-1 ring-blue-500 => approximate with boxShadow
            boxShadow: '0 0 0 1px #3b82f6',
          },
          '&.Mui-disabled': {
            backgroundColor: '#f3f4f6',
            cursor: 'not-allowed',
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },

    // Outlined input
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32),
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#9ca3af',
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#3b82f6',
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },

    // Menu item style for <Select /> dropdown items
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px', // text-base
          '&.Mui-selected': {
            backgroundColor: 'rgba(107,114,128,0.2)', // ~ bg-gray-500/20
            '&:hover, &:focus, &:active': {
              backgroundColor: 'rgba(107,114,128,0.2)',
            },
          },
        },
      },
    },

    // Label styling
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            fontSize: '1rem', // text-base
            color: '#7a7a7b', // text-gray-700
          },
        },
      },
    },
  };
}
