import { Delete, DeviceHub, EditRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import GridViewIcon from "@mui/icons-material/GridView";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { ButtonGroup, Menu, MenuItem, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import SAYASearchBox from "components/CustomSearchBox/CustomSearchBox";
import SAYAMUIDataTable from "components/Table/MuiDataTable";
import {
  deleteDataSet,
  fetchDataSets,
} from "../../action-handlers/DataSetHandler";
import Card from "../../components/Card/Card";
import CardFooter from "../../components/Card/CardFooter";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import ConfirmationOverlay from "../../components/ConfirmOverlay/ConfirmationOverlay";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Snackbar from "../../components/Snackbar/Snackbar";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { notificationParams } from "../../variables/general";
const styles = (theme) => ({
  cardCategoryWhite: {
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },

  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  stats: {
    "& svg": {
      marginLeft: "3px",
      marginRight: "3px",
      top: "0px",
    },
    display: "inline-flex",
    fontSize: "12px",
  },
  dialogWindow: {
    minWidth: "auto",
  },
  errorMessgage: {
    fontSize: 12,
    color: theme.palette.error.main,
    margin: "0px 0px 10px 0",
  },
  cardTitle: {
    color: theme.palette.text.primary,
  },
  imagePreview: {
    width: 100,
    "& img": {
      width: "100%",
    },
  },
  loaderImg: {
    position: "relative",
    left: "48%",
    top: 100,
  },
  logoImage: {
    float: "left",
    border: "1px solid " + theme.palette.grey[300],
  },
  iconSpacing: {
    marginRight: "8px",
    position: "relative",
    top: "5px",
  },
});

const useStyles = makeStyles(styles);

export default function DataSetHome() {
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const organisationId = parseInt(UserAuthContext.state.clientOrgId);

  const [dataSets, setDataSets] = useState([]);
  const [dataSetId, setDataSetId] = useState("");

  const [loaderState, setLoaderState] = useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [showConfirmOverlay, setConfirmOverlay] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let itemId = useRef({ deleteId: "" });

  const location = useLocation();
  const navigate = useNavigate();

  // const allowedPermissions =
  //   JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];

  const selectedItem =
    location?.state?.selectedItem || sessionStorage.getItem("selectedItem");


  let allowedPermissions = [];


  if (selectedItem && selectedItem !== "undefined") {
    allowedPermissions = JSON.parse(selectedItem)?.allowedPermissions || [];
  }

  const fetchDataSetsHandler = (organisationId) => {
    fetchDataSets(organisationId, fetchCallback);
  };

  const fetchCallback = (dataSets) => {
    if (dataSets && dataSets.length && dataSets.length > 0) {
      setLoaderState(false);
      setDataSets(dataSets);
    } else {
      setLoaderState(false);
      notifyHandler("Unable to load the data sets", "danger");
    }
  };

  useEffect(() => {
    if (
      organisationId &&
      (allowedPermissions.includes("view") ||
        allowedPermissions.includes("modify") ||
        allowedPermissions.includes("delete"))
    ) {
      fetchDataSetsHandler(organisationId);
    }
  }, [organisationId]);

  const createDataSetHandler = () => {
    navigate("/saya-platform/admin/data-sets/new", {
      state: {
        entity: {},
        selectedItem:
          location?.state?.selectedItem || sessionStorage.selectedItem,
      },
    });
  };

  const editDataSetHandler = (dataSetId) => {
    const dataSet = fetchDataSet(dataSetId);
    setDataSetId(dataSetId);

    navigate(`/saya-platform/admin/data-sets/${dataSetId}`, {
      state: {
        entity: dataSet,
        selectedItem:
          location?.state?.selectedItem || sessionStorage.selectedItem,
      },
    });
  };

  const deleteDataSetHandler = (dataSetId) => {
    itemId.current.deleteId = dataSetId;
    openOverlay(true);
  };

  const deleteOverlayHandler = () => {
    deleteDataSet(itemId.current.deleteId, organisationId, deleteConfirm);
  };

  const deleteConfirm = (response) => {
    if (response) {
      notifyHandler("Data set deleted successfully");
      closeOverlay();
      fetchDataSetsHandler(organisationId);
    } else {
      notifyHandler(
        "Unable to delete data set, please contact support!",
        "danger"
      );
    }
  };

  const fetchDataSet = (dataSetId) => {
    const getItem = dataSets.filter((item) => item.dataSetId === dataSetId);
    return getItem[0];
  };

  const notifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const handleCloseNotification = () => {
    setNotificationObj({
      ...notificationParams,
      open: false,
    });
  };

  const openOverlay = () => {
    setConfirmOverlay(true);
  };

  const closeOverlay = () => {
    setConfirmOverlay(false);
  };

  const [showTableView, setShowTableView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (viewType) => {
    setShowTableView(viewType === "table");
  };

  const open = Boolean(anchorEl);

  const handleRowActionsClick = (event, dataSetId) => {
    setAnchorEl(event.currentTarget);
    setDataSetId(dataSetId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div style={{ margin: '0 -18px' }}>
      <GridContainer style={{ marginBottom: "15px" }}>
        {allowedPermissions.includes("add") && (
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Button onClick={createDataSetHandler} variant="contained">
              Create Data Set
            </Button>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!showTableView ? (
              <div style={{ paddingRight: "10px" }}>
                <SAYASearchBox
                  searchText={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            ) : null}
            <ButtonGroup>
              <Button
                variant={showTableView ? "outlined" : "contained"}
                onClick={() => handleButtonClick("card")}
              >
                <Tooltip title="Card View">
                  <GridViewIcon />
                </Tooltip>
              </Button>

              <Button
                variant={showTableView ? "contained" : "outlined"}
                onClick={() => handleButtonClick("table")}
              >
                <Tooltip title="Table View">
                  <TableRowsIcon />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </div>
        </GridItem>
      </GridContainer>
      {showTableView ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <SAYAMUIDataTable
              title="Data Sets"
              data={dataSets}
              options={{
                selectableRows: "none",
                filter: true,
                viewColumns: true,
                print: true,
                download: true,
              }}
              columns={[
                { name: "dataSetId", label: "Data Set Id" },
                { name: "shortName", label: "Data Set Name" },
                { name: "longName", label: "Data Set Description" },
                {
                  name: "dataFormats",
                  label: "Data Formats",
                  options: {
                    customBodyRender: (item) =>
                      item.map((v) => v.value).join(", "),
                  },
                },
                ...(allowedPermissions.includes("modify") ||
                  allowedPermissions.includes("delete")
                  ? [
                    {
                      name: "actions",
                      label: "Actions",
                      options: {
                        sort: false,
                        filter: false,
                        customBodyRender: (value, tableMeta) =>
                          allowedPermissions.includes("view") ||
                            allowedPermissions.includes("modify") ||
                            allowedPermissions.includes("delete") ? (
                            <div>
                              <IconButton
                                id="basic-button"
                                aria-controls={
                                  open ? "basic-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  handleRowActionsClick(
                                    e,
                                    tableMeta.rowData[0]
                                  )
                                }
                              >
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                {allowedPermissions.includes("modify") && (
                                  <MenuItem
                                    onClick={() => {
                                      console.log(
                                        "On click table meta",
                                        tableMeta
                                      );
                                      editDataSetHandler(dataSetId);
                                      handleClose();
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                )}
                                {allowedPermissions.includes("delete") && (
                                  <MenuItem
                                    className={classes.deletBtn}
                                    onClick={() => {
                                      handleClose();
                                      deleteDataSetHandler(dataSetId);
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          ) : null,
                      },
                    },
                  ]
                  : []),
              ]}
            />
          </GridItem>
        </GridContainer>
      ) : (
        <>
          {allowedPermissions.includes("view") ||
            allowedPermissions.includes("modify") ||
            allowedPermissions.includes("delete") ? (
            <>
              <GridContainer>
                {dataSets && dataSets.length > 0 ? (
                  dataSets
                    .filter(
                      (dataSet) =>
                        dataSet.shortName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        String(dataSet.dataSetId)
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                    )
                    .map((dataSet, index) => (
                      <GridItem xs={12} sm={6} md={4} key={index}>
                        <Card>
                          <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                              <DeviceHub />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              #{dataSet.dataSetId}
                            </p>
                            <h3 className={classes.cardTitle}>
                              {dataSet.shortName}
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <IconButton
                                onClick={() => {
                                  editDataSetHandler(dataSet.dataSetId);
                                }}
                                disableElevation
                              >
                                <EditRounded />
                              </IconButton>

                              {allowedPermissions.includes("delete") && (
                                <IconButton
                                  className={classes.deletBtn}
                                  onClick={() =>
                                    deleteDataSetHandler(dataSet.dataSetId)
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))
                ) : (
                  <>
                    {[1, 2, 3].map((index) => (
                      <GridItem xs={12} sm={6} md={4} key={index}>
                        <Card style={{ width: "100%", marginRight: 20 }}>
                          <CardHeader color="primary" stats icon>
                            <CardIcon
                              color="primary"
                              style={{ background: "transparent" }}
                            >
                              <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            </CardIcon>

                            <p className={classes.cardCategory}>
                              <Skeleton
                                animation="wave"
                                style={{ float: "right" }}
                                width={140}
                              />
                            </p>
                            <h3 className={classes.cardTitle}>
                              <Skeleton animation="wave" width={50} />
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <Skeleton animation="wave" width={150} />
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))}
                  </>
                )}
                {loaderState && (
                  <GridItem xs={12} sm={12} md={12}>
                    <CircularProgress className={classes.loaderImg} />
                  </GridItem>
                )}
              </GridContainer>
            </>
          ) : null}
        </>
      )}
      {dataSets.length > 0 &&
        dataSets.filter(
          (dataSet) =>
            dataSet.shortName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            String(dataSet.dataSetId)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        ).length === 0 && (
          <GridItem xs={12} sm={12} md={12}>
            <h4 style={{ textAlign: "center" }}>No results found</h4>
          </GridItem>
        )}
      {notificationObj && (
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
          autoHideDuration={3000}
        />
      )}
      {showConfirmOverlay && (
        <ConfirmationOverlay
          {...{
            isOverlayOpen: showConfirmOverlay,
            closeOverlay,
            handler: deleteOverlayHandler,
            title: "DELETE",
            message: `Are you sure you want to delete? Once you delete you will loss all the data associated to the record.`,
          }}
        />
      )}
    </div>
  );
}
