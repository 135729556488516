import * as React from "react";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import styles from "../../views/ReconXWorkspace/ReconStyles";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReconXContext } from "context/ReconX/ReconXContext";

export default function DaysDatePicker({
  options = [],
  onDateChange,
  showFormObject,
}) {
  const loc = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const period = query.get("period");
  const dashboardPeriod = loc.state && loc.state.selectedPeriod;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const ReconXContextData = React.useContext(ReconXContext);

  const { tenorPeriod } = ReconXContextData.state;
  const targetValue = period ? period : tenorPeriod?.tenorPeriodVal || "P-7D";
  const tpIndex = options.findIndex((option) => option.value === targetValue);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [customDate, setCustomDate] = React.useState(false);
  const [validateDate, setValidateDate] = React.useState(false);
  const [tenorPeriodObj, setTenorPeriod] = React.useState({
    startDate:
      period === "CUSTOM"
        ? dashboardPeriod.startDate
        : tenorPeriod?.startDate || null,
    endDate:
      period === "CUSTOM"
        ? dashboardPeriod.endDate
        : tenorPeriod?.endDate || null,
    tenorPeriodVal: targetValue,
  });
  const [selectedOption, setSelectedOption] = React.useState(options[tpIndex]); // Default selected option
  const open = Boolean(anchorEl);

  // React.useEffect(() => {
  //   if (dashboardPeriod !== null) {
  //     if (period === "CUSTOM") {
  //       setCustomDate(true);
  //       setStartDate(dashboardPeriod.startDate);
  //       setEndDate(dashboardPeriod.endDate);
  //     }
  //   }
  // }, [dashboardPeriod]);

  const handleClickListItem = (event) => {
    if (!showFormObject) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = (event, option) => {
    setSelectedOption(option);
    setStartDate(null);
    setEndDate(null);
    setCustomDate(false);
    setAnchorEl(null);
  };

  const handleCustomMenuItemClick = (event, option) => {
    setCustomDate(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  React.useEffect(() => {
    if (selectedOption) {
      setTenorPeriod(() => ({
        startDate: null,
        endDate: null,
        tenorPeriodVal: selectedOption.value,
      }));
      setCustomDate(false);
    }
  }, [selectedOption]);

  React.useEffect(() => {
    if (startDate && endDate) {
      const startDateFormat = new Date(startDate?.$d);
      const endDateFormat = new Date(endDate?.$d);
      const startDateVal = formatDateToYYYYMMDD(startDateFormat);
      const endDateVal = formatDateToYYYYMMDD(endDateFormat);

      setTenorPeriod(() => ({
        startDate: startDateVal,
        endDate: endDateVal,
        tenorPeriodVal: "CUSTOM",
      }));

      if (startDate < endDate) {
        setAnchorEl(null);
      }
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (
      tenorPeriodObj?.tenorPeriodVal === "CUSTOM" &&
      !(tenorPeriodObj?.startDate <= tenorPeriodObj?.endDate)
    ) {
      setValidateDate(true);
    } else {
      setValidateDate(false);
      onDateChange(tenorPeriodObj);
    }
  }, [
    tenorPeriodObj?.tenorPeriodVal,
    tenorPeriodObj?.startDate,
    tenorPeriodObj?.endDate,
  ]);

  const disablePastDates = (date) => {
    const today = dayjs();
    return date > today;
  };

  return (
    <div className="flex items-start flex-col">
      <label
        className="period-input block text-xs text-gray-700 mb-1"
        style={{ lineHeight: "16px" }}
      >
        Period
      </label>
      <List
        component="nav"
        aria-label="days date"
        className="bg-white border border-gray-300 rounded-md"
        sx={{
          bgcolor: "",
          minWidth: "200px",
          padding: "0",
          width: "100%",
        }}
      >
        <ListItem
          button
          id="date-button"
          aria-haspopup="listbox"
          aria-controls="date-menu"
          aria-label="days date item"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{paddingTop: '5px !important', paddingBottom: '4px !important'}}
        >
          <ListItemIcon sx={{ minWidth: "32px" }}>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText
            primary={""}
            secondary={
              <Typography
                variant="div"
                sx={{ display: "block", fontSize: "13px !important", fontWeight: "400" }}
              >
                {tenorPeriodObj?.tenorPeriodVal === "CUSTOM"
                  ? `${tenorPeriodObj?.startDate} - ${tenorPeriodObj?.endDate}`
                  : selectedOption.label}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Menu
        id="days-date-menu"
        sx={{ minWidth: "200px" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "days-date-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            sx={{ minWidth: "200px" }}
            selected={option.value === selectedOption?.value && !customDate}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option.label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={(event) => handleCustomMenuItemClick(event)}>
          <Typography
            variant="div"
            sx={{ fontSize: "13px", fontWeight: "bold" }}
          >
            Custom{" "}
            {validateDate && (
              <span
                style={{
                  marginLeft: "20px",
                  color: "red",
                  fontWeight: "300",
                }}
              >
                <b style={{ fontWeight: "500" }}>From Date</b> should be
                lessthan <b style={{ fontWeight: "500" }}>To Date</b>
              </span>
            )}
          </Typography>
        </MenuItem>

        {customDate && (
          <Grid
            container
            xs={10}
            spacing={1}
            sx={{ marginLeft: "10px", marginRight: "10px" }}
            className={classes.datePickerGrid}
          >
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="From Date"
                  value={startDate}
                  inputFormat="YYYY/MM/DD"
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  shouldDisableDate={disablePastDates}
                  // slotProps={{
                  //   textField: {
                  //     InputProps: { size: "medium", fullWidth: "100px" },
                  //   },
                  // }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="To Date"
                  value={endDate}
                  inputFormat="YYYY/MM/DD"
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  shouldDisableDate={disablePastDates}
                  // slotProps={{ textField: { InputProps: { size: "medium" } } }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </Menu>
    </div>
  );
}
