import React, { useCallback, useContext, useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer.js";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import Tile from "components/v2/Tile";
import BasicSelect from "components/v2/Select";
import {
  FolderSync,
  FolderCheck,
  Database,
  ChartNoAxesCombined,
} from "lucide-react";
import axios from "axios";
import { Box } from "@mui/material";
import useDebounce from "hooks/useDebounce.js";
import ReconXDashboard from "./ReconXDashboard.js";
import ResolveXDashboard from "./ResolveXDashboard.js";
import AnalyticsDashboard from "./AnalyticsDashboard.js";
import DataXDashboard from "./DataXDashboard.js";
import { getUserData, renderText, showInfo } from "./DashboardUtil.js";
import DaysDatePicker from "components/DaysDatePicker/index.js";
import { API } from "URL.js";
import { ReconResolveAPI } from "URL.js";
import { useNavigate } from "react-router-dom";
import { DATAX } from "URL.js";

export default function Dashboard() {
  const navigate = useNavigate();
  const [opsProcesses, setOPSProcesses] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [selectedOPSProcess, setSelectedOPSProcess] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [isAllBlocked, setAllBlocked] = useState(false);
  const [dashboardData, setDashboardData] = useState([
    {
      type: "ReconX",
      count: 0,
      isActive: false,
      title: "Recon Processes",
      isVisible: false,
      Icon: FolderSync,
      isDisabled: false,
    },
    {
      type: "ResolveX",
      count: 0,
      isActive: false,
      title: "Resolve Processes",
      isVisible: false,
      Icon: FolderCheck,
      isDisabled: false,
    },
    {
      type: "AnalytiX",
      count: 0,
      isActive: false,
      title: "Analytix Insights",
      isVisible: false,
      Icon: ChartNoAxesCombined,
      isDisabled: false,
    },
    {
      type: "DataX",
      count: 0,
      isActive: false,
      title: "Data Sets",
      isVisible: false,
      Icon: Database,
      isDisabled: false,
    },
  ]);
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [selectedSorting, setSelectedSorting] = useState("Descending");

  const period = [
    { label: "All Time", value: "All" },
    { label: "Last 1 Day", value: "P-1D" },
    { label: "Last 7 Days", value: "P-7D" },
    { label: "Last 30 Days", value: "P-30D" },
    { label: "Last 90 Days", value: "P-90D" },
    { label: "Last 180 Days", value: "P-180D" },
    { label: "Last 1 Year", value: "P-1Y" },
  ];

  const sortOptions = [
    { key: "Descending", value: "Descending" },
    { key: "Ascending", value: "Ascending" },
  ];

  const UserAuthContext = useContext(UserAuthDetailsContext);
  const { userId, originalMenuHierarchy = [] } = UserAuthContext.state;
  const { orgId, roleId } = getUserData();
  const activeItem = dashboardData.filter((dd) => dd.isActive)[0];

  const fetchOPSProcesses = async (userId) => {
    try {
      const response = await axios.get(`${API}/reconunits/byuser/${userId}`);
      if (response.status === 200) {
        setOPSProcesses(response.data);
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
    }
  };

  const fetchDatasets = async () => {
    try {
      const response = await axios.get(
        `${DATAX}/data-sets/id-values?organisationId=${orgId}`
      );
      if (response.status === 200) {
        setDataSets(response.data);
      }
    } catch (ex) {
      console.error("Exception in fetchDatasets", ex.message);
    }
  };

  const fetchReconxMetrixSummary = async () => {
    try {
      const response = await axios.get(
        `${API}/recon/metrics/summary?userId=${userId}&organisationId=${orgId}`
      );
      if (response.status === 200) {
        setDashboardData((prevData) =>
          prevData.map((item, index) =>
            index === 0
              ? { ...item, count: response.data?.reconProcessesCount || 0 }
              : item
          )
        );
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
    }
  };

  const fetchResolvexMetrixSummary = async () => {
    try {
      const response = await axios.get(
        `${ReconResolveAPI}/workflows/metrics/summary?userId=${userId}&organisationId=${orgId}`
      );
      if (response.status === 200) {
        setDashboardData((prevData) =>
          prevData.map((item, index) =>
            index === 1
              ? { ...item, count: response.data?.workflowProcessesCount || 0 }
              : item
          )
        );
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
    }
  };

  const fetchAnalytixMetrixSummary = async () => {
    try {
      if (orgId && roleId) {
        const response = await axios.get(
          `${API}/reconanalytix/metrics/summary?roleId=${roleId}&organisationId=${orgId}`
        );
        if (response.status === 200) {
          setDashboardData((prevData) =>
            prevData.map((item, index) =>
              index === 2
                ? { ...item, count: response.data?.insightsCount || 0 }
                : item
            )
          );
        }
      }
    } catch (ex) {
      console.error("Exception in fetchAnalytixMetrixSummary", ex.message);
    }
  };

  const fetchDataxMetrixSummary = async () => {
    try {
      if (userId && roleId) {
        const response = await axios.get(
          `${DATAX}/ingestions/metrics/summary?userId=${userId}&organisationId=${orgId}`
        );
        if (response.status === 200) {
          setDashboardData((prevData) =>
            prevData.map((item, index) =>
              index === 3
                ? { ...item, count: response.data?.dataSetsCount || 0 }
                : item
            )
          );
        }
      }
    } catch (ex) {
      console.error("Exception in fetchDataxMetrixSummary", ex.message);
    }
  };

  useEffect(() => {
    if (userId !== 0) {
      fetchOPSProcesses(userId);
      fetchDatasets();
    }
  }, [userId]);

  useEffect(() => {
    if (opsProcesses.length > 0) {
      if (activeItem && activeItem.type === "ReconX") {
        const reconOPS = opsProcesses
          .filter((ops) => ops.reconEnabled)
          .map((ops) => ops.reconUnitId);
        setSelectedOPSProcess(reconOPS);
      } else if (activeItem && activeItem.type === "ResolveX") {
        const reconOPS = opsProcesses
          .filter((ops) => ops.workflowEnabled)
          .map((ops) => ops.reconUnitId);
        setSelectedOPSProcess(reconOPS);
      }
    }
  }, [activeItem?.type, opsProcesses]);

  useEffect(() => {
    if (dataSets.length > 0) {
      if (activeItem && activeItem.type === "DataX") {
        setSelectedDatasets(dataSets.map((ops) => ops.id));
      }
    }
  }, [activeItem?.type, dataSets]);

  function updateCanShow(menuHierarchy, dashboardData) {
    const specificIdentifiers = ["ReconX", "ResolveX", "AnalytiX", "DataX"];
    const result = {};

    menuHierarchy.forEach((menu) => {
      if (specificIdentifiers.includes(menu.menuIdentifier)) {
        result[menu.menuIdentifier] = menu.allowedPermissions;
      }
    });
    dashboardData.forEach((item) => {
      if (result[item.type]?.includes("view")) {
        item.isVisible = true;
      }
    });

    return dashboardData;
  }

  useEffect(() => {
    if (originalMenuHierarchy.length > 0) {
      const updatedDashboardData = updateCanShow(
        originalMenuHierarchy,
        dashboardData
      );
      const activeItems = updatedDashboardData.filter((di) => di.isVisible);
      if (activeItems.length > 0) {
        activeItems[0].isActive = true;
        setDashboardData(activeItems);
        for (let i = 0; i < activeItems.length; i++) {
          if (activeItems[i].type == "ReconX") {
            fetchReconxMetrixSummary();
          } else if (activeItems[i].type === "ResolveX") {
            fetchResolvexMetrixSummary();
          } else if (activeItems[i].type === "AnalytiX") {
            fetchAnalytixMetrixSummary();
          } else if (activeItems[i].type === "DataX") {
            fetchDataxMetrixSummary();
          }
        }
      } else {
        setDashboardData(updatedDashboardData);
        setAllBlocked(true);
      }
    }
  }, [originalMenuHierarchy.length]);

  const handleOPS = (values) => {
    if (values.length > 0) {
      setSelectedOPSProcess(values.filter((v) => v !== "All"));
    } else {
      setSelectedOPSProcess(values);
    }
  };

  const handleDatasets = (values) => {
    if (values.length > 0) {
      setSelectedDatasets(values.filter((v) => v !== "All"));
    } else {
      setSelectedDatasets(values);
    }
  };

  const handlePeriod = useCallback(
    async (date) => {
      setSelectedPeriod(date);
    },
    [selectedPeriod]
  );

  const handleSorting = (value) => {
    setSelectedSorting(value);
  };

  // const debouncedFetch = useDebounce((userId, opsProcess, period) => {
  //   fetchReconxMetrix(userId, opsProcess, period);
  // }, 500);

  // useEffect(() => {
  //   if (
  //     activeItem &&
  //     activeItem.type === "ReconX" &&
  //     userId !== 0 &&
  //     selectedPeriod
  //   ) {
  //     debouncedFetch(userId, selectedOPSProcess, selectedPeriod);
  //   }
  // }, [activeItem, userId, selectedOPSProcess, selectedPeriod, debouncedFetch]);

  // On click of Resolvex tile

  const handleTileClick = (tileData = {}) => {
    if (tileData?.type !== activeItem?.type) {
      setSelectedOPSProcess([]);
      setSelectedDatasets([]);
      setDashboardData((prevState) =>
        prevState.map((tile) =>
          tile.type === tileData.type
            ? { ...tile, isActive: true }
            : { ...tile, isActive: false }
        )
      );
    }
  };

  const getDropdownData = (type = "") => {
    if (type === "ReconX" || type === "ResolveX") {
      return (
        <BasicSelect
          {...{
            label: "Ops Process",
            name: "Ops Process",
            value: selectedOPSProcess,
            selectData:
              activeItem.type === "ReconX"
                ? opsProcesses.filter((ops) => ops.reconEnabled)
                : activeItem.type === "ResolveX"
                ? opsProcesses.filter((ops) => ops.workflowEnabled)
                : opsProcesses,
            optionLabel: "clientReconName",
            optionValue: "reconUnitId",
            onChange: handleOPS,
            isMulti: true,
          }}
        />
      );
    } else if (type === "DataX") {
      return (
        <BasicSelect
          {...{
            label: "Data Set",
            name: "Dataset",
            value: selectedDatasets,
            selectData: dataSets,
            optionLabel: "value",
            optionValue: "id",
            onChange: handleDatasets,
            isMulti: true,
          }}
        />
      );
    }
    return null;
  };

  const showWarning = (type = "") => {
    return (
      <div className="my-6 grid flex-1 scroll-mt-20 items-start gap-6 md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:gap-6">
        <div className="bg-white  themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out hover:z-30">
          <div className="h-[calc(100%-4rem)] leading-5 mx-auto text-center text-sm items-center  justify-center p-2 md:p-6 pb-8 min-h-96 flex">
            {renderText(type)}
          </div>
        </div>

        <div className="bg-white themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out">
          <div className="h-[calc(100%-4rem)] text-sm mx-auto leading-5 text-center  items-center justify-center  p-2 md:p-6 pb-8 min-h-96 flex">
            {renderText(type)}
          </div>
        </div>
      </div>
    );
  };

  const showDashboardData = (type = "") => {
    if (type === "ReconX") {
      if (selectedOPSProcess.length > 0) {
        return (
          <ReconXDashboard
            {...{
              userId,
              orgId,
              selectedOPSProcess,
              selectedPeriod,
              selectedSorting,
            }}
          />
        );
      } else {
        return showWarning(type);
      }
    } else if (type === "ResolveX") {
      if (selectedOPSProcess.length > 0) {
        return (
          <ResolveXDashboard
            {...{
              userId,
              orgId,
              selectedOPSProcess,
              selectedPeriod,
              selectedSorting,
            }}
          />
        );
      } else {
        return showWarning(type);
      }
    } else if (type === "AnalytiX") {
      return <AnalyticsDashboard {...{ userId, orgId, roleId }} />;
    } else if (type === "DataX") {
      if (selectedDatasets.length > 0) {
        return (
          <DataXDashboard
            {...{
              userId,
              orgId,
              selectedDatasets,
              selectedPeriod,
              selectedSorting,
            }}
          />
        );
      } else {
        return showWarning(type);
      }
    }
  };

  if (isAllBlocked) {
    return showInfo(
      "No applications are available. Please contact your administrator for access."
    );
  }

  return (
    <Box sx={{ paddingTop: "10px" }}>
      <div className="bg-gradient-to-b pb-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {dashboardData.map((item, index) => {
            if (item.isVisible) {
              return <Tile {...{ item, click: handleTileClick }} key={index} />;
            }
            return null;
          })}
        </div>
      </div>

      {activeItem?.type !== "AnalytiX" && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 relative z-10">
          <div className="space-y-2">{getDropdownData(activeItem?.type)}</div>

          <div className="space-y-2 period-date-picker">
            <DaysDatePicker
              onDateChange={handlePeriod}
              showFormObject={false}
              tenorPeriod={selectedPeriod.tenorPeriodVal}
              options={period}
            />
          </div>
        </div>
      )}

      <div className="my-6">{showDashboardData(activeItem?.type)}</div>
    </Box>
  );
}
