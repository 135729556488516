import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  filterActive: {
    backgroundColor: theme.palette.background.card,
    borderRadius: "5px",
    color: theme.palette.primary.main,
  },
  muiTableWrapper: {
    "& thead": {
      borderTop: "1px solid #e4e4e7",

      "& th": {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        backgroundColor: '#f9fafb !important',
      },
      "& th > span": {
        marginTop: "3px !important",
        marginBottom: "3px !important",
      },
    },
    "& tbody": {
      borderTop: "1px solid #e4e4e7",
    },
    "& .MuiToolbar-root": {
      padding: "0 10px",
      minHeight: "44px !important",
      borderRadius: '6px',
      "& .MuiTypography-root": {
        textTransform: "capitalize",
        paddingLeft: "10px",
        fontWeight: "600",
        fontSize: "14px",
      },
    },
    "& .MuiTablePagination-root": {
      borderRadius: '6px',
      "& .MuiToolbar-root": {
        padding: "0 10px",
        minHeight: "32px !important",
        borderRadius: '6px',
      },
    },
    "& .MuiToolbar-root .MuiButtonBase-root": {
      padding: "0.3rem",
      borderRadius: "4px",
      border: "1px solid #e4e4e7",
      marginLeft: "10px",
      "& svg": {
        width: "18px",
        height: "18px",
      },
      "&:hover": {
        backgroundColor: "#f4f4f5",
      },
    },
    "& td.MuiTableCell-root": {
      borderColor: theme.palette.border.secondary,
      padding: "8px 10px",
      whiteSpace: "nowrap",
    },
    "& th.MuiTableCell-root": {
      borderColor: theme.palette.border.primary,
      backgroundColor: theme.palette.common.bgShade,
    },
    "& th.MuiTableCell-root, td.MuiTableCell-root, .MuiTypography-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiPaper-root": {
      background: theme.palette.background.card,
      border: '1px solid #e4e4e7',
      borderRadius: '6px',
    },
    "& tr.active td": {
      backgroundColor: "rgba(145, 158, 171, 0.08) !important",
    },
  },
}));

export default function SAYAMUIDataTable(props) {
  const { title, columns, data, options, filterActive } = props;
  const classes = useStyles();
  const activeRowIndicesRef = useRef([]);
  const tableWrapperRef = useRef(null); // Ref to the table wrapper

  const components = {
    icons: {
      FilterIcon: function (props) {
        if (filterActive)
          return <FilterListIcon className={classes.filterActive} />;
        return <FilterListIcon />;
      },
    },
  };

  const applyActiveClasses = () => {
    if (!tableWrapperRef.current) return;

    const rows = tableWrapperRef.current.querySelectorAll("table tbody tr");
    rows.forEach((row) => {
      const rowIndex = parseInt(row.dataset.index, 10);
      if (activeRowIndicesRef.current.includes(rowIndex)) {
        row.classList.add("active");
      } else {
        row.classList.remove("active");
      }
    });
  };

  useEffect(() => {
    if (!tableWrapperRef.current) return;

    const tableWrapper = tableWrapperRef.current;
    const clickHandler = function () {
      const rowIndex = parseInt(this.dataset.index, 10);
      if (isNaN(rowIndex)) return;

      // Set the clicked row as active
      activeRowIndicesRef.current = [rowIndex];

      // Apply classes to reflect this change
      applyActiveClasses();
    };

    const reattachEventListenersAndIndices = () => {
      const rows = tableWrapper.querySelectorAll("table tbody tr");
      rows.forEach((row, i) => {
        // Assign data-index to keep track of row index in the current view
        row.dataset.index = i;

        // Remove old events to avoid duplication
        row.removeEventListener("click", clickHandler);

        // Add single-click event to set active class
        row.addEventListener("click", clickHandler);
      });
    };

    // Use a MutationObserver to detect when the table body updates
    const tableBody = tableWrapper.querySelector("table tbody");
    const observer = new MutationObserver(() => {
      reattachEventListenersAndIndices();
      applyActiveClasses();
    });

    if (tableBody) {
      observer.observe(tableBody, { childList: true, subtree: true });
    }

    // Initial setup
    reattachEventListenersAndIndices();
    applyActiveClasses();

    return () => {
      observer.disconnect();
      const rows = tableWrapper.querySelectorAll("table tbody tr");
      rows.forEach((row) => {
        row.removeEventListener("click", clickHandler);
      });
    };
  }, [data]);

  return (
    <div className={classes.muiTableWrapper} ref={tableWrapperRef}>
      <MUIDataTable
        title={title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}
        columns={columns}
        data={data}
        options={options}
        components={components}
      />
    </div>
  );
}

SAYAMUIDataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  options: PropTypes.object,
  components: PropTypes.object,
};
