import { rankWith } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";
import axios from "axios";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";
import BasicSelect from "components/v2/Select";
import { useEffect, useState } from "react";
import { getUserData } from "views/Dashboard/DashboardUtil";

/**
 * HttpApiRenderer is a custom JSON Forms renderer that fetches options from an API
 * and renders them as a dropdown using SAYAComboBox or BasicSelect components.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.schema - The JSON schema for the form.
 * @param {Object} props.uischema - The UI schema for the form.
 * @param {string} props.path - The path to the data in the JSON schema.
 * @param {Function} props.handleChange - The function to call when the value changes.
 * @returns {JSX.Element} The rendered component.
 */
const HttpApiRenderer = (props) => {
  const [enumOptions, setEnumOptions] = useState([]);
  const [selectedSingleValue, setSelectedSingleValue] = useState("");
  const [selectedMultipleValues, setSelectedMultipleValues] = useState([]);
  const { schema, uischema, path, handleChange, data, enabled } = props;
  const { orgId: organisationId } = getUserData();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const env = process.env.REACT_APP_ENV;

        const origin =
          window.location.hostname === "localhost"
            ? env === "local" && schema.apiUrl.startsWith("/saya-recon") ? "http://localhost:8083" : "https://sayadev.3cortex.com"
            : window.location.origin;

        let urlWithParams;
        if (schema.apiUrl.indexOf('?') > 0) {
          urlWithParams = `${schema.apiUrl}&organisationId=${organisationId}`;
        }
        else {
          urlWithParams = `${schema.apiUrl}?organisationId=${organisationId}`;
        }

        const url = `${origin}${urlWithParams}`;
        const config = {};
        config["headers"] = {};
        config["headers"]["Content-Type"] = "application/json";

        const response = await axios.get(url, config);

        const enumOptions = [];
        const enumValues = [];
        const enumLabels = [];
        response.data.forEach(item => {
          if (schema.labelProperty && schema.valueProperty) {
            enumOptions.push({ label: item[schema.labelProperty], value: item[schema.valueProperty] });
            enumLabels.push(item[schema.labelProperty]);
            enumValues.push(item[schema.valueProperty]);
          }
          else {
            enumOptions.push({ label: item.value, value: item.id });
            enumLabels.push(item.value);
            enumValues.push(item.id);
          }
        });
        setEnumOptions(enumOptions);
        schema.enum = enumValues;
        schema.enumNames = enumLabels;

      } catch (err) {
        console.error("Failed to fetch options ", err);
      } finally {
      }
    };

    fetchOptions();
  }, [schema.apiUrl]);

  useEffect(() => {
    if (
      Array.isArray(data) &&
      data.length === 0 &&
      uischema.options?.selectAll &&
      enumOptions.length > 0
    ) {
      const allValues = enumOptions.map((opt) => opt.value);
      setSelectedMultipleValues(allValues);
      handleChange(path, allValues);
    }
    else if (Array.isArray(data)) {
      setSelectedMultipleValues(data);
    }
    else {
      setSelectedSingleValue(data);
    }
  }, [data, enumOptions, path, handleChange, uischema.options]);

  const internalSingleValueHandler = (val) => {

    const selectedSingleValue = val;
    setSelectedSingleValue(selectedSingleValue);
    handleChange(path, selectedSingleValue);
  };

  const internalMultiValueHandler = (event) => {
    const selectedMultiValue = event;
    setSelectedMultipleValues(selectedMultiValue);
    handleChange(path, selectedMultiValue);
  };

  return uischema.isMultiSelect && uischema.isMultiSelect === true ? (
    <BasicSelect
      {...{
        label: uischema.label,
        name: uischema.label,
        value: selectedMultipleValues,
        selectData: enumOptions,
        optionLabel: "label",
        optionValue: "value",
        onChange: internalMultiValueHandler,
        isMulti: true,
        shrinkLabel: true,
        isVisibleOptions: uischema?.visibleOptions || false,
        size: "large",
        disabled: !enabled
      }}
    />
  ) : (
    <BasicSelect
      {...{
        label: uischema.label,
        name: uischema.label,
        value: selectedSingleValue,
        selectData: enumOptions,
        optionLabel: "label",
        optionValue: "value",
        onChange: internalSingleValueHandler,
        shrinkLabel: true,
        size: "large",
        disabled: !enabled
      }}
    />
  );
};

export const httpApiTester = rankWith(20, (uischema, schema) => {
  const isDataSourceHttp =
    uischema.dataSourceType && uischema.dataSourceType.endsWith("http");
  return isDataSourceHttp;
});

export default withJsonFormsControlProps(HttpApiRenderer);
