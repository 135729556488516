import React from 'react';
import { SvgIcon } from '@mui/material';

const MuiIcon = ({ icon: Icon, useLucide = false, color = 'primary', size = 'default', ...props }) => {
  if (useLucide) {
    return (
      <Icon
        style={{
          width: size === 'small' ? 16 : size === 'large' ? 32 : 24,
          height: size === 'small' ? 16 : size === 'large' ? 32 : 24,
          stroke: 'currentColor', // Ensures proper color
          fill: 'none',
          strokeWidth: 1.5,
        }}
        color={color}
        {...props}
      />
    );
  }
  return <SvgIcon component={Icon} color={color} fontSize={size} {...props} />;
};

export default MuiIcon;
